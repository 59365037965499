import React, { useState } from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import CompanyProfile from "../shared/profiles/CompanyProfile";
import { useFormContext } from '../context/FormContext';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const FirstStep = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const navigate = useNavigate();

    const { formData, updateFormData } = useFormContext();

    const handleConfirm = () => {
        if (activeStep === steps.length - 1) {
            alert("All steps completed");
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            navigate('/second');
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            navigate('/previous');
        }
    };

    const handleFormSuccess = () => {
        setIsFormSubmitted(true);
    }

    return (
        <>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} height='100vh' sx={{ padding: '32px', margin: '32px', borderRadius: '12px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%' }}>
                    <Box>
                        <Typography variant='h1' sx={{ fontSize: { xs: '24px', md: '32px' } }}>
                            Enter your organization details
                        </Typography>
                        <Typography variant='h2' sx={{ fontSize: { xs: '16px', md: '20px' }, marginTop: '8px' }}>
                            Please fill in your organizations details
                        </Typography>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <CompanyProfile
                    companyName={formData.companyName}
                    setCompanyName={(value) => updateFormData('companyName', value)}
                    registrationNo={formData.registrationNo}
                    setRegistrationNo={(value) => updateFormData('registrationNo', value)}
                    addressLine1={formData.addressLine1}
                    setAddressLine1={(value) => updateFormData('addressLine1', value)}
                    postcode={formData.postcode}
                    setPostcode={(value) => updateFormData('postcode', value)}
                    state={formData.state}
                    setState={(value) => updateFormData('state', value)}
                    country={formData.country}
                    setCountry={(value) => updateFormData('country', value)}
                    requiredFields={['companyName', 'registrationNo', 'addressLine1', 'postcode', 'state', 'country']}
                    onFormSubmitSuccess={handleFormSuccess}
                />
                <Box sx={{ ml: 'auto', mt:5, display: 'flex', gap: '12px', justifyContent: 'flex-end' }}>
                    <Button variant="cancel" onClick={handleBack} disabled={activeStep === 0}>Previous</Button>
                    <Button variant="save" onClick={handleConfirm} disabled={!isFormSubmitted}>Next</Button>
                </Box>
            </Paper>
        </>
    );
};

export default FirstStep;