import React, { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import AddEventForm from "./AddEventForm";
import AddProductToEvent from "./AddProductToEvent";

const AddEvents = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box flex={1} sx={{padding:'32px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h1'>
                        Add New Events
                    </Typography >
                    <Typography variant='h2'>
                        Fill out the details below to add a new event, customize event types, and set event availability.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    {/*for buttons*/}
                </Box>
            </Box>

            <Box height='38px' paddingTop='32px'>
                <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                    <Tab label="add new event" />
                    <Tab label="Add product to event" />
                </Tabs>
            </Box>

            <Divider sx={{margin: '41px 0 32px 0'}}/>

            <Box>
                { value === 0 && <AddEventForm/> }
                { value === 1 && <AddProductToEvent/> }
            </Box>
        </Box>
    );
};

export default AddEvents;
