import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all payment details
export const fetchPaymentDetails = createAsyncThunk('paymentDetails/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/payment-details`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new payment detail
export const createPaymentDetail = createAsyncThunk('paymentDetails/create', async (paymentData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/payment-details`, paymentData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific payment detail by ID
export const fetchPaymentDetailById = createAsyncThunk('paymentDetails/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/payment-details/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a payment detail by ID
export const deletePaymentDetail = createAsyncThunk('paymentDetails/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/payment-details/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a payment detail by ID
export const updatePaymentDetail = createAsyncThunk('paymentDetails/update', async ({ id, paymentData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/payment-details/${id}`, paymentData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Payment Details
const paymentDetailsSlice = createSlice({
    name: 'paymentDetails',
    initialState: {
        paymentDetails: [],
        selectedPaymentDetail: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentDetails = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchPaymentDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch payment details';
            })

            .addCase(createPaymentDetail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPaymentDetail.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentDetails.push(action.payload);
            })
            .addCase(createPaymentDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create payment detail';
            })

            .addCase(fetchPaymentDetailById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentDetailById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPaymentDetail = action.payload;
            })
            .addCase(fetchPaymentDetailById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch payment detail by ID';
            })

            .addCase(deletePaymentDetail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePaymentDetail.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentDetails = state.paymentDetails.filter(detail => detail.id !== action.payload);
                if (state.selectedPaymentDetail && state.selectedPaymentDetail.id === action.payload) {
                    state.selectedPaymentDetail = null;
                }
            })
            .addCase(deletePaymentDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete payment detail';
            })

            .addCase(updatePaymentDetail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePaymentDetail.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.paymentDetails.findIndex(detail => detail.id === action.payload.id);
                if (index !== -1) state.paymentDetails[index] = action.payload;
                if (state.selectedPaymentDetail && state.selectedPaymentDetail.id === action.payload.id) {
                    state.selectedPaymentDetail = action.payload;
                }
            })
            .addCase(updatePaymentDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update payment detail';
            });
    },
});

export default paymentDetailsSlice.reducer;
