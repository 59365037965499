export const bankOptions = [
    {
        imgSrc: '/mayBank.png',
        imgAlt: 'Maybank',
        title: 'Maybank',
    },
    {
        imgSrc: '/cimb.png',
        imgAlt: 'CIMB Group Holdings Berhad',
        title: 'CIMB Group Holdings Berhad',
    },
    {
        imgSrc: '/amBank.png',
        imgAlt: 'AmBank (M) Berhad',
        title: 'AmBank (M) Berhad',
    },
    {
        imgSrc: '/standardChartered.png',
        imgAlt: 'Standard Chartered',
        title: 'Standard Chartered',
    },
    {
        imgSrc: '/hongLeong.png',
        imgAlt: 'Hong Leong Bank',
        title: 'Hong Leong Bank',
    },
    {
        imgSrc: '/hsbc.png',
        imgAlt: 'HSBC Bank Malaysia',
        title: 'HSBC Bank Malaysia',
    },
    {
        imgSrc: '/alRhajhi.png',
        imgAlt: 'Al Rajhi Bank Malaysia',
        title: 'Al Rajhi Bank Malaysia',
    },
    {
        imgSrc: '/bankIslam.png',
        imgAlt: 'Bank Islam Malaysia',
        title: 'Bank Islam Malaysia',
    },
    {
        imgSrc: '/publicBank.png',
        imgAlt: 'Public Bank Berhad',
        title: 'Public Bank Berhad',
    },
    {
        imgSrc: '/ocbc.png',
        imgAlt: 'OCBC Bank (Malaysia) Berhad',
        title: 'OCBC Bank (Malaysia) Berhad',
    },
];
