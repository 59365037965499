import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint

// 1. Fetch all user role permissions
export const fetchUserRolePermissions = createAsyncThunk(
    'userRolePermissions/fetchUserRolePermissions',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-role-permissions`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 2. Create a new user role permission
export const createUserRolePermission = createAsyncThunk(
    'userRolePermissions/createUserRolePermission',
    async (permissionData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/user-role-permissions`, permissionData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 3. Fetch a single user role permission by ID
export const fetchSingleUserRolePermission = createAsyncThunk(
    'userRolePermissions/fetchSingleUserRolePermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-role-permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 4. Delete a user role permission by ID
export const deleteUserRolePermission = createAsyncThunk(
    'userRolePermissions/deleteUserRolePermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/user-role-permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Slice reducer
const userRolePermissionsSlice = createSlice({
    name: 'userRolePermissions',
    initialState: {
        permissions: [],
        selectedPermission: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRolePermissions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserRolePermissions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.permissions = action.payload;
            })
            .addCase(fetchUserRolePermissions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createUserRolePermission.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.permissions.push(action.payload);
            })
            .addCase(fetchSingleUserRolePermission.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPermission = action.payload;
            })
            .addCase(deleteUserRolePermission.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.permissions = state.permissions.filter(permission => permission.id !== action.payload);
                if (state.selectedPermission && state.selectedPermission.id === action.payload) {
                    state.selectedPermission = null;
                }
            });
    },
});

export default userRolePermissionsSlice.reducer;
