import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all event packages
export const fetchEventPackages = createAsyncThunk('eventPackages/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/bridges/event-has-packages`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new event package
export const createEventPackage = createAsyncThunk('eventPackages/create', async (eventData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/bridges/event-has-packages`, eventData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific event package by ID
export const fetchEventPackageById = createAsyncThunk('eventPackages/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/bridges/event-has-packages/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update an event package by ID
export const updateEventPackage = createAsyncThunk('eventPackages/update', async ({ id, eventData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/bridges/event-has-packages/${id}`, eventData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete an event package by ID
export const deleteEventPackage = createAsyncThunk('eventPackages/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        await axios.delete(`${API_BASE_URL}/bridges/event-has-packages/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch event plan by event ID
export const fetchEventPlanByEventId = createAsyncThunk('eventPackages/fetchPlanByEventId', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/bridges/event-has-packages/events/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Event Packages Operations
const eventPackagesSlice = createSlice({
    name: 'eventPackages',
    initialState: {
        eventPackages: [],
        selectedPackage: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventPackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventPackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventPackages = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchEventPackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event packages';
            })

            .addCase(createEventPackage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEventPackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventPackages.push(action.payload);
            })
            .addCase(createEventPackage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create event package';
            })

            .addCase(fetchEventPackageById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventPackageById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPackage = action.payload;
            })
            .addCase(fetchEventPackageById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event package by ID';
            })

            .addCase(updateEventPackage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEventPackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.eventPackages.findIndex(pkg => pkg.id === action.payload.id);
                if (index !== -1) state.eventPackages[index] = action.payload;
                if (state.selectedPackage && state.selectedPackage.id === action.payload.id) {
                    state.selectedPackage = action.payload;
                }
            })
            .addCase(updateEventPackage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update event package';
            })

            .addCase(deleteEventPackage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteEventPackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventPackages = state.eventPackages.filter(pkg => pkg.id !== action.payload);
                if (state.selectedPackage && state.selectedPackage.id === action.payload) {
                    state.selectedPackage = null;
                }
            })
            .addCase(deleteEventPackage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete event package';
            })

            .addCase(fetchEventPlanByEventId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventPlanByEventId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // handle the fetched event plan data, if needed
            })
            .addCase(fetchEventPlanByEventId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event plan by event ID';
            });
    },
});

export default eventPackagesSlice.reducer;
