import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import ChartComponent from "./ChartComponent";

const MainDashboard = () => {
    const { keycloak, initialized } = useKeycloak();
    const [organizationId, setOrganizationId] = useState(null);

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            const token = keycloak.tokenParsed;
            // console.log('Token:', token); // Log the entire token
            const organization = token?.organization; // Access the organization claim
            setOrganizationId(organization);
            // console.log('Organization ID:', organization);
        }
    }, [initialized, keycloak]);

    return (
        <Container>
            <Grid container spacing={3}>
                {/* Today's Overview */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Today</Typography>
                            <Typography variant="h4">RM0.00</Typography>
                            <Typography variant="body2">Estimated future payouts</Typography>
                            <Typography variant="h6">Payouts: RM570.23</Typography>
                            <Typography variant="body2">Deposited Apr 28, 2023</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Your overview */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Your overview</Typography>
                            {/* Add your time range selection here */}
                            <Box>
                                <Typography variant="body2">Gross volume: RM215.9K</Typography>
                                <Typography variant="body2">Net volume from sales: RM208.9K</Typography>
                                {/* Insert chart components here */}
                                <ChartComponent />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Payments, Gross Volume, Net Volume */}
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Payments</Typography>
                            {/* Insert payment details here */}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Gross Volume</Typography>
                            {/* Insert gross volume details here */}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Net Volume from Sales</Typography>
                            {/* Insert net volume details here */}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Failed Payments, New Customers, Top Customers by Spend */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Failed Payments</Typography>
                            {/* Insert failed payments details here */}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">New Customers</Typography>
                            {/* Insert new customers details here */}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Top Customers by Spend</Typography>
                            {/* Insert top customers details here */}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MainDashboard;