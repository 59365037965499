import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';

const CustomInput = styled(Input)(({ theme, type }) => ({
    borderRadius: '8px',
    border: '1px solid var(--Gray-300, #D0D5DD)',
    backgroundColor: 'var(--White, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    padding: type === 'color' ? '0' : '10px',
    '&::before, &::after': {
        display: 'none',
    },
}));

export default CustomInput;
