import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUsers = createAsyncThunk(
    'adminUsers/fetchUsers',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/users`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createUser = createAsyncThunk(
    'adminUsers/createUser',
    async (userData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/users`,
                userData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleUser = createAsyncThunk(
    'adminUsers/fetchSingleUser',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/users/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminUsersSlice = createSlice({
    name: 'adminUsers',
    initialState: {
        users: [],
        selectedUser: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch users';
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(fetchSingleUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSingleUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedUser = action.payload;
            })
            .addCase(fetchSingleUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch user';
            });
    },
});

export default adminUsersSlice.reducer;