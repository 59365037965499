import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunks for space has packages operations
export const fetchSpacePackages = createAsyncThunk(
    'spaceHasPackages/fetchSpacePackages',
    async (_, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/bridges/space-has-packages`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createSpaceHasPackages = createAsyncThunk(
    'spaceHasPackages/createSpaceHasPackages',
    async (packageData, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/bridges/space-has-packages`, packageData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getSpacePlan = createAsyncThunk(
    'spaceHasPackages/getSpacePlan',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/bridges/space-has-packages/${id}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const patchSpaceHasPackages = createAsyncThunk(
    'spaceHasPackages/patchSpaceHasPackages',
    async ({ id, packageData }, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/bridges/space-has-packages/${id}`, packageData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteEventPlan = createAsyncThunk(
    'spaceHasPackages/deleteEventPlan',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/bridges/space-has-packages/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getSpacePlanBySpaceId = createAsyncThunk(
    'spaceHasPackages/getSpacePlanBySpaceId',
    async (spaceId, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/bridges/space-has-packages/spaces/${spaceId}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Space Has Packages slice
const spaceHasPackagesSlice = createSlice({
    name: 'spaceHasPackages',
    initialState: {
        packages: [],
        selectedPlan: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {
        // You can add any additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            // Fetch all space packages
            .addCase(fetchSpacePackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSpacePackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages = action.payload;
            })
            .addCase(fetchSpacePackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch space packages';
            })
            // Create space has packages
            .addCase(createSpaceHasPackages.fulfilled, (state, action) => {
                state.packages.push(action.payload);
            })
            // Get space plan
            .addCase(getSpacePlan.fulfilled, (state, action) => {
                state.selectedPlan = action.payload;
            })
            // Patch space has packages
            .addCase(patchSpaceHasPackages.fulfilled, (state, action) => {
                const index = state.packages.findIndex(pkg => pkg.id === action.payload.id);
                if (index !== -1) {
                    state.packages[index] = action.payload;
                }
                if (state.selectedPlan && state.selectedPlan.id === action.payload.id) {
                    state.selectedPlan = action.payload;
                }
            })
            // Delete event plan
            .addCase(deleteEventPlan.fulfilled, (state, action) => {
                state.packages = state.packages.filter(pkg => pkg.id !== action.payload);
                if (state.selectedPlan && state.selectedPlan.id === action.payload) {
                    state.selectedPlan = null;
                }
            })
            // Get space plan by space ID
            .addCase(getSpacePlanBySpaceId.fulfilled, (state, action) => {
                state.selectedPlan = action.payload;
            });
    },
});

export default spaceHasPackagesSlice.reducer;