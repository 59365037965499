import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Link} from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const ReviewPay = ({ finalPrice, handleConfirmPayment }) => {
    return (
        <Box sx={{ padding: { xs: 0, sm: '0 32px' },mb:2 }}>
            <Box sx={{ marginBottom: 2}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1, sm: 0 } }}>
                    <Typography variant="body2" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Pay in full</Typography>
                    <Typography fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>RM {finalPrice.toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: { xs: 1, sm: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1, sm: 0 } }}>
                    <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                        <Typography variant="body2" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Pay less up front</Typography>
                        <Typography variant="caption" color="textSecondary">
                            The rest will be charged later. No extra fees.
                        </Typography>
                    </Box>
                    <Typography fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>RM {(finalPrice / 2).toFixed(2)} now</Typography>
                </Box>
                {/* Confirmation Button */}
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, marginTop: '24px', flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}>
                    <Button variant="save" onClick={handleConfirmPayment} sx={{ width: { xs: '100%', sm: 'auto' } }}>
                        Confirm and Pay
                    </Button>
                    <Button variant="cancel" component={Link} to="/select-product" sx={{ width: { xs: '100%', sm: 'auto' } }}>
                        Cancel Payment
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ReviewPay;