import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from "react";
import PersonalProfile from "../shared/profiles/PersonalProfile";
import CompanyProfile from "../shared/profiles/CompanyProfile";
import Verification from "./Verification";

const MerchantOnboarding = () => {
    const [value, setValue] = useState(0);
    const [companyName, setCompanyName] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [postcode, setPostcode] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const requiredFields = ['companyName', 'registrationNo', 'addressLine1', 'postcode', 'state', 'country'];

    return (
        <Box height='100vh' sx={{ padding:'32px', backgroundColor:'#f5f5f5'}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h1'>
                        Welcome Merchant!
                    </Typography >
                    <Typography variant='h2'>
                        Please fill in your company profile to proceed your company services in Eventlah.com
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    <Button variant="cancel" onClick={ ()=>{} }>Cancel</Button>
                    <Button variant="save" onClick={ ()=>{} }>Save as Draft</Button>
                </Box>
            </Box>

            <Box height='38px' sx={{display: 'flex', paddingTop:'32px', paddingBottom:'42px'}}>
                <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                    <Tab label="Personal Profile" />
                    <Tab label="Company Profile" />
                    <Tab label="Verification" />
                </Tabs>
            </Box>

            <Divider
                orientation="vertical"
                sx={{
                    height: '1px',
                    alignSelf: 'stretch',
                    backgroundColor: 'var(--Gray-200, #EAECF0)',
                    margin: '4px 0 32px 0'
                }}
            />

            <Box>
                {value === 0 && <PersonalProfile/>}
                {value === 1 && <CompanyProfile
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    registrationNo={registrationNo}
                    setRegistrationNo={setRegistrationNo}
                    addressLine1={addressLine1}
                    setAddressLine1={setAddressLine1}
                    postcode={postcode}
                    setPostcode={setPostcode}
                    state={state}
                    setState={setState}
                    country={country}
                    setCountry={setCountry}
                    requiredFields={requiredFields}
                />}
                {value === 2 && <Verification/>}
            </Box>
        </Box>
    );
};

export default MerchantOnboarding;