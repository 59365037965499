// redux/organizationSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {getToken} from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async actions for API calls with Bearer token
export const fetchOrganizations = createAsyncThunk('organizations/fetchOrganizations', async () => {
    const token = await getToken(); // Get the token
    const response = await axios.get(`${API_BASE_URL}/organizations/1`, { // Adjust API_BASE_URL
        headers: {
            'Authorization': `Bearer ${token}`, // Add Bearer token to header
        }
    });
    return response.data.data;
});

// New patchOrganization thunk
export const patchOrganization = createAsyncThunk(
    'organizations/patchOrganization',
    async (organizationData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/organizations/${organizationData.id}`,
                organizationData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const organizationSlice = createSlice({
    name: 'organizations',
    initialState: {
        organization: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        updateOrganization: (state, action) => {
            state.organization = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organization = action.payload;
            })
            .addCase(fetchOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(patchOrganization.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(patchOrganization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organization = action.payload;
            })
            .addCase(patchOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { updateOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;