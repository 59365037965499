import React from 'react';
import {
    Box,
    IconButton,
    Paper,
    Typography
} from '@mui/material';

import {DataGrid} from "@mui/x-data-grid";
import {ViewIcon} from "../../../shared/IconButtons";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";

const rows = [
    { id: 1, name: 'iftar2023_senior_promo', pricing: 'RM59.99', daysBeforeEvent: 10 },
    { id: 2, name: 'iftar2023_child_promo', pricing: 'RM49.99', daysBeforeEvent: 7 },
    { id: 3, name: 'iftar2023_adult_promo', pricing: 'RM89.99', daysBeforeEvent: 15 },
    { id: 4, name: 'iftar2023_child_EB', pricing: 'RM39.99', daysBeforeEvent: 12 },
    { id: 5, name: 'iftar2023_Adult_EB', pricing: 'RM79.99', daysBeforeEvent: 14 }
];

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'pricing',
        headerName: 'Pricing',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'daysBeforeEvent',
        headerName: 'Days Before Event',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'actions',
        headerName: '',
        width: 150,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                <IconButton aria-label="view" onClick={() => console.log('view', params.row)}>
                    <ViewIcon />
                </IconButton>
                <IconButton aria-label="edit" onClick={() => console.log('edit', params.row)}>
                    <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => console.log('delete', params.row.id)}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        ),
    },
];

const Incoming = () => {
    return (
        <Paper
            sx={{
                width: "100%",
                borderRadius: "8px",
                marginTop: '24px',
                border: "1px solid var(--Gray-200, #EAECF0)",
                background: "var(--White, #FFF)",
                boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
            }}
        >
            <Typography
                sx={{
                    color: "var(--Gray-900, #101828)",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "28px",
                    padding: "20px 24px",
                }}
            >
                All Incoming Appointments
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                rowHeight={70}
                pageSizeOptions={[5, 10, 20]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 6,
                        },
                    },
                }}
            />
        </Paper>
    )
}

export default Incoming;