import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Editor from './Editor';
import Page from './Page';

const PageBuilder = () => {
    const [content, setContent] = useState(null);
    const [isEditing, setIsEditing] = useState(true);

    const handleSave = (data) => {
        setContent(data);
        setIsEditing(false);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Page Builder
            </Typography>
            {isEditing ? (
                <Editor data={content} onSave={handleSave} />
            ) : (
                <Box>
                    <Page data={content} />
                    <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
                        Edit
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default PageBuilder;
