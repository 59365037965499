import React, { useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ServiceFormGallery = () => {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState({});

    const handleFileUpload = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        newFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: 0,
                }));
            };
            reader.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentage = Math.round((event.loaded / event.total) * 100);
                    setProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: percentage,
                    }));
                }
            };
            reader.onloadend = () => {
                setProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: 100,
                }));
            };
            reader.readAsDataURL(file);
        });
    };

    const handleDelete = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
        setProgress((prevProgress) => {
            const newProgress = { ...prevProgress };
            delete newProgress[fileName];
            return newProgress;
        });
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ padding: 1, borderRadius: 2 }}>
                <Box display="flex" sx={{ backgroundColor: '#f4f6f8' }}>
                    <Box padding="24px">
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                            <path
                                d="M33.5339 7.95652L27.087 1.50957C26.7609 1.18339 26.3186 1.0001 25.8574 1H2.73913C2.27788 1 1.83553 1.18323 1.50938 1.50938C1.18323 1.83553 1 2.27788 1 2.73913V39.2609C1.0001 39.7221 1.18339 40.1644 1.50957 40.4904L33.7374 8.26261C33.6812 8.15329 33.613 8.05062 33.5339 7.95652Z"
                                fill="white"/>
                            <path
                                d="M7.95605 13.1741C7.95605 14.0966 8.32251 14.9813 8.97481 15.6336C9.62711 16.2859 10.5118 16.6523 11.4343 16.6523C12.3568 16.6523 13.2415 16.2859 13.8938 15.6336C14.5461 14.9813 14.9126 14.0966 14.9126 13.1741C14.9126 12.2516 14.5461 11.3669 13.8938 10.7146C13.2415 10.0623 12.3568 9.6958 11.4343 9.6958C10.5118 9.6958 9.62711 10.0623 8.97481 10.7146C8.32251 11.3669 7.95605 12.2516 7.95605 13.1741Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M20.8682 17.8311C20.79 17.706 20.6814 17.6029 20.5524 17.5314C20.4234 17.4599 20.2783 17.4224 20.1308 17.4224C19.9833 17.4224 19.8383 17.4599 19.7093 17.5314C19.5803 17.6029 19.4716 17.706 19.3934 17.8311L14.8039 25.1737L12.9343 22.1789C12.8561 22.0538 12.7474 21.9507 12.6184 21.8792C12.4894 21.8077 12.3444 21.7702 12.1969 21.7702C12.0494 21.7702 11.9044 21.8077 11.7754 21.8792C11.6464 21.9507 11.5377 22.0538 11.4595 22.1789L6.21777 30.565H28.8265L20.8682 17.8311Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M7.95605 13.1741C7.95605 14.0966 8.32251 14.9813 8.97481 15.6336C9.62711 16.2859 10.5118 16.6523 11.4343 16.6523C12.3568 16.6523 13.2415 16.2859 13.8938 15.6336C14.5461 14.9813 14.9126 14.0966 14.9126 13.1741C14.9126 12.2516 14.5461 11.3669 13.8938 10.7146C13.2415 10.0623 12.3568 9.6958 11.4343 9.6958C10.5118 9.6958 9.62711 10.0623 8.97481 10.7146C8.32251 11.3669 7.95605 12.2516 7.95605 13.1741Z"
                                stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M21.9465 19.5563L20.8682 17.8311C20.79 17.706 20.6814 17.6029 20.5524 17.5314C20.4234 17.4599 20.2783 17.4224 20.1308 17.4224C19.9833 17.4224 19.8383 17.4599 19.7093 17.5314C19.5803 17.6029 19.4716 17.706 19.3934 17.8311L14.8039 25.1737L12.9343 22.1789C12.8561 22.0538 12.7474 21.9507 12.6184 21.8792C12.4894 21.8077 12.3444 21.7702 12.1969 21.7702C12.0494 21.7702 11.9044 21.8077 11.7754 21.8792C11.6464 21.9507 11.5377 22.0538 11.4595 22.1789L6.21777 30.565H14.9134"
                                stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M18.3913 41H2.73913C2.27788 41 1.83553 40.8168 1.50938 40.4906C1.18323 40.1645 1 39.7221 1 39.2609V2.73913C1 2.27788 1.18323 1.83553 1.50938 1.50938C1.83553 1.18323 2.27788 1 2.73913 1H25.8574C26.3186 1.0001 26.7609 1.18339 27.087 1.50957L33.5339 7.95652C33.8601 8.2826 34.0434 8.72488 34.0435 9.18609V16.6522"
                                stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M30.5654 25.3281V35.7629" stroke="#00303E" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M35.7824 30.5459H25.3477" stroke="#00303E" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <Typography
                            sx={{
                                color: 'var(--dark-500, #09090B)',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                                paddingTop: '24px',
                            }}
                        >
                            Click to upload or drag and drop
                        </Typography>
                        <Typography
                            sx={{
                                color: 'var(--Gray-500, #667085)',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '18px',
                            }}
                        >
                            SVG, PNG, JPG or GIF (max. 1440×900px)
                        </Typography>
                    </Box>

                    <Box sx={{paddingTop: '24px', paddingRight: '24px'}}>
                        <Button
                            variant='cancel'
                            onClick={() => document.getElementById('file-upload-input').click()}
                        >
                            + Add Image
                        </Button>
                        <input
                            id="file-upload-input"
                            type="file"
                            hidden
                            multiple
                            onChange={handleFileUpload}
                        />
                    </Box>
                </Box>
            </Paper>
            <List>
                {files.map((file) => (
                    <ListItem key={file.name}>
                        <ListItemText
                            primary={file.name}
                            secondary={`${Math.round(file.size / 1024)} KB`}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file.name)}>
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                        <Box sx={{width: '100%', marginTop: 1}}>
                            <LinearProgress variant="determinate" value={progress[file.name] || 0}/>
                            <Typography variant="caption" display="block" align="right">
                                {progress[file.name] || 0}%
                            </Typography>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ServiceFormGallery;
