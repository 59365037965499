import { Box, Divider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%', // Full width on smaller screens
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px', // Fixed width on medium screens and up
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const PersonalProfile = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (newEmail && !validateEmail(newEmail)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    return (
        <Box component="form" noValidate autoComplete="off">
            {/* First Name */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    First Name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                    label='Enter First Name'
                    required
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            {/* Last Name */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Last Name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                    label='Enter Last Name'
                    required
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            {/* Email */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Email <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    label='Enter Email'
                    required
                    type="email"
                    error={!!emailError}
                    helperText={emailError}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>
        </Box>
    );
};

export default PersonalProfile;