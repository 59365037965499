import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SecurityIcon from '@mui/icons-material/Security';
import DescriptionIcon from '@mui/icons-material/Description';
import PlanIcon from '@mui/icons-material/ViewAgenda';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import TicketingIcon from '@mui/icons-material/ConfirmationNumber';
import InfoIcon from '@mui/icons-material/Info';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import HistoryIcon from '@mui/icons-material/History';
import ContactsIcon from '@mui/icons-material/Contacts';
import BillingIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { styled } from '@mui/material/styles';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { SvgIcon } from '@mui/material';

const CustomCard = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    border: 'none',
    display: 'flex',
    borderRadius: '5px',
    backgroundColor: '#fff',
    height: '100%',
    cursor: 'pointer',
}));

const CardContentBox = styled(CardContent)(({ theme }) => ({
    padding: '10px',
    display: 'flex',
    gap: 2,
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-500, #667085)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: '3px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
}));

const SettingsPage = () => {
    const navigate = useNavigate();

    const businessSettings = [
        { icon: <AccountBoxIcon />, title: 'Profile Settings', description: 'Personal details, password, communication preferences, and your active sessions.', path: 'profile-settings' },
        { icon: <EmailIcon />, title: 'Email Notification', description: 'Set up and manage email notifications.', path: 'email-notifications' },
        { icon: <PublicIcon />, title: 'Landing Page', description: 'Design and manage your landing pages.', path: 'landing-page' },
        { icon: <BusinessIcon />, title: 'Branding', description: 'Account details, account health, public info, payouts, legal entity, custom domains, and more.', path: 'branding' },
        { icon: <DescriptionIcon />, title: 'Compliance and Documents', description: 'PCI compliance, documents, and legacy exports.', path: 'compliance-documents' },
        { icon: <PlanIcon />, title: 'Subscriptions', description: 'Manage your subscription plans.', path: 'subscriptions' },
        { icon: <SecurityIcon />, title: 'Team and Security', description: 'Team members, roles, account security, authorized apps, and shared resources.', path: 'team-security' },
        // { icon: <PersonIcon />, title: 'Freelance Agent/DropShipper', description: 'Manage freelance agents and dropshippers.', path: 'freelance-agent' },
        // { icon: <InfoIcon />, title: 'Business Details', description: 'Detailed information about your business.', path: 'business-details' },
        // { icon: <ManageAccountsIcon />, title: 'Public Details', description: 'Add public details for customers.', path: 'public-details' },
        { icon: <AssessmentIcon />, title: 'Account Status', description: 'Check the status of your account.', path: 'account-status' },
        // { icon: <InfoIcon />, title: 'Tax Details', description: 'Manage and update your tax details.', path: 'tax-details' },
        { icon: <PublicIcon />, title: 'Custom Domain', description: 'Set up and manage your custom domain.', path: 'custom-domain' },
        { icon: <SvgIcon component={StorefrontIcon} />, title: 'Store Setup', description: 'Configure your store settings, layout, and branding.', path: 'store-setup' },
        { icon: <PhotoLibraryIcon />, title: 'Uploads', description: 'Manage and upload your media files.', path: 'media-gallery' },
    ];

    const productSettings = [
        { icon: <ShoppingCartIcon />, title: 'Product Catalog', description: 'Manage your products, categories, and inventory.', path: 'product-catalog' },
        { icon: <TicketingIcon />, title: 'Events', description: 'Manage your events.', path: 'events' },
        { icon: <LocalOfferIcon />, title: 'Coupons', description: 'Create and manage discount coupons for your products.', path: 'coupon' },
        { icon: <LocalShippingIcon />, title: 'Shipping Rates', description: 'Define shipping rates based on regions and delivery methods.', path: 'shipping-rate' },
        // { icon: <AttachMoneyIcon />, title: 'Tax Rates', description: 'Set up tax rates for different regions and product categories.', path: 'tax-rate' },
        // KIV { icon: <PriceCheckIcon />, title: 'PricingTable', description: 'Create a branded, responsive pricing table to embed on your website.', path: 'pricing-table' }
    ];

    // const otherSettings = [
    //     { icon: <CalendarTodayIcon />, title: 'Appointments', description: 'Manage your appointments and schedules.', path: 'appointments' },
    //     { icon: <BookmarkIcon />, title: 'Booking/Order/Ticketing Status', description: 'Track the status of your bookings, orders, and tickets.', path: 'booking-order-status' },
    //     { icon: <HistoryIcon />, title: 'Transaction History', description: 'View your transaction history and details.', path: 'transaction-history' },
    //     { icon: <ContactsIcon />, title: 'Customer Record & Profile', description: 'Manage customer records and profiles.', path: 'customer-records' },
    //     { icon: <BillingIcon />, title: 'Billing & Plans', description: 'Easily Access and Update Your Plans and Billing Information.', path: 'billing' },
    //     { icon: <PaymentIcon />, title: 'Payment', description: 'Set up and manage payment methods and transactions.', path: 'payment' },
    // ];


    const handleCardClick = (path) => {
        if (path === 'landing-page') {
            window.location.href = 'https://pages.eventlah.com';
        } else if(path === 'events') {
            navigate('/events');
        } else {
            navigate(path);
        }
    };

    return (
        <Box paddingRight='15%'>
            <Box>
                <Typography variant='h1'>
                    Business Settings
                </Typography>
                <Typography variant='h2' sx={{ paddingBottom: '22px' }}>
                    Manage your business preferences and configurations
                </Typography>
                <Grid container spacing={3}>
                    {businessSettings.map((item, index) => (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                            <CustomCard onClick={() => handleCardClick(item.path)}>
                                <CardContentBox>
                                    <Box
                                        sx={{
                                            height: '30px',
                                            width: '30px',
                                            backgroundColor: 'white',
                                            borderRadius: '7px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding:'15px',
                                        }}
                                    >
                                        {item.icon}
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)',
                                                fontFamily: 'Inter',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: '20px',
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <DescriptionTypography>
                                            {item.description}
                                        </DescriptionTypography>
                                    </Box>
                                </CardContentBox>
                            </CustomCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box sx={{ paddingTop: '32px' }}>
                <Typography variant='h1'>
                    Product Settings
                </Typography>
                <Typography variant='h2' sx={{ paddingBottom: '22px' }}>
                    Control and configure your product settings
                </Typography>
                <Grid container spacing={3}>
                    {productSettings.map((item, index) => (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                            <CustomCard onClick={() => handleCardClick(item.path)}>
                                <CardContentBox>
                                    <Box
                                        sx={{
                                            height: '30px',
                                            width: '30px',
                                            backgroundColor: 'white',
                                            borderRadius: '7px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding:'15px',
                                        }}
                                    >
                                        {item.icon}
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)',
                                                fontFamily: 'Inter',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: '20px',
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <DescriptionTypography>
                                            {item.description}
                                        </DescriptionTypography>
                                    </Box>
                                </CardContentBox>
                            </CustomCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/*Others*/}
            {/*<Box sx={{ paddingTop: '32px' }}>*/}
            {/*    <Typography variant='h1'>*/}
            {/*        Others*/}
            {/*    </Typography>*/}
            {/*    <Typography variant='h2' sx={{ paddingBottom: '22px' }}>*/}
            {/*        Additional settings and configurations*/}
            {/*    </Typography>*/}
            {/*    <Grid container spacing={3}>*/}
            {/*        {otherSettings.map((item, index) => (*/}
            {/*            <Grid item xs={12} sm={12} md={4} lg={4} key={index}>*/}
            {/*                <CustomCard onClick={() => handleCardClick(item.path)}>*/}
            {/*                    <CardContentBox>*/}
            {/*                        <Box*/}
            {/*                            sx={{*/}
            {/*                                height: '30px',*/}
            {/*                                width: '30px',*/}
            {/*                                backgroundColor: 'white',*/}
            {/*                                borderRadius: '7px',*/}
            {/*                                display: 'flex',*/}
            {/*                                alignItems: 'center',*/}
            {/*                                justifyContent: 'center',*/}
            {/*                                padding:'15px',*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            {item.icon}*/}
            {/*                        </Box>*/}
            {/*                        <Box>*/}
            {/*                            <Typography*/}
            {/*                                sx={{*/}
            {/*                                    color: 'var(--dark-500, #09090B)',*/}
            {/*                                    fontFamily: 'Inter',*/}
            {/*                                    fontSize: '16px',*/}
            {/*                                    fontStyle: 'normal',*/}
            {/*                                    fontWeight: 600,*/}
            {/*                                    lineHeight: '20px',*/}
            {/*                                }}*/}
            {/*                            >*/}
            {/*                                {item.title}*/}
            {/*                            </Typography>*/}
            {/*                            <DescriptionTypography>*/}
            {/*                                {item.description}*/}
            {/*                            </DescriptionTypography>*/}
            {/*                        </Box>*/}
            {/*                    </CardContentBox>*/}
            {/*                </CustomCard>*/}
            {/*            </Grid>*/}
            {/*        ))}*/}
            {/*    </Grid>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default SettingsPage;
