import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all event relationships
export const fetchEventRelationships = createAsyncThunk('referenceEventRelationships/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/event-relationships`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new event relationship
export const createEventRelationship = createAsyncThunk('referenceEventRelationships/create', async (eventRelationshipData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/event-relationships`, eventRelationshipData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update an event relationship by ID
export const updateEventRelationship = createAsyncThunk('referenceEventRelationships/update', async ({ id, eventRelationshipData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/event-relationships/${id}`, eventRelationshipData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific event relationship by ID
export const fetchEventRelationshipById = createAsyncThunk('referenceEventRelationships/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/event-relationships/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete an event relationship by ID
export const deleteEventRelationship = createAsyncThunk('referenceEventRelationships/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/event-relationships/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Reference Event Relationships
const referenceEventRelationshipsSlice = createSlice({
    name: 'referenceEventRelationships',
    initialState: {
        eventRelationships: [],
        selectedEventRelationship: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventRelationships.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventRelationships.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventRelationships = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchEventRelationships.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event relationships';
            })

            .addCase(createEventRelationship.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEventRelationship.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventRelationships.push(action.payload);
            })
            .addCase(createEventRelationship.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create event relationship';
            })

            .addCase(updateEventRelationship.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEventRelationship.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.eventRelationships.findIndex(event => event.id === action.payload.id);
                if (index !== -1) {
                    state.eventRelationships[index] = action.payload;
                }
            })
            .addCase(updateEventRelationship.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update event relationship';
            })

            .addCase(fetchEventRelationshipById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventRelationshipById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedEventRelationship = action.payload;
            })
            .addCase(fetchEventRelationshipById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event relationship by ID';
            })

            .addCase(deleteEventRelationship.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteEventRelationship.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventRelationships = state.eventRelationships.filter(event => event.id !== action.payload);
                if (state.selectedEventRelationship && state.selectedEventRelationship.id === action.payload) {
                    state.selectedEventRelationship = null;
                }
            })
            .addCase(deleteEventRelationship.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete event relationship';
            });
    },
});

export default referenceEventRelationshipsSlice.reducer;
