import {Box} from "@mui/material";
import TeamSecurity from "../../settings/business-settings/TeamSecurity";

const AdminSecuritySettings = () => {
    return (
        <Box>
            <TeamSecurity/>
        </Box>
    )
}

export default AdminSecuritySettings;