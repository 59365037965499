const SpacesListExamples = () => {
    return (
        [
            { id: 1, name: 'The Common Ground Ampang', pax: '200 - 500 pax', price: 'RM 500 - RM 1,200', rating: 4, date: '19 Jan 2024', src: '/1.jpeg' },
            { id: 2, name: 'The Common Ground Ara Damansara', pax: '200 - 500 pax', price: 'RM 500 - RM 1,200', rating: 4, date: '19 Jan 2024', src: '/2.jpeg' },
            { id: 3, name: 'The Common Ground Bandar Utama', pax: '200 - 500 pax', price: 'RM 500 - RM 1,200', rating: 4, date: '19 Jan 2024', src: '/3.jpeg' },
            { id: 4, name: 'The Common Ground Bangsar South', pax: '100 - 200 pax', price: 'RM 1,500 - RM 3,200', rating: 4, date: '25 Dec 2023', src: '/4.jpeg' },
            { id: 5, name: 'The Common Ground Bukit Bintang', pax: '100 - 200 pax', price: 'RM 4,000 - RM 8,200', rating: 4, date: '25 Dec 2023', src: '/5.jpeg' },
            { id: 6, name: 'The Common Ground Damansara Heights', pax: '200 - 500 pax', price: 'RM 500 - RM 1,200', rating: 4, date: '25 Dec 2023', src: '/6.jpeg' },
        ]
    )
};

export default SpacesListExamples;
