import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    InputAdornment,
    Grid,
    ButtonGroup
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const VariantTable = ({ variant, updateVariantValue }) => {
    return (
        <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox />
                        </TableCell>
                        <TableCell>Variant</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Available</TableCell>
                        <TableCell>On hand</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {variant.optionValues.map((value, index) => (
                        <TableRow key={index}>
                            <TableCell padding="checkbox">
                                <Checkbox />
                            </TableCell>
                            <TableCell>{value}</TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    placeholder="20.00"
                                    type="number"
                                    value={variant.prices[index] || ""}
                                    onChange={(e) => updateVariantValue(variant.id, 'prices', index, e.target.value)}
                                    inputProps={{
                                        step: "0.01",
                                        min: "0"
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    placeholder="0"
                                    type="number"
                                    value={variant.available[index] || ""}
                                    onChange={(e) => updateVariantValue(variant.id, 'available', index, e.target.value)}
                                    inputProps={{
                                        min: "0"
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    placeholder="0"
                                    type="number"
                                    value={variant.onHand[index] || ""}
                                    onChange={(e) => updateVariantValue(variant.id, 'onHand', index, e.target.value)}
                                    inputProps={{
                                        min: "0"
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ProductVariant = () => {
    const [variants, setVariants] = useState([]);
    const [selectedVariantIndexes, setSelectedVariantIndexes] = useState({});
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        if (variants.length > 0) {
            const initialIndexes = variants.reduce((acc, variant, index) => {
                acc[index] = 0;
                return acc;
            }, {});
            setSelectedVariantIndexes(initialIndexes);
        }
    }, [variants]);

    const addVariant = () => {
        const newVariant = {
            id: Date.now(),
            optionName: '',
            optionValues: [''],
            prices: [''],
            available: [''],
            onHand: ['']
        };
        setVariants([...variants, newVariant]);
    };

    const updateVariant = (id, field, value) => {
        const newVariants = variants.map(variant =>
            variant.id === id ? { ...variant, [field]: value } : variant
        );
        setVariants(newVariants);
    };

    const updateVariantValue = (id, field, index, value) => {
        const newVariants = variants.map(variant => {
            if (variant.id === id) {
                const newValues = [...variant[field]];
                newValues[index] = value;
                return { ...variant, [field]: newValues };
            }
            return variant;
        });
        setVariants(newVariants);
    };

    const addOptionValue = (variantId) => {
        const newVariants = variants.map(variant => {
            if (variant.id === variantId) {
                return {
                    ...variant,
                    optionValues: [...variant.optionValues, ''],
                    prices: [...variant.prices, ''],
                    available: [...variant.available, ''],
                    onHand: [...variant.onHand, '']
                };
            }
            return variant;
        });
        setVariants(newVariants);
    };

    const removeVariant = (id) => {
        const newVariants = variants.filter(variant => variant.id !== id);
        setVariants(newVariants);
    };

    const removeOptionValue = (variantId, valueIndex) => {
        const newVariants = variants.map(variant => {
            if (variant.id === variantId) {
                return {
                    ...variant,
                    optionValues: variant.optionValues.filter((_, i) => i !== valueIndex),
                    prices: variant.prices.filter((_, i) => i !== valueIndex),
                    available: variant.available.filter((_, i) => i !== valueIndex),
                    onHand: variant.onHand.filter((_, i) => i !== valueIndex)
                };
            }
            return variant;
        });
        setVariants(newVariants);
    };

    const getJsonOutput = () => {
        const output = variants.map(variant => ({
            optionName: variant.optionName,
            optionValues: variant.optionValues,
            prices: variant.prices.map(price => parseFloat(price) || 0),
            available: variant.available.map(qty => parseInt(qty) || 0),
            onHand: variant.onHand.map(qty => parseInt(qty) || 0)
        }));
        return JSON.stringify(output, null, 2);
    };

    const handleSave = () => {
        console.log(getJsonOutput());
    };

    const handleQuantityChange = (amount) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + amount));
    };

    const handleOptionSelect = (variantIndex, optionIndex) => {
        setSelectedVariantIndexes(prev => ({
            ...prev,
            [variantIndex]: optionIndex
        }));
    };

    const calculatePrice = () => {
        return variants.reduce((total, variant, index) => {
            const selectedOptionIndex = selectedVariantIndexes[index] || 0;
            return total + (parseFloat(variant.prices[selectedOptionIndex]) || 0);
        }, 0).toFixed(2);
    };

    const getAvailableQuantity = () => {
        return variants.reduce((min, variant, index) => {
            const selectedOptionIndex = selectedVariantIndexes[index] || 0;
            const available = parseInt(variant.available[selectedOptionIndex]) || 0;
            return Math.min(min, available);
        }, Infinity);
    };

    const getOnHandQuantity = () => {
        return variants.reduce((min, variant, index) => {
            const selectedOptionIndex = selectedVariantIndexes[index] || 0;
            const onHand = parseInt(variant.onHand[selectedOptionIndex]) || 0;
            return Math.min(min, onHand);
        }, Infinity);
    };

    return (
        <Box sx={{ display: 'flex', maxWidth: '100%', padding: 2 }}>
            <Box sx={{ width: '50%', paddingRight: 2 }}>
                <Typography sx={{fontSize: '22px', fontWeight: '500', mb:1}}>
                    Variants
                </Typography>
                {variants.length === 0 && (
                    <Button
                        onClick={addVariant}
                        variant='save'
                        sx={{ marginBottom: 2 }}
                    >
                        + Add options like size or color
                    </Button>
                )}
                {variants.map((variant) => (
                    <Paper key={variant.id} elevation={3} sx={{ padding: 2, marginBottom: 2, borderRadius:'12px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <TextField
                                fullWidth
                                label="Option name"
                                placeholder="Enter option details (e.g., size, color, material)"
                                value={variant.optionName}
                                onChange={(e) => updateVariant(variant.id, 'optionName', e.target.value)}
                                sx={{ marginRight: 1 }}
                            />
                            <IconButton onClick={() => removeVariant(variant.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="subtitle1" gutterBottom>
                            Option values
                        </Typography>
                        {variant.optionValues.map((value, valueIndex) => (
                            <Box key={valueIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <TextField
                                    fullWidth
                                    value={value}
                                    onChange={(e) => updateVariantValue(variant.id, 'optionValues', valueIndex, e.target.value)}
                                    placeholder={`Value ${valueIndex + 1}`}
                                    sx={{ marginRight: 1 }}
                                />
                                <IconButton onClick={() => removeOptionValue(variant.id, valueIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button
                            onClick={() => addOptionValue(variant.id)}
                            variant='cancel'
                            sx={{
                                width: '100%',
                                height: '42px',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                fontWeight: '500',
                                fontSize: '16px',
                                marginBottom: '16px',
                            }}
                        >
                            Add another value
                        </Button>
                        {variant.optionValues.some(value => value !== '') && (
                            <VariantTable variant={variant} updateVariantValue={updateVariantValue} />
                        )}
                    </Paper>
                ))}
                {variants.length > 0 && (
                    <Box sx={{display:'flex'}}>
                        <Button
                            onClick={addVariant}
                            variant='save'
                            sx={{ marginTop: 2, marginRight: 2 }}
                        >
                            + Add another option
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant='cancel'
                            sx={{ marginTop: 2 }}
                        >
                            Save
                        </Button>
                    </Box>
                )}
            </Box>

            <Box sx={{ width: '50%', maxWidth:'700px', paddingLeft: 10 }}>
                <Paper elevation={3} sx={{ padding: 2, borderRadius: '12px' }}>
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Test Product
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography>Product Image Placeholder</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                                RM {calculatePrice()}
                            </Typography>
                            {variants.map((variant, variantIndex) => (
                                <Box key={variant.id} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {variant.optionName}
                                    </Typography>
                                    <ButtonGroup variant="outlined" aria-label="variant selection" sx={{ mb: 2, flexWrap: 'wrap' }}>
                                        {variant.optionValues.map((value, optionIndex) => (
                                            <Button
                                                key={optionIndex}
                                                variant={optionIndex === (selectedVariantIndexes[variantIndex] || 0) ? 'save' : 'cancel'}
                                                sx={{ borderRadius: '20px', m: 0.5 }}
                                                onClick={() => handleOptionSelect(variantIndex, optionIndex)}
                                            >
                                                {value}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Box>
                            ))}
                            <Typography variant="subtitle1" gutterBottom>
                                Quantity
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Button variant="cancel" sx={{ minWidth: '40px' }} onClick={() => handleQuantityChange(-1)}>-</Button>
                                <TextField
                                    value={quantity}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    sx={{ mx: 1, width: '60px' }}
                                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                                />
                                <Button variant="cancel" sx={{ minWidth: '40px' }} onClick={() => handleQuantityChange(1)}>+</Button>
                            </Box>
                            <Button variant="save" fullWidth sx={{ mb: 2 }}>
                                Add to cart
                            </Button>
                            <Typography variant="body2">
                                Available: {getAvailableQuantity()}
                            </Typography>
                            <Typography variant="body2">
                                On hand: {getOnHandQuantity()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Box>
    );
};

export default ProductVariant;