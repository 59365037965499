import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint

// 1. Fetch all vouchers
export const fetchVouchers = createAsyncThunk(
    'vouchers/fetchVouchers',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/vouchers`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 2. Create a new voucher
export const createVoucher = createAsyncThunk(
    'vouchers/createVoucher',
    async (voucherData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/vouchers`, voucherData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 3. Fetch a specific voucher by ID
export const fetchSingleVoucher = createAsyncThunk(
    'vouchers/fetchSingleVoucher',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/vouchers/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 4. Delete a voucher by ID
export const deleteVoucher = createAsyncThunk(
    'vouchers/deleteVoucher',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/vouchers/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 5. Update a voucher
export const updateVoucher = createAsyncThunk(
    'vouchers/updateVoucher',
    async ({ id, updatedData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(`${API_BASE_URL}/vouchers/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 6. Update the status of a voucher
export const updateVoucherStatus = createAsyncThunk(
    'vouchers/updateVoucherStatus',
    async ({ id, entityId, statusData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(`${API_BASE_URL}/vouchers/${id}/status/${entityId}`, statusData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Slice reducer
const vouchersSlice = createSlice({
    name: 'vouchers',
    initialState: {
        vouchers: [],
        selectedVoucher: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVouchers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVouchers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vouchers = action.payload;
            })
            .addCase(fetchVouchers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createVoucher.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vouchers.push(action.payload);
            })
            .addCase(fetchSingleVoucher.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedVoucher = action.payload;
            })
            .addCase(deleteVoucher.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vouchers = state.vouchers.filter(voucher => voucher.id !== action.payload);
                if (state.selectedVoucher && state.selectedVoucher.id === action.payload) {
                    state.selectedVoucher = null;
                }
            })
            .addCase(updateVoucher.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.vouchers.findIndex(voucher => voucher.id === action.payload.id);
                if (index !== -1) {
                    state.vouchers[index] = action.payload;
                }
            })
            .addCase(updateVoucherStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.vouchers.findIndex(voucher => voucher.id === action.payload.id);
                if (index !== -1) {
                    state.vouchers[index] = action.payload;
                }
            });
    },
});

export default vouchersSlice.reducer;
