import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchRoles = createAsyncThunk(
    'adminRoles/fetchRoles',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/roles`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createRole = createAsyncThunk(
    'adminRoles/createRole',
    async (roleData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/roles`,
                roleData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateRole = createAsyncThunk(
    'adminRoles/updateRole',
    async ({ id, ...updateData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/roles/${id}`,
                updateData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleRole = createAsyncThunk(
    'adminRoles/fetchSingleRole',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/roles/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteRole = createAsyncThunk(
    'adminRoles/deleteRole',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/roles/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchRolesByOrganizationId = createAsyncThunk(
    'adminRoles/fetchRolesByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/roles/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { organizationId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteRolesByOrganizationId = createAsyncThunk(
    'adminRoles/deleteRolesByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/roles/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return organizationId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminRolesSlice = createSlice({
    name: 'adminRoles',
    initialState: {
        roles: [],
        selectedRole: null,
        rolesByOrganization: {},
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.roles.push(action.payload);
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                const index = state.roles.findIndex(role => role.id === action.payload.id);
                if (index !== -1) {
                    state.roles[index] = action.payload;
                }
                if (state.selectedRole && state.selectedRole.id === action.payload.id) {
                    state.selectedRole = action.payload;
                }
            })
            .addCase(fetchSingleRole.fulfilled, (state, action) => {
                state.selectedRole = action.payload;
            })
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.roles = state.roles.filter(role => role.id !== action.payload);
                if (state.selectedRole && state.selectedRole.id === action.payload) {
                    state.selectedRole = null;
                }
            })
            .addCase(fetchRolesByOrganizationId.fulfilled, (state, action) => {
                state.rolesByOrganization[action.payload.organizationId] = action.payload.data;
            })
            .addCase(deleteRolesByOrganizationId.fulfilled, (state, action) => {
                delete state.rolesByOrganization[action.payload];
                state.roles = state.roles.filter(role => role.organizationId !== action.payload);
            })
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.payload?.message || 'An error occurred';
                }
            )
            .addMatcher(
                action => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            );
    },
});

export default adminRolesSlice.reducer;