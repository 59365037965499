import React from 'react';
import {Box, Typography, Grid, TextField, FormControl, Select, MenuItem, FormLabel,} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AddTaxRate = () => {
    return (
        <Box sx={{padding:'20px 0 20px 0'}}>
            <Grid container spacing={4}>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <FormLabel sx={{ fontWeight: 500, fontSize: '15px', mb: 1, display: 'flex', alignItems: 'center' }}>
                            Type
                            <InfoOutlinedIcon sx={{ fontSize: '16px', ml: 0.5 , color:'#6D6D6D'}} />
                        </FormLabel>
                        <Select defaultValue="Sales tax">
                            <MenuItem value="Sales tax">Sales tax</MenuItem>
                            {/* Add more options here if needed */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={7}/>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel sx={{ fontWeight: 500, fontSize: '15px', mb: 1, display: 'flex', alignItems: 'center' }}>
                            Region
                            <InfoOutlinedIcon sx={{ fontSize: '16px', ml: 0.5 , color:'#6D6D6D'}} />
                        </FormLabel>
                        <Select defaultValue="" displayEmpty>
                            <MenuItem value="" disabled>
                                Select region...
                            </MenuItem>
                            <MenuItem value="region1">Region 1</MenuItem>
                            <MenuItem value="region2">Region 2</MenuItem>
                            {/* Add more region options here */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}/>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <FormLabel sx={{fontWeight:'500', fontsize:'15px', mb:1}}>Rate</FormLabel>
                        <TextField
                            fullWidth
                            placeholder="0"
                            InputProps={{
                                endAdornment: <Typography variant="body2">%</Typography>,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={8}/>

                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <FormLabel sx={{fontWeight:'500', fontsize:'15px', mb:1}}>Include tax in price</FormLabel>
                        <Select defaultValue="No (exclusive)">
                            <MenuItem value="No (exclusive)">No (exclusive)</MenuItem>
                            <MenuItem value="Yes (inclusive)">Yes (inclusive)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={7}/>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel sx={{ fontWeight: 500, fontSize: '15px', mb: 1, display: 'flex', alignItems: 'center' }}>
                            Description
                            <InfoOutlinedIcon sx={{ fontSize: '16px', ml: 0.5 , color:'#6D6D6D'}} />
                        </FormLabel>
                        <TextField
                            fullWidth
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddTaxRate;
