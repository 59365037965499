import React from "react";
import CompanyProfile from "../../../shared/profiles/CompanyProfile";
import { Box, Divider, Typography } from "@mui/material";

const BusinessDetail = () => {
    const requiredFields = ['companyName', 'registrationNo', 'companyAddress_1', 'postcode', 'state', 'country'];

    const handleFormSubmitSuccess = () => {
        // Handle successful form submission if needed
        console.log("Form submitted successfully");
    };

    return (
        <>
            <Box>
                <Typography varriant='h1' fontSize='20px' fontWeight='600'>
                    Business Details
                </Typography>
                <Typography variant='h2'>
                    Provide us details about your business.
                </Typography>
            </Box>

            <Divider/>

            <CompanyProfile
                requiredFields={requiredFields}
                onFormSubmitSuccess={handleFormSubmitSuccess}
            />
        </>
    );
};

export default BusinessDetail;