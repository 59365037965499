import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    CircularProgress,
    Typography
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditAgreement = ({ open, onClose, editedAgreement, handleEditChange, handleSaveEdit, status }) => {
    const handleContentChange = (content) => {
        handleEditChange({ target: { name: 'content', value: content } });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant="h5" component="div">
                    Edit Agreement
                </Typography>
            </DialogTitle>
            <DialogContent>
                {editedAgreement && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                        <TextField
                            name="title"
                            label="Agreement Title"
                            value={editedAgreement.title}
                            onChange={handleEditChange}
                            fullWidth
                            variant="outlined"
                        />
                        <Box>
                            <Typography variant="subtitle1" gutterBottom>
                                Agreement Content
                            </Typography>
                            <ReactQuill
                                theme="snow"
                                value={editedAgreement.content}
                                onChange={handleContentChange}
                                style={{ height: '300px', marginBottom: '50px' }}
                            />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button
                    variant='save'
                    onClick={handleSaveEdit}
                    disabled={status === 'loading'}
                    startIcon={status === 'loading' ? <CircularProgress size={20} /> : null}
                >
                    {status === 'loading' ? 'Saving...' : 'Save Changes'}
                </Button>
                <Button variant='cancel' onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditAgreement;