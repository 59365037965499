import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => ({
    'Authorization': `Bearer ${await getToken()}`,
    'Content-Type': 'application/json'
});

// Fetch all booking packages
export const fetchBookingPackages = createAsyncThunk('bookingPackages/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/booking-has-packages`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Create a new booking package
export const createBookingPackage = createAsyncThunk('bookingPackages/create', async (bookingData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/booking-has-packages`, bookingData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch a booking package by ID
export const fetchBookingPackageById = createAsyncThunk('bookingPackages/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/booking-has-packages/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Update a booking package by ID
export const updateBookingPackage = createAsyncThunk('bookingPackages/update', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/bridges/booking-has-packages/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Delete a booking package by ID
export const deleteBookingPackage = createAsyncThunk('bookingPackages/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/booking-has-packages/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch booking plan by Booking ID
export const fetchBookingPlanByBookingId = createAsyncThunk('bookingPackages/fetchByBookingId', async (bookingId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/booking-has-packages/bookings/${bookingId}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Booking Packages
const bookingPackagesSlice = createSlice({
    name: 'bookingPackages',
    initialState: {
        packages: [],
        selectedPackage: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBookingPackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBookingPackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchBookingPackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch booking packages';
            })
            .addCase(createBookingPackage.fulfilled, (state, action) => {
                state.packages.push(action.payload);
            })
            .addCase(fetchBookingPackageById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBookingPackageById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPackage = action.payload;
            })
            .addCase(fetchBookingPackageById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch booking package';
            })
            .addCase(updateBookingPackage.fulfilled, (state, action) => {
                const index = state.packages.findIndex(pkg => pkg.id === action.payload.id);
                if (index !== -1) state.packages[index] = action.payload;
                if (state.selectedPackage && state.selectedPackage.id === action.payload.id) {
                    state.selectedPackage = action.payload;
                }
            })
            .addCase(deleteBookingPackage.fulfilled, (state, action) => {
                state.packages = state.packages.filter(pkg => pkg.id !== action.payload);
                if (state.selectedPackage && state.selectedPackage.id === action.payload) {
                    state.selectedPackage = null;
                }
            })
            .addCase(fetchBookingPlanByBookingId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBookingPlanByBookingId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // handle the fetched booking plan data
            })
            .addCase(fetchBookingPlanByBookingId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch booking plan by booking ID';
            });
    },
});

export default bookingPackagesSlice.reducer;
