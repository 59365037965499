import React from 'react';
import AdminAccountStatusTable from "./AdminAccountStatusTable";

const Incomplete = () => {
    const rows = [
        { id: 21, merchantId: '3003001', merchantName: 'The Coffee Beanery', merchantEmail: 'contact@coffeebeanery.com', status: 'Incomplete' },
        { id: 22, merchantId: '3003002', merchantName: 'Emerald Apparel', merchantEmail: 'support@emeraldapparel.com', status: 'Incomplete' },
        { id: 23, merchantId: '3003003', merchantName: 'Zenith Fitness', merchantEmail: 'info@zenithfitness.com', status: 'Incomplete' },
        { id: 24, merchantId: '3003004', merchantName: 'Nova Tech Solutions', merchantEmail: 'service@novatech.com', status: 'Incomplete' },
        { id: 25, merchantId: '3003005', merchantName: 'Sunshine Organic Market', merchantEmail: 'sales@sunshineorganic.com', status: 'Incomplete' },
        { id: 26, merchantId: '3003006', merchantName: 'Metro City Realtors', merchantEmail: 'inquiries@metrocityrealtors.com', status: 'Incomplete' },
        { id: 27, merchantId: '3003007', merchantName: 'Crystal Clear Pools', merchantEmail: 'admin@crystalclearpools.com', status: 'Incomplete' },
        { id: 28, merchantId: '3003008', merchantName: 'Urban Eats Deli', merchantEmail: 'orders@urbaneatsdeli.com', status: 'Incomplete' },
        { id: 29, merchantId: '3003009', merchantName: 'Ace Electronics', merchantEmail: 'info@aceelectronics.com', status: 'Incomplete' },
        { id: 30, merchantId: '3003010', merchantName: 'Luxe Salon & Spa', merchantEmail: 'appointments@luxesalon.com', status: 'Incomplete' },
    ];
    return (
        <>
            <AdminAccountStatusTable title='Incomplete' rows={rows} />
        </>
    );
};

export default Incomplete;
