import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint
export const getUserHasBookings = createAsyncThunk(
    'userHasBookings/getUserHasBookings',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/user-has-bookings`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createUserHasBookings = createAsyncThunk(
    'userHasBookings/createUserHasBookings',
    async (bookingData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/bridges/user-has-bookings`, bookingData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getUserHasBookingsById = createAsyncThunk(
    'userHasBookings/getUserHasBookingsById',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/user-has-bookings/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const patchUserHasBookings = createAsyncThunk(
    'userHasBookings/patchUserHasBookings',
    async ({ id, updateData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/bridges/user-has-bookings/${id}`, updateData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserHasBookings = createAsyncThunk(
    'userHasBookings/deleteUserHasBookings',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/bridges/user-has-bookings/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getUserHasBookingsByUser = createAsyncThunk(
    'userHasBookings/getUserHasBookingsByUser',
    async (userId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/user-has-bookings/users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getUserHasBookingsByOrganization = createAsyncThunk(
    'userHasBookings/getUserHasBookingsByOrganization',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/user-has-bookings/organizations/${organizationId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getUserHasBookingsByBooking = createAsyncThunk(
    'userHasBookings/getUserHasBookingsByBooking',
    async (bookingId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/user-has-bookings/bookings/${bookingId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const userHasBookingsSlice = createSlice({
    name: 'userHasBookings',
    initialState: {
        bookings: [],
        selectedBooking: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserHasBookings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserHasBookings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings = action.payload;
            })
            .addCase(getUserHasBookings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createUserHasBookings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings.push(action.payload);
            })
            .addCase(getUserHasBookingsById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedBooking = action.payload;
            })
            .addCase(patchUserHasBookings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.bookings.findIndex(booking => booking.id === action.payload.id);
                if (index !== -1) {
                    state.bookings[index] = action.payload;
                }
                if (state.selectedBooking && state.selectedBooking.id === action.payload.id) {
                    state.selectedBooking = action.payload;
                }
            })
            .addCase(deleteUserHasBookings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings = state.bookings.filter(booking => booking.id !== action.payload);
                if (state.selectedBooking && state.selectedBooking.id === action.payload) {
                    state.selectedBooking = null;
                }
            })
            .addCase(getUserHasBookingsByUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings = action.payload;
            })
            .addCase(getUserHasBookingsByOrganization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings = action.payload;
            })
            .addCase(getUserHasBookingsByBooking.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings = action.payload;
            });
    },
});

export default userHasBookingsSlice.reducer;