import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Link,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Divider,
} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AddIcon from '@mui/icons-material/Add';
import EastIcon from '@mui/icons-material/East';
import CreateCoupon from './CreateCoupon';
import FilterListIcon from "@mui/icons-material/FilterList";
import GetAppIcon from "@mui/icons-material/GetApp";

const Coupon = () => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h1'>
                        Coupons
                    </Typography >
                    <Typography variant='h2'>
                        Create and manage discount coupons for your products.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    <Button variant="cancel" startIcon={<FilterListIcon />}>
                        Filter
                    </Button>
                    <Button variant="cancel" startIcon={<GetAppIcon />}>
                        Export
                    </Button>
                    <Button variant="cancel" startIcon={<AddIcon />}>
                        New
                    </Button>
                </Box>
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop:'150px'}}>
                <Box>
                    <IconButton sx={{ backgroundColor: '#e0e0e0', p: 2, borderRadius: 1, mb: 4 }}>
                        <LocalOfferIcon sx={{ fontSize: 40 }} />
                    </IconButton>

                    <Typography variant="h5" sx={{ fontSize:'22px', fontWeight: '700', mb: 1 }}>
                        No coupons
                    </Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 400, mb: 1 }}>
                        Create coupons to offer discounts on invoices, subscriptions, or entire customer accounts for however long you choose.
                    </Typography>
                    <Link href="#" variant="body2" color="#f5c542" underline="none">
                        Learn more <EastIcon sx={{fontSize:'14px', ml:'2px'}}/>
                    </Link>
                    <Box sx={{ marginTop: 3 }}>
                        <Button variant="save" startIcon={<AddIcon />} onClick={handleClickOpen}>
                            Create a coupon
                        </Button>
                    </Box>
                </Box>
            </Box>


            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <Box sx={{padding:'15px 10px'}}>
                    <DialogTitle>
                        <Typography variant='h1' fontSize='25px' fontWeight='700'>
                            Create a coupon
                        </Typography>
                        <Typography variant="body1" color="textSecondary" fontSize='15px' sx={{ mb: 1 }}>
                            Coupons can be used to discount invoices, subscriptions, or entire customer accounts.
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <CreateCoupon />
                    </DialogContent>
                    <DialogActions>
                        <Button variant='cancel' onClick={handleClose} >
                            Cancel
                        </Button>
                        <Button variant="save">
                            Create coupon
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    );
};

export default Coupon;
