import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Paper, TextField, Typography, Stepper, Step, StepLabel, CircularProgress, Snackbar } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { createOrUpdateProduct } from "../../redux/productSlice"; // Make sure this path is correct

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const FourthStep = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const productStatus = useSelector(state => state.products.status);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            productName: '',
            price: '',
            productDescription: '',
        }
    });

    const handleCancel = () => {
        navigate('/third');
    };

    const handleConfirm = () => {
        navigate('/fifth');
    };

    const onSubmit = async (data) => {
        const productData = {
            name: data.productName,
            minimum_pax: 0,
            maximum_pax: 0,
            minimum_amount: parseFloat(data.price),
            maximum_amount: parseFloat(data.price),
            description: data.productDescription,
            organizationId: 1,
            categoryId: 2,
            minimumCurrencyId: 2,
            maximumCurrencyId: 2,
            statusOwnershipId: 15,
        };

        try {
            await dispatch(createOrUpdateProduct(productData)).unwrap();
            setSnackbarMessage('Product created successfully');
            setSnackbarOpen(true);
            handleConfirm();
        } catch (error) {
            setSnackbarMessage('Error creating product: ' + error.message);
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <Stepper activeStep={3} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant='h1' sx={{ fontSize: { xs: '24px', md: '32px' } }}>
                        Product Setup
                    </Typography>
                    <Typography variant='h2' sx={{ fontSize: { xs: '16px', md: '20px' }, marginTop: '8px' }}>
                        Please provide your product details to add it to your store inventory.
                    </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Product Name <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <Controller
                            name="productName"
                            control={control}
                            rules={{ required: "Product name is required" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Enter product name"
                                    fullWidth
                                    error={!!errors.productName}
                                    helperText={errors.productName?.message}
                                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                                />
                            )}
                        />
                    </Box>

                    <Divider />

                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Price <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <Controller
                            name="price"
                            control={control}
                            rules={{
                                required: "Price is required",
                                pattern: {
                                    value: /^\d+(\.\d{1,2})?$/,
                                    message: "Please enter a valid price (e.g., 59.99)"
                                }
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Enter price"
                                    type="number"
                                    fullWidth
                                    error={!!errors.price}
                                    helperText={errors.price?.message}
                                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                                />
                            )}
                        />
                    </Box>

                    <Divider />

                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>Product Description</TextFieldTitle>
                        <Controller
                            name="productDescription"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Enter product description"
                                    fullWidth
                                    multiline
                                    rows={7}
                                    inputProps={{ maxLength: 250 }}
                                    helperText={`${field.value.length}/250`}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            padding: '15px',
                                            '& .MuiOutlinedInput-input': {
                                                padding: 0,
                                            },
                                        },
                                        paddingRight: { md: '50px', lg: '240px' }
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-end' }, mt: 5 }}>
                        <Button
                            onClick={handleConfirm}
                            sx={{
                                textTransform: 'none',
                                color: 'orange',
                                border: 'none',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                },
                            }}
                        >
                            Skip for now
                        </Button>
                        <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={productStatus === 'loading'}
                            sx={{ textTransform: 'none', height: '34px' }}
                        >
                            {productStatus === 'loading' ? <CircularProgress size={24} /> : 'Next'}
                        </Button>
                    </Box>
                </Box>
            </Paper>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </>
    );
};

export default FourthStep;