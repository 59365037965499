import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Divider,
    TextField,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Snackbar,
    Alert,
    CircularProgress
} from "@mui/material";
import { styled } from "@mui/system";
import {
    fetchOrganizationProfiles,
    saveOrganizationProfile,
    updateOrganizationProfile
} from '../../../redux/organizationProfilesSlice';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const CompanyProfile = ({ requiredFields = [], onFormSubmitSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const { profiles, status, error } = useSelector((state) => state.organizationProfiles);
    const [profileData, setProfileData] = useState({
        id: '',
        code: '',
        name: '',
        description: '',
        registrationNumber: '',
        company_address_1: '',
        company_address_2: '',
        postcode: '',
        state: '',
        country: '',
        storeName: '',
        contactNumber: '',
        emailAddress: '',
        operatingHours: '',
        operatingDays: '',
        storeDescription: '',
        storeUrl: ''
    });

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchOrganizationProfiles())
                .unwrap()
                .then((data) => console.log('Fetched organization profiles:', data))
                .catch((error) => console.error('Failed to fetch organization profiles:', error));
        }
    }, [status, dispatch]);

    useEffect(() => {
        if (profiles && profiles.length > 0) {
            const fetchedProfileData = profiles[0].data || {};
            console.log('Fetched profile data:', fetchedProfileData);
            setProfileData(prevData => ({
                ...prevData,
                ...fetchedProfileData,
            }));
        }
    }, [profiles]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProfileData(prevData => {
            const newData = {
                ...prevData,
                [name]: value
            };
            console.log('Updated profile data:', newData);
            return newData;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (Object.keys(profileData).length === 0) {
                throw new Error('Profile data is required');
            }

            const apiData = {
                id: profileData.id,
                code: profileData.code,
                name: profileData.name,
                description: profileData.description,
                registrationNumber: profileData.registrationNumber,
                company_address_1: profileData.company_address_1,
                company_address_2: profileData.company_address_2,
                postcode: profileData.postcode,
                state: profileData.state,
                country: profileData.country,
                storeName: profileData.storeName,
                contactNumber: profileData.contactNumber,
                emailAddress: profileData.emailAddress,
                operatingHours: profileData.operatingHours,
                operatingDays: profileData.operatingDays,
                storeDescription: profileData.storeDescription,
                storeUrl: profileData.storeUrl,
            };

            console.log('Submitting profile data:', apiData);

            let result;
            if (profileData.id) {
                result = await dispatch(updateOrganizationProfile({ id: profileData.id, profile: apiData })).unwrap();
            } else {
                result = await dispatch(saveOrganizationProfile({ profiles: [{ data: apiData }] })).unwrap();
            }

            console.log('API response:', result);

            if (result.data) {
                setProfileData({
                    ...result.data,
                });
            }

            setSnackbar({
                open: true,
                message: 'Business details saved successfully!',
                severity: 'success'
            });
            if (onFormSubmitSuccess) {
                onFormSubmitSuccess();
            }
        } catch (error) {
            console.error('Failed to save profile:', error);
            setSnackbar({
                open: true,
                message: 'Failed to save business details. Please try again.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const malaysiaStates = [
        "Johor", "Kedah", "Kelantan", "Malacca", "Negeri Sembilan",
        "Pahang", "Penang", "Perak", "Perlis", "Sabah",
        "Sarawak", "Selangor", "Terengganu", "Kuala Lumpur"
    ];

    const countries = [
        "Malaysia",
    ];

    if (status === 'loading') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Company Name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    value={profileData.name || ''}
                    onChange={handleInputChange}
                    name="name"
                    fullWidth
                    label='Enter Company Name'
                    required={requiredFields.includes('name')}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Registration No. <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="registrationNumber"
                    value={profileData.registrationNumber || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label='Enter Company Registration No. (SSM)'
                    required={requiredFields.includes('registrationNumber')}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Company Address Line 1 <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="company_address_1"
                    value={profileData.company_address_1 || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label='Enter Address Line 1'
                    required={requiredFields.includes('company_address_1')}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Company Address Line 2
                </TextFieldTitle>
                <TextField
                    name="company_address_2"
                    value={profileData.company_address_2 || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label='Enter Address Line 2'
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Postcode <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="postcode"
                    value={profileData.postcode || ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value) && value.length <= 6) {
                            handleInputChange(e);
                        }
                    }}
                    fullWidth
                    label='Enter Postcode'
                    required={requiredFields.includes('postcode')}
                    inputProps={{ maxLength: 6 }}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    State <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                    <InputLabel id="state-select-label">Select State</InputLabel>
                    <Select
                        labelId="state-select-label"
                        id="state-select"
                        name="state"
                        value={profileData.state || ''}
                        onChange={handleInputChange}
                        required={requiredFields.includes('state')}
                    >
                        {malaysiaStates.map((state) => (
                            <MenuItem key={state} value={state}>
                                {state}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Country <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                    <InputLabel id="country-select-label">Select Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        id="country-select"
                        name="country"
                        value={profileData.country || ''}
                        onChange={handleInputChange}
                        required={requiredFields.includes('country')}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ marginTop: '32px', display: 'flex', gap: '16px' }}>
                <Button
                    variant="save"
                    type="submit"
                    disabled={loading}
                >
                    {loading ? 'Saving...' : (profileData.id ? 'Update' : 'Save') + ' Business Details'}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default CompanyProfile;
