import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk for fetching all status ownerships
export const fetchStatusOwnerships = createAsyncThunk(
    'statusOwnerships/fetchStatusOwnerships',
    async (_, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(
                `${API_BASE_URL}/status-ownerships`,
                { headers }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Thunk for fetching a single status ownership by ID
export const fetchStatusOwnershipById = createAsyncThunk(
    'statusOwnerships/fetchStatusOwnershipById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(
                `${API_BASE_URL}/status-ownerships/${id}`,
                { headers }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Status Ownerships slice
const statusOwnershipsSlice = createSlice({
    name: 'statusOwnerships',
    initialState: {
        ownerships: [],
        selectedOwnership: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {
        // You can add any additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatusOwnerships.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStatusOwnerships.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.ownerships = action.payload;
                state.error = null;
            })
            .addCase(fetchStatusOwnerships.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch status ownerships';
            })
            .addCase(fetchStatusOwnershipById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStatusOwnershipById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedOwnership = action.payload;
                state.error = null;
            })
            .addCase(fetchStatusOwnershipById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch status ownership';
            });
    },
});

export default statusOwnershipsSlice.reducer;