import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all currencies
export const fetchCurrencies = createAsyncThunk('referenceCurrencies/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/currencies`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new currency
export const createCurrency = createAsyncThunk('referenceCurrencies/create', async (currencyData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/currencies`, currencyData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a currency by ID
export const updateCurrency = createAsyncThunk('referenceCurrencies/update', async ({ id, currencyData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/currencies`, currencyData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific currency by ID
export const fetchCurrencyById = createAsyncThunk('referenceCurrencies/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/currencies/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a currency by ID
export const deleteCurrency = createAsyncThunk('referenceCurrencies/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/currencies/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Reference Currencies
const referenceCurrenciesSlice = createSlice({
    name: 'referenceCurrencies',
    initialState: {
        currencies: [],
        selectedCurrency: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrencies.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCurrencies.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currencies = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchCurrencies.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch currencies';
            })

            .addCase(createCurrency.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createCurrency.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currencies.push(action.payload);
            })
            .addCase(createCurrency.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create currency';
            })

            .addCase(updateCurrency.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCurrency.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.currencies.findIndex(currency => currency.id === action.payload.id);
                if (index !== -1) {
                    state.currencies[index] = action.payload;
                }
            })
            .addCase(updateCurrency.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update currency';
            })

            .addCase(fetchCurrencyById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCurrencyById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedCurrency = action.payload;
            })
            .addCase(fetchCurrencyById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch currency by ID';
            })

            .addCase(deleteCurrency.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteCurrency.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currencies = state.currencies.filter(currency => currency.id !== action.payload);
                if (state.selectedCurrency && state.selectedCurrency.id === action.payload) {
                    state.selectedCurrency = null;
                }
            })
            .addCase(deleteCurrency.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete currency';
            });
    },
});

export default referenceCurrenciesSlice.reducer;
