import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint

// 1. Fetch all vouchers with spaces
export const fetchVoucherHasSpaces = createAsyncThunk(
    'voucherHasSpaces/fetchVoucherHasSpaces',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/voucher-has-spaces`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 2. Create a new voucher with spaces
export const createVoucherHasSpace = createAsyncThunk(
    'voucherHasSpaces/createVoucherHasSpace',
    async (voucherData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/bridges/voucher-has-spaces`, voucherData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 3. Fetch a specific voucher with spaces by ID
export const fetchSingleVoucherHasSpace = createAsyncThunk(
    'voucherHasSpaces/fetchSingleVoucherHasSpace',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/voucher-has-spaces/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 4. Update a voucher with spaces
export const updateVoucherHasSpace = createAsyncThunk(
    'voucherHasSpaces/updateVoucherHasSpace',
    async ({ id, updatedData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(`${API_BASE_URL}/bridges/voucher-has-spaces/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 5. Delete a voucher with spaces by ID
export const deleteVoucherHasSpace = createAsyncThunk(
    'voucherHasSpaces/deleteVoucherHasSpace',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/bridges/voucher-has-spaces/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 6. Fetch spaces related to a specific voucher by ID
export const fetchVoucherSpacesByVoucherId = createAsyncThunk(
    'voucherHasSpaces/fetchVoucherSpacesByVoucherId',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/voucher-has-spaces/vouchers/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Slice reducer
const voucherHasSpacesSlice = createSlice({
    name: 'voucherHasSpaces',
    initialState: {
        vouchers: [],
        selectedVoucher: null,
        voucherSpaces: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVoucherHasSpaces.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVoucherHasSpaces.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vouchers = action.payload;
            })
            .addCase(fetchVoucherHasSpaces.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createVoucherHasSpace.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vouchers.push(action.payload);
            })
            .addCase(fetchSingleVoucherHasSpace.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedVoucher = action.payload;
            })
            .addCase(updateVoucherHasSpace.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.vouchers.findIndex(voucher => voucher.id === action.payload.id);
                if (index !== -1) {
                    state.vouchers[index] = action.payload;
                }
            })
            .addCase(deleteVoucherHasSpace.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vouchers = state.vouchers.filter(voucher => voucher.id !== action.payload);
                if (state.selectedVoucher && state.selectedVoucher.id === action.payload) {
                    state.selectedVoucher = null;
                }
            })
            .addCase(fetchVoucherSpacesByVoucherId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.voucherSpaces = action.payload;
            });
    },
});

export default voucherHasSpacesSlice.reducer;
