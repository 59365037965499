import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import keycloak from "../keycloak";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getToken = async () => {
    try {
        await new Promise(resolve => setTimeout(resolve, 3000));
        if (keycloak.token) {
            return keycloak.token;
        } else {
            throw new Error('Token is not available');
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw new Error('Failed to refresh authentication token');
    }
};

const initialState = {
    profiles: [],
    status: 'idle',
    error: null,
};

export const fetchOrganizationProfiles = createAsyncThunk(
    'organizationProfiles/fetchOrganizationProfiles',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/organizations/1`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const saveOrganizationProfile = createAsyncThunk(
    'organizationProfiles/saveOrganizationProfile',
    async (profile, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/organizations`, profile, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const updateOrganizationProfile = createAsyncThunk(
    'organizationProfiles/updateOrganizationProfile',
    async ({ id, profile }, { rejectWithValue }) => {
        if (!profile || Object.keys(profile).length === 0) {
            return rejectWithValue('Profile data is required');
        }

        try {
            const token = await getToken();
            console.log('Payload data:', profile);
            const response = await axios.patch(`${API_BASE_URL}/organizations/${id}`, profile, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const organizationProfilesSlice = createSlice({
    name: 'organizationProfiles',
    initialState,
    reducers: {
        updateProfile: (state, action) => {
            console.log('Current state profiles:', state.profiles);
            const index = state.profiles.findIndex(profile => profile.id === action.payload.id);
            if (index !== -1) {
                console.log('Found existing profile:', state.profiles[index]);
                const updatedProfile = {
                    ...state.profiles[index],
                    ...action.payload,
                    data: {
                        ...state.profiles[index].data,
                    }
                };
                state.profiles[index] = updatedProfile;
            } else {
                const newProfile = {
                    ...action.payload,
                    data: {
                        name: action.payload.name,
                    }
                };
                state.profiles.push(newProfile);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationProfiles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationProfiles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.profiles = [ action.payload];
            })
            .addCase(fetchOrganizationProfiles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(saveOrganizationProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveOrganizationProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.profiles.push(action.payload);
            })
            .addCase(saveOrganizationProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })
            .addCase(updateOrganizationProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateOrganizationProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.profiles.findIndex(profile => profile.id === action.payload.id);
                if (index !== -1) {
                    state.profiles[index] = action.payload;
                }
            })
            .addCase(updateOrganizationProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    },
});

export default organizationProfilesSlice.reducer;