import React from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import OnlinePayment from "../settings/others/Payment/PaymentMethod/OnlinePayment";

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5']; // Stepper steps

const FifthStep = () => {
    const navigate = useNavigate();  // Initialize the useNavigate hook

    // Function to handle "Next" button click
    const handleConfirm = () => {
        navigate('/finish');  // Navigate to /finish
    };

    // Function to handle "Previous" button click
    const handleCancel = () => {
        navigate('/fourth');  // Navigate to /fourth
    };

    return (
        <>
            <Stepper activeStep={4} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box>
                    <Typography variant='h1' sx={{ fontSize: { xs: '24px', md: '32px' } }}>
                        Payment Method
                    </Typography>
                    <Typography variant='h2' sx={{ fontSize: { xs: '16px', md: '20px' }, marginTop: '8px' }}>
                        Select your preferred payment method.
                    </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                <OnlinePayment />

                <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-end' }, mt: 5 }}>
                    <Button
                        onClick={handleConfirm}
                        sx={{
                            textTransform: 'none',
                            color: 'orange',
                            border: 'none',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                            },
                        }}
                    >
                        Skip for now
                    </Button>
                    <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                    <Button
                        variant="contained"
                        onClick={handleConfirm}
                        sx={{ textTransform: 'none', height: '34px' }}
                    >
                        Next
                    </Button>
                </Box>
            </Paper>
        </>
    );
}

export default FifthStep;
