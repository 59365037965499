import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all features
export const fetchFeatures = createAsyncThunk('features/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/features`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific feature by ID
export const fetchFeatureById = createAsyncThunk('features/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/features/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Features
const featuresSlice = createSlice({
    name: 'features',
    initialState: {
        features: [],
        selectedFeature: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch features';
            })

            .addCase(fetchFeatureById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFeatureById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedFeature = action.payload;
            })
            .addCase(fetchFeatureById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch feature by ID';
            });
    },
});

export default featuresSlice.reducer;
