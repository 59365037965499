import {Box} from "@mui/material";
import ProductCatalog from "../../settings/product-settings/ProductCatalog/ProductCatalog";

const ListOfProductsCreated = () => {
    return (
        <Box>
            <ProductCatalog/>
        </Box>
    )
}

export default ListOfProductsCreated;