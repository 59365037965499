import React from 'react';
import AdminAccountStatusTable from "./AdminAccountStatusTable";

const Incomplete = () => {

    const rows = [
        { id: 31, merchantId: '4004001', merchantName: 'Vintage Antiques', merchantEmail: 'contact@vintageantiques.com', status: 'Expired' },
        { id: 32, merchantId: '4004002', merchantName: 'Oceanic Seafood', merchantEmail: 'info@oceanicseafood.com', status: 'Expired' },
        { id: 33, merchantId: '4004003', merchantName: 'Skyline Architects', merchantEmail: 'design@skylinearchitects.com', status: 'Expired' },
        { id: 34, merchantId: '4004004', merchantName: 'Fusion Bistro', merchantEmail: 'reservations@fusionbistro.com', status: 'Expired' },
        { id: 35, merchantId: '4004005', merchantName: 'Maple Wood Crafts', merchantEmail: 'sales@maplewoodcrafts.com', status: 'Expired' },
        { id: 36, merchantId: '4004006', merchantName: 'Bright Star Education', merchantEmail: 'admin@brightstaredu.com', status: 'Expired' },
        { id: 37, merchantId: '4004007', merchantName: 'Urban Styles', merchantEmail: 'support@urbanstyles.com', status: 'Expired' },
        { id: 38, merchantId: '4004008', merchantName: 'Green Valley Farms', merchantEmail: 'orders@greenvalleyfarms.com', status: 'Expired' },
        { id: 39, merchantId: '4004009', merchantName: 'Liberty Travel', merchantEmail: 'inquiries@libertytravel.com', status: 'Expired' },
        { id: 40, merchantId: '4004010', merchantName: 'Tech Haven', merchantEmail: 'support@techhaven.com', status: 'Expired' },
    ];
    return (
        <>
            <AdminAccountStatusTable title='Expired' rows={rows} />
        </>
    );
};

export default Incomplete;
