import { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';

import SpacesListExamples from '../spaces/SpacesListExamples';
import ServicesListExamples from '../services/ServicesListExamples';

const categories = ServicesListExamples();
const products = SpacesListExamples();

const SelectProduct = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleListItemClick = index => {
        setSelectedIndex(index);
    };

    const handleProductClick = product => {
        setSelectedProduct(product);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: { xs: '0px', sm: '32px' },
            }}
        >
            <Grid
                container
                sx={{
                    flexGrow: 1,
                    margin: { xs: '16px 0 32px 0', sm: '32px 0 52px 0' },
                }}
            >
                {/* Sidebar Menu */}
                <Grid
                    item
                    xs={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: { xs: '16px', sm: 0 },
                    }}
                >
                    <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                        <List
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                overflowX: { xs: 'scroll', sm: 'hidden' },
                            }}
                        >
                            {categories.map((category, index) => (
                                <ListItem
                                    component='div'
                                    selected={index === selectedIndex}
                                    key={index}
                                    onClick={() => handleListItemClick(index)}
                                    sx={{
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        mb: { xs: 0, sm: 1 },
                                        mr: { xs: 1, sm: 0 },
                                        cursor: 'pointer',
                                        flexDirection: 'column',
                                        backgroundColor:
                                            index === selectedIndex
                                                ? 'rgba(0, 0, 0, 0.08)'
                                                : 'inherit',
                                        '&:hover': {
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.04)',
                                        },
                                        minWidth: { xs: '100px', sm: 'auto' }, // Ensures items have a minimum width on mobile
                                        height: { xs: '140px', sm: 'auto' },
                                    }}
                                >
                                    <Box
                                        component='img'
                                        src={category.src}
                                        alt={category.name}
                                        width='50px'
                                        sx={{ borderRadius: '8px' }}
                                    />
                                    <ListItemText
                                        primary={category.name}
                                        primaryTypographyProps={{
                                            style: {
                                                height: '35px',
                                                fontSize: {
                                                    xs: '12px',
                                                    sm: '14px',
                                                },
                                            },
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>

                {/* Main Menu Content */}
                <Grid
                    xs={9}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '32px',
                        padding: { xs: '16px', sm: '32px' },
                    }}
                >
                    <Grid container spacing={2}>
                        {products.map((product, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Paper
                                    onClick={() => handleProductClick(product)}
                                    sx={{
                                        padding: { xs: '8px', sm: '16px' },
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                        cursor: 'pointer',
                                        backgroundColor:
                                            selectedProduct === product
                                                ? 'rgba(0, 0, 0, 0.08)'
                                                : 'inherit',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        '&:hover': {
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.05)',
                                        },
                                    }}
                                >
                                    <Box
                                        component='img'
                                        src={product.src}
                                        alt={product.name}
                                        sx={{
                                            borderRadius: '12px',
                                            width: '100%',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            marginTop: {
                                                xs: '8px',
                                                sm: '16px',
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                wordWrap: 'break-word',
                                                fontSize: {
                                                    xs: '16px',
                                                    sm: '18px',
                                                },
                                            }}
                                        >
                                            {product.name}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            fontSize: {
                                                xs: '16px',
                                                sm: '18px',
                                            },
                                        }}
                                    >
                                        {product.price}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            {/* Bottom Navigation */}
            <AppBar
                position='fixed'
                color='default'
                sx={{
                    top: 'auto',
                    bottom: 0,
                    backgroundColor: '#e5e5e5',
                    padding: { xs: '8px', sm: '16px' },
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', sm: 'row' },
                        justifyContent: { xs: 'space-between', sm: 'flex-end' },
                    }}
                >
                    <Typography
                        variant='h6'
                        sx={{
                            fontSize: { xs: '16px', sm: '18px' },
                            mr: { xs: 0, sm: 2 },
                        }}
                    >
                        Price Estimation
                        <br />
                        {selectedProduct ? selectedProduct.price : 'RM 0.00'}
                    </Typography>
                    <Button component={Link} to='/checkout' variant='save'>
                        Confirm
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default SelectProduct;
