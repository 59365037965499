import React, { useState } from 'react';
import { Box, Divider, Typography, Paper } from "@mui/material";
import StoreSetupForm from "../../shared/StoreSetupForm";

const StoreSetup = () => {
    const [saveSuccess, setSaveSuccess] = useState(false);

    const handleSaveSuccess = () => {
        setSaveSuccess(true);
        // You can add additional logic here, such as showing a success message or redirecting
    };

    return (
        <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <Typography variant='h1' sx={{ fontSize: { xs: '24px', md: '32px' } }}>
                    Store Setup
                </Typography>
                <Typography variant='h2' sx={{ fontSize: { xs: '16px', md: '20px' }, marginTop: '8px' }}>
                    Please provide your store details to begin setting up your online presence on Eventlah.
                </Typography>
            </Box>

            <Divider/>

            <StoreSetupForm onSaveSuccess={handleSaveSuccess} />

            {saveSuccess && (
                <Typography color="success" sx={{ mt: 2 }}>
                    Store setup saved successfully!
                </Typography>
            )}
        </Paper>
    );
};

export default StoreSetup;