import React, { useState } from 'react';
import {
    Box, Button, Card, CardContent,
    FormControl,
    Grid,
    MenuItem,
    Select, Switch,
    TextField,
    Typography
} from "@mui/material";


const AddPricingTable = () => {
    const [backgroundColor, setBackgroundColor] = useState('#C5B591');
    const [buttonColor, setButtonColor] = useState('#000000');
    const [font, setFont] = useState('Montserrat');
    const [buttonShape, setButtonShape] = useState('Pill');
    const [isMobileView, setIsMobileView] = useState(false);

    const toggleViewMode = () => {
        setIsMobileView(!isMobileView);
    };

    return (
        <Box sx={{ height: '60vh' }}>
            <Box display="flex" justifyContent="space-between">
                <Box sx={{ width:'450px', paddingTop:'32px' }}>
                    <Typography variant="h4" sx={{fontWeight:'600', fontSize:'20px'}}>Display Settings</Typography>

                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems:'center', mt:2}}>
                        <Typography variant='h6' fontSize='14px'>Toggle View</Typography>
                        <Box sx={{display:'flex', justifyContent: 'center', alignItems:'center'}}>
                            <Button variant="cancel" onClick={toggleViewMode} sx={{height:'42px'}}>
                                {isMobileView ? 'Desktop' : 'Mobile'}
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems:'center', mt:2}}>
                        <Typography variant='h6' fontSize='14px'>Language</Typography>
                        <FormControl sx={{width:'250px'}}>
                            <Select defaultValue="English">
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems:'center', mt:2 }}>
                        <Typography variant='h6' fontSize='14px'>Background Color</Typography>
                        <Box sx={{display:'flex'}}>
                            <TextField
                                type="color"
                                value={backgroundColor}
                                onChange={(e) => setBackgroundColor(e.target.value)}
                                sx={{ width:'40px',}}
                                InputProps={{
                                    sx: {
                                        '& input[type="color"]': {
                                            width: '40px',
                                            height: '40px',
                                            padding: '0',
                                            borderRadius:'8px 0 0 8px',
                                            backgroundColor: backgroundColor,
                                            border: 'none',
                                        }
                                    }
                                }}
                            />
                            <Box sx={{ display:'flex', backgroundColor:'white', alignItems:'center', boxShadow: 2, border: '1px solid #d9d9d9', borderRadius:'0px 8px 8px 0px', height:'40px', width:'100px'}}>
                                <Typography sx={{mx:'auto'}}>
                                    {backgroundColor}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems:'center', mt:2 }}>
                        <Typography variant='h6' fontSize='14px'>Button Color</Typography>
                        <Box sx={{display:'flex'}}>
                            <TextField
                                type="color"
                                value={buttonColor}
                                onChange={(e) => setButtonColor(e.target.value)}
                                sx={{ width:'40px' }}
                                InputProps={{
                                    sx: {
                                        '& input[type="color"]': {
                                            width: '40px',
                                            height: '40px',
                                            padding: '0',
                                            borderRadius:'8px 0 0 8px',
                                            backgroundColor: buttonColor,
                                            border: 'none',
                                        }
                                    }
                                }}
                            />
                            <Box sx={{ display:'flex', backgroundColor:'white', alignItems:'center', boxShadow: 2, border: '1px solid #d9d9d9', borderRadius:'0px 8px 8px 0px', height:'40px', width:'100px'}}>
                                <Typography sx={{mx:'auto'}}>
                                    {buttonColor}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems:'center', mt:2}}>
                        <Typography variant='h6' fontSize='14px'>Font</Typography>
                        <FormControl sx={{width:'200px'}}>
                            <Select
                                value={font}
                                onChange={(e) => setFont(e.target.value)}
                            >
                                <MenuItem value="Montserrat">Montserrat</MenuItem>
                                <MenuItem value="Inter">Inter</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems:'center', mt:2 }}>
                        <Typography variant='h6' fontSize='14px'>Button Shape</Typography>
                        <FormControl sx={{width:'200px'}}>
                            <Select
                                value={buttonShape}
                                onChange={(e) => setButtonShape(e.target.value)}
                            >
                                <MenuItem value="Pill">Pill</MenuItem>
                                <MenuItem value="Square">Square</MenuItem>
                                <MenuItem value="Rounded">Rounded</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems:'center', mt:2 }}>
                        <Typography variant='h6' fontSize='14px'>Highlight product</Typography>
                        <Switch />
                    </Box>
                </Box>

                <Box sx={{ width: '750px' }}>
                    { !isMobileView ?
                        <Box sx={{ display:'flex', alignItems:'center', boxShadow: 2, border: '1px solid #d9d9d9', borderRadius:'8px 8px 0 0', height:'30px'}}>
                            <Box sx={{display:'flex', gap:'4px', alignItems:'center', ml:2}}>
                                <Box sx={{height:'8px', width:'8px', backgroundColor:'#d9d9d9', borderRadius:'50%'}}/>
                                <Box sx={{height:'8px', width:'8px', backgroundColor:'#d9d9d9', borderRadius:'50%'}}/>
                                <Box sx={{height:'8px', width:'8px', backgroundColor:'#d9d9d9', borderRadius:'50%'}}/>
                            </Box>
                            <Box sx={{ mx:"auto", height:'15px', width:'500px', backgroundColor:'#d9d9d9', borderRadius:'8px' }}/>
                        </Box>
                        :
                        <Box/>
                    }

                    <Grid container
                          sx={{
                              backgroundColor: backgroundColor,
                              padding: '0 10px 0 10px',
                              mx: 'auto',
                              borderRadius: isMobileView ? '12px' : '0 0 8px 8px',
                              boxShadow: 2,
                              width: isMobileView ? '300px' : '100%',
                              maxHeight: '500px',
                              overflowY: 'auto',  // Add this line
                          }}
                    >
                        {[1, 2, 3].map((item) => (
                            <Grid item xs={isMobileView ? 12 : 4} key={item} sx={{ display: 'flex', justifyContent: 'center', }}>
                                <Card sx={{ border: 'none', boxShadow: 'none', backgroundColor: backgroundColor }}>
                                    <CardContent sx={{textAlign: isMobileView ? 'center' : 'flex-start'}}>
                                        <Typography sx={{ fontFamily: font, fontSize:'28px', fontWeight:'700' }}>Product</Typography>
                                        <Typography variant="h3" sx={{ fontFamily: font }}>
                                            A short description that describes this product tier.
                                        </Typography>
                                        <Typography variant="h4" sx={{ fontFamily: font, fontWeight: '700' }}>$0</Typography>
                                        <Typography variant="body2" sx={{ fontFamily: font }}>per month</Typography>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: buttonColor,
                                                color: '#fff',
                                                margin: '10px 0',
                                                fontFamily: font,
                                                fontWeight:'300',
                                                fontSize:'10px',
                                                textTransform: 'none',
                                                borderRadius: buttonShape === 'Pill' ? '20px' : buttonShape === 'Rounded' ? '10px' : '0px',
                                            }}
                                            fullWidth
                                        >
                                            Subscribe
                                        </Button>
                                        <Typography variant="body2" sx={{ fontFamily: font }}>This includes:</Typography>
                                        <Box
                                            component="ul"
                                            sx={{
                                                padding: 0,
                                                listStyleType: 'none',
                                                '& li': {
                                                    fontSize: '10px',
                                                    fontWeight: '700',
                                                    marginBottom: '7px',
                                                    fontFamily: font,
                                                },
                                            }}
                                        >
                                            <ul>
                                                <li>Feature 1</li>
                                                <li>Feature 2</li>
                                                <li>Feature 3</li>
                                            </ul>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default AddPricingTable;
