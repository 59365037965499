import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Link,
    MenuItem,
    Select, Switch
} from '@mui/material';
import EastIcon from "@mui/icons-material/East";

const CreateCoupon = () => {
    return (
        <Box>
            <form>
                <Box sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        label="Name"
                        placeholder="First purchase discount"
                        helperText="This will appear on customers' receipts and invoices."
                        variant="outlined"
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        label="ID"
                        placeholder="Optional"
                        helperText="This will identify this coupon in the API. We recommend leaving this blank so we can generate an ID for you."
                        variant="outlined"
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" sx={{fontWeight:'700', fontSize:'15px'}}>
                            Type
                        </FormLabel>
                        <RadioGroup defaultValue="percentage">
                            <FormControlLabel
                                value="percentage"
                                control={<Radio />}
                                label={<Typography variant="body2" fontSize="13px">Percentage discount</Typography>}
                            />
                            <FormControlLabel
                                value="fixed"
                                control={<Radio />}
                                label={<Typography variant="body2" fontSize="13px">Fixed amount discount</Typography>}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        label="Percentage off"
                        placeholder="%"
                        variant="outlined"
                        InputProps={{
                            endAdornment: <Typography>%</Typography>,
                        }}
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    <FormControlLabel control={<Checkbox />} label="Apply to specific products" />
                </Box>

               <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth variant="outlined">
                        <FormLabel sx={{fontWeight:'700', fontsize:'15px', mb:1}}>Duration</FormLabel>
                        <Select defaultValue="forever">
                            <MenuItem value="forever">Forever</MenuItem>
                            <MenuItem value="once">Once</MenuItem>
                            <MenuItem value="repeating">Repeating</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" color="textSecondary">
                        For subscriptions and customers, this determines how long this coupon will apply once redeemed. One-off invoices accept both "once" and "forever" coupons.
                        <Link href="#" color='#f5c542' underline="none" sx={{ ml: 1 }}>
                            Learn more <EastIcon sx={{fontSize:'14px', ml:'2px'}}/>
                        </Link>
                    </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <FormControlLabel control={<Checkbox />} label="Limit the date range when customers can redeem this coupon" />
                    <FormControlLabel control={<Checkbox />} label="Limit the total number of times this coupon can be redeemed" />
                </Box>
                <Box sx={{ mb: 2 }}>
                    <FormControlLabel control={<Switch />} label="Use customer-facing coupon codes" />
                </Box>
            </form>
        </Box>
    );
};

export default CreateCoupon;
