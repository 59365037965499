import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUploads = createAsyncThunk(
    'uploads/fetchUploads',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/uploads/organizations/1`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred while fetching uploads');
        }
    }
);

export const addUpload = createAsyncThunk(
    'uploads/addUpload',
    async ({ files, productId }, { rejectWithValue, dispatch }) => {
        try {
            const token = await getToken();
            const axiosInstance = axios.create({
                baseURL: API_BASE_URL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const uploadResults = [];

            for (let file of files) {
                const formData = new FormData();
                formData.append('files', file);
                formData.append('id', productId);

                const response = await axiosInstance.post('/uploads/organizations', formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        dispatch(updateProgress({ fileName: file.name, percentage }));
                    },
                });

                uploadResults.push({
                    fileName: file.name,
                    data: response.data,
                });
            }

            return uploadResults;

        } catch (error) {
            console.error('Error uploading files:', error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const deleteUpload = createAsyncThunk(
    'uploads/deleteUpload',
    async (uploadId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/uploads/organizations/${uploadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return uploadId;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred while deleting upload');
        }
    }
);

const uploadsSlice = createSlice({
    name: 'uploads',
    initialState: {
        items: [],
        progress: {},
        uploadedImages: [],
        error: null,
        status: 'idle',
    },
    reducers: {
        updateProgress: (state, action) => {
            const { fileName, percentage } = action.payload;
            state.progress[fileName] = percentage;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUploads.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUploads.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload.data.documents;
            })
            .addCase(fetchUploads.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch uploads';
            })
            .addCase(addUpload.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addUpload.fulfilled, (state, action) => {
                const uploadedFiles = action.payload || [];
                uploadedFiles.forEach((file) => {
                    const newDocuments = file.data.data.documents || [];
                    state.items.push(...newDocuments);
                    state.progress[file.fileName] = 100;
                });
                state.status = 'succeeded';
            })
            .addCase(addUpload.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to upload files';
            })
            .addCase(deleteUpload.fulfilled, (state, action) => {
                state.items = state.items.filter(upload => upload.id !== action.payload);
            })
            .addCase(deleteUpload.rejected, (state, action) => {
                state.error = action.payload || 'Failed to delete upload';
            });
    },
});

export const { updateProgress } = uploadsSlice.actions;
export default uploadsSlice.reducer;