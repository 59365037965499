import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const migrateAPI = createAsyncThunk(
    'apiMigrations/migrateAPI',
    async (migrationData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/migrations/api`,
                migrationData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const apiMigrationsSlice = createSlice({
    name: 'apiMigrations',
    initialState: {
        migrationStatus: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(migrateAPI.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(migrateAPI.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.migrationStatus = action.payload;
            })
            .addCase(migrateAPI.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate API';
            });
    },
});

export default apiMigrationsSlice.reducer;