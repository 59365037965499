import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk for migrating roles
export const migrateRoles = createAsyncThunk(
    'roleMigrations/migrateRoles',
    async (migrationData, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.post(
                `${API_BASE_URL}/migrations/roles`,
                migrationData,
                { headers }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Role Migrations slice
const roleMigrationsSlice = createSlice({
    name: 'roleMigrations',
    initialState: {
        migrationStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        migrationResult: null,
        error: null,
    },
    reducers: {
        // You can add any additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(migrateRoles.pending, (state) => {
                state.migrationStatus = 'loading';
                state.error = null;
            })
            .addCase(migrateRoles.fulfilled, (state, action) => {
                state.migrationStatus = 'succeeded';
                state.migrationResult = action.payload;
                state.error = null;
            })
            .addCase(migrateRoles.rejected, (state, action) => {
                state.migrationStatus = 'failed';
                state.error = action.payload?.message || 'Failed to migrate roles';
            });
    },
});

export default roleMigrationsSlice.reducer;
