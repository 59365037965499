import { useState } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InfoIcon from '@mui/icons-material/Info';
import { paymentOptions } from '../../settings/others/Payment/PaymentMethod/OnlinePayment';
import { bankOptions } from '../../shared/BankOptions';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const SelectPayment = () => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = event => {
        setSelectedOption(event.target.value);
    };

    return (
        <Box sx={{ padding: { xs: 0, sm: '0 32px' } }}>
            {/* Online Payment */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <AccountBalanceIcon sx={{ marginRight: 1 }} />
                    <Typography variant='body1'>Online Payment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl component='fieldset'>
                        <RadioGroup
                            value={selectedOption}
                            onChange={handleOptionChange}
                        >
                            {paymentOptions.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={option.title}
                                    control={<Radio />}
                                    sx={{ my: 1 }}
                                    label={
                                        <Grid
                                            container
                                            alignItems='center'
                                            sx={{ flexWrap: 'nowrap' }}
                                        >
                                            <Grid item>
                                                <Box
                                                    component='img'
                                                    sx={{
                                                        width: {
                                                            xs: 30,
                                                            sm: 40,
                                                        },
                                                        height: {
                                                            xs: 30,
                                                            sm: 40,
                                                        },
                                                        mr: 2,
                                                    }}
                                                    alt={option.imgAlt}
                                                    src={option.imgSrc}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2'>
                                                    {option.title}
                                                </Typography>
                                                <Typography
                                                    variant='caption'
                                                    color='textSecondary'
                                                >
                                                    {option.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>

            {/* Credit / Debit Card */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <CreditCardIcon sx={{ marginRight: 1 }} />
                    <Typography variant='body1'>Credit / Debit Card</Typography>
                    <InfoIcon sx={{ marginLeft: 1, fontSize: 'small' }} />
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl component='fieldset'>
                        <RadioGroup
                            value={selectedOption}
                            onChange={handleOptionChange}
                        >
                            {bankOptions.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={option.title}
                                    control={<Radio />}
                                    sx={{ my: { xs: 0, sm: 1 } }}
                                    label={
                                        <Grid
                                            container
                                            alignItems='center'
                                            sx={{ flexWrap: 'nowrap' }}
                                        >
                                            <Grid item>
                                                <Box
                                                    component='img'
                                                    sx={{
                                                        maxHeight: {
                                                            xs: '20px',
                                                            sm: '30px',
                                                        },
                                                        maxWidth: {
                                                            xs: '30px',
                                                            sm: '50px',
                                                        },
                                                        mr: 2,
                                                    }}
                                                    alt={option.imgAlt}
                                                    src={option.imgSrc}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2'>
                                                    {option.title}
                                                </Typography>
                                                <Typography
                                                    variant='caption'
                                                    color='textSecondary'
                                                >
                                                    {option.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>

            {/* Partial Payment */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <AccountBalanceWalletIcon sx={{ marginRight: 1 }} />
                    <Typography variant='body1'>Partial Payment</Typography>
                    <InfoIcon sx={{ marginLeft: 1, fontSize: 'small' }} />
                </AccordionSummary>
                <AccordionDetails>
                    {/* 1 Month Plan */}
                    <Typography variant='caption' sx={{ marginBottom: 1 }}>
                        1 Month - RM69.85 (0% interest)
                    </Typography>
                    <Divider />

                    {/* 3 Months Plan - Recommended */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        <Typography variant='caption'>
                            3 Months - RM23.28/month (0% interest)
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: '#ff5722',
                                color: '#fff',
                                borderRadius: 1,
                                paddingX: 1,
                                marginLeft: 1,
                                fontSize: '0.75rem',
                            }}
                        >
                            Recommended
                        </Box>
                    </Box>
                    <Divider />

                    {/* 6 Months Plan */}
                    <Typography
                        variant='caption'
                        sx={{ marginTop: 1, marginBottom: 1 }}
                    >
                        6 Months - RM12.35/month (1% interest)
                    </Typography>
                    <Divider />

                    {/* 12 Months Plan */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        <Typography variant='caption'>
                            12 Months - RM7.22/month (2% interest)
                        </Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default SelectPayment;
