import React, { useState } from 'react';
import {Box, Button, Paper, Typography, TextField, Chip, Divider, IconButton, Switch} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DataGrid } from "@mui/x-data-grid";
import GetAppIcon from "@mui/icons-material/GetApp";
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import {ViewIcon} from "../../../shared/IconButtons";

const initialRows = [
    { id: 1, email: 'john.doe@example.com', name: 'John Doe', billingDate: '2023-07-10', plan: 'Eventlah Premium', dateSubscribed: 'Jul 10, 2:45 PM', emailReminder: true },
    { id: 2, email: 'jane.smith@email.com', name: 'Jane Smith', billingDate: '2023-07-09', plan: 'Eventlah Premium', dateSubscribed: 'Jul 9, 12:39 PM', emailReminder: false },
    { id: 3, email: 'michael.brown@webmail.com', name: 'Michael Brown', billingDate: '2023-08-01', plan: 'Eventlah Premium', dateSubscribed: 'Aug 1, 10:22 AM', emailReminder: true },
    { id: 4, email: 'sarah.jones@domain.com', name: 'Sarah Jones', billingDate: '2023-08-03', plan: 'Eventlah Premium', dateSubscribed: 'Aug 3, 1:15 PM', emailReminder: false },
    { id: 5, email: 'robert.wilson@service.com', name: 'Robert Wilson', billingDate: '2023-08-05', plan: 'Eventlah Premium', dateSubscribed: 'Aug 5, 2:15 PM', emailReminder: true },
    { id: 6, email: 'emily.davis@mail.com', name: 'Emily Davis', billingDate: '2023-07-20', plan: 'Eventlah Premium', dateSubscribed: 'Jul 20, 9:45 AM', emailReminder: false },
    { id: 7, email: 'daniel.taylor@company.com', name: 'Daniel Taylor', billingDate: '2023-07-25', plan: 'Eventlah Premium', dateSubscribed: 'Jul 25, 9:50 AM', emailReminder: true },
    { id: 8, email: 'alice.martin@workplace.com', name: 'Alice Martin', billingDate: '2023-08-07', plan: 'Eventlah Basic', dateSubscribed: 'Aug 7, 3:10 PM', emailReminder: false },
    { id: 9, email: 'charlie.adams@webmail.com', name: 'Charlie Adams', billingDate: '2023-07-30', plan: 'Eventlah Pro', dateSubscribed: 'Jul 30, 8:05 AM', emailReminder: true },
    { id: 10, email: 'olivia.jones@internet.com', name: 'Olivia Jones', billingDate: '2023-07-15', plan: 'Eventlah Standard', dateSubscribed: 'Jul 15, 2:30 PM', emailReminder: false }
];

const statusColors = {
    'Active': { backgroundColor: '#20c52f', textColor: '#ffffff' },
    'Inactive': { backgroundColor: '#ff0000', textColor: '#ffffff' }
};

const StatusChip = ({ status }) => {
    const { backgroundColor, textColor } = statusColors[status] || {};
    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.7rem',
                height: '20px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const AllSubscriptions = () => {
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(initialRows);
    const [filteredRows, setFilteredRows] = useState(rows);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchText(value);
        setFilteredRows(
            rows.filter((row) =>
                Object.keys(row).some((key) =>
                    String(row[key]).toLowerCase().includes(value.toLowerCase())
                )
            )
        );
    };

    const handleView = (id) => {
        navigate(`/settings/subscriptions/details/${id}`);
    };

    const handleEmailReminderToggle = (id) => {
        const updatedRows = rows.map(row =>
            row.id === id ? { ...row, emailReminder: !row.emailReminder } : row
        );
        setRows(updatedRows);
        setFilteredRows(updatedRows);
    };

    const columns = [
        { field: 'email', headerName: 'Email', flex: 0.8 },
        { field: 'name', headerName: 'Customer name', flex: 0.6 },
        { field: 'billingDate', headerName: 'Billing Date', flex: 0.4 },
        { field: 'plan', headerName: 'Plan', flex: 0.5 },
        { field: 'dateSubscribed', headerName: 'Date Subscribed', flex: 0.5 },
        {
            field: 'emailReminder',
            headerName: 'Email Reminder',
            flex: 0.45,
            renderCell: (params) => (
                <Switch
                    checked={params.row.emailReminder}
                    onChange={() => handleEmailReminderToggle(params.row.id)}
                    inputProps={{ 'aria-label': 'Email Reminder Switch' }}
                />
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.2,
            renderCell: (params) => (
                <IconButton aria-label="view" onClick={() => handleView(params.row.id)}>
                    <ViewIcon/>
                </IconButton>
            )
        },
    ];

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#5e21d9', padding: '15px 32px', borderRadius: '8px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500', color: 'white' }}>
                    Learn how to set up subscriptions
                </Typography>
                <Button variant="cancel" color="secondary">
                    Get started <ArrowForwardIosIcon sx={{ fontSize: 'small' }} />
                </Button>
            </Box>

            <Divider />

            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '8px' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search…"
                        value={searchText}
                        onChange={handleSearch}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& input': { height: '34px', padding: '0 14px' }
                            }
                        }}
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button variant="cancel" startIcon={<GetAppIcon />}>
                            Export
                        </Button>
                        <Button variant="cancel" startIcon={<AssessmentIcon />}>
                            Analyze
                        </Button>
                        <Button variant="cancel" startIcon={<SettingsIcon />}>
                            Edit columns
                        </Button>
                    </Box>
                </Box>

                <Paper sx={{ width: "100%", borderRadius: "8px", border: "1px solid var(--Gray-200, #EAECF0)", background: "var(--White, #FFF)", boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)" }}>
                    <Typography sx={{ color: "var(--Gray-900, #101828)", fontFamily: "Inter", fontSize: "18px", fontWeight: 500, lineHeight: "28px", padding: "20px 24px" }}>User Accounts</Typography>
                    <DataGrid
                        rows={filteredRows}
                        columns={columns}
                        checkboxSelection
                        disableSelectionOnClick
                        rowHeight={70}
                        pageSizeOptions={[5, 10, 20]}
                        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default AllSubscriptions;