import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchPaymentStructures = createAsyncThunk(
    'adminPaymentStructures/fetchPaymentStructures',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/payment-structures`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params,
            });
            console.log('fetchPaymentStructures response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchPaymentStructures error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const createPaymentStructure = createAsyncThunk(
    'adminPaymentStructures/createPaymentStructure',
    async (paymentStructureData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/payment-structures`,
                paymentStructureData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('createPaymentStructure response:', response.data);
            return response.data;
        } catch (error) {
            console.error('createPaymentStructure error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchSinglePaymentStructure = createAsyncThunk(
    'adminPaymentStructures/fetchSinglePaymentStructure',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/payment-structures/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchSinglePaymentStructure response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchSinglePaymentStructure error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deletePaymentStructure = createAsyncThunk(
    'adminPaymentStructures/deletePaymentStructure',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/payment-structures/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deletePaymentStructure success for id:', id);
            return id;
        } catch (error) {
            console.error('deletePaymentStructure error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const updatePaymentStructure = createAsyncThunk(
    'adminPaymentStructures/updatePaymentStructure',
    async ({ id, ...updateData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/payment-structures/${id}`,
                updateData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('updatePaymentStructure response:', response.data);
            return response.data;
        } catch (error) {
            console.error('updatePaymentStructure error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

const adminPaymentStructuresSlice = createSlice({
    name: 'adminPaymentStructures',
    initialState: {
        paymentStructures: [],
        selectedPaymentStructure: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentStructures.pending, (state) => {
                state.status = 'loading';
                console.log('fetchPaymentStructures pending');
            })
            .addCase(fetchPaymentStructures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentStructures = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
                console.log('fetchPaymentStructures fulfilled, state:', state);
            })
            .addCase(fetchPaymentStructures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch payment structures';
                console.error('fetchPaymentStructures rejected, error:', state.error);
            })
            .addCase(createPaymentStructure.fulfilled, (state, action) => {
                state.paymentStructures.push(action.payload);
                state.pagination.totalItems += 1;
                console.log('createPaymentStructure fulfilled, updated state:', state);
            })
            .addCase(fetchSinglePaymentStructure.fulfilled, (state, action) => {
                state.selectedPaymentStructure = action.payload;
                console.log('fetchSinglePaymentStructure fulfilled, selectedPaymentStructure:', state.selectedPaymentStructure);
            })
            .addCase(deletePaymentStructure.fulfilled, (state, action) => {
                state.paymentStructures = state.paymentStructures.filter(ps => ps.id !== action.payload);
                state.pagination.totalItems -= 1;
                if (state.selectedPaymentStructure && state.selectedPaymentStructure.id === action.payload) {
                    state.selectedPaymentStructure = null;
                }
                console.log('deletePaymentStructure fulfilled, updated state:', state);
            })
            .addCase(updatePaymentStructure.fulfilled, (state, action) => {
                const index = state.paymentStructures.findIndex(ps => ps.id === action.payload.id);
                if (index !== -1) {
                    state.paymentStructures[index] = action.payload;
                }
                if (state.selectedPaymentStructure && state.selectedPaymentStructure.id === action.payload.id) {
                    state.selectedPaymentStructure = action.payload;
                }
                console.log('updatePaymentStructure fulfilled, updated state:', state);
            });
    },
});

export default adminPaymentStructuresSlice.reducer;