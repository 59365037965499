import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all payment structures
export const fetchPaymentStructures = createAsyncThunk('paymentStructures/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/payment-structures`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new payment structure
export const createPaymentStructure = createAsyncThunk('paymentStructures/create', async (structureData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/payment-structures`, structureData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific payment structure by ID
export const fetchPaymentStructureById = createAsyncThunk('paymentStructures/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/payment-structures/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a payment structure by ID
export const deletePaymentStructure = createAsyncThunk('paymentStructures/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/payment-structures/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a payment structure by ID
export const updatePaymentStructure = createAsyncThunk('paymentStructures/update', async ({ id, structureData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/payment-structures/${id}`, structureData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Payment Structures
const paymentStructuresSlice = createSlice({
    name: 'paymentStructures',
    initialState: {
        paymentStructures: [],
        selectedPaymentStructure: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentStructures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentStructures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentStructures = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchPaymentStructures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch payment structures';
            })

            .addCase(createPaymentStructure.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPaymentStructure.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentStructures.push(action.payload);
            })
            .addCase(createPaymentStructure.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create payment structure';
            })

            .addCase(fetchPaymentStructureById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentStructureById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPaymentStructure = action.payload;
            })
            .addCase(fetchPaymentStructureById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch payment structure by ID';
            })

            .addCase(deletePaymentStructure.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePaymentStructure.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentStructures = state.paymentStructures.filter(structure => structure.id !== action.payload);
                if (state.selectedPaymentStructure && state.selectedPaymentStructure.id === action.payload) {
                    state.selectedPaymentStructure = null;
                }
            })
            .addCase(deletePaymentStructure.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete payment structure';
            })

            .addCase(updatePaymentStructure.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePaymentStructure.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.paymentStructures.findIndex(structure => structure.id === action.payload.id);
                if (index !== -1) state.paymentStructures[index] = action.payload;
                if (state.selectedPaymentStructure && state.selectedPaymentStructure.id === action.payload.id) {
                    state.selectedPaymentStructure = action.payload;
                }
            })
            .addCase(updatePaymentStructure.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update payment structure';
            });
    },
});

export default paymentStructuresSlice.reducer;
