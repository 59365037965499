import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunks for role operations
export const fetchRoles = createAsyncThunk(
    'roles/fetchRoles',
    async (_, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/roles`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createRole = createAsyncThunk(
    'roles/createRole',
    async (roleData, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/roles`, roleData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateRole = createAsyncThunk(
    'roles/updateRole',
    async (roleData, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/roles`, roleData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchRoleById = createAsyncThunk(
    'roles/fetchRoleById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/roles/${id}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteRole = createAsyncThunk(
    'roles/deleteRole',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/roles/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Roles slice
const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: [],
        selectedRole: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {
        // You can add any additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            // Fetch all roles
            .addCase(fetchRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = action.payload;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch roles';
            })
            // Create role
            .addCase(createRole.fulfilled, (state, action) => {
                state.roles.push(action.payload);
            })
            // Update role
            .addCase(updateRole.fulfilled, (state, action) => {
                const index = state.roles.findIndex(role => role.id === action.payload.id);
                if (index !== -1) {
                    state.roles[index] = action.payload;
                }
                if (state.selectedRole && state.selectedRole.id === action.payload.id) {
                    state.selectedRole = action.payload;
                }
            })
            // Fetch role by ID
            .addCase(fetchRoleById.fulfilled, (state, action) => {
                state.selectedRole = action.payload;
            })
            // Delete role
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.roles = state.roles.filter(role => role.id !== action.payload);
                if (state.selectedRole && state.selectedRole.id === action.payload) {
                    state.selectedRole = null;
                }
            });
    },
});

export default rolesSlice.reducer;