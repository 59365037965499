import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch a voucher by code
export const fetchVoucherByCode = createAsyncThunk('public/fetchVoucherByCode', async (code, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/public/vouchers/${code}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch event types
export const fetchEventTypes = createAsyncThunk('public/fetchEventTypes', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/public/event-types`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch all event types
export const fetchAllEventTypes = createAsyncThunk('public/fetchAllEventTypes', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/public/event-types/all`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Public APIs
const publicSlice = createSlice({
    name: 'public',
    initialState: {
        voucher: null,
        eventTypes: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVoucherByCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVoucherByCode.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.voucher = action.payload;
            })
            .addCase(fetchVoucherByCode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch voucher';
            })

            .addCase(fetchEventTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventTypes = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchEventTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event types';
            })

            .addCase(fetchAllEventTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllEventTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventTypes = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchAllEventTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch all event types';
            });
    },
});

export default publicSlice.reducer;
