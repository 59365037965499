import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Link,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Divider,
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import EastIcon from '@mui/icons-material/East';
import FilterListIcon from "@mui/icons-material/FilterList";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddTaxRate from "./AddTaxRate";

const TaxRate = () => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h1'>
                        Tax Rates
                    </Typography >
                    <Typography variant='h2'>
                        Set up tax rates for different regions and product categories.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '8px' }}>
                    <Button variant="cancel" startIcon={<FilterListIcon />}>
                        Filter
                    </Button>
                    <Button variant="cancel" startIcon={<AddIcon />}>
                        New
                        <Typography variant="caption" sx={{ ml: 1, backgroundColor: '#f0f0f0', p: '0 4px', borderRadius: 1 }}>
                            N
                        </Typography>
                    </Button>
                    <Button variant="cancel" startIcon={<GetAppIcon />}>
                        Export
                    </Button>
                </Box>
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop:'150px'}}>
                <Box>
                    <IconButton sx={{ backgroundColor: '#e0e0e0', p: 2, borderRadius: 1, mb: 4 }}>
                        <AttachMoneyIcon sx={{ fontSize: 40 }} />
                    </IconButton>

                    <Typography variant="h5" sx={{ fontSize:'22px', fontWeight: '700', mb: 1 }}>
                        No tax rates
                    </Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 400, mb: 1 }}>
                        Apply manual tax rates to invoices, subscriptions, and one-time payments or subscriptions created through Stripe Checkout.
                    </Typography>
                    <Link href="#" variant="body2" color="#f5c542" underline="none">
                        Learn more <EastIcon sx={{fontSize:'14px', ml:'2px'}}/>
                    </Link>
                    <Box sx={{ marginTop: 3 }}>
                        <Button variant="save" startIcon={<AddIcon />} onClick={handleClickOpen}>
                            Add a tax rate
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Box sx={{padding:'15px 10px', backgroundColor:'#f5f5f5'}}>
                    <DialogTitle>
                        <Typography variant='h1' fontSize='25px' fontWeight='700'>
                            Add tax rate
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <AddTaxRate />
                    </DialogContent>
                    <DialogActions>
                        <Button variant='cancel' onClick={handleClose} >
                            Cancel
                        </Button>
                        <Button variant="save">
                            Add tax rate
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    );
};

export default TaxRate;
