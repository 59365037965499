import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Typography,
    Chip,
    Divider,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { GetApp, Edit } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fetchEvents } from '../../../redux/eventsSlice';

const statusColors = {
    'Succeeded': { backgroundColor: '#20c52f', textColor: '#ffffff' },
    'Failed': { backgroundColor: '#ff0000', textColor: '#ffffff' },
    'Pending': { backgroundColor: '#ffa500', textColor: '#ffffff' },
};

const StatusChip = ({ status }) => {
    const { backgroundColor, textColor } = statusColors[status] || {};
    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.6rem',
                height: '20px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const TransactionHistory = () => {
    const dispatch = useDispatch();
    const eventsData = useSelector((state) => state.events.events);
    const eventStatus = useSelector((state) => state.events.status);
    const [open, setOpen] = useState(false);
    const [openPaymentSuccessDialog, setOpenPaymentSuccessDialog] = useState(false);
    const [transactionsData, setTransactionsData] = useState([]);

    useEffect(() => {
        if (eventStatus === 'idle') {
            dispatch(fetchEvents());
        }
    }, [eventStatus, dispatch]);

    useEffect(() => {
        if (Array.isArray(eventsData)) {
            // Transform events data to transaction history format
            console.log(eventsData);
            const transformedData = eventsData.map(event => ({
                id: event.id,
                amount: `RM${event.totalAmount?.toFixed(2) || '0.00'}`,
                status: event.status || 'Pending',
                paymentMethod: event.paymentMethod || 'N/A',
                description: event.description || 'Event transaction',
                customer: event.space?.name || 'N/A',  // Set customer as space.name
                date: event.activityDate || '-',
                refundedDate: event.refundedDate || '-',
                declineReason: event.declineReason || '-',
            }));
            setTransactionsData(transformedData);
        }
    }, [eventsData]);


    const handleConfirm = () => {
        setOpen(false);
        setOpenPaymentSuccessDialog(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        { field: 'amount', headerName: 'Amount', flex: 0.4 },
        { field: 'status', headerName: 'Status', flex: 0.3, renderCell: (params) => <StatusChip status={params.value} /> },
        { field: 'paymentMethod', headerName: 'Payment Method', flex: 0.5 },
        { field: 'description', headerName: 'Description', flex: 0.7 },
        { field: 'customer', headerName: 'Customer', flex: 0.4 },
        { field: 'date', headerName: 'Date', flex: 0.4 },
        { field: 'refundedDate', headerName: 'Refunded Date', flex: 0.5, valueGetter: (params) => params.value ? params.value : '-' },
        { field: 'declineReason', headerName: 'Decline Reason', flex: 0.5, valueGetter: (params) => params.value ? params.value : '-' }
    ];

    return (
        <Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                <Box>
                    <Typography variant='h1'>Transaction History</Typography>
                    <Typography variant='h2'>View your transaction history and details.</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button variant='save' startIcon={<AddIcon />}>
                        Create payment
                    </Button>
                    <Button variant='cancel' onClick={() => setOpen(true)}>
                        Verify this Order
                    </Button>
                </Box>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', paddingBottom: '8px' }}>
                <Button variant='cancel' startIcon={<GetApp />}>
                    Export
                </Button>
                <Button variant='cancel' startIcon={<Edit />}>
                    Edit columns
                </Button>
            </Box>

            <Paper sx={{ borderRadius: '8px', boxShadow: 'none' }}>
                {eventStatus === 'loading' ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        rows={transactionsData}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={70}
                        pageSizeOptions={[7, 10, 20]}
                        initialState={{ pagination: { paginationModel: { pageSize: 7 } } }}
                    />
                )}
            </Paper>

            {/* Dialog for Verification */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Verify Order</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to verify this order?</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ mb: 1 }}>
                    <Button variant='save' onClick={handleConfirm}>Confirm</Button>
                    <Button variant='cancel' onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Payment Success Dialog */}
            <Dialog
                open={openPaymentSuccessDialog}
                onClose={() => setOpenPaymentSuccessDialog(false)}
                maxWidth='xs'
                fullWidth
            >
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        padding: { xs: '24px', sm: '32px' },
                    }}
                >
                    <CheckCircleIcon
                        color='success'
                        sx={{ fontSize: { xs: 40, sm: 60 } }}
                    />
                    <Typography
                        variant='h5'
                        fontWeight='bold'
                        gutterBottom
                        sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                    >
                        Order Successfully Verified!
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        gutterBottom
                    >
                        The order has been successfully verified. A confirmation email has been sent to admin@mycompany.com.
                    </Typography>
                    <Typography variant='body2' fontWeight='bold' gutterBottom>
                        Order Number
                    </Typography>
                    <Typography variant='h6' gutterBottom>
                        12345
                    </Typography>
                    <Button
                        variant='save'
                        onClick={() => setOpenPaymentSuccessDialog(false)}
                        sx={{
                            marginTop: '16px',
                            width: { xs: '100%', sm: 'auto' },
                        }}
                    >
                        Return to Orders
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default TransactionHistory;