import React, { useState, useEffect } from "react";
import { Box, Divider, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';
import {createOrUpdateProduct} from "../../../../../redux/productSlice";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '312px',
}));

const CustomizeProduct = () => {
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const product = location.state?.product || {};  // Get product data from location state, or set it to an empty object

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            title: product.name || '',
            price: product.price || '',
            description: product.description || '',
        }
    });

    const [loading, setLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);

    // Function to set the organization
    const setOrganization = async () => {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

        try {
            await axios.post(`${API_BASE_URL}/profiles/self/organizations/1`, {
                id: 1 // The organization ID to set
            }, {
                headers: {
                    'Authorization': `Bearer ${keycloak.token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Organization set successfully');
        } catch (error) {
            console.error('Error setting the organization:', error);
        }
    };

    useEffect(() => {
        if (initialized) {
            setOrganization();
        }
    }, [initialized]);

    const onSubmit = async (data) => {
        setLoading(true);
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

        const postData = {
            name: data.title,
            minimum_pax: 0,
            maximum_pax: 0,
            minimum_amount: parseFloat(data.price),
            maximum_amount: parseFloat(data.price),
            description: data.description,
            organizationId: 1,
            categoryId: 2,
            // minimumCurrencyId: 2,
            // maximumCurrencyId: 1,
            minimumCurrencyId: 2,
            maximumCurrencyId: 2,
            statusOwnershipId: 15,
        };

        try {
            await dispatch(createOrUpdateProduct(postData)).unwrap();
            setSuccessDialogOpen(true);
        } catch (error) {
            console.error("There was an error creating/updating the service:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDialogClose = () => {
        setSuccessDialogOpen(false);
        navigate('/settings/product-catalog');
    };

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex">
                    <TextFieldTitle>Product Name</TextFieldTitle>
                    <Controller
                        name="title"
                        control={control}
                        rules={{ required: "Product name is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the name for product"
                                fullWidth
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                sx={{ paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }}}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex">
                    <TextFieldTitle>Product Price</TextFieldTitle>
                    <Controller
                        name="price"
                        control={control}
                        rules={{
                            required: "Price is required",
                            validate: {
                                positive: value => parseFloat(value) > 0 || "Please enter a valid price. Use numbers only (e.g., 59.99)",
                                float: value => /^[+-]?\d+(\.\d+)?$/.test(value) || "Please enter a valid price. Use numbers only (e.g., 59.99)"
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the pricing for product"
                                fullWidth
                                error={!!errors.price}
                                helperText={errors.price?.message}
                                inputProps={{
                                    inputMode: 'decimal', // Opens numeric keyboard on mobile devices
                                    pattern: '[0-9.]*',
                                    step: '0.01',
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    // Allow only numbers and a single decimal point
                                    if (/^\d*\.?\d*$/.test(value)) {
                                        field.onChange(value);
                                    }
                                }}
                                sx={{ paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }}}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex">
                    <TextFieldTitle>Description for Product</TextFieldTitle>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the description for product"
                                fullWidth
                                multiline
                                rows={7}
                                inputProps={{ maxLength: 250 }}
                                helperText={`${field.value.length}/250`}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '15px',
                                        '& .MuiOutlinedInput-input': {
                                            padding: 0,
                                        },
                                    },
                                    paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }
                                }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box sx={{ marginTop: '16px' }}>
                    <Button
                        variant="save"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save Product'}
                    </Button>
                </Box>
            </form>

            <Dialog
                open={successDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The product has been saved successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='save' autoFocus sx={{margin:'0 18px 8px 0'}}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomizeProduct;