import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all statuses
export const fetchStatuses = createAsyncThunk('referenceStatuses/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/statuses`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new status
export const createStatus = createAsyncThunk('referenceStatuses/create', async (statusData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/statuses`, statusData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a status by ID
export const updateStatus = createAsyncThunk('referenceStatuses/update', async ({ id, statusData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/statuses/${id}`, statusData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific status by ID
export const fetchStatusById = createAsyncThunk('referenceStatuses/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/statuses/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a status by ID
export const deleteStatus = createAsyncThunk('referenceStatuses/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/statuses/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Reference Statuses
const referenceStatusesSlice = createSlice({
    name: 'referenceStatuses',
    initialState: {
        statuses: [],
        selectedStatus: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatuses.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStatuses.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuses = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchStatuses.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch statuses';
            })

            .addCase(createStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuses.push(action.payload);
            })
            .addCase(createStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create status';
            })

            .addCase(updateStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.statuses.findIndex(status => status.id === action.payload.id);
                if (index !== -1) {
                    state.statuses[index] = action.payload;
                }
            })
            .addCase(updateStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update status';
            })

            .addCase(fetchStatusById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStatusById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedStatus = action.payload;
            })
            .addCase(fetchStatusById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch status by ID';
            })

            .addCase(deleteStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuses = state.statuses.filter(status => status.id !== action.payload);
                if (state.selectedStatus && state.selectedStatus.id === action.payload) {
                    state.selectedStatus = null;
                }
            })
            .addCase(deleteStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete status';
            });
    },
});

export default referenceStatusesSlice.reducer;
