import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUserOrganizations = createAsyncThunk(
    'adminUserOrganizations/fetchUserOrganizations',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-organizations`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createUserOrganization = createAsyncThunk(
    'adminUserOrganizations/createUserOrganization',
    async (userOrgData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/user-organizations`,
                userOrgData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const changeUserOrganizationDefault = createAsyncThunk(
    'adminUserOrganizations/changeUserOrganizationDefault',
    async ({ id, isActive }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/user-organizations/${id}/default/${isActive}`,
                {},
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleUserOrganization = createAsyncThunk(
    'adminUserOrganizations/fetchSingleUserOrganization',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-organizations/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserOrganization = createAsyncThunk(
    'adminUserOrganizations/deleteUserOrganization',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-organizations/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserOrganizationsByUserId = createAsyncThunk(
    'adminUserOrganizations/fetchUserOrganizationsByUserId',
    async (userId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-organizations/users/${userId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { userId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserOrganizationsByUserId = createAsyncThunk(
    'adminUserOrganizations/deleteUserOrganizationsByUserId',
    async (userId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-organizations/users/${userId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return userId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserOrganizationsByOrganizationId = createAsyncThunk(
    'adminUserOrganizations/fetchUserOrganizationsByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-organizations/organization/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { organizationId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserOrganizationsByOrganizationId = createAsyncThunk(
    'adminUserOrganizations/deleteUserOrganizationsByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-organizations/organization/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return organizationId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminUserOrganizationsSlice = createSlice({
    name: 'adminUserOrganizations',
    initialState: {
        userOrganizations: [],
        selectedUserOrganization: null,
        userOrganizationsByUser: {},
        userOrganizationsByOrganization: {},
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserOrganizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userOrganizations = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(createUserOrganization.fulfilled, (state, action) => {
                state.userOrganizations.push(action.payload);
            })
            .addCase(changeUserOrganizationDefault.fulfilled, (state, action) => {
                const index = state.userOrganizations.findIndex(uo => uo.id === action.payload.id);
                if (index !== -1) {
                    state.userOrganizations[index] = action.payload;
                }
            })
            .addCase(fetchSingleUserOrganization.fulfilled, (state, action) => {
                state.selectedUserOrganization = action.payload;
            })
            .addCase(deleteUserOrganization.fulfilled, (state, action) => {
                state.userOrganizations = state.userOrganizations.filter(uo => uo.id !== action.payload);
                if (state.selectedUserOrganization && state.selectedUserOrganization.id === action.payload) {
                    state.selectedUserOrganization = null;
                }
            })
            .addCase(fetchUserOrganizationsByUserId.fulfilled, (state, action) => {
                state.userOrganizationsByUser[action.payload.userId] = action.payload.data;
            })
            .addCase(deleteUserOrganizationsByUserId.fulfilled, (state, action) => {
                delete state.userOrganizationsByUser[action.payload];
                state.userOrganizations = state.userOrganizations.filter(uo => uo.userId !== action.payload);
            })
            .addCase(fetchUserOrganizationsByOrganizationId.fulfilled, (state, action) => {
                state.userOrganizationsByOrganization[action.payload.organizationId] = action.payload.data;
            })
            .addCase(deleteUserOrganizationsByOrganizationId.fulfilled, (state, action) => {
                delete state.userOrganizationsByOrganization[action.payload];
                state.userOrganizations = state.userOrganizations.filter(uo => uo.organizationId !== action.payload);
            })
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.payload?.message || 'An error occurred';
                }
            )
            .addMatcher(
                action => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            );
    },
});

export default adminUserOrganizationsSlice.reducer;