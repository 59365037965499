import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const pastPayments = [
    { month: 'Jul', paymentDate: '10 Aug 2024', amount: 'RM40.00' },
    { month: 'Jun', paymentDate: '10 Jul 2024', amount: 'RM38.50' },
    { month: 'May', paymentDate: '10 Jun 2024', amount: 'RM42.75' },
    { month: 'Apr', paymentDate: '10 May 2024', amount: 'RM36.30' },
    { month: 'Mar', paymentDate: '10 Apr 2024', amount: 'RM50.00' },
    { month: 'Feb', paymentDate: '10 Mar 2024', amount: 'RM90.00' },
];

const Billed = () => {
    return (
        <Container maxWidth='md'>
            {pastPayments.map((payment, index) => (
                <Accordion
                    key={index}
                    sx={{ marginBottom: 2, boxShadow: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Box display='flex' alignItems='center' width='100%'>
                            {/* Month, Payment Label and Payment Date */}
                            <Box flex='3'>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '1rem', sm: '1.25rem' },
                                    }}
                                >
                                    {payment.month}
                                </Typography>

                                <Box
                                    sx={{
                                        border: '1px solid rgba(0, 128, 0, 0.4)',
                                        display: 'inline-block',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: '10px',
                                                sm: '12px',
                                            },
                                            fontWeight: '400',
                                            color: 'green',
                                            padding: '0 4px',
                                        }}
                                    >
                                        Paid
                                    </Typography>
                                </Box>

                                <Typography
                                    variant='body2'
                                    color='textSecondary'
                                    sx={{
                                        fontSize: {
                                            xs: '0.75rem',
                                            sm: '0.875rem',
                                        },
                                    }}
                                >
                                    Payment Date {payment.paymentDate}
                                </Typography>
                            </Box>
                            {/* Amount */}
                            <Box flex='2' textAlign='right'>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '1rem', sm: '1.25rem' },
                                    }}
                                >
                                    {payment.amount}
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                            sx={{
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                            }}
                        >
                            This is the detailed view of the payment for{' '}
                            {payment.month}.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
};

export default Billed;
