import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            console.log('Token received in fetchProducts:', token); // Log the token
            const response = await axios.get(`${API_BASE_URL}/services`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data.data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 502) {
                    console.error('Error in fetchProducts: 502 Bad Gateway', error);
                    return rejectWithValue({ message: '502 Bad Gateway: Unable to connect to API' });
                } else {
                    console.error('Error in fetchProducts:', error);
                    return rejectWithValue({ message: error.message });
                }
            }
            console.error('Error in fetchProducts:', error);
            return rejectWithValue({ message: 'An unknown error occurred' });
        }
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async ({ id }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            console.log('Token received in deleteProduct:', token); // Log the token
            await axios.delete(`${API_BASE_URL}/services/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return id;
        } catch (error) {
            console.error('Error in deleteProduct:', error);
            return rejectWithValue(error.message);
        }
    }
);

export const createOrUpdateProduct = createAsyncThunk(
    'products/createOrUpdateProduct',
    async (productData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/services`, productData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in createOrUpdateProduct:', error);
            return rejectWithValue(error.message);
        }
    }
);

const productSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload.map((item) => ({
                    id: item.id,
                    name: item.name,
                    status: item.statusOwnership.status.name,
                    price: item.minimum_amount,
                    description: item.description,
                    // pricing: `${item.minimumCurrency.symbol}${item.minimum_amount} - ${item.maximumCurrency.symbol}${item.maximum_amount}`,
                    pricing: item.minimum_amount,
                    created: new Date().toLocaleDateString(),
                    updated: new Date().toLocaleDateString(),
                }));
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.products = state.products.filter(product => product.id !== action.payload);
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    }
});

export default productSlice.reducer;