import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, } from "@mui/material";

const SettingsLayout = () => {
    return (
        <Box sx={{ padding: '32px', height: '100%', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
            <Outlet />
        </Box>
    );
};

export default SettingsLayout;
