import {Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, InputAdornment, Link, TextField, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";

const AddNewUsers = () => {
    return (
        <Box>
            <Typography sx={{fontSize:'20px', fontWeight:'700', marginBottom:'10px'}}>
                Invite team members
            </Typography>

            <Box sx={{display:'flex', background:'white'}}>
                <Box sx={{
                    width: '50%',
                    border: '2px solid',
                    borderRight:'none',
                    borderColor: 'var(--Gray-200, #EAECF0)'
                }}>
                    <Box sx={{padding:2}}>
                        <FormControl fullWidth margin="normal">
                            <FormLabel sx={{fontWeight:'700', fontSize:'14px', marginBottom:'8px'}}>Enter team member email addresses</FormLabel>
                            <TextField
                                variant="outlined"
                                placeholder="ada@eventlah.com, andy@example.com, etc."
                            />
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <FormLabel sx={{fontWeight:'600', fontSize:'14px', marginBottom:'8px'}}>Select team member roles</FormLabel>
                            <TextField
                                variant="outlined"
                                placeholder="Search by role..."
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {/* Admin Roles */}
                            <Box sx={{ display:'flex', backgroundColor:'#f5f5f5', height:'40px', alignItems:'center', paddingLeft:'20px'}}>
                                <Typography sx={{fontWeight:'600', fontSize:'14px'}}>
                                    Admin roles
                                </Typography>
                            </Box>
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Administrator"
                                sx={{marginLeft:'25px'}}
                            />
                            <Divider sx={{margin:0}} />
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="IAM Admin"
                                sx={{marginLeft:'25px'}}
                            />


                            {/* Connect Roles */}
                            <Box sx={{ display:'flex', backgroundColor:'#f5f5f5', height:'40px', alignItems:'center', paddingLeft:'20px'}}>
                                <Typography sx={{fontWeight:'600', fontSize:'14px'}}>
                                    Connect roles
                                </Typography>
                            </Box>
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Connect Onboarding Analyst"
                                sx={{marginLeft:'25px'}}
                            />
                            <Divider sx={{margin:0}} />
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Transfer Analyst"
                                sx={{marginLeft:'25px'}}
                            />


                            {/* Developer Roles */}
                            <Box sx={{ display:'flex', backgroundColor:'#f5f5f5', height:'40px', alignItems:'center', paddingLeft:'20px'}}>
                                <Typography sx={{fontWeight:'600', fontSize:'14px'}}>
                                    Developer roles
                                </Typography>
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Developer"
                                    sx={{marginLeft:'25px'}}
                                />
                            </Box>

                            {/* Identity Roles */}
                            <Box sx={{ display:'flex', backgroundColor:'#f5f5f5', height:'40px', alignItems:'center', paddingLeft:'20px'}}>
                                <Typography sx={{fontWeight:'600', fontSize:'14px'}}>
                                    Identity roles
                                </Typography>
                            </Box>
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Identity Analyst"
                                sx={{marginLeft:'25px'}}
                            />
                            <Divider sx={{margin:0}} />
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Identity View Only"
                                sx={{marginLeft:'25px'}}
                            />

                            {/* Payment Roles */}
                            <Box sx={{ display:'flex', backgroundColor:'#f5f5f5', height:'40px', alignItems:'center', paddingLeft:'20px'}}>
                                <Typography sx={{fontWeight:'600', fontSize:'14px'}}>
                                    Payment roles
                                </Typography>
                            </Box>
                            <Box sx={{ paddingLeft: 2 }}>
                                {/* Add more roles as needed */}
                            </Box>
                        </FormControl>
                    </Box>
                </Box>

                {/*Role description*/}
                <Box sx={{
                    width: '50%',
                    border: '2px solid',
                    borderColor: 'var(--Gray-200, #EAECF0)'
                }}>
                    <Box sx={{ padding: 2, height:'100%'}}>
                        {/* Header */}
                        <Typography gutterBottom sx={{fontSize:'18px', fontWeight:'600', marginTop:'10px'}}>
                            Role description
                        </Typography>

                        {/* Information message */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <InfoIcon fontSize="small" sx={{ marginRight: 1, color: 'text.secondary' }} />
                            <Typography variant="body2" color="text.secondary">
                                The team member(s) will receive the combined set of permissions provided by these roles.
                            </Typography>
                        </Box>

                        <Divider/>

                        {/* Role 1 - Administrator */}
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Administrator
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This role is for people who need similar access as the account owner. This role can see and manage almost everything.
                            </Typography>
                            <Link href="#" variant="body2" sx={{ fontWeight: 'bold', color:'orange' }}>
                                View details
                            </Link>
                        </Box>

                        <Divider/>

                        {/* Role 2 - IAM Admin */}
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                IAM Admin
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                The Identity and Access Management (IAM) Admin role is for people who need to invite team members and assign roles.
                            </Typography>
                            <Link href="#" variant="body2" sx={{ fontWeight: 'bold', color:'orange' }}>
                                View details
                            </Link>
                        </Box>

                        <Box sx={{height:'300px'}}/>

                        <Box sx={{display:'flex', justifyContent:'end', gap: 1}}>
                            <Button variant='cancel' sx={{display:'absolute', mt:'auto'}}>Cancel</Button>
                            <Button variant='save' sx={{display:'absolute', mt:'auto'}}>Send invites</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AddNewUsers;