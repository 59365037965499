import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAdminFeatures = createAsyncThunk(
    'adminFeatures/fetchAdminFeatures',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/features`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params,
            });
            console.log('fetchAdminFeatures response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchAdminFeatures error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchSingleFeature = createAsyncThunk(
    'adminFeatures/fetchSingleFeature',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/features/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchSingleFeature response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchSingleFeature error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const createAdminFeature = createAsyncThunk(
    'adminFeatures/createAdminFeature',
    async (featureData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/features`,
                featureData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('createAdminFeature response:', response.data);
            return response.data;
        } catch (error) {
            console.error('createAdminFeature error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const updateAdminFeature = createAsyncThunk(
    'adminFeatures/updateAdminFeature',
    async (featureData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/features/${featureData.id}`,
                featureData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('updateAdminFeature response:', response.data);
            return response.data;
        } catch (error) {
            console.error('updateAdminFeature error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deleteAdminFeature = createAsyncThunk(
    'adminFeatures/deleteAdminFeature',
    async (featureId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/features/${featureId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deleteAdminFeature success for id:', featureId);
            return featureId;
        } catch (error) {
            console.error('deleteAdminFeature error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

const adminFeatureSlice = createSlice({
    name: 'adminFeatures',
    initialState: {
        features: [],
        selectedFeature: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminFeatures.pending, (state) => {
                state.status = 'loading';
                console.log('fetchAdminFeatures pending');
            })
            .addCase(fetchAdminFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload.data.data;
                state.pagination = {
                    page: action.payload.data.page,
                    limit: action.payload.data.limit,
                    totalItems: action.payload.data.total_items,
                    totalPages: action.payload.data.total_pages,
                };
                console.log('fetchAdminFeatures fulfilled, state:', state);
            })
            .addCase(fetchAdminFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload && typeof action.payload === 'object'
                    ? action.payload.message || JSON.stringify(action.payload)
                    : action.payload || 'An error occurred';
                console.error('fetchAdminFeatures rejected, error:', state.error);
            })
            .addCase(fetchSingleFeature.pending, (state) => {
                state.status = 'loading';
                console.log('fetchSingleFeature pending');
            })
            .addCase(fetchSingleFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedFeature = action.payload;
                console.log('fetchSingleFeature fulfilled, selectedFeature:', state.selectedFeature);
            })
            .addCase(fetchSingleFeature.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload && typeof action.payload === 'object'
                    ? action.payload.message || JSON.stringify(action.payload)
                    : action.payload || 'An error occurred';
                console.error('fetchSingleFeature rejected, error:', state.error);
            })
            .addCase(createAdminFeature.fulfilled, (state, action) => {
                state.features.push(action.payload);
                state.pagination.totalItems += 1;
                console.log('createAdminFeature fulfilled, updated state:', state);
            })
            .addCase(updateAdminFeature.fulfilled, (state, action) => {
                const index = state.features.findIndex(feature => feature.id === action.payload.id);
                if (index !== -1) {
                    state.features[index] = action.payload;
                }
                if (state.selectedFeature && state.selectedFeature.id === action.payload.id) {
                    state.selectedFeature = action.payload;
                }
                console.log('updateAdminFeature fulfilled, updated state:', state);
            })
            .addCase(deleteAdminFeature.fulfilled, (state, action) => {
                state.features = state.features.filter(feature => feature.id !== action.payload);
                state.pagination.totalItems -= 1;
                if (state.selectedFeature && state.selectedFeature.id === action.payload) {
                    state.selectedFeature = null;
                }
                console.log('deleteAdminFeature fulfilled, updated state:', state);
            });
    },
});

export default adminFeatureSlice.reducer;