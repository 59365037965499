import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';  // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Thunk to create customer details
export const createCustomerDetail = createAsyncThunk('customerDetails/create', async (customerData, { rejectWithValue }) => {
    try {
        const token = await getToken();  // Fetch the token
        const headers = {
            'Authorization': `Bearer ${token}`,  // Add the Authorization header
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${API_BASE_URL}/public/customers/details`, customerData, { headers });
        console.log('createCustomerDetail response:', response.data);
        return response.data;
    } catch (error) {
        console.error('createCustomerDetail error:', error.response?.data || error.message);
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Customer Details
const customerDetailsSlice = createSlice({
    name: 'customerDetails',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createCustomerDetail.pending, (state) => {
                state.status = 'loading';
                console.log('createCustomerDetail pending');
            })
            .addCase(createCustomerDetail.fulfilled, (state) => {
                state.status = 'succeeded';
                console.log('createCustomerDetail fulfilled');
            })
            .addCase(createCustomerDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create customer detail';
                console.error('createCustomerDetail rejected, error:', state.error);
            });
    },
});

export default customerDetailsSlice.reducer;
