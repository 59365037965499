import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to migrate statuses
export const migrateStatuses = createAsyncThunk('migrations/migrateStatuses', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/migrations/statuses`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to migrate status ownerships
export const migrateStatusOwnerships = createAsyncThunk('migrations/migrateStatusOwnerships', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/migrations/status-ownerships`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to migrate roles
export const migrateRoles = createAsyncThunk('migrations/migrateRoles', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/migrations/roles`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to migrate permission has features
export const migratePermissionHasFeatures = createAsyncThunk('migrations/migratePermissionHasFeatures', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/migrations/permission-has-features`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to migrate features
export const migrateFeatures = createAsyncThunk('migrations/migrateFeatures', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/migrations/features`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update feature migrations
export const updateFeatureMigrations = createAsyncThunk('migrations/updateFeatures', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/migrations/features`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to migrate API
export const migrateApi = createAsyncThunk('migrations/migrateApi', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/migrations/api`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to migrate current users
export const migrateCurrentUsers = createAsyncThunk('migrations/migrateCurrentUsers', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/migrations/current-users`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Migrations
const migrationsSlice = createSlice({
    name: 'migrations',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(migrateStatuses.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migrateStatuses.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migrateStatuses.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate statuses';
            })

            .addCase(migrateStatusOwnerships.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migrateStatusOwnerships.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migrateStatusOwnerships.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate status ownerships';
            })

            .addCase(migrateRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migrateRoles.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migrateRoles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate roles';
            })

            .addCase(migratePermissionHasFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migratePermissionHasFeatures.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migratePermissionHasFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate permission has features';
            })

            .addCase(migrateFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migrateFeatures.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migrateFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate features';
            })

            .addCase(updateFeatureMigrations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateFeatureMigrations.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(updateFeatureMigrations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update feature migrations';
            })

            .addCase(migrateApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migrateApi.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migrateApi.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate API';
            })

            .addCase(migrateCurrentUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migrateCurrentUsers.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migrateCurrentUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate current users';
            });
    },
});

export default migrationsSlice.reducer;
