import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import {
    Box,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Link,
    TextField,
    Snackbar,
    Alert,
} from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from "react-router-dom";
import ResetPassword from "./ResetPassword";

const UserProfileSettings = () => {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userData, setUserData] = useState({
        email: '',
        name: '',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const isAdmin = keycloak.hasRealmRole('eventlah-admin');

    // Get the store name from the Redux store
    const storeName = useSelector(state => state.organizationProfiles.profiles[0]?.data?.storeName || 'Merchant Store Name');

    useEffect(() => {
        // Fetch user data from Keycloak
        if (keycloak.authenticated) {
            setUserData({
                email: keycloak.tokenParsed.email || '',
                name: keycloak.tokenParsed.name || '',
            });
        }
    }, [keycloak]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEditClick = () => {
        if (isAdmin) {
            setIsEditing(true);
        } else {
            navigate('/settings/profile-settings/edit');
        }
    };

    const handleSave = () => {
        // Here you would typically make an API call to save the data
        // For Keycloak, you might need to use its API to update user info
        setIsEditing(false);
        setSnackbar({
            open: true,
            message: 'Profile updated successfully!',
            severity: 'success'
        });
    };

    const handleOpenResetDialog = () => {
        setOpenResetDialog(true);
    };

    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const renderAdminView = () => (
        <TableContainer sx={{ width: '100%', maxWidth: '600px', boxShadow: 'none', border: 'none' }}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none', width: '30%' }}>
                            <Typography variant='h2' fontWeight='600'>Email</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            {isEditing ? (
                                <TextField
                                    name="email"
                                    value={userData.email}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            ) : (
                                <Typography variant='h2'>{userData.email}</Typography>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant='h2' fontWeight='600'>Name</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            {isEditing ? (
                                <TextField
                                    name="name"
                                    value={userData.name}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            ) : (
                                <Typography variant='h2'>{userData.name}</Typography>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant='h2' fontWeight='600'>Password</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant='h2' sx={{ marginRight: 2, marginBottom:1 }}>••••••••</Typography>
                                <Link
                                    component="button"
                                    onClick={handleOpenResetDialog}
                                    sx={{
                                        textDecoration: 'none',
                                        color: 'orange',
                                        fontSize: '14px',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        }
                                    }}
                                >
                                    Reset Password
                                </Link>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderNormalView = () => (
        <TableContainer sx={{ width: '100%', maxWidth: '600px', boxShadow: 'none', border: 'none' }}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none', width: '30%' }}>
                            <Typography variant='h2' fontWeight='600'>Email</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant='h2'>{userData.email}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant='h2' fontWeight='600'>Name</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant='h2'>{userData.name}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant='h2' fontWeight='600'>Password</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant='h2' sx={{ marginRight: 2, marginBottom:1 }}>••••••••</Typography>
                                <Link
                                    component="button"
                                    onClick={handleOpenResetDialog}
                                    sx={{
                                        textDecoration: 'none',
                                        color: 'orange',
                                        fontSize: '14px',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        }
                                    }}
                                >
                                    Reset Password
                                </Link>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    return(
        <>
            <Box sx={{display:'flex', justifyContent: 'space-between'}}>
                <Typography variant='h1' fontSize='20px' fontWeight='600'>
                    {isAdmin ? 'Admin Profile' : 'User Profile'}
                </Typography>
                {isAdmin && isEditing ? (
                    <Button variant='contained' onClick={handleSave} sx={{height:'32px'}}>
                        Save
                    </Button>
                ) : (
                    <Button variant='cancel' onClick={handleEditClick} sx={{height:'32px'}}>
                        <EditNoteIcon/> Edit
                    </Button>
                )}
            </Box>

            <Divider/>

            {isAdmin ? (
                <Box>
                    <Typography variant='h2' fontSize='16px' fontWeight='600' sx={{mb: 2}}>
                        Admin Dashboard
                    </Typography>
                    {renderAdminView()}
                </Box>
            ) : (
                <Box>
                    <Typography variant='h2' fontSize='16px' fontWeight='600' sx={{mb: 2}}>
                        {storeName}
                    </Typography>
                    {renderNormalView()}
                </Box>
            )}

            <ResetPassword open={openResetDialog} onClose={handleCloseResetDialog} />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default UserProfileSettings;