import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Added
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Header from './components/header/Header';
import Sidebar from './components/Sidebar';
import { CompanyLogo } from './components/header/HeaderSvgImages';
import './App.css';
import AppRoutes from './AppRoutes';
import { FormProvider } from "./components/context/FormContext";
import { fetchOrganizationProfiles } from './redux/organizationProfilesSlice'; // Added

const drawerWidth = 311;

const App = () => {
    const { keycloak, initialized } = useKeycloak();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch(); // Added
    const { status } = useSelector((state) => state.organizationProfiles); // Added

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        if (!initialized) {
            return;
        }

        if (!keycloak.authenticated) {
            keycloak.login();
        } else {
            const fetchData = async () => {
                try {
                    const token = keycloak.tokenParsed;
                    const organization = token?.organization;
                    const role = token?.role;
                    console.log('Token:', token);
                    console.log('Organization:', organization);
                    console.log('Role:', role);

                    // Added: Fetch organization profiles
                    if (status === 'idle') {
                        dispatch(fetchOrganizationProfiles());
                    }

                } catch (error) {
                    setSnackbar({
                        open: true,
                        message: 'Failed to connect to the server. Please try again later.',
                        severity: 'error'
                    });
                    if (process.env.NODE_ENV === 'development' && error.name === 'AbortError') {
                        console.log(error.message);
                    } else {
                        navigate('/login', { state: { from: location }, replace: true });
                    }
                }
            };

            fetchData();
        }
    }, [initialized, keycloak, navigate, location, dispatch, status]); // Added dispatch and status to dependencies

    if (!initialized) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CompanyLogo className="heartbeat" />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isSidebarOpen} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    transition: 'margin-left 0.3s, width 0.3s',
                    marginLeft: { xs: 0, sm: isSidebarOpen ? 0 : `-${drawerWidth}px`, },
                    width: { xs: '100%', sm: isSidebarOpen ? `calc(100% + ${drawerWidth}px)` : '100%' },
                    position: 'relative',
                }}
            >
                <Toolbar />
                <FormProvider>
                    <AppRoutes />
                </FormProvider>
            </Box>
        </Box>
    );
};

export default App;