import React, { useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Button,
    Snackbar,
    Alert, Divider
} from "@mui/material";
import { styled } from "@mui/system";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const EditProfileSettings = () => {
    const [profile, setProfile] = useState({
        email: '',
        name: '',
        password: ''
    });
    const [errors, setErrors] = useState({
        email: ''
    });
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));

        if (name === 'email') {
            if (!validateEmail(value)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: 'Please enter a valid email address'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: ''
                }));
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateEmail(profile.email)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                email: 'Please enter a valid email address'
            }));
            return;
        }
        setLoading(true);

        // Simulating an API call
        setTimeout(() => {
            setLoading(false);
            setSnackbar({
                open: true,
                message: 'Profile updated successfully!',
                severity: 'success'
            });
        }, 1000);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Typography variant="h1">Edit Profile</Typography>
            <Typography variant="h2">Update your personal information and preferences</Typography>

            <Divider/>
            {/* Email */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Email <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="email"
                    value={profile.email}
                    onChange={handleChange}
                    fullWidth
                    label='Enter Email'
                    required
                    type="email"
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            {/* Name */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="name"
                    value={profile.name}
                    onChange={handleChange}
                    fullWidth
                    label='Enter Name'
                    required
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            {/* Password */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Password <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="password"
                    value={profile.password}
                    onChange={handleChange}
                    fullWidth
                    label='Enter Password'
                    required
                    type="password"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            {/* Submit Button */}
            <Box sx={{ marginTop: '32px' }}>
                <Button
                    variant="save"
                    type="submit"
                    disabled={loading || !!errors.email}
                >
                    {loading ? 'Updating...' : 'Update Profile'}
                </Button>
            </Box>

            {/* Snackbar for success message */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EditProfileSettings;