import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    Container,
    Paper,
    TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PaymentIcon from '@mui/icons-material/Payment';
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const ManualPaymentPage = () => {
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(20 * 60); // 20 minutes in seconds

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            // Redirect or trigger event when the countdown reaches 0
            alert('Time is up!');
            navigate('/some-other-page'); // Replace with your desired route
        }
    }, [timeLeft, navigate]);

    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return {
            minutes: minutes.toString().padStart(2, '0'),
            seconds: seconds.toString().padStart(2, '0'),
        };
    };

    const { minutes, seconds } = formatTime();

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            sx={{ backgroundColor: '#f5f5f5', padding: '32px' }}
        >
            {/* ManualPayment Form */}
            <Container
                maxWidth='sm'
                sx={{
                    paddingLeft: { xs: 0, sm: '16px' },
                    paddingRight: { xs: 0, sm: '16px' },
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        padding: { xs: 2, sm: 4 },
                        marginTop: { xs: 2, sm: 4 },
                        borderRadius: '12px',
                    }}
                >
                    <Typography sx={{ fontWeight: '800', fontSize: '20px' }}>
                        Complete your payment before the timer runs out to
                        secure your order.
                    </Typography>

                    {/* Timer */}
                    <Grid container justifyContent='center' spacing={2} mt={4}>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            display='flex'
                            justifyContent='center'
                        >
                            <TimerTwoToneIcon
                                sx={{ fontSize: 60, color: 'primary.main' }}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sm={4}
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                        >
                            <Box
                                sx={{
                                    backgroundColor: '#a8a8a8',
                                    padding: '20px 30px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    width: '100%',
                                    maxWidth: '200px',
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    sx={{ fontSize: '1.5rem' }}
                                >
                                    {' '}
                                    {minutes}
                                </Typography>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: '#f5f5f5',
                                        fontSize: '0.875rem',
                                    }}
                                >
                                    MINUTES
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sm={4}
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                        >
                            <Box
                                sx={{
                                    backgroundColor: '#a8a8a8',
                                    padding: '20px 30px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    width: '100%',
                                    maxWidth: '200px',
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    sx={{ fontSize: '1.5rem' }}
                                >
                                    {' '}
                                    {seconds}
                                </Typography>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: '#f5f5f5',
                                        fontSize: '0.875rem',
                                    }}
                                >
                                    SECONDS
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Pamentform */}
                    <Grid container sx={{ mt: 5 }}>
                        <Grid item xs={12}>
                            <Box display='flex' alignItems='center' mb={2}>
                                <PaymentIcon
                                    sx={{
                                        marginRight: 1,
                                        fontSize: { xs: 20, sm: 24 },
                                    }}
                                />
                                <Typography variant='body1'>
                                    Payment Amount
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Amount'
                                variant='outlined'
                                required
                                size='small'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                display='flex'
                                alignItems='center'
                                mt={4}
                                mb={2}
                            >
                                <UploadFileIcon
                                    sx={{
                                        marginRight: 1,
                                        fontSize: { xs: 20, sm: 24 },
                                    }}
                                />
                                <Typography variant='body1'>
                                    Payment Proof
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant='cancel'
                                    component='label'
                                    fullWidth
                                    sx={{
                                        textTransform: 'none',
                                        padding: {
                                            xs: '6px 12px',
                                            sm: '8px 16px',
                                        },
                                    }}
                                >
                                    Upload Document
                                    <input type='file' hidden />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant='save' fullWidth>
                                    Submit Details
                                </Button>
                            </Grid>
                            {/* Other grid items here */}
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default ManualPaymentPage;
