import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => ({
    'Authorization': `Bearer ${await getToken()}`,
    'Content-Type': 'application/json'
});

// Thunks for Appointments API

// Fetch all appointments
export const fetchAppointments = createAsyncThunk('appointments/fetchAppointments', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/appointments`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Create a new appointment
export const createAppointment = createAsyncThunk('appointments/createAppointment', async (appointmentData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/appointments`, appointmentData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch a single appointment by ID
export const fetchAppointmentById = createAsyncThunk('appointments/fetchAppointmentById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/appointments/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Update an appointment by ID
export const updateAppointment = createAsyncThunk('appointments/updateAppointment', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/appointments/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Delete an appointment by ID
export const deleteAppointment = createAsyncThunk('appointments/deleteAppointment', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/appointments/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Appointments
const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState: {
        appointments: [],
        selectedAppointment: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch all appointments
        builder
            .addCase(fetchAppointments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAppointments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.appointments = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchAppointments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch appointments';
            });

        // Create a new appointment
        builder
            .addCase(createAppointment.fulfilled, (state, action) => {
                state.appointments.push(action.payload);
            });

        // Fetch a single appointment by ID
        builder
            .addCase(fetchAppointmentById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAppointmentById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedAppointment = action.payload;
            })
            .addCase(fetchAppointmentById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch appointment';
            });

        // Update an appointment by ID
        builder
            .addCase(updateAppointment.fulfilled, (state, action) => {
                const index = state.appointments.findIndex(appointment => appointment.id === action.payload.id);
                if (index !== -1) state.appointments[index] = action.payload;
                if (state.selectedAppointment && state.selectedAppointment.id === action.payload.id) {
                    state.selectedAppointment = action.payload;
                }
            });

        // Delete an appointment by ID
        builder
            .addCase(deleteAppointment.fulfilled, (state, action) => {
                state.appointments = state.appointments.filter(appointment => appointment.id !== action.payload);
                if (state.selectedAppointment && state.selectedAppointment.id === action.payload) {
                    state.selectedAppointment = null;
                }
            });
    },
});

export default appointmentsSlice.reducer;
