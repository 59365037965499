import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk for fetching all statuses
export const fetchStatuses = createAsyncThunk(
    'statuses/fetchStatuses',
    async (_, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(
                `${API_BASE_URL}/statuses`,
                { headers }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Thunk for fetching a single status by ID
export const fetchStatusById = createAsyncThunk(
    'statuses/fetchStatusById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(
                `${API_BASE_URL}/statuses/${id}`,
                { headers }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Statuses slice
const statusesSlice = createSlice({
    name: 'statuses',
    initialState: {
        statuses: [],
        selectedStatus: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {
        // You can add any additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatuses.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStatuses.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuses = action.payload;
                state.error = null;
            })
            .addCase(fetchStatuses.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch statuses';
            })
            .addCase(fetchStatusById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStatusById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedStatus = action.payload;
                state.error = null;
            })
            .addCase(fetchStatusById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch status';
            });
    },
});

export default statusesSlice.reducer;