import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Paper,
    Box,
    Typography,
    Button,
    Chip,
    Divider,
    TextField,
    CircularProgress,
} from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { fetchEvents } from '../../../redux/eventsSlice';

const statusColors = {
    'Paid': { backgroundColor: '#20c52f', textColor: '#ffffff' },
    'Pending': { backgroundColor: '#ffa500', textColor: '#ffffff' },
    'Partial Payment': { backgroundColor: '#ffcc00', textColor: '#ffffff' }
};

const StatusChip = ({ status }) => {
    const { backgroundColor, textColor } = statusColors[status] || {};
    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.6rem',
                height: '18px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const CellRenderer = ({ value }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Typography variant="body2">{value}</Typography>
    </Box>
);

const columns = [
    { field: 'id', headerName: 'Invoice ID', flex: 1, renderCell: (params) => <CellRenderer value={params.value} /> },
    { field: 'activityDate', headerName: 'Date', flex: 1, renderCell: (params) => <CellRenderer value={params.value} /> },
    { field: 'amountPaid', headerName: 'Paid', flex: 1, renderCell: (params) => <CellRenderer value={`RM${params.value?.toFixed(2)}`} /> },
    { field: 'remaining', headerName: 'Remaining', flex: 1, renderCell: (params) => <CellRenderer value={`RM${params.value?.toFixed(2)}`} /> },
    { field: 'totalAmount', headerName: 'Total Amount', flex: 1, renderCell: (params) => <CellRenderer value={`RM${params.value?.toFixed(2)}`} /> },
    { field: 'status', headerName: 'Status', flex: 0.6, renderCell: (params) => <StatusChip status={params.value} /> },
];

const Booking = () => {
    const dispatch = useDispatch();
    const eventsData = useSelector((state) => state.events.events);
    const eventStatus = useSelector((state) => state.events.status);
    const [searchText, setSearchText] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);

    useEffect(() => {
        if (eventStatus === 'idle') {
            dispatch(fetchEvents());
        }
    }, [eventStatus, dispatch]);

    useEffect(() => {
        if (Array.isArray(eventsData)) {
            // Transform event data to match the required format
            const transformedData = eventsData.map(event => ({
                ...event,
                amountPaid: event.amountPaid || 0,
                remaining: event.remaining || 0,
                totalAmount: event.totalAmount || 0,
                status: event.status || 'Pending'
            }));
            setFilteredRows(transformedData);
        }
    }, [eventsData]);

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchText(value);

        setFilteredRows(
            eventsData.filter((row) =>
                Object.keys(row).some((key) =>
                    String(row[key]).toLowerCase().includes(value.toLowerCase())
                )
            )
        );
    };

    return (
        <Box sx={{padding:'32px'}}>
            <Box>
                <Typography variant='h1'>Bookings</Typography>
                <Typography variant='h2'>Manage and Track Your Booking Orders and Ticket Status easily.</Typography>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '8px' }}>
                <TextField
                    variant="outlined"
                    placeholder="Search…"
                    value={searchText}
                    onChange={handleSearch}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': { height: '34px', padding: '0 14px' }
                        }
                    }}
                />
                <Button variant='cancel' startIcon={<GetApp />}>Export</Button>
            </Box>

            <Paper sx={{ width: "100%", borderRadius: "8px", marginTop: '24px', border: "1px solid var(--Gray-200, #EAECF0)", background: "var(--White, #FFF)", boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)" }}>
                <Typography sx={{ color: "var(--Gray-900, #101828)", fontFamily: "Inter", fontSize: "18px", fontWeight: 500, lineHeight: "28px", padding: "20px 24px" }}>All Booking Order and Ticketing Status</Typography>

                {eventStatus === 'loading' ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={filteredRows}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={70}
                        pageSizeOptions={[5, 10, 20]}
                        initialState={{ pagination: { paginationModel: { pageSize: 6 } } }}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                backgroundColor: '#737373',
                                color: '#fff',
                                height: '34px',
                                my: 1,
                                minWidth: 'auto',
                                '&:hover': {
                                    backgroundColor: '#404040',
                                },
                            },
                        }}
                    />
                )}
            </Paper>
        </Box>
    );
};

export default Booking;