import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import UpcomingBill from './UpcomingBill';
import Billed from './Billed';

const BillsPage = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box display='flex'>
            <Box padding='32px' flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h1'>My Bills</Typography>
                        <Typography variant='h2'>
                            Your Billing Details and Payment History
                        </Typography>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label='service tabs'
                    >
                        <Tab label='Upcoming Bill' />
                        <Tab label='Billed' />
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>
                    {value === 0 && <UpcomingBill />}
                    {value === 1 && <Billed />}
                </Box>
            </Box>
        </Box>
    );
};

export default BillsPage;
