import React from 'react';
import AdminAccountStatusTable from "./AdminAccountStatusTable";

const Verified = () => {
    const rows = [
        { id: 1, merchantId: '1001001', merchantName: 'John Anderson Ltd.', merchantEmail: 'john.anderson@andersonltd.com', status: 'Verified' },
        { id: 2, merchantId: '1001002', merchantName: 'Miller Enterprises', merchantEmail: 'susan.miller@millerent.com', status: 'Verified' },
        { id: 3, merchantId: '1001003', merchantName: 'Harris Trading Co.', merchantEmail: 'michael.harris@harristrading.com', status: 'Verified' },
        { id: 4, merchantId: '1001004', merchantName: 'Clark Industries', merchantEmail: 'anna.clark@clarkindustries.com', status: 'Verified' },
        { id: 5, merchantId: '1001005', merchantName: 'Davis & Sons', merchantEmail: 'william.davis@davissons.com', status: 'Verified' },
        { id: 6, merchantId: '1001006', merchantName: 'Brown Innovations', merchantEmail: 'linda.brown@browninnovations.com', status: 'Verified' },
        { id: 7, merchantId: '1001007', merchantName: 'Taylor Health Solutions', merchantEmail: 'james.taylor@taylorhealth.com', status: 'Verified' },
        { id: 8, merchantId: '1001008', merchantName: 'Walker Technologies', merchantEmail: 'patricia.walker@walkertech.com', status: 'Verified' },
        { id: 9, merchantId: '1001009', merchantName: 'White Consulting', merchantEmail: 'daniel.white@whiteconsulting.com', status: 'Verified' },
        { id: 10, merchantId: '1001010', merchantName: 'Moore Marketing', merchantEmail: 'nancy.moore@mooremarketing.com', status: 'Verified' },
    ];
    return (
        <>
            <AdminAccountStatusTable title='Verified' rows={rows} />
        </>
    );
};

export default Verified;
