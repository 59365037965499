import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all categories
export const fetchCategories = createAsyncThunk('referenceCategories/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/categories`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new category
export const createCategory = createAsyncThunk('referenceCategories/create', async (categoryData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/categories`, categoryData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a category by ID
export const updateCategory = createAsyncThunk('referenceCategories/update', async ({ id, categoryData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/categories/${id}`, categoryData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific category by ID
export const fetchCategoryById = createAsyncThunk('referenceCategories/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/categories/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a category by ID
export const deleteCategory = createAsyncThunk('referenceCategories/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/categories/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Reference Categories
const referenceCategoriesSlice = createSlice({
    name: 'referenceCategories',
    initialState: {
        categories: [],
        selectedCategory: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch categories';
            })

            .addCase(createCategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories.push(action.payload);
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create category';
            })

            .addCase(updateCategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.categories.findIndex(category => category.id === action.payload.id);
                if (index !== -1) {
                    state.categories[index] = action.payload;
                }
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update category';
            })

            .addCase(fetchCategoryById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCategoryById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedCategory = action.payload;
            })
            .addCase(fetchCategoryById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch category by ID';
            })

            .addCase(deleteCategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = state.categories.filter(category => category.id !== action.payload);
                if (state.selectedCategory && state.selectedCategory.id === action.payload) {
                    state.selectedCategory = null;
                }
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete category';
            });
    },
});

export default referenceCategoriesSlice.reducer;
