import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
    CircularProgress,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ViewIcon, EditIcon, DeleteIcon } from "../IconButtons";
import AddIcon from "@mui/icons-material/Add";
import { fetchEvents } from '../../../redux/eventsSlice';

const images = [
    "/1.jpeg",
    "/2.jpeg",
    "/3.jpeg",
    "/4.jpeg",
    "/5.jpeg",
    "/6.jpeg",
];

const EventList = () => {
    const dispatch = useDispatch();
    const eventsData = useSelector((state) => state.events.events);
    const eventStatus = useSelector((state) => state.events.status);
    const [searchQuery, setSearchQuery] = useState('');
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const [currentEventId, setCurrentEventId] = useState(null);

    useEffect(() => {
        if (eventStatus === 'idle') {
            dispatch(fetchEvents());
        }
    }, [eventStatus, dispatch]);

    const handleImageDialogOpen = (eventId) => {
        setCurrentEventId(eventId);
        setImageDialogOpen(true);
    };

    const handleImageDialogClose = () => {
        setImageDialogOpen(false);
        setCurrentEventId(null);
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image);
        handleImageDialogClose();
        // Here you would update the event's image in a real application
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredRows = React.useMemo(() => {
        const eventList = Array.isArray(eventsData)
            ? eventsData.map(event => ({
                ...event,
                // for reference
                // eventType: event.activityType?.name || '',
            }))
            : [];
        return eventList.filter((row) =>
            row.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [eventsData, searchQuery]);



    const columns = [
        {
            field: 'image',
            headerName: '',
            flex: 0.4,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '60px',
                            height: 'auto',
                            borderRadius: '4px',
                            cursor: 'pointer',
                        }}
                        alt="Product Image"
                        src={selectedImage}
                        onClick={() => handleImageDialogOpen(params.row.id)}
                    />
                </Box>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2" noWrap>{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'dateRange',
            headerName: 'Start Date - End Date',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{`${params.row.startDate} - ${params.row.endDate}`}</Typography>
                </Box>
            )
        },
        {
            field: 'eventType',
            headerName: 'Event Type',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'dailyEvents',
            headerName: 'Daily Events',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view">
                        <ViewIcon/>
                    </IconButton>
                    <IconButton aria-label="edit">
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete">
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{padding:'32px'}}>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                        <Typography variant='h1'>Events</Typography>
                        <Typography variant='h2'>View, edit, and manage all your scheduled events in one place.</Typography>
                    </Box>
                    <Box>
                        <Button component={Link} to="/events/add-events" variant="save" startIcon={<AddIcon />}>
                            Add New Events
                        </Button>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Events
                    </Typography>

                    {eventStatus === 'loading' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={filteredRows}
                            columns={columns}
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            pageSizeOptions={[5, 10, 20]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 6,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>

            {/* Image Selection Dialog */}
            <Dialog open={imageDialogOpen} onClose={handleImageDialogClose}>
                <DialogTitle>Select Default Image</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {images.map((image) => (
                            <Box
                                key={image}
                                component="img"
                                src={image}
                                alt="Event option"
                                sx={{
                                    width: '100px',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    border: selectedImage === image ? '2px solid #1976d2' : '1px solid #ccc',
                                }}
                                onClick={() => handleImageSelect(image)}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleImageDialogClose} variant="cancel">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EventList;