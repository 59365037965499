import {Box} from "@mui/material";

const PublicUrlGenerated = () => {
    return (
        <Box>
            PublicUrlGenerated
        </Box>
    )
}

export default PublicUrlGenerated;