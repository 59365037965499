import React from 'react';
import AdminAccountStatusTable from "./AdminAccountStatusTable";

const Warming = () => {
    const rows = [
        { id: 11, merchantId: '2002001', merchantName: 'Smith & Co.', merchantEmail: 'contact@smithco.com', status: 'Warning' },
        { id: 12, merchantId: '2002002', merchantName: 'Green Leaf Enterprises', merchantEmail: 'info@greenleaf.com', status: 'Warning' },
        { id: 13, merchantId: '2002003', merchantName: 'Blue Sky Technologies', merchantEmail: 'support@blueskytech.com', status: 'Warning' },
        { id: 14, merchantId: '2002004', merchantName: 'Red Stone Real Estate', merchantEmail: 'sales@redstone.com', status: 'Warning' },
        { id: 15, merchantId: '2002005', merchantName: 'Yellow Brick Manufacturing', merchantEmail: 'admin@yellowbrick.com', status: 'Warning' },
        { id: 16, merchantId: '2002006', merchantName: 'Purple Wave Consulting', merchantEmail: 'consult@purplewave.com', status: 'Warning' },
        { id: 17, merchantId: '2002007', merchantName: 'Orange Blossom Bakery', merchantEmail: 'orders@orangeblossombakery.com', status: 'Warning' },
        { id: 18, merchantId: '2002008', merchantName: 'Silver Line Transport', merchantEmail: 'dispatch@silverline.com', status: 'Warning' },
        { id: 19, merchantId: '2002009', merchantName: 'Golden Harvest Farms', merchantEmail: 'farms@goldenharvest.com', status: 'Warning' },
        { id: 20, merchantId: '2002010', merchantName: 'Black Pearl Events', merchantEmail: 'events@blackpearl.com', status: 'Warning' },
    ];
    return (
        <>
            <AdminAccountStatusTable title='Warning' rows={rows} />
        </>
    );
};

export default Warming;
