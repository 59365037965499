import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUserRoles = createAsyncThunk(
    'adminUserRoles/fetchUserRoles',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-roles`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createUserRole = createAsyncThunk(
    'adminUserRoles/createUserRole',
    async (roleData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/user-roles`,
                roleData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const changeUserRoleDefault = createAsyncThunk(
    'adminUserRoles/changeUserRoleDefault',
    async ({ id, isActive }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/user-roles/${id}/default/${isActive}`,
                {},
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleUserRole = createAsyncThunk(
    'adminUserRoles/fetchSingleUserRole',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-roles/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRole = createAsyncThunk(
    'adminUserRoles/deleteUserRole',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-roles/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserRolesByUserId = createAsyncThunk(
    'adminUserRoles/fetchUserRolesByUserId',
    async (userId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-roles/users/${userId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { userId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolesByUserId = createAsyncThunk(
    'adminUserRoles/deleteUserRolesByUserId',
    async (userId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-roles/users/${userId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return userId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserRolesByRoleId = createAsyncThunk(
    'adminUserRoles/fetchUserRolesByRoleId',
    async (roleId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-roles/roles/${roleId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { roleId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolesByRoleId = createAsyncThunk(
    'adminUserRoles/deleteUserRolesByRoleId',
    async (roleId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-roles/roles/${roleId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return roleId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserRolesByOrganizationId = createAsyncThunk(
    'adminUserRoles/fetchUserRolesByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-roles/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { organizationId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolesByOrganizationId = createAsyncThunk(
    'adminUserRoles/deleteUserRolesByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-roles/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return organizationId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminUserRolesSlice = createSlice({
    name: 'adminUserRoles',
    initialState: {
        userRoles: [],
        selectedUserRole: null,
        userRolesByUser: {},
        userRolesByRole: {},
        userRolesByOrganization: {},
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRoles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userRoles = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(createUserRole.fulfilled, (state, action) => {
                state.userRoles.push(action.payload);
            })
            .addCase(changeUserRoleDefault.fulfilled, (state, action) => {
                const index = state.userRoles.findIndex(role => role.id === action.payload.id);
                if (index !== -1) {
                    state.userRoles[index] = action.payload;
                }
                if (state.selectedUserRole && state.selectedUserRole.id === action.payload.id) {
                    state.selectedUserRole = action.payload;
                }
            })
            .addCase(fetchSingleUserRole.fulfilled, (state, action) => {
                state.selectedUserRole = action.payload;
            })
            .addCase(deleteUserRole.fulfilled, (state, action) => {
                state.userRoles = state.userRoles.filter(role => role.id !== action.payload);
                if (state.selectedUserRole && state.selectedUserRole.id === action.payload) {
                    state.selectedUserRole = null;
                }
            })
            .addCase(fetchUserRolesByUserId.fulfilled, (state, action) => {
                state.userRolesByUser[action.payload.userId] = action.payload.data;
            })
            .addCase(deleteUserRolesByUserId.fulfilled, (state, action) => {
                delete state.userRolesByUser[action.payload];
            })
            .addCase(fetchUserRolesByRoleId.fulfilled, (state, action) => {
                state.userRolesByRole[action.payload.roleId] = action.payload.data;
            })
            .addCase(deleteUserRolesByRoleId.fulfilled, (state, action) => {
                delete state.userRolesByRole[action.payload];
            })
            .addCase(fetchUserRolesByOrganizationId.fulfilled, (state, action) => {
                state.userRolesByOrganization[action.payload.organizationId] = action.payload.data;
            })
            .addCase(deleteUserRolesByOrganizationId.fulfilled, (state, action) => {
                delete state.userRolesByOrganization[action.payload];
            })
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.payload?.message || 'An error occurred';
                }
            )
            .addMatcher(
                action => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            );
    },
});

export default adminUserRolesSlice.reducer;