import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Box,
    Typography,
    IconButton,
    Paper,
    TextField,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    Button,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as ViewIcon } from '@mui/icons-material';
import {
    getAgreements,
    deleteAgreement,
    fetchAgreement,
    updateAgreement,
} from '../../redux/agreementSlice';
import AddAgreement from "./AdminAgreementManagementContents/AddAgreement";
import EditAgreement from "./AdminAgreementManagementContents/EditAgreement";

const AdminAgreementPage = () => {
    const { agreements, status, error } = useSelector(state => state.agreement);
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedAgreement, setSelectedAgreement] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const totalCount = useSelector(state => state.agreement.totalCount);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        agreementId: null
    });

    const [editedAgreement, setEditedAgreement] = useState(null);

    useEffect(() => {
        dispatch(getAgreements(paginationModel));
    }, [dispatch, paginationModel]);

    const handleEdit = async (agreement) => {
        try {
            await dispatch(fetchAgreement(agreement.id)).unwrap();
            setEditedAgreement(agreement);
            setOpenEditDialog(true);
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to fetch agreement details: ' + error.message,
                severity: 'error'
            });
        }
    };

    const handleSaveEdit = async () => {
        try {
            await dispatch(updateAgreement({ id: editedAgreement.id, agreementData: editedAgreement })).unwrap();
            setSnackbar({
                open: true,
                message: 'Agreement updated successfully',
                severity: 'success'
            });
            setOpenEditDialog(false);
            dispatch(getAgreements(paginationModel));
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to update agreement: ' + error.message,
                severity: 'error'
            });
        }
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleView = (agreement) => {
        setSelectedAgreement(agreement);
        setOpenViewDialog(true);
    };

    const handleDeleteClick = (id) => {
        setConfirmDialog({
            open: true,
            agreementId: id
        });
    };

    const handleConfirmDelete = async () => {
        const id = confirmDialog.agreementId;
        try {
            await dispatch(deleteAgreement(id)).unwrap();
            setSnackbar({
                open: true,
                message: 'Agreement deleted successfully',
                severity: 'success'
            });
            dispatch(getAgreements(paginationModel));
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to delete agreement: ' + error.message,
                severity: 'error'
            });
        } finally {
            setConfirmDialog({ open: false, agreementId: null });
        }
    };

    const handleEditChange = (event) => {
        const { name, value } = event.target;
        setEditedAgreement(prev => ({ ...prev, [name]: value }));
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialog({ open: false, agreementId: null });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleAddSuccess = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
        dispatch(getAgreements(paginationModel));
    };

    const filteredAgreements = Array.isArray(agreements)
        ? agreements.filter((agreement) =>
            agreement.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : [];

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    }

    const columns = [
        { field: 'title', headerName: 'Agreement Title', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 },
        {
            field: 'actions',
            headerName: '',
            flex: 0.4,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => handleView(params.row)}>
                        <ViewIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box sx={{ padding: '32px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant="h1">Agreement Management</Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <Box>
                        <Button variant="save" onClick={() => setOpenAddDialog(true)}>+ Add Agreement</Button>
                    </Box>
                </Box>
            </Box>

            <Divider />

            <TextField
                variant="outlined"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                size="small"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        height: '34px',
                        '& input': {
                            height: '34px',
                            padding: '0 14px',
                        },
                        '& .MuiInputBase-input': {
                            height: '34px',
                            padding: '0 14px',
                        }
                    }
                }}
            />

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Agreement List
                </Typography>
                {agreements && agreements.length > 0 ? (
                    <DataGrid
                        rows={agreements}
                        columns={columns}
                        loading={status === 'loading'}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={[5, 10, 20]}
                        rowCount={totalCount}
                        paginationMode="server"
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={70}
                        slots={{ toolbar: GridToolbar }}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                backgroundColor: '#737373',
                                color: '#fff',
                                height: '34px',
                                my: 1,
                                minWidth: 'auto',
                                '&:hover': {
                                    backgroundColor: '#404040',
                                },
                            },
                        }}
                    />
                ) : (
                    <Typography>No agreements available</Typography>
                )}
            </Paper>

            {/* Add Agreement Dialog */}
            <AddAgreement
                open={openAddDialog}
                onClose={() => setOpenAddDialog(false)}
                onSuccess={handleAddSuccess}
            />

            {/* View Dialog */}
            <Dialog open={openViewDialog} onClose={() => setOpenViewDialog(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    {selectedAgreement && (
                        <>
                            <Typography variant="h2" gutterBottom>{selectedAgreement.title}</Typography>
                            <div dangerouslySetInnerHTML={createMarkup(selectedAgreement.content)} />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant='cancel' onClick={() => setOpenViewDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Edit Agreement Dialog */}
            <EditAgreement
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                editedAgreement={editedAgreement}
                handleEditChange={handleEditChange}
                handleSaveEdit={handleSaveEdit}
                status={status}
            />

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmDialog.open}
                onClose={handleCloseConfirmDialog}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this agreement? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} variant="cancel" disabled={status === 'loading'}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="save"
                        autoFocus
                        disabled={status === 'loading'}
                        startIcon={status === 'loading' ? <CircularProgress size={20} /> : null}
                    >
                        {status === 'loading' ? 'Deleting...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AdminAgreementPage;