import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => ({
    'Authorization': `Bearer ${await getToken()}`,
    'Content-Type': 'application/json'
});

// Migrate current users
export const migrateCurrentUsers = createAsyncThunk('currentUserMigrations/migrate', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/migrations/current-users`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Current User Migrations
const currentUserMigrationsSlice = createSlice({
    name: 'currentUserMigrations',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(migrateCurrentUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migrateCurrentUsers.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migrateCurrentUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate current users';
            });
    },
});

export default currentUserMigrationsSlice.reducer;
