import {Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {StaticDatePicker} from "@mui/x-date-pickers/StaticDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import dayjs from "dayjs";
import React from "react";

const SelectDate = () => {
    return (
        <Box sx={{ padding: { xs: 0, sm: '0 32px' }, mb:'12px' }}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <CalendarTodayIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body1">Select Date</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Container
                        maxWidth="xs"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column', // Ensures items stack vertically
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDatePicker defaultValue={dayjs('2022-04-17')} />
                        </LocalizationProvider>
                    </Container>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default SelectDate;