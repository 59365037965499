import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => ({
    'Authorization': `Bearer ${await getToken()}`,
    'Content-Type': 'application/json'
});

// User-related thunks
export const fetchUsers = createAsyncThunk('admin/fetchUsers', async (params = {}, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/administrations/users`, { headers, params });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createUser = createAsyncThunk('admin/createUser', async (userData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/administrations/users`, userData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateUser = createAsyncThunk('admin/updateUser', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/administrations/users/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteUser = createAsyncThunk('admin/deleteUser', async (userId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/administrations/users/${userId}`, { headers });
        return userId;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Role-related thunks
export const fetchRoles = createAsyncThunk('admin/fetchRoles', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/administrations/roles`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createRole = createAsyncThunk('admin/createRole', async (roleData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/administrations/roles`, roleData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateRole = createAsyncThunk('admin/updateRole', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/administrations/roles/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteRole = createAsyncThunk('admin/deleteRole', async (roleId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/administrations/roles/${roleId}`, { headers });
        return roleId;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Organization-related thunks
export const fetchOrganizations = createAsyncThunk('admin/fetchOrganizations', async (params = {}, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/administrations/organizations`, { headers, params });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createOrganization = createAsyncThunk('admin/createOrganization', async (orgData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/administrations/organizations`, orgData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateOrganization = createAsyncThunk('admin/updateOrganization', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/administrations/organizations/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteOrganization = createAsyncThunk('admin/deleteOrganization', async (orgId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/administrations/organizations/${orgId}`, { headers });
        return orgId;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Permission-related thunks
export const fetchPermissions = createAsyncThunk('admin/fetchPermissions', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/administrations/permissions`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createPermission = createAsyncThunk('admin/createPermission', async (permData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/administrations/permissions`, permData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updatePermission = createAsyncThunk('admin/updatePermission', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/administrations/permissions/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deletePermission = createAsyncThunk('admin/deletePermission', async (permId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/administrations/permissions/${permId}`, { headers });
        return permId;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Payment structure-related thunks
export const fetchPaymentStructures = createAsyncThunk('admin/fetchPaymentStructures', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/administrations/payment-structures`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createPaymentStructure = createAsyncThunk('admin/createPaymentStructure', async (structureData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/administrations/payment-structures`, structureData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updatePaymentStructure = createAsyncThunk('admin/updatePaymentStructure', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/administrations/payment-structures/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deletePaymentStructure = createAsyncThunk('admin/deletePaymentStructure', async (structureId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/administrations/payment-structures/${structureId}`, { headers });
        return structureId;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Feature-related thunks
export const fetchFeatures = createAsyncThunk('admin/fetchFeatures', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/administrations/features`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createFeature = createAsyncThunk('admin/createFeature', async (featureData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/administrations/features`, featureData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateFeature = createAsyncThunk('admin/updateFeature', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/administrations/features/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteFeature = createAsyncThunk('admin/deleteFeature', async (featureId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/administrations/features/${featureId}`, { headers });
        return featureId;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for managing all state related to administration
const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        users: [],
        roles: [],
        organizations: [],
        permissions: [],
        paymentStructures: [],
        features: [],
        // Add other state properties as needed for each entity
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Users
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch users';
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const index = state.users.findIndex(user => user.id === action.payload.id);
                if (index !== -1) state.users[index] = action.payload;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.users = state.users.filter(user => user.id !== action.payload);
            });

        // Roles
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch roles';
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.roles.push(action.payload);
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                const index = state.roles.findIndex(role => role.id === action.payload.id);
                if (index !== -1) state.roles[index] = action.payload;
            })
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.roles = state.roles.filter(role => role.id !== action.payload);
            });

        // Organizations
        builder
            .addCase(fetchOrganizations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizations = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch organizations';
            })
            .addCase(createOrganization.fulfilled, (state, action) => {
                state.organizations.push(action.payload);
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                const index = state.organizations.findIndex(org => org.id === action.payload.id);
                if (index !== -1) state.organizations[index] = action.payload;
            })
            .addCase(deleteOrganization.fulfilled, (state, action) => {
                state.organizations = state.organizations.filter(org => org.id !== action.payload);
            });

        // Permissions
        builder
            .addCase(fetchPermissions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPermissions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.permissions = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchPermissions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch permissions';
            })
            .addCase(createPermission.fulfilled, (state, action) => {
                state.permissions.push(action.payload);
            })
            .addCase(updatePermission.fulfilled, (state, action) => {
                const index = state.permissions.findIndex(perm => perm.id === action.payload.id);
                if (index !== -1) state.permissions[index] = action.payload;
            })
            .addCase(deletePermission.fulfilled, (state, action) => {
                state.permissions = state.permissions.filter(perm => perm.id !== action.payload);
            });

        // Payment Structures
        builder
            .addCase(fetchPaymentStructures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentStructures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentStructures = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchPaymentStructures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch payment structures';
            })
            .addCase(createPaymentStructure.fulfilled, (state, action) => {
                state.paymentStructures.push(action.payload);
            })
            .addCase(updatePaymentStructure.fulfilled, (state, action) => {
                const index = state.paymentStructures.findIndex(structure => structure.id === action.payload.id);
                if (index !== -1) state.paymentStructures[index] = action.payload;
            })
            .addCase(deletePaymentStructure.fulfilled, (state, action) => {
                state.paymentStructures = state.paymentStructures.filter(structure => structure.id !== action.payload);
            });

        // Features
        builder
            .addCase(fetchFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch features';
            })
            .addCase(createFeature.fulfilled, (state, action) => {
                state.features.push(action.payload);
            })
            .addCase(updateFeature.fulfilled, (state, action) => {
                const index = state.features.findIndex(feature => feature.id === action.payload.id);
                if (index !== -1) state.features[index] = action.payload;
            })
            .addCase(deleteFeature.fulfilled, (state, action) => {
                state.features = state.features.filter(feature => feature.id !== action.payload);
            });

        // Repeat similar extraReducers for other entities as necessary
    },
});

export default adminSlice.reducer;
