// Page.js
import React from 'react';
import { Render } from '@measured/puck';
import '@measured/puck/puck.css';
import { ContentfulImageField, ContentfulRichTextField } from '@measured/puck-field-contentful';

// Create Puck component config
const config = {
    components: {
        HeadingBlock: {
            fields: {
                children: {
                    type: 'text',
                },
            },
            render: ({ children }) => {
                return <h1>{children}</h1>;
            },
        },
        ImageBlock: {
            fields: {
                src: {
                    type: 'text',
                },
                alt: {
                    type: 'text',
                },
            },
            render: ({ src, alt }) => {
                return <img src={src} alt={alt} style={{ maxWidth: '100%' }} />;
            },
        },
        ContentfulImageBlock: {
            fields: {
                image: ContentfulImageField,
            },
            render: ({ image }) => {
                return <img src={image.url} alt={image.title} style={{ maxWidth: '100%' }} />;
            },
        },
        ContentfulRichTextBlock: {
            fields: {
                content: ContentfulRichTextField,
            },
            render: ({ content }) => {
                return <div dangerouslySetInnerHTML={{ __html: content }} />;
            },
        },
    },
};

const Page = ({ data }) => {
    return <Render config={config} data={data} />;
};

export default Page;