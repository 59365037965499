import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Alert,
} from '@mui/material';
import CheckoutIcon from '@mui/icons-material/ShoppingCart';
import PaymentLinksIcon from '@mui/icons-material/Link';
import CustomerPortalIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';

const CustomDomain = () => {
    const [open, setOpen] = useState(false);
    const [domain, setDomain] = useState('');
    const [error, setError] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDomain('');
        setError(false);
    };

    const handleDomainChange = (event) => {
        setDomain(event.target.value);
        setError(event.target.value.split('.').length < 2);
    };

    const handleAdd = () => {
        if (domain.split('.').length >= 2) {
            console.log('Domain added:', domain);
            handleClose();
        } else {
            setError(true);
        }
    };

    const EmailPreview = ({ icon: Icon, label, suffix }) => (
        <Box display="flex" alignItems="center" mt={1}>
            <Icon sx={{ color: '#4caf50', mr: 1 }} />
            <Typography variant="body2">
                { `${domain}/${suffix}` }
            </Typography>
        </Box>
    );

    return (
        <Box>
            <Box>
                <Typography variant='h1'>
                    Custom Domain
                </Typography>
                <Typography variant='h2'>
                    Set up and manage your custom domain.
                </Typography>
            </Box>

            <Divider />

            <Box>
                <Typography variant="h2" gutterBottom>
                    The following products use eventlah.com as the default domain. Or, use your own domain.
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <CheckoutIcon sx={{ color: '#4caf50', marginRight: '8px' }} />
                    <Box>
                        <Typography variant="h2" fontSize='15px'>CHECKOUT</Typography>
                        <Typography variant="body1" fontWeight='700'>checkout.eventlah.com</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <PaymentLinksIcon sx={{ color: '#4caf50', marginRight: '8px' }} />
                    <Box>
                        <Typography variant="h2" fontSize='15px'>PAYMENT LINKS</Typography>
                        <Typography variant="body1" fontWeight='700'>buy.eventlah.com</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <CustomerPortalIcon sx={{ color: '#4caf50', marginRight: '8px' }} />
                    <Box>
                        <Typography variant="h2" fontSize='15px'>CUSTOMER PORTAL</Typography>
                        <Typography variant="body1" fontWeight='700'>billing.eventlah.com</Typography>
                    </Box>
                </Box>

                <Button variant="save" sx={{marginTop:'32px'}} onClick={handleClickOpen}>
                    Add your domain
                </Button>
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add your domain</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" gutterBottom>
                        Enter your domain name <InfoIcon fontSize="small" />
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={domain}
                        onChange={handleDomainChange}
                        error={error}
                        helperText={error ? "Your domain must have at least 2 parts (ex: yourdomain.com)" : ""}
                    />
                    {!error && domain && (
                        <Box sx={{backgroundColor:'#f5f5f5', padding:2, borderRadius:'8px',mt:4 }}>
                            <Typography variant="subtitle2">This is how your domains will look:</Typography>
                            <EmailPreview icon={CheckoutIcon} label="CHECKOUT" suffix="checkout/..." />
                            <EmailPreview icon={PaymentLinksIcon} label="PAYMENT LINKS" suffix="buy/..." />
                            <EmailPreview icon={CustomerPortalIcon} label="CUSTOMER PORTAL" suffix="portal/..." />
                        </Box>
                    )}
                    <Box sx={{backgroundColor:'#f5f5f5', padding:2, borderRadius:'8px',mt:2 }}>
                        <Typography variant="subtitle2">Add your domain</Typography>
                        <Typography sx={{fontWeight:'700', fontSize:'20px'}}>RM1,000,000.00 <Typography component="span" variant="body2">per month</Typography></Typography>
                        <Typography variant="body2" color="text.secondary" mt={1}>
                            By clicking "Add" you are enrolling in an automatically renewing subscription until you
                            cancel your subscription in accordance to our terms. You may cancel your subscription at
                            any time.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='cancel'>Cancel</Button>
                    <Button onClick={handleAdd} variant="save" disabled={error || !domain}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomDomain;