import {Box, Button, Divider, Tab, Tabs, Typography} from "@mui/material";
import React, {useState} from "react";
import OnlinePayment from "./OnlinePayment";
import ManualPayment from "./ManualPayment";

const PaymentMethod = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography sx={{fontSize:'17px', fontWeight:'600px'}}>
                            Select your payment method
                        </Typography>
                    </Box>
                </Box>

                <Box paddingTop='12px' >
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs" >
                        <Tab label="Online Payment" sx={{ fontSize: '10px', paddingBottom:0 }} />
                        <Tab label="Manual Payment" sx={{ fontSize: '10px', paddingBottom:0 }} />
                    </Tabs>
                </Box>

                <Box sx={{marginTop: '32px'}}>
                    { value === 0 && <OnlinePayment/> }
                    { value === 1 && <ManualPayment/> }
                </Box>
            </Box>
        </Box>
    )
}

export default PaymentMethod;