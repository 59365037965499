import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAdminEventTypes = createAsyncThunk(
    'adminEventTypes/fetchAdminEventTypes',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/event-types`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params,
            });
            console.log('fetchAdminEventTypes response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchAdminEventTypes error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchSingleEventType = createAsyncThunk(
    'adminEventTypes/fetchSingleEventType',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/event-types/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchSingleEventType response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchSingleEventType error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const createAdminEventType = createAsyncThunk(
    'adminEventTypes/createAdminEventType',
    async (eventTypeData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/event-types`,
                eventTypeData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('createAdminEventType response:', response.data);
            return response.data;
        } catch (error) {
            console.error('createAdminEventType error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const updateAdminEventType = createAsyncThunk(
    'adminEventTypes/updateAdminEventType',
    async (eventTypeData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/event-types/${eventTypeData.id}`,
                eventTypeData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('updateAdminEventType response:', response.data);
            return response.data;
        } catch (error) {
            console.error('updateAdminEventType error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deleteAdminEventType = createAsyncThunk(
    'adminEventTypes/deleteAdminEventType',
    async (eventTypeId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/event-types/${eventTypeId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deleteAdminEventType success for id:', eventTypeId);
            return eventTypeId;
        } catch (error) {
            console.error('deleteAdminEventType error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

const adminEventTypeSlice = createSlice({
    name: 'adminEventTypes',
    initialState: {
        eventTypes: [],
        selectedEventType: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminEventTypes.pending, (state) => {
                state.status = 'loading';
                console.log('fetchAdminEventTypes pending');
            })
            .addCase(fetchAdminEventTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventTypes = action.payload.data.data;
                state.pagination = {
                    page: action.payload.data.page,
                    limit: action.payload.data.limit,
                    totalItems: action.payload.data.total_items,
                    totalPages: action.payload.data.total_pages,
                };
                console.log('fetchAdminEventTypes fulfilled, state:', state);
            })
            .addCase(fetchAdminEventTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload && typeof action.payload === 'object'
                    ? action.payload.message || JSON.stringify(action.payload)
                    : action.payload || 'An error occurred';
                console.error('fetchAdminEventTypes rejected, error:', state.error);
            })
            .addCase(fetchSingleEventType.pending, (state) => {
                state.status = 'loading';
                console.log('fetchSingleEventType pending');
            })
            .addCase(fetchSingleEventType.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedEventType = action.payload;
                console.log('fetchSingleEventType fulfilled, selectedEventType:', state.selectedEventType);
            })
            .addCase(fetchSingleEventType.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload && typeof action.payload === 'object'
                    ? action.payload.message || JSON.stringify(action.payload)
                    : action.payload || 'An error occurred';
                console.error('fetchSingleEventType rejected, error:', state.error);
            })
            .addCase(createAdminEventType.fulfilled, (state, action) => {
                state.eventTypes.push(action.payload);
                state.pagination.totalItems += 1;
                console.log('createAdminEventType fulfilled, updated state:', state);
            })
            .addCase(updateAdminEventType.fulfilled, (state, action) => {
                const index = state.eventTypes.findIndex(et => et.id === action.payload.id);
                if (index !== -1) {
                    state.eventTypes[index] = action.payload;
                }
                if (state.selectedEventType && state.selectedEventType.id === action.payload.id) {
                    state.selectedEventType = action.payload;
                }
                console.log('updateAdminEventType fulfilled, updated state:', state);
            })
            .addCase(deleteAdminEventType.fulfilled, (state, action) => {
                state.eventTypes = state.eventTypes.filter(et => et.id !== action.payload);
                state.pagination.totalItems -= 1;
                if (state.selectedEventType && state.selectedEventType.id === action.payload) {
                    state.selectedEventType = null;
                }
                console.log('deleteAdminEventType fulfilled, updated state:', state);
            });
    },
});

export default adminEventTypeSlice.reducer;