import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const bills = [
    { month: 'Jan', dueDate: '10 Feb 2025', amount: 'RM35.77' },
    { month: 'Dec', dueDate: '10 Jan 2025', amount: 'RM35.77' },
    { month: 'Nov', dueDate: '10 Dec 2024', amount: 'RM35.77' },
    { month: 'Oct', dueDate: '10 Nov 2024', amount: 'RM35.77' },
    { month: 'Sep', dueDate: '10 Oct 2024', amount: 'RM54.23' },
    { month: 'Aug', dueDate: '10 Sep 2024', amount: 'RM84.25' },
];

const UpcomingBill = () => {
    return (
        <Container maxWidth='md'>
            {bills.map((bill, index) => (
                <Accordion
                    key={index}
                    sx={{ marginBottom: 2, boxShadow: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Box display='flex' alignItems='center' width='100%'>
                            {/* Month, Upcoming Bill Label and Due Date */}
                            <Box flex='3'>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '1rem', sm: '1.25rem' },
                                    }}
                                >
                                    {bill.month}
                                </Typography>

                                <Box
                                    sx={{
                                        border: '1px solid rgba(255, 0, 0, 0.4)',
                                        display: 'inline-block',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: '10px',
                                                sm: '12px',
                                            },
                                            fontWeight: '400',
                                            color: 'red',
                                            padding: '0 4px',
                                        }}
                                    >
                                        Upcoming Bill
                                    </Typography>
                                </Box>

                                <Typography
                                    variant='body2'
                                    color='textSecondary'
                                    sx={{
                                        fontSize: {
                                            xs: '0.75rem',
                                            sm: '0.875rem',
                                        },
                                    }}
                                >
                                    Due Date {bill.dueDate}
                                </Typography>
                            </Box>
                            {/* Amount */}
                            <Box flex='2' textAlign='right'>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '1rem', sm: '1.25rem' },
                                    }}
                                >
                                    {bill.amount}
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                            sx={{
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                            }}
                        >
                            This is the detailed view of the bill for{' '}
                            {bill.month}.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
};

export default UpcomingBill;
