import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint

// 1. Fetch all voucher space packages
export const fetchVoucherSpacePackages = createAsyncThunk(
    'voucherSpacePackages/fetchVoucherSpacePackages',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/voucher-space-has-packages`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 2. Create a new voucher space package
export const createVoucherSpacePackage = createAsyncThunk(
    'voucherSpacePackages/createVoucherSpacePackage',
    async (packageData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/bridges/voucher-space-has-packages`, packageData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 3. Fetch a specific voucher space package by ID
export const fetchSingleVoucherSpacePackage = createAsyncThunk(
    'voucherSpacePackages/fetchSingleVoucherSpacePackage',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/voucher-space-has-packages/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 4. Update a voucher space package
export const updateVoucherSpacePackage = createAsyncThunk(
    'voucherSpacePackages/updateVoucherSpacePackage',
    async ({ id, updatedData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(`${API_BASE_URL}/bridges/voucher-space-has-packages/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 5. Delete a voucher space package by ID
export const deleteVoucherSpacePackage = createAsyncThunk(
    'voucherSpacePackages/deleteVoucherSpacePackage',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/bridges/voucher-space-has-packages/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 6. Fetch packages related to a specific voucher space by ID
export const fetchPackagesByVoucherSpaceId = createAsyncThunk(
    'voucherSpacePackages/fetchPackagesByVoucherSpaceId',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/bridges/voucher-space-has-packages/voucher-spaces/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Slice reducer
const voucherSpacePackagesSlice = createSlice({
    name: 'voucherSpacePackages',
    initialState: {
        packages: [],
        selectedPackage: null,
        relatedPackages: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVoucherSpacePackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVoucherSpacePackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages = action.payload;
            })
            .addCase(fetchVoucherSpacePackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createVoucherSpacePackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages.push(action.payload);
            })
            .addCase(fetchSingleVoucherSpacePackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPackage = action.payload;
            })
            .addCase(updateVoucherSpacePackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.packages.findIndex(pkg => pkg.id === action.payload.id);
                if (index !== -1) {
                    state.packages[index] = action.payload;
                }
            })
            .addCase(deleteVoucherSpacePackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages = state.packages.filter(pkg => pkg.id !== action.payload);
                if (state.selectedPackage && state.selectedPackage.id === action.payload) {
                    state.selectedPackage = null;
                }
            })
            .addCase(fetchPackagesByVoucherSpaceId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.relatedPackages = action.payload;
            });
    },
});

export default voucherSpacePackagesSlice.reducer;
