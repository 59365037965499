import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Typography,
    TextField,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { useKeycloak } from '@react-keycloak/web';
import { fetchProducts, deleteProduct } from '../../../../redux/productSlice';
import { CompanyLogo } from '../../../header/HeaderSvgImages';
import { EditIcon, ViewIcon, DeleteIcon } from "../../../shared/IconButtons";

const images = [
    "/1.jpeg",
    "/2.jpeg",
    "/3.jpeg",
    "/4.jpeg",
    "/5.jpeg",
    "/6.jpeg",
];

const ProductCatalog = () => {
    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();
    const products = useSelector((state) => state.products.products);
    const productStatus = useSelector((state) => state.products.status);
    const [searchQuery, setSearchQuery] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog
    const [productIdToDelete, setProductIdToDelete] = useState(null); // State to store the product ID to delete
    const [imageDialogOpen, setImageDialogOpen] = useState(false); // State to control image selection dialog
    const [selectedImage, setSelectedImage] = useState(images[0]); // State to store selected image
    const [currentProductId, setCurrentProductId] = useState(null); // State to store the product ID for image change

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        const fetchProductsAsync = async () => {
            try {
                console.log('Calling fetchProductsAsync');
                dispatch(fetchProducts(keycloak.token));
            } catch (error) {
                console.log('Error occurred:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to connect to backend server. Please try again.',
                    severity: 'error'
                });
            }
        };

        if (initialized && productStatus === 'idle') {
            console.log('Calling fetchProductsAsync due to initialized and productStatus');
            fetchProductsAsync();
        }
    }, [initialized, productStatus, dispatch, keycloak.token]);

    const handleDelete = () => {
        if (productIdToDelete) {
            dispatch(deleteProduct({ id: productIdToDelete, token: keycloak.token }));
            setDialogOpen(false);
        }
    };

    const handleDeleteClick = (id) => {
        setProductIdToDelete(id); // Set the product ID to delete
        setDialogOpen(true); // Open the dialog
    };

    const handleDialogClose = () => {
        setDialogOpen(false); // Close the dialog
        setProductIdToDelete(null); // Reset the product ID
    };

    const handleImageDialogOpen = (productId) => {
        setCurrentProductId(productId); // Set current product ID for image selection
        setImageDialogOpen(true); // Open image selection dialog
    };

    const handleImageDialogClose = () => {
        setImageDialogOpen(false); // Close image selection dialog
        setCurrentProductId(null); // Reset current product ID
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image); // Set selected image
        handleImageDialogClose(); // Close image selection dialog
        // Here you can dispatch an action to save the new default image for the product if needed
    };

    const handleView = (id) => {
        navigate(`/settings/product-catalog/${id}/details`);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (product) => {
        navigate('/settings/product-catalog/add-product', { state: { product } });
    };

    const filteredRows = React.useMemo(() => {
        return products.filter((row) =>
            row.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [products, searchQuery]);

    if (!initialized || productStatus === 'idle') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CompanyLogo className="heartbeat" />
            </Box>
        );
    }

    const columns = [
        {
            field: 'Image',
            headerName: '',
            flex: 0.4,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '60px',
                            height: 'auto',
                            borderRadius: '4px',
                            cursor: 'pointer',
                        }}
                        alt="Product Image"
                        src={selectedImage} // Use the selected image as source
                        onClick={() => handleImageDialogOpen(params.row.id)} // Open dialog on click
                    />
                </Box>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2" noWrap>{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'pricing',
            headerName: 'Pricing',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'created',
            headerName: 'Created Date',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'updated',
            headerName: 'Updated Date',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => handleView(params.row.id)}>
                        <ViewIcon/>
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                width: '100%'
            }}>
                <Box>
                    <Typography variant='h1'>
                        Product Catalog
                    </Typography>
                    <Typography variant='h2'>
                        Manage your products, categories, and inventory.
                    </Typography>
                </Box>
                <Box sx={{ml: 'auto', display: 'flex',}}>
                    <Button
                        variant="save"
                        startIcon={<AddIcon/>}
                        component={Link}
                        to="/settings/product-catalog/add-product"
                    >
                        Add product
                    </Button>
                </Box>
            </Box>

            <Divider/>

            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" gap="12px">
                    </Box>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Product Catalogs
                    </Typography>

                    {productStatus === 'loading' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={filteredRows}
                            columns={columns}
                            checkboxSelection
                            disableSelectionOnClick
                            rowHeight={70}
                            pageSizeOptions={[5, 10, 20]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 6,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>

            {/* Image Selection Dialog */}
            <Dialog open={imageDialogOpen} onClose={handleImageDialogClose}>
                <DialogTitle>Select Default Image</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {images.map((image) => (
                            <Box
                                key={image}
                                component="img"
                                src={image}
                                alt="Product option"
                                sx={{
                                    width: '100px', // Fixed width
                                    height: 'auto', // Auto height to maintain aspect ratio
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    border: selectedImage === image ? '2px solid #1976d2' : '1px solid #ccc',
                                }}
                                onClick={() => handleImageSelect(image)}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleImageDialogClose} variant="cancel">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this product? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="cancel">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} variant="save" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProductCatalog;
