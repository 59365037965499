import React from 'react';
import { Box, TextField, Typography, Grid, Button, Container, Paper } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const ManualPayment = () => {
    return (
        <Container
            maxWidth="sm"
            sx={{
                paddingLeft: { xs: 0, sm: '16px' },
                paddingRight: { xs: 0, sm: '16px' },
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: { xs: 2, sm: 4 },
                    marginTop: { xs: 2, sm: 4 },
                    borderRadius: '12px',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Manual Payment
                </Typography>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mb={1}>
                            <AccountCircleIcon sx={{ marginRight: 1, fontSize: { xs: 20, sm: 24 } }} />
                            <Typography variant="body1">Customer Details</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Phone No"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mb={1}>
                            <AccountBalanceIcon sx={{ marginRight: 1, fontSize: { xs: 20, sm: 24 } }} />
                            <Typography variant="body1">Billing Address</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Street Address"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="City"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="State"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Postal Code"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Country"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mb={1}>
                            <PaymentIcon sx={{ marginRight: 1, fontSize: { xs: 20, sm: 24 } }} />
                            <Typography variant="body1">Payment Amount</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Amount"
                            variant="outlined"
                            required
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mb={1}>
                            <UploadFileIcon sx={{ marginRight: 1, fontSize: { xs: 20, sm: 24 } }} />
                            <Typography variant="body1">Payment Proof</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="cancel"
                            component="label"
                            fullWidth
                            sx={{ textTransform: 'none', padding: { xs: '6px 12px', sm: '8px 16px' } }}
                        >
                            Upload Document
                            <input type="file" hidden />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="save"
                            fullWidth
                        >
                            Submit Details
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default ManualPayment;
