const ServicesListExamples = () => {
    return (
        [
            { id: 1, name: 'Catering Package A', pax: '10 - 50 pax', price: 'RM 500', rating: 4, date: '19 Jan 2024', src: '/img.jpeg' },
            { id: 2, name: 'Catering Package B', pax: '50 - 200 pax', price: 'RM 1,200', rating: 4, date: '19 Jan 2024', src: '/img.jpeg' },
            { id: 3, name: 'Catering Package C', pax: '200 - 500 pax', price: 'RM 1,800', rating: 3, date: '19 Jan 2024', src: '/img.jpeg' },
            { id: 4, name: 'Catering Package VIP Gold', pax: '10 - 50 pax', price: 'RM 1,500 - RM 3,200', rating: 4, date: '25 Dec 2023', src: '/img.jpeg' },
            { id: 5, name: 'Catering Package VIP Diamond', pax: '50 - 100 pax', price: 'RM 3,200 - RM 8,000', rating: 4, date: '25 Dec 2023', src: '/img.jpeg' },
            { id: 6, name: 'Catering Package VIP Platinum', pax: '200 - 500 pax', price: 'RM 8,000 - RM 15,000', rating: 4, date: '25 Dec 2023', src: '/img.jpeg' },
            { id: 7, name: 'Catering Package A', pax: '10 - 50 pax', price: 'RM 500', rating: 4, date: '19 Jan 2024', src: '/img.jpeg' },
            { id: 8, name: 'Catering Package B', pax: '50 - 200 pax', price: 'RM 1,200', rating: 4, date: '19 Jan 2024', src: '/img.jpeg' },
            { id: 9, name: 'Catering Package C', pax: '200 - 500 pax', price: 'RM 1,800', rating: 3, date: '19 Jan 2024', src: '/img.jpeg' },
            { id: 10, name: 'Catering Package VIP Gold', pax: '10 - 50 pax', price: 'RM 1,500 - RM 3,200', rating: 4, date: '25 Dec 2023', src: '/img.jpeg' },
            { id: 11, name: 'Catering Package VIP Diamond', pax: '50 - 100 pax', price: 'RM 3,200 - RM 8,000', rating: 4, date: '25 Dec 2023', src: '/img.jpeg' },
            { id: 12, name: 'Catering Package VIP Platinum', pax: '200 - 500 pax', price: 'RM 8,000 - RM 15,000', rating: 4, date: '25 Dec 2023', src: '/img.jpeg' },
        ]
    )
};

export default ServicesListExamples;
