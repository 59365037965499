import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to migrate permission has features
export const migratePermissionHasFeatures = createAsyncThunk('permissionHasFeatures/migrate', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/migrations/permission-has-features`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Permission Has Feature Migrations
const permissionHasFeaturesSlice = createSlice({
    name: 'permissionHasFeatures',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(migratePermissionHasFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(migratePermissionHasFeatures.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(migratePermissionHasFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to migrate permission has features';
            });
    },
});

export default permissionHasFeaturesSlice.reducer;
