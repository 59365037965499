import React from 'react';
import { Box, Typography, Divider, Chip, Button, Link, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const rows = [
    { id: 1, email: 'john.doe@example.com', name: 'John Doe', status: 'Active', billing: 'Auto', tax: 'None', product: 'Eventlah Premium', created: 'Jul 10, 2:45 PM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'US_sub_1Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'US_si_01ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 2, email: 'jane.smith@email.com', name: 'Jane Smith', status: 'Inactive', billing: 'Auto', tax: 'None', product: 'Eventlah Premium', created: 'Jul 9, 12:39 PM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'CA_sub_2Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'CA_si_02ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 3, email: 'michael.brown@webmail.com', name: 'Michael Brown', status: 'Active', billing: 'Auto', tax: 'None', product: 'Eventlah Premium', created: 'Aug 1, 10:22 AM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'UK_sub_3Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'UK_si_03ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 4, email: 'sarah.jones@domain.com', name: 'Sarah Jones', status: 'Inactive', billing: 'Auto', tax: 'None', product: 'Eventlah Premium', created: 'Aug 3, 1:15 PM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'AU_sub_4Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'AU_si_04ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 5, email: 'robert.wilson@service.com', name: 'Robert Wilson', status: 'Active', billing: 'Auto', tax: 'None', product: 'Eventlah Premium', created: 'Aug 5, 2:15 PM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'NZ_sub_5Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'NZ_si_05ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 6, email: 'emily.davis@mail.com', name: 'Emily Davis', status: 'Inactive', billing: 'Auto', tax: 'None', product: 'Eventlah Premium', created: 'Jul 20, 9:45 AM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'MY_sub_6Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'MY_si_06ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 7, email: 'daniel.taylor@company.com', name: 'Daniel Taylor', status: 'Active', billing: 'Auto', tax: 'None', product: 'Eventlah Premium', created: 'Jul 25, 9:50 AM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'SG_sub_7Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'SG_si_07ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 8, email: 'alice.martin@workplace.com', name: 'Alice Martin', status: 'Inactive', billing: 'Manual', tax: 'GST', product: 'Eventlah Basic', created: 'Aug 7, 3:10 PM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'DE_sub_8Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'DE_si_08ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 9, email: 'charlie.adams@webmail.com', name: 'Charlie Adams', status: 'Active', billing: 'Auto', tax: 'None', product: 'Eventlah Pro', created: 'Jul 30, 8:05 AM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'FR_sub_9Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'FR_si_09ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
    { id: 10, email: 'olivia.jones@internet.com', name: 'Olivia Jones', status: 'Inactive', billing: 'Manual', tax: 'VAT', product: 'Eventlah Standard', created: 'Jul 15, 2:30 PM', discounts: 'No coupon applied', paymentDue: '3 days after invoice is sent', sub_id: 'JP_sub_10Pr8UDDcU1Neo3xkbZBQkcd', subscriptionItemId: 'JP_si_10ZdyUwf87gJr', qty: 1, total: 'RM99.00 MYR / day', upcomingInvoice: 'Aug 25' },
];

const InfoRow = ({ label, value, isLink = false }) => (
    <Typography sx={{fontWeight:'400', fontSize:'12px', color: 'text.secondary'}}>
        <Typography component="span" sx={{ fontWeight: 'bold', color: 'text.secondary', mr:2 }}>
            {label}
        </Typography>
        {isLink ? <Link href="#" sx={{ color: 'orange' }}>{value}</Link> : value}
    </Typography>
);

const FlexContent = ({ children }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {children}
    </Box>
);

const SubscriptionDetails = () => {
    const { id } = useParams();
    const subscription = rows.find(row => row.id === parseInt(id, 10));

    if (!subscription) {
        return <Typography variant="h6">Subscription not found</Typography>;
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Typography variant="body2">{subscription.subscriptionItemId}</Typography>
            </Box>
            {/* Header */}
            <FlexContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '28px', fontWeight: '600', mr: 1 }}>
                        {subscription.name}
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'normal', color: 'text.secondary', mr: 1 }}>
                        on
                    </Typography>
                    <Typography sx={{ fontSize: '28px', fontWeight: '500', color: 'text.secondary' }}>
                        {subscription.product}
                    </Typography>
                    <Chip
                        label={subscription.status}
                        color={subscription.status === 'Active' ? 'success' : 'default'}
                        sx={{
                            ml: 2,
                            mt: '4px',
                            borderRadius: '4px',
                            height: '24px',
                            '& .MuiChip-label': { padding: '0 8px' },
                        }}
                    />
                </Box>
                {/*<Box>*/}
                {/*    <Button variant="save" endIcon={<KeyboardArrowDownIcon />}>*/}
                {/*        Actions*/}
                {/*    </Button>*/}
                {/*</Box>*/}
            </FlexContent>

            <Divider />

            {/* Subscription Details */}
            <Box>
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>Subscription Details</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InfoRow label="Customer" value={subscription.name} isLink />
                        <InfoRow label="Created" value={subscription.created} />
                        <InfoRow label="Current Period" value={subscription.currentPeriod} />
                        <InfoRow label="ID" value="sub_1Pr8UDDcU1Neo3xkbZBQkcd" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InfoRow label="Discounts" value={subscription.discounts} />
                        <InfoRow label="Billing Method" value={subscription.billing} />
                        <InfoRow label="Payment Due" value={subscription.paymentDue} />
                        <InfoRow label="Tax Calculation" value={subscription.tax} />
                    </Grid>
                </Grid>
            </Box>

            <Divider />

            {/*/!* Metadata *!/*/}
            {/*<FlexContent>*/}
            {/*    <Box>*/}
            {/*        <Typography sx={{ fontWeight: '700', fontSize: '20px', mb: 1 }}>Metadata</Typography>*/}
            {/*        <Typography variant="body2" color='text.secondary'>No metadata</Typography>*/}
            {/*    </Box>*/}
            {/*    <Button variant="cancel" startIcon={<EditIcon />}>Edit metadata</Button>*/}
            {/*</FlexContent>*/}

            {/*<Divider />*/}

            {/* Pricing */}
            <Box>
                <Typography sx={{ fontWeight: '700', fontSize: '20px', mb: 1 }}>Pricing</Typography>
                <FlexContent>
                    <InfoRow label="Product" value={subscription.product} />
                    <InfoRow label="Subscription Item ID" value={subscription.subscriptionItemId} />
                    <InfoRow label="QTY" value={subscription.qty} />
                    <InfoRow label="Total" value={subscription.total} />
                </FlexContent>
            </Box>

            <Divider />

            {/* Upcoming Invoice */}
            <Box>
                <Typography sx={{ fontWeight: '700', fontSize: '20px', mb: 1 }}>Upcoming Invoice</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    This is a preview of the invoice that will be billed on <Link href="#" sx={{ color: 'orange' }}>{subscription.upcomingInvoice}</Link>. It may change if the subscription is updated.
                </Typography>
                <FlexContent>
                    <InfoRow label="Aug 25 - Aug 26, 2024" value="" />
                    <InfoRow label="QTY" value={1} />
                    <InfoRow label="UNIT PRICE" value="RM99.00" />
                    <InfoRow label="AMOUNT" value="RM99.00" />
                </FlexContent>
                <Divider sx={{ margin: '4px 0' }} />
                <FlexContent>
                    <Link href="#" sx={{ color: 'orange' }}>
                        View full invoice for RM99.00 on {subscription.upcomingInvoice}
                    </Link>
                    <InfoRow label="Total Due" value="RM99.00" />
                </FlexContent>
            </Box>


            <Divider />

            {/* Tax Calculation */}
            <Box>
                {/* Tax Calculation Section */}
                {/*<Box sx={{ mb: 4 }}>*/}
                {/*    <Typography sx={{ fontWeight: '700', fontSize: '20px', mb: 1 }}>Tax Calculation</Typography>*/}
                {/*    <Typography variant="body2" color="text.secondary">No tax rate applied.</Typography>*/}
                {/*    <Typography variant="body2" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>*/}
                {/*        <InfoOutlinedIcon fontSize="small" sx={{ mr: 1 }} />*/}
                {/*        Calculate tax automatically on future subscriptions using Stripe Tax. <Link href="#" sx={{ color: 'orange', ml: 1 }}>Start now</Link>*/}
                {/*    </Typography>*/}
                {/*</Box>*/}

                {/*<Divider />*/}

                {/* Invoices Section */}
                <Box>
                    <Typography sx={{ fontWeight: '700', fontSize: '20px', mb: 1 }}>Invoices</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary', flex: 1 }}>
                            Amount
                        </Typography>
                        <Box sx={{ flex: 0.5 }} /> {/* Placeholder to align with Chip */}
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary', flex: 1 }}>
                            Invoice Number
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary', flex: 2 }}>
                            Customer
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary', flex: 1 }}>
                            Due
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary', flex: 1 }}>
                            Created
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
                            RM99.00
                        </Typography>
                        <Box sx={{ flex: 0.5, display: 'flex', justifyContent: 'center' }}>
                            <Chip label="Draft" size="small" />
                        </Box>
                        <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
                            B0FB83B07-DRAFT
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ flex: 2 }}>
                            {subscription.email}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
                            Aug 27
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
                            {subscription.created}
                        </Typography>
                    </Box>
                </Box>

                <Divider/>

                {/*/!* Logs Section *!/*/}
                {/*<Typography sx={{ fontWeight: '700', fontSize: '20px', mb: 1 }}>Logs</Typography>*/}
                {/*<Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>*/}
                {/*    No logs*/}
                {/*</Typography>*/}

                {/*<Divider/>*/}

                {/*Events Section*/}
                {/*<Typography sx={{ fontWeight: '700', fontSize: '20px', mb: 1 }}>Events</Typography>*/}
                {/*<Box>*/}
                {/*    <Box sx={{ display: 'flex' }}>*/}
                {/*        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary', flex: 5 }}>*/}
                {/*            {subscription.email} subscribed to {subscription.product}*/}
                {/*        </Typography>*/}
                {/*        <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>*/}
                {/*            8/24/24, 9:09:49am*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*    <Box sx={{ display: 'flex' }}>*/}
                {/*        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary', flex: 5 }}>*/}
                {/*            A draft invoice was created*/}
                {/*        </Typography>*/}
                {/*        <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>*/}
                {/*            8/24/24, 9:09:49am*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </Box>
        </Box>
    );
};

export default SubscriptionDetails;
