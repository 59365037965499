import React, { useState } from 'react';
import { Button, Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import AddIcon from "@mui/icons-material/Add";
import AllSubscriptions from "./SubscriptionContents/AllSubscriptions";
import TestClocks from "./SubscriptionContents/TestClocks";
import Migrations from "./SubscriptionContents/Migrations";

const Subscriptions = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box flex={1}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h1'>
                        Subscriptions
                    </Typography >
                    <Typography variant='h2'>
                        Manage your subscriptions plans.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    <Button
                        component={RouterLink}
                        to="/settings/subscriptions/create-subscription"
                        variant="save"
                        startIcon={<AddIcon />}
                    >
                        Create subscriptions
                    </Button>
                </Box>
            </Box>

            <Box height='38px' paddingTop='32px'>
                <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                    <Tab label="All subscriptions" />
                    {/*<Tab label="Test clocks" />*/}
                    {/*<Tab label="Migration" />*/}
                </Tabs>
            </Box>

            <Divider sx={{margin: '41px 0 32px 0'}}/>

            <Box>
                { value === 0 && <AllSubscriptions/> }
                {/*{ value === 1 && <TestClocks/> }*/}
                {/*{ value === 2 &&  <Migrations/> }*/}
            </Box>
        </Box>
    );
};

export default Subscriptions;
