import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

// const API_BASE_URL = 'http://localhost:3001/api';
const API_BASE_URL = 'https://api-dev.eventlah.com/api'

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to create an agreement
export const createAgreement = createAsyncThunk('agreement/create', async (agreementData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/agreements`, agreementData, { headers });
        return response.data.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Updated thunk to fetch a specific agreement
export const fetchAgreement = createAsyncThunk('agreement/fetchAgreement', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/agreements?id=${id}`, { headers });
        return response.data.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const getAgreements = createAsyncThunk(
    'agreement/getAgreements',
    async ({ page, pageSize, searchQuery }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/agreements`, {
                headers,
                params: {
                    page: page + 1,
                    limit: pageSize,
                    search: searchQuery,
                },
            });
            return response.data.data; // Return the data object directly
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Thunk to update an agreement
export const updateAgreement = createAsyncThunk('agreement/update', async ({ id, agreementData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/agreements?id=${id}`, agreementData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete an agreement
export const deleteAgreement = createAsyncThunk('agreement/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.delete(`${API_BASE_URL}/agreements?id=${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

const agreementSlice = createSlice({
    name: 'agreement',
    initialState: {
        agreements: [],
        currentAgreement: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createAgreement.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createAgreement.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.agreements.push(action.payload);
            })
            .addCase(createAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create agreement';
            })
            .addCase(getAgreements.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAgreements.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.agreements = action.payload.agreements;
                state.totalCount = action.payload.pagination.totalCount;
            })
            .addCase(getAgreements.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch agreements';
            })
            .addCase(updateAgreement.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAgreement.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currentAgreement = action.payload;
            })
            .addCase(fetchAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch agreement';
            })
            .addCase(updateAgreement.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const index = state.agreements.findIndex(agreement => agreement.id === action.payload.id);
            if (index !== -1) {
                state.agreements[index] = action.payload;
            }
        })
            .addCase(updateAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update agreement';
            })
            .addCase(deleteAgreement.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteAgreement.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.agreements = state.agreements.filter(agreement => agreement.id !== action.payload);
            })
            .addCase(deleteAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete agreement';
            });
    },
});

export default agreementSlice.reducer;