import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint

// 1. Fetch all user roles
export const fetchUserRoles = createAsyncThunk(
    'userRoles/fetchUserRoles',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-roles`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 2. Create a new user role
export const createUserRole = createAsyncThunk(
    'userRoles/createUserRole',
    async (roleData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/user-roles`, roleData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 3. Change user role default status
export const changeUserRoleDefault = createAsyncThunk(
    'userRoles/changeUserRoleDefault',
    async ({ id, isActive }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/user-roles/${id}/default/${isActive}`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 4. Fetch a specific user role by ID
export const fetchSingleUserRole = createAsyncThunk(
    'userRoles/fetchSingleUserRole',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-roles/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 5. Delete a user role by ID
export const deleteUserRole = createAsyncThunk(
    'userRoles/deleteUserRole',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/user-roles/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Slice reducer
const userRolesSlice = createSlice({
    name: 'userRoles',
    initialState: {
        roles: [],
        selectedRole: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserRoles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = action.payload;
            })
            .addCase(fetchUserRoles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createUserRole.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles.push(action.payload);
            })
            .addCase(changeUserRoleDefault.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.roles.findIndex(role => role.id === action.payload.id);
                if (index !== -1) {
                    state.roles[index] = action.payload;
                }
            })
            .addCase(fetchSingleUserRole.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedRole = action.payload;
            })
            .addCase(deleteUserRole.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = state.roles.filter(role => role.id !== action.payload);
                if (state.selectedRole && state.selectedRole.id === action.payload) {
                    state.selectedRole = null;
                }
            });
    },
});

export default userRolesSlice.reducer;
