import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint

// 1. Fetch all user role permission features
export const fetchUserRolePermissionFeatures = createAsyncThunk(
    'userRolePermissionFeatures/fetchUserRolePermissionFeatures',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-role-permission-features`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 2. Create a new user role permission feature
export const createUserRolePermissionFeature = createAsyncThunk(
    'userRolePermissionFeatures/createUserRolePermissionFeature',
    async (featureData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/user-role-permission-features`, featureData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 3. Update the status of a user role permission feature
export const updateUserRolePermissionFeatureStatus = createAsyncThunk(
    'userRolePermissionFeatures/updateUserRolePermissionFeatureStatus',
    async ({ id, entityId, statusData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(`${API_BASE_URL}/user-role-permission-features/${id}/status/${entityId}`, statusData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 4. Fetch a single user role permission feature by ID
export const fetchSingleUserRolePermissionFeature = createAsyncThunk(
    'userRolePermissionFeatures/fetchSingleUserRolePermissionFeature',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-role-permission-features/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// 5. Delete a user role permission feature by ID
export const deleteUserRolePermissionFeature = createAsyncThunk(
    'userRolePermissionFeatures/deleteUserRolePermissionFeature',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/user-role-permission-features/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Slice reducer
const userRolePermissionFeaturesSlice = createSlice({
    name: 'userRolePermissionFeatures',
    initialState: {
        features: [],
        selectedFeature: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRolePermissionFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserRolePermissionFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload;
            })
            .addCase(fetchUserRolePermissionFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createUserRolePermissionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features.push(action.payload);
            })
            .addCase(updateUserRolePermissionFeatureStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.features.findIndex(feature => feature.id === action.payload.id);
                if (index !== -1) {
                    state.features[index] = action.payload;
                }
            })
            .addCase(fetchSingleUserRolePermissionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedFeature = action.payload;
            })
            .addCase(deleteUserRolePermissionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = state.features.filter(feature => feature.id !== action.payload);
                if (state.selectedFeature && state.selectedFeature.id === action.payload) {
                    state.selectedFeature = null;
                }
            });
    },
});

export default userRolePermissionFeaturesSlice.reducer;
