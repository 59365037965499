import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider } from 'react-redux'; // Import Redux Provider
import App from './App';
import theme from './theme';
import keycloak from './keycloak'; // Ensure this path is correct
import ErrorBoundary from './components/ErrorBoundary'; // Import the ErrorBoundary component
import { store } from './redux/store';
import {Helmet} from "react-helmet"; // Import the Redux store

const eventLogger = (event, error) => {
    console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens) => {
    // console.log('onKeycloakTokens', tokens);
};

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const keycloakInitConfig = {
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
};

root.render(
    <ErrorBoundary>
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}
            initOptions={window.location.hostname === 'localhost' ?
                { onLoad: 'check-sso', promiseType: 'native' } :
                { onLoad: 'check-sso', promiseType: 'native', redirectUri: window.location.origin + '/' }
            }
        >
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    <Provider store={store}> {/* Wrap your app with Redux Provider */}
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </ThemeProvider>
            </React.StrictMode>
        </ReactKeycloakProvider>
    </ErrorBoundary>
);