import React from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Card,
    CardContent, Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';



const ProductPackage = () => {
    const handleDialogOpen = () => {
        return (
            console.log('DialogOpen')
        )
    }

    return (
        <Box padding='32px'>
            <Typography variant='h1'>Product package</Typography>
            <Typography variant='h2'>Upload your product packages</Typography>

            <Divider/>

            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow:1 }}>
                <Box sx={{display: 'flex'}}>
                    <Button
                        variant='cancel'
                        sx={{
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px',
                            whiteSpace: 'nowrap',
                            fontSize: '14px',
                            height: '42px',
                        }}
                    >
                        Active Packages
                    </Button>
                    <Button
                        variant='cancel'
                        sx={{
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            color: 'var(--dark-50, #71717A)',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            lineHeight: '20px',
                            background: 'var(--gray-30, #F7F7F7)',
                            height:'42px',
                        }}
                    >
                        Archived Packages
                    </Button>
                </Box>
                <TextField
                    variant="outlined"
                    placeholder="Search for package"
                    InputProps={{
                        startAdornment: <SearchIcon sx={{ marginRight: 1 }} />,
                    }}
                    sx={{ mx:'48px', flexGrow:1 }}
                />
                <Box display='flex'>
                    <Button variant="cancel" startIcon={<FilterListIcon />} sx={{ marginRight: 2, height: '42px', }}>
                        Filters
                    </Button>
                    <Button variant="cancel" startIcon={<AddIcon />} onClick={handleDialogOpen} sx={{whiteSpace: 'nowrap', height: '42px',}}>
                        Add Packages
                    </Button>
                </Box>
            </Box>
            <Box sx={{padding:'8px', backgroundColor:'#FFF', borderRadius: '5px', marginTop:'24px'}}>
                <Card sx={{ border: '1px dashed #D0D5DD', backgroundColor: 'var(--gray-30, #F7F7F7)', textAlign: 'center', boxShadow:'none' }}>
                    <CardContent sx={{padding:0}}>
                        <Box sx={{ height: '88px', paddingTop:'24px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 42 42"
                                 fill="none">
                                <path
                                    d="M15 8.84863C15 7.74406 15.8954 6.84863 17 6.84863H38C39.1046 6.84863 40 7.74406 40 8.84863V16.3183V35.1517C40 36.2562 39.1046 37.1517 38 37.1517H27.5H17C15.8954 37.1517 15 36.2562 15 35.1517V8.84863Z"
                                    fill="white"/>
                                <path
                                    d="M40 27.5V8.84863C40 7.74406 39.1046 6.84863 38 6.84863H17C15.8954 6.84863 15 7.74406 15 8.84863V35.1517C15 36.2562 15.8954 37.1517 17 37.1517H27.5"
                                    stroke="#00303E"/>
                                <rect x="21" y="11" width="13" height="13" rx="6.5" fill="#E7E7E7" stroke="#00303E"/>
                                <line x1="17.7725" y1="26.0454" x2="37.227" y2="26.0454" stroke="#00303E"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <line x1="17.7725" y1="28.3184" x2="37.227" y2="28.3184" stroke="#00303E"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M1 3C1 1.89543 1.89543 1 3 1H30C31.1046 1 32 1.89543 32 3V21V39C32 40.1046 31.1046 41 30 41H16.5H3C1.89543 41 1 40.1046 1 39V3Z"
                                    fill="white"/>
                                <path
                                    d="M32 21V3C32 1.89543 31.1046 1 30 1H3C1.89543 1 1 1.89543 1 3V39C1 40.1046 1.89543 41 3 41H16.5"
                                    stroke="#00303E"/>
                                <rect x="10" y="5" width="13" height="13" rx="6.5" fill="#E7E7E7" stroke="#00303E"/>
                                <rect x="5" y="22" width="23" height="3" rx="1.5" fill="white" stroke="#00303E"/>
                                <line x1="5.5" y1="28.5" x2="28.5" y2="28.5" stroke="#00303E" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <line x1="5.5" y1="31.5" x2="28.5" y2="31.5" stroke="#00303E" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path
                                    d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                    fill="#E7E7E7"/>
                                <path
                                    d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                    stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M30.5654 25.3281V35.7629" stroke="#00303E" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M35.7824 30.5459H25.3477" stroke="#00303E" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </Box>
                        <Typography sx={{
                            color: 'var(--dark-500, #09090B)', // Text color
                            fontFamily: 'Inter', // Font family
                            fontSize: '14px', // Font size
                            fontStyle: 'normal', // Font style
                            fontWeight: 600, // Font weight
                            lineHeight: '20px', // Line height
                        }}>
                            Setup package
                        </Typography>
                        <Typography sx={{
                            color: 'var(--Gray-500, #667085)', // Text color
                            fontFamily: 'Inter', // Font family
                            fontSize: '12px', // Font size
                            fontStyle: 'normal', // Font style
                            fontWeight: 400, // Font weight
                            lineHeight: '18px', // Line height
                        }}>
                            Setup packages to each of your products
                        </Typography>
                        <Button variant="cancel" startIcon={<AddIcon/>} onClick={ handleDialogOpen } sx={{marginTop:'24px'}}>
                            Add Packages
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default ProductPackage;
