import React, { useState } from 'react';
import { Box, Button, Divider, Typography, Switch, Dialog, useMediaQuery, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaymentForm from "./OnlinePaymentForms/PaymentForm";

export const paymentOptions = [
    {
        imgSrc: '/senangPay.png',
        imgAlt: 'senangPay',
        title: 'senangPay',
        description: 'Popular in Malaysia',
    },
    // {
    //     imgSrc: '/fiuu.webp',
    //     imgAlt: 'fiuu',
    //     title: 'Fiuu',
    //     description: 'Popular in south-east asia',
    // },
    // {
    //     imgSrc: '/googlePay.svg',
    //     imgAlt: 'Google Pay',
    //     title: 'Google Pay',
    //     description: 'Popular globally',
    // },
    // {
    //     imgSrc: '/grab.svg',
    //     imgAlt: 'GrabPay',
    //     title: 'GrabPay',
    //     description: 'Popular in Malaysia and Singapore',
    // },
    // {
    //     imgSrc: '/fpx.svg',
    //     imgAlt: 'FPX',
    //     title: 'FPX',
    //     description: 'Popular in Malaysia',
    // },
    // {
    //     imgSrc: '/applePay.svg',
    //     imgAlt: 'Apple Pay',
    //     title: 'Apple Pay',
    //     description: 'Popular globally',
    // },
    // {
    //     imgSrc: '/alipay.svg',
    //     imgAlt: 'Alipay',
    //     title: 'Alipay',
    //     description: 'Popular in China',
    // },
];

const OnlinePayment = () => {
    const [activeStates, setActiveStates] = useState(paymentOptions.map(() => false));
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleActive = (index) => {
        setActiveStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    const handleDialogOpen = (index) => {
        setSelectedIndex(index);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedIndex(null);
    };

    const handleSave = (isEnabled) => {
        if (selectedIndex !== null) {
            setActiveStates(prevStates => {
                const newStates = [...prevStates];
                newStates[selectedIndex] = isEnabled;
                return newStates;
            });
            console.log('Settings saved');
        }
        handleDialogClose();
    };

    const handleCancel = () => {
        console.log('Changes discarded');
        handleDialogClose();
    };

    return (
        <Box>
            {paymentOptions.map((option, index) => (
                <Box key={index}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        padding: isMobile ? '10px 0' : '5px 0'
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 1 : 0 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: '6px', cursor: 'pointer' }} onClick={() => handleDialogOpen(index)}>
                                {dialogOpen && selectedIndex === index ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </Box>
                            <Box component="img" src={option.imgSrc} alt={option.imgAlt} sx={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                            <Box sx={{ marginLeft: '5px' }}>
                                <Typography sx={{ fontSize: isMobile ? '13px' : '14px', fontWeight: '700' }}>{option.title}</Typography>
                                <Typography sx={{ fontSize: isMobile ? '10px' : '11px', fontWeight: '500' }}>{option.description}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '3px',
                            width: isMobile ? '100%' : 'auto'
                        }}>
                            <Switch
                                checked={activeStates[index]}
                                onChange={() => toggleActive(index)}
                                size="small"
                            />
                            {activeStates[index] ? (
                                <CheckCircleOutlineIcon
                                    sx={{ color: 'green', fontSize: isMobile ? '18px' : '20px', fontWeight: '900', cursor: 'pointer' }}
                                    onClick={() => toggleActive(index)}
                                />
                            ) : (
                                <CancelIcon
                                    sx={{ color: 'red', fontSize: isMobile ? '18px' : '20px', fontWeight: '900', cursor: 'pointer' }}
                                    onClick={() => toggleActive(index)}
                                />
                            )}
                            <Typography sx={{ fontSize: isMobile ? '12px' : '14px', fontWeight: '500' }}>
                                {activeStates[index] ? 'Active' : 'Inactive'}
                            </Typography>
                            <Button
                                variant='cancel'
                                sx={{
                                    minWidth: '1px',
                                    width: '20px',
                                    height: '33px',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    marginLeft: 'auto'
                                }}
                                onClick={() => handleDialogOpen(index)}
                            >
                                <Typography sx={{ marginBottom: '7px' }}>...</Typography>
                            </Button>
                        </Box>
                    </Box>
                    {index < paymentOptions.length - 1 && <Divider />}
                </Box>
            ))}

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="sm"
            >
                {selectedIndex !== null && (
                    <PaymentForm
                        header={paymentOptions[selectedIndex].title}
                        subHeader={paymentOptions[selectedIndex].description}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        imgSrc={paymentOptions[selectedIndex].imgSrc}
                        imgAlt={paymentOptions[selectedIndex].imgAlt}
                        initialEnabled={activeStates[selectedIndex]}
                    />
                )}
            </Dialog>
        </Box>
    );
};

export default OnlinePayment;