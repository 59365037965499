import {Box} from "@mui/material";
import PublicDetails from "../../settings/business-settings/ProfileSettingsContents/PublicDetails";

const AdminPublicDetails = () => {
    return (
        <Box>
            <PublicDetails/>
        </Box>
    )
}

export default AdminPublicDetails;