import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { IconButton, Box, Typography, FormControl, InputLabel, Input, Grid } from '@mui/material';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { styled } from '@mui/system';
import CustomInput from "../../../../input/CustomInput";

const PhoneFrame = styled(Box)(({ theme }) => ({
    width: '375px',
    border: '16px solid #e0e0e0',
    borderRadius: '36px',
    padding: '10px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    margin: '0 auto'
}));

const BrowserFrame = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '1024px',
    border: '16px solid #e0e0e0',
    borderRadius: '12px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    overflow: 'auto',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column'
}));

const BrowserHeader = styled(Box)(({ theme }) => ({
    height: '40px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    borderBottom: '1px solid #ddd'
}));

const AddressBar = styled(Box)(({ theme }) => ({
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '5px 10px',
    marginLeft: '10px',
    marginRight: '10px',
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)'
}));

const Email = () => {
    const [config, setConfig] = useState({
        text: 'Sample text',
        color: '#000000',
        backgroundColor: '#ffffff',
        fontSize: 16
    });
    const [isMobileView, setIsMobileView] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfig({ ...config, [name]: value });
    };

    const toggleToMobileView = () => {
        setIsMobileView(true);
    };

    const toggleToDesktopView = () => {
        setIsMobileView(false);
    };

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' });

    const commonStyles = {
        color: config.color,
        fontSize: `${config.fontSize}px`,
        backgroundColor: config.backgroundColor,
        padding: '10px',
        marginTop: '10px',
        boxSizing: 'border-box'
    };

    const iconButtonStyles = (selected) => ({
        backgroundColor: selected ? '#e0e0e0' : 'transparent',
        margin: '0 4px',
        borderRadius: '8px',
        padding: '8px'
    });

    return (
        <Box display="flex" flexDirection={isTabletOrMobile ? 'column' : 'row'}>
            <Box flex={isTabletOrMobile ? '1' : '0 0 30%'} padding="10px" borderRight={isDesktop ? 'none' : 'none'}>
                {/*<Typography variant="h5">Configuration</Typography>*/}
                <Box marginBottom="10px">
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="text">Text</InputLabel>
                        <Input
                            id="text"
                            name="text"
                            value={config.text}
                            onChange={handleChange}
                            aria-describedby="text-helper-text"
                        />
                    </FormControl>
                </Box>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                        <InputLabel htmlFor="color">Color</InputLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomInput
                            id="color"
                            name="color"
                            type="color"
                            value={config.color}
                            onChange={handleChange}
                            aria-describedby="color-helper-text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                        <InputLabel htmlFor="backgroundColor">Background Color</InputLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomInput
                            id="backgroundColor"
                            name="backgroundColor"
                            type="color"
                            value={config.backgroundColor}
                            onChange={handleChange}
                            aria-describedby="backgroundColor-helper-text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                        <InputLabel htmlFor="fontSize">Font Size</InputLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <Input
                            id="fontSize"
                            name="fontSize"
                            type="number"
                            value={config.fontSize}
                            onChange={handleChange}
                            aria-describedby="fontSize-helper-text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box flex={isTabletOrMobile ? '1' : '0 0 70%'} padding="10px" display="flex" flexDirection="column" alignItems="center" bgcolor="#f5f5f5">
                {/*<Typography variant="h5">Email Preview</Typography>*/}
                <Box display="flex" alignItems="center" marginBottom="10px">
                    <IconButton
                        onClick={toggleToMobileView}
                        aria-label="mobile view"
                        style={iconButtonStyles(isMobileView)}
                    >
                        <PhoneIphoneIcon color={isMobileView ? "primary" : "inherit"} />
                    </IconButton>
                    <IconButton
                        onClick={toggleToDesktopView}
                        aria-label="desktop view"
                        style={iconButtonStyles(!isMobileView)}
                    >
                        <DesktopWindowsIcon color={!isMobileView ? "primary" : "inherit"} />
                    </IconButton>
                </Box>
                {isMobileView ? (
                    <PhoneFrame>
                        <Box style={commonStyles}>
                            <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0">
                                <tbody>
                                <tr>
                                    <td
                                        align="center"
                                        valign="top"
                                        bgcolor="#ffffff"
                                        style={{ backgroundSize: 'cover', backgroundPosition: 'top' }}
                                    >
                                        <table
                                            width="100%"
                                            border="0"
                                            align="center"
                                            cellPadding="0"
                                            cellSpacing="0"
                                        >
                                            <tbody>
                                            <tr>
                                                <td height="40"></td>
                                            </tr>

                                            {/* title */}
                                            <tr>
                                                <td
                                                    align="center"
                                                    style={{
                                                        fontFamily: "'Open Sans', Arial, sans-serif",
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                        color: '#D5B874',
                                                    }}
                                                >
                                                    <img
                                                        width="50%"
                                                        src="https://www.orchardvilla.com.my/iftar2023/assets/img/logo-mail.png"
                                                        alt="Logo"
                                                    />
                                                    <br />
                                                    Thank you, we have received your booking.
                                                    <br />
                                                    Please find our official receipt in View My Booking below.
                                                </td>
                                            </tr>
                                            {/* end title */}

                                            <tr>
                                                <td height="20"></td>
                                            </tr>

                                            <tr>
                                                <td height="35"></td>
                                            </tr>
                                            <tr>
                                                <td align="center">
                                                    <table
                                                        align="center"
                                                        width="183"
                                                        border="0"
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                    >
                                                        <tbody>
                                                        <tr>
                                                            <td
                                                                bgcolor="#ff646a"
                                                                height="2"
                                                                style={{ lineHeight: '0px', fontSize: '0px' }}
                                                            >
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td height="30"></td>
                                            </tr>

                                            {/* content */}
                                            <tr>
                                                <td
                                                    align="center"
                                                    style={{
                                                        fontFamily: "'Open Sans', Arial, sans-serif",
                                                        fontSize: '17px',
                                                        color: '#7f8c8d',
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    See you on your booking date.
                                                    <br />
                                                    Please show <b>QR TICKET</b> below during admissions.
                                                    <br />
                                                    Please note that the venue will <b>open at 6:30 PM</b>
                                                    <br />
                                                    and the buffet will be <b>available at 7.00 PM.</b>
                                                    <br />
                                                    If you are arriving separately, kindly share the qr code with your
                                                    guest/invitees
                                                    <br />
                                                    ENJOY THE SERENITY!.
                                                </td>
                                            </tr>
                                            {/* end content */}

                                            <tr>
                                                <td height="50"></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Box>
                    </PhoneFrame>
                ) : (
                    <BrowserFrame>
                        <BrowserHeader>
                            <AddressBar>Email</AddressBar>
                        </BrowserHeader>
                        <Box display="flex" justifyContent="center" padding="20px">
                            <Box bgcolor="#ffffff" padding="20px" borderRadius="8px" width="100%">
                                <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
                                    <tbody>
                                    <tr>
                                        <td
                                            align="center"
                                            valign="top"
                                            bgcolor="#ffffff"
                                            style={{ backgroundSize: 'cover', backgroundPosition: 'top' }}
                                        >
                                            <table
                                                width="100%"
                                                border="0"
                                                align="center"
                                                cellpadding="0"
                                                cellspacing="0"
                                            >
                                                <tbody>
                                                <tr>
                                                    <td height="40"></td>
                                                </tr>

                                                {/* title */}
                                                <tr>
                                                    <td
                                                        align="center"
                                                        style={{
                                                            fontFamily: "'Open Sans', Arial, sans-serif",
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                            color: '#D5B874',
                                                        }}
                                                    >
                                                        <img
                                                            width="50%"
                                                            src="https://www.orchardvilla.com.my/iftar2023/assets/img/logo-mail.png"
                                                            alt="Logo"
                                                        />
                                                        <br />
                                                        Thank you, we have received your booking.
                                                        <br />
                                                        Please find our official receipt in View My Booking below.
                                                    </td>
                                                </tr>
                                                {/* end title */}

                                                <tr>
                                                    <td height="20"></td>
                                                </tr>

                                                <tr>
                                                    <td height="35"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center">
                                                        <table
                                                            align="center"
                                                            width="183"
                                                            border="0"
                                                            cellpadding="0"
                                                            cellspacing="0"
                                                        >
                                                            <tbody>
                                                            <tr>
                                                                <td
                                                                    bgcolor="#ff646a"
                                                                    height="2"
                                                                    style={{ lineHeight: '0px', fontSize: '0px' }}
                                                                >
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td height="30"></td>
                                                </tr>

                                                {/* content */}
                                                <tr>
                                                    <td
                                                        align="center"
                                                        style={{
                                                            fontFamily: "'Open Sans', Arial, sans-serif",
                                                            fontSize: '17px',
                                                            color: '#7f8c8d',
                                                            lineHeight: '24px',
                                                        }}
                                                    >
                                                        See you on your booking date.
                                                        <br />
                                                        Please show <b>QR TICKET</b> below during admissions.
                                                        <br />
                                                        Please note that the venue will <b>open at 6:30 PM</b>
                                                        <br />
                                                        and the buffet will be <b>available at 7.00 PM.</b>
                                                        <br />
                                                        If you are arriving separately, kindly share the qr code with
                                                        your
                                                        guest/invitees
                                                        <br />
                                                        ENJOY THE SERENITY!.
                                                    </td>
                                                </tr>
                                                {/* end content */}

                                                <tr>
                                                    <td height="50"></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    </BrowserFrame>
                )}
            </Box>
        </Box>
    );
};

export default Email;