import {Box} from "@mui/material";
import ComplianceDocuments from "../../settings/business-settings/ComplianceDocuments";

const AdminComplianceDocuments = () => {
    return (
        <Box>
            <ComplianceDocuments/>
        </Box>
    )
}

export default AdminComplianceDocuments;