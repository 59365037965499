import { Box, Button, Divider, Link, Switch, Typography } from "@mui/material";

const PaymentDomain = () => {
    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
                    Custom Options
                </Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>
                    These settings apply to payment pages you create using Stripe Checkout and Payment Links.
                </Typography>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '15px', fontWeight: '600', width: '300px' }}>
                    Address autocomplete
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '740px', mr: '25%' }}>
                    <Switch />
                    <Typography sx={{ ml: 2 }}>
                        Enable address autocomplete with Google Maps.
                    </Typography>
                </Box>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '15px', fontWeight: '600', width: '300px' }}>
                    Adaptive Pricing
                </Typography>
                <Typography sx={{ width: '740px', mr: '25%' }}>
                    Go to{' '}
                    <Link
                        href="" // Replace with the actual link
                        sx={{
                            color: '#8438ff',
                            textDecoration: 'none',
                            fontWeight: '600',
                            '&:hover': {
                                textDecoration: 'underline',
                            }
                        }}
                    >
                        Adaptive Pricing settings
                    </Link>{' '}
                    for real-time currency conversions of your prices into the customer's currency.
                </Typography>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '15px', fontWeight: '600', width: '300px' }}>
                    Public information
                </Typography>
                <Typography sx={{ width: '740px', mr: '25%' }}>
                    Go to{' '}
                    <Link
                        href="" // Replace with the actual link
                        sx={{
                            color: '#8438ff',
                            textDecoration: 'none',
                            fontWeight: '600',
                            '&:hover': {
                                textDecoration: 'underline',
                            }
                        }}
                    >
                        Public details
                    </Link>{' '}
                    to update your business name and statement descriptor.
                </Typography>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '15px', fontWeight: '600', width: '300px' }}>
                    Appearance
                </Typography>
                <Typography sx={{ width: '740px', mr: '25%' }}>
                    Go to{' '}
                    <Link
                        href="" // Replace with the actual link
                        sx={{
                            color: '#8438ff',
                            textDecoration: 'none',
                            fontWeight: '600',
                            '&:hover': {
                                textDecoration: 'underline',
                            }
                        }}
                    >
                        Branding settings
                    </Link>{' '}
                    to upload your icon or logo, and set colors.
                </Typography>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '15px', fontWeight: '600', width: '300px' }}>
                    Domain
                </Typography>
                <Typography sx={{ width: '740px', mr: '25%' }}>
                    Go to{' '}
                    <Link
                        href="" // Replace with the actual link
                        sx={{
                            color: '#8438ff',
                            textDecoration: 'none',
                            fontWeight: '600',
                            '&:hover': {
                                textDecoration: 'underline',
                            }
                        }}
                    >
                        Custom domains
                    </Link>{' '}
                    to change the domain of your Checkout page.
                </Typography>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '15px', fontWeight: '600', width: '300px' }}>
                    No-cost orders
                </Typography>
                <Typography sx={{ width: '740px', mr: '25%' }}>
                    Allow customers to place orders at no cost when using payment links or pricing tables with free products or 100%-off coupons. Customers won't need to enter their payment information to complete no-cost orders.
                    <Button variant='cancel' sx={{ display: 'flex' }}>Enable</Button>
                </Typography>
            </Box>
        </Box>
    );
}

export default PaymentDomain;
