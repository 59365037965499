import React, { useState } from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    Snackbar,
    Alert
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const Logo = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2L2 7L12 12L22 7L12 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 17L12 22L22 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12L12 17L22 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const AddEventForm = () => {
    const [eventName, setEventName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [eventType, setEventType] = useState('');
    const [status, setStatus] = useState(true);
    const [dailyEvents, setDailyEvents] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleEventTypeChange = (event) => {
        setEventType(event.target.value);
    };

    const handleStatusChange = (event) => {
        const newStatus = event.target.checked;
        setStatus(newStatus);
        setSnackbarMessage(newStatus ? "Event status set to Active" : "Event status set to Inactive");
        setSnackbarSeverity(newStatus ? "success" : "error");
        setSnackbarOpen(true);
    };

    const handleDailyEventsChange = (event) => {
        const newDailyEvents = event.target.checked;
        setDailyEvents(newDailyEvents);
        setSnackbarMessage(newDailyEvents ? "Daily events enabled" : "Daily events disabled");
        setSnackbarSeverity(newDailyEvents ? "success" : "error");
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <form noValidate autoComplete="off">
                {/* Event Name Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Event Name</TextFieldTitle>
                    <TextField
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        fullWidth
                        placeholder='Event name'
                        sx={{paddingRight: { md: '50px', lg: '240px' }}}
                    />
                </Box>

                <Divider />

                {/* Description Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Description</TextFieldTitle>
                    <TextField
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter event description"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                            paddingRight: { md: '50px', lg: '240px' }
                        }}
                    />
                </Box>

                <Divider />

                {/* Start Date and End Date Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Start Date - End Date</TextFieldTitle>
                    <Box sx={{ display: 'flex', gap: 2, width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <TextField
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>

                <Divider />

                {/* Event Type Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Event Type</TextFieldTitle>
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <InputLabel>Event Type</InputLabel>
                        <Select
                            value={eventType}
                            onChange={handleEventTypeChange}
                            label="Event Type"
                        >
                            <MenuItem value="Wedding">Wedding</MenuItem>
                            <MenuItem value="Birthday">Birthday</MenuItem>
                            <MenuItem value="Buffet">Buffet</MenuItem>
                            <MenuItem value="Team Building">Team Building</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Divider />

                {/* Status Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2, alignItems: 'center' }}>
                    <TextFieldTitle>Status</TextFieldTitle>
                    <Box sx={{ paddingRight: { md: '50px', lg: '240px' }, width: '100%' }}>
                        <FormControlLabel
                            control={<Switch checked={status} onChange={handleStatusChange} />}
                            label=''
                        />
                    </Box>
                </Box>

                <Divider />

                {/* Daily Events Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2, alignItems: 'center' }}>
                    <TextFieldTitle>Daily Events</TextFieldTitle>
                    <Box sx={{ paddingRight: { md: '50px', lg: '240px' }, width: '100%' }}>
                        <FormControlLabel
                            control={<Switch checked={dailyEvents} onChange={handleDailyEventsChange} />}
                            label=''
                        />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px', paddingTop: '32px' }}>
                    <Button variant='cancel'>Cancel</Button>
                    <Button variant='save'>Save</Button>
                </Box>
            </form>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                    icon={<Logo />}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddEventForm;
