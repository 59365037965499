import {Box} from "@mui/material";
import Payment from "../../settings/others/Payment/Payment";

const TypeOfPaymentProcessor = () => {
    return (
        <Box>
            <Payment/>
        </Box>
    )
}

export default TypeOfPaymentProcessor;