import React from "react";
import { Box, Typography, IconButton, Paper } from "@mui/material";
import { Star as StarIcon } from "@mui/icons-material";
import { DataGrid } from '@mui/x-data-grid';
import SpacesListExamples from "./SpacesListExamples";

const rows = SpacesListExamples();

const columns = [
    {
        field: 'name',
        headerName: 'Name of services',
        flex: 3,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <img src={ params.row.src } alt={params.value} style={{ width: 60, height: 40, marginRight: 10, borderRadius: '4px' }} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body2">{params.value}</Typography>
                    <Typography variant="caption" color="textSecondary">{params.row.date}</Typography>
                </Box>
            </Box>
        )
    },
    { field: 'pax', headerName: 'Pax', width: 143, },
    { field: 'price', headerName: 'Price', width: 196, },
    {
        field: 'rating',
        headerName: 'Ratings',
        width: 144,
        renderCell: (params) => (
            [...Array(5)].map((_, index) => (
                <StarIcon key={index} style={{ color: index < params.value ? '#ffb800' : '#e0e0e0', height:'11px', marginRight: '-12px'}} />
            ))
        )
    },
    {
        field: 'actions',
        headerName: '',
        width: 174,
        renderCell: (params) => (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight:'20px'  }}>
                <IconButton aria-label="view">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M21.2297 10.8837C21.4753 11.1899 21.6114 11.5878 21.6114 12.0004C21.6114 12.4129 21.4753 12.8108 21.2297 13.1171C19.6741 15.0004 16.1333 18.6672 11.9999 18.6672C7.8665 18.6672 4.3257 15.0004 2.77012 13.1171C2.52443 12.8108 2.38843 12.4129 2.38843 12.0004C2.38843 11.5878 2.52443 11.1899 2.77012 10.8837C4.3257 9.00026 7.8665 5.3335 11.9999 5.3335C16.1333 5.3335 19.6741 9.00026 21.2297 10.8837Z"
                            stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M12.0001 14.9631C13.6365 14.9631 14.9631 13.6365 14.9631 12.0001C14.9631 10.3637 13.6365 9.03711 12.0001 9.03711C10.3637 9.03711 9.03711 10.3637 9.03711 12.0001C9.03711 13.6365 10.3637 14.9631 12.0001 14.9631Z"
                            stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </IconButton>
                <IconButton aria-label="edit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M9.53559 18.4544L3.99292 20.0064L5.54487 14.4637L15.6941 4.36375C15.8088 4.24644 15.9459 4.15322 16.097 4.08959C16.2483 4.02595 16.4107 3.99316 16.5748 3.99316C16.7389 3.99316 16.9013 4.02595 17.0526 4.08959C17.2037 4.15322 17.3408 4.24644 17.4555 4.36375L19.6356 6.55619C19.751 6.67069 19.8426 6.80693 19.9052 6.95701C19.9678 7.1071 19.9999 7.2681 19.9999 7.4307C19.9999 7.5933 19.9678 7.75429 19.9052 7.90439C19.8426 8.05448 19.751 8.19071 19.6356 8.30521L9.53559 18.4544Z"
                            stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </IconButton>
                <IconButton aria-label="delete">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4.9231 7.69238H19.6923" stroke="#667085" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path
                            d="M6.76953 7.69238H17.8465V18.7693C17.8465 19.0957 17.7167 19.4088 17.486 19.6396C17.2552 19.8704 16.9421 20.0001 16.6157 20.0001H8.0003C7.67388 20.0001 7.36083 19.8704 7.13001 19.6396C6.89921 19.4088 6.76953 19.0957 6.76953 18.7693V7.69238Z"
                            stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M9.23071 7.69231V7.07692C9.23071 6.26087 9.55489 5.47824 10.1319 4.90121C10.709 4.32417 11.4916 4 12.3076 4C13.1237 4 13.9063 4.32417 14.4834 4.90121C15.0604 5.47824 15.3846 6.26087 15.3846 7.07692V7.69231"
                            stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.4617 11.3862V16.3112" stroke="#667085" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M14.1541 11.3862V16.3112" stroke="#667085" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </IconButton>
            </Box>
        ),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
    }
];

const Published = () => {
    return (
        <Paper
            sx={{
                width: "100%",
                borderRadius: "8px",
                marginTop: '24px',
                border: "1px solid var(--Gray-200, #EAECF0)",
                background: "var(--White, #FFF)",
                boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
            }}
        >
            <Typography
                sx={{
                    color: "var(--Gray-900, #101828)",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "28px",
                    padding: "20px 24px",
                }}
            >
                All Published Spaces
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                rowHeight={70}
                pageSizeOptions={[5, 10, 20]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 6,
                        },
                    },
                }}
            />
        </Paper>
    );
};

export default Published;
