import React from 'react';
import {Box, Typography, Button, Divider, Link, Accordion, AccordionSummary, AccordionDetails, IconButton} from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {CompanyLogo} from "../../header/HeaderSvgImages";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleIcon from '@mui/icons-material/People';
import ScaleIcon from '@mui/icons-material/Scale';

const BillingPage = () => {
    return (
        <Box>
            <Box>
                <Typography variant='h1'>
                    Billing & Plans
                </Typography>
                <Typography variant='h2'>
                    Easily Access and Update Your Plans and Billing Information
                </Typography>
            </Box>

            <Divider/>

            <Box>
                <Box sx={{ marginBottom: '24px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '8px' }}>
                        <Typography variant="h6">Current billing cycle</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Running total: RM0.00</Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        Billed on: 27 Aug 2024
                        <Link href="#" sx={{ textTransform: 'none', marginLeft: '8px', fontSize: '14px', fontWeight:'600', color:'blue'}}>
                            View current charges
                        </Link>
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: '24px' }}>
                    <Button
                        variant="cancel"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', textTransform: 'none', height:'52px' }}
                        startIcon={<ControlPointIcon />}
                    >
                        Add payment method
                    </Button>
                </Box>

                <Box sx={{ marginBottom: '24px' }}>
                    <Typography variant="body2" color="textSecondary">
                        You're RM60.00 away from reaching your billing threshold of RM60.00. If reached, you will be billed automatically, outside of your regular billing cycle.
                    </Typography>
                </Box>

                <Divider/>

                <Box sx={{ marginBottom: '24px' }}>
                    <Typography variant="h6">Past bills</Typography>
                    <Typography variant="body2" color="textSecondary">Your past bills will appear here.</Typography>
                </Box>

                <Divider/>

                <Box sx={{ marginBottom: '24px' }}>
                    <Typography variant="h6">Subscriptions</Typography>
                    <Box sx={{ marginTop: '8px' }}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <CompanyLogo/>
                            <Box>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Trial</Typography>
                                <Typography variant="body2" color="textSecondary">Your trial ends on 26 Aug 2024</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '12px' }}>
                            <Accordion sx={{ width: '320px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>View all subscriptions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Here are your current subscriptions:
                                    </Typography>
                                    <Typography>
                                        - Eventlah's Plan: RM29/month
                                    </Typography>
                                    <Typography>
                                        - Eventlah's Apps: RM15/month
                                    </Typography>
                                    <Typography>
                                        - Domain Name: RM12/year
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                </Box>

                <Divider />

                <Box>
                    <Typography variant="h6">Statement of charges</Typography>
                    <Typography variant="body2" color="textSecondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                </Box>
            </Box>

            {/*Plans*/}
            <Box sx={{mt:'32px'}}>
                <Box>
                    <Typography variant='h1'>
                        Your Plans
                    </Typography>
                </Box>

                <Divider/>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        backgroundColor: '#f9f9f9',
                        width: '100%',
                        maxWidth: '600px',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                    }}
                >
                    <Typography variant="body1" sx={{ marginRight: '8px', fontWeight: 'bold', flex: 0.5 }}>
                        Lucidchart
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#777', flex: 0.5 }}>
                        Team
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                        <PeopleIcon sx={{ fontSize: '20px', color: '#777', marginRight: '4px' }} />
                        <Typography variant="body2" sx={{ color: '#777' }}>
                            3
                        </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', flex: 1 }}>
                        RM360.00/yr
                    </Typography>
                    <IconButton size="small">
                        <MoreVertIcon sx={{ fontSize: '20px', color: '#777' }} />
                    </IconButton>
                </Box>

                <Divider/>

                <Box sx={{mt:'24px'}}>
                    <Typography sx={{fontWeight:'600', fontSize:'22px'}} >Plans available to try or buy</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt:2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StarIcon sx={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="h6">Lucidspark</Typography>
                        </Box>
                        <Link href="#" sx={{ fontSize: '16px', fontWeight:'600', color:'blue'}}>
                            Try Lucidspark
                        </Link>
                    </Box>
                    <Box sx={{ marginTop: '16px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                            <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body1">
                                Easily add your thoughts with sticky notes and freehand drawing.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                            <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body1">
                                Surface the best ideas with real-time collaboration and voting.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', margin: '8px 0 16px 0' }}>
                            <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body1">
                                Turn ideas into action via integrations with Jira, Azure DevOps, and Smartsheet.
                            </Typography>
                        </Box>
                        <Link href="#" sx={{ textTransform: 'none', fontSize: '16px', fontWeight:'600', color:'blue'}}>
                            See if Lucidspark can add value to your team
                        </Link>
                    </Box>
                </Box>

                <Divider/>

                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ScaleIcon sx={{ color: 'blue', marginRight: '8px' }} />
                            <Typography variant="h6">Lucidscale</Typography>
                        </Box>
                        <Link href="#" sx={{ fontSize: '16px', fontWeight:'600', color:'blue'}}>
                            Try Lucidscale
                        </Link>
                    </Box>
                    <Box sx={{ marginTop: '16px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                            <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body1">
                                Visualize to better understand what’s in your cloud environment.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                            <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body1">
                                Verify new architecture was built correctly.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', margin: '8px 0 16px 0' }}>
                            <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body1">
                                Easily automate cloud documentation for internal sharing and collaboration.
                            </Typography>
                        </Box>
                        <Link href="#" sx={{ fontSize: '16px', fontWeight:'600', color:'blue'}}>
                            See if Lucidscale can add value to your team
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BillingPage;
