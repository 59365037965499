import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    Divider,
    Grid,
    MenuItem,
    Dialog,
    DialogContent,
    AccordionSummary,
    AccordionDetails,
    Accordion,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { couponsOptions } from '../shared/CouponsOptions';
import SpacesListExamples from '../spaces/SpacesListExamples';
import SelectPayment from './CheckoutContents/SelectPayment';
import VoucherCard from './CheckoutContents/SelectCoupon';
import SelectDate from './CheckoutContents/SelectDate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReviewPay from './CheckoutContents/ReviewPay';

const CheckoutPage = () => {
    const spaces = SpacesListExamples();
    const [selectedSpace, setSelectedSpace] = useState(spaces[0]);
    const [selectedCoupon, setSelectedCoupon] = useState(couponsOptions[0]);

    const [openPaymentSuccessDialog, setOpenPaymentSuccessDialog] =
        useState(false);

    const priceValue = parseFloat(
        selectedSpace.price.split(' - ')[0].replace(/[^0-9.-]+/g, '')
    );
    const discountAmount = selectedCoupon
        ? parseFloat(selectedCoupon.discount.replace(/[^0-9.-]+/g, ''))
        : 0;
    const finalPrice = priceValue - discountAmount;

    const handleConfirmPayment = () => {
        setOpenPaymentSuccessDialog(true); // Open the payment success dialog
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            padding: { xs: '18px 8px', sm: '32px' },
                            backgroundColor: '#f5f5f5',
                            minHeight: '100vh',
                        }}
                    >
                        <Typography
                            variant='h5'
                            fontWeight='bold'
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1.5rem', sm: '1.7rem' },
                                padding: { xs: '8px 0', sm: '8px 32px' },
                            }}
                        >
                            Confirm and Pay
                        </Typography>

                        {/* Space Selection */}
                        <Box sx={{ padding: { xs: 0, sm: '0 32px' }, mb: 1 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <LocationOnIcon sx={{ marginRight: 1 }} />
                                    <Typography variant='body1'>
                                        Select Event Space
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{ marginBottom: { xs: 1, sm: 2 } }}
                                    >
                                        {spaces.map(space => (
                                            <MenuItem
                                                key={space.id}
                                                value={space.id}
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.70rem',
                                                        sm: '1rem',
                                                    },
                                                    textAlign: 'center',
                                                    justifyContent: {
                                                        xs: 'center',
                                                        sm: 'start',
                                                    },
                                                }}
                                            >
                                                {space.name} - {space.price}
                                            </MenuItem>
                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        {/*Select Date*/}
                        <SelectDate />

                        {/* Selected Bank Display */}
                        <Typography
                            variant='h6'
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                padding: { xs: 0, sm: '8px 32px' },
                            }}
                        >
                            Select Payment Method
                        </Typography>
                        <SelectPayment />

                        <Divider />

                        {/* Selected Coupon Display */}
                        <Typography
                            variant='h6'
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                padding: { xs: 0, sm: '8px 32px' },
                            }}
                        >
                            Select Coupon
                        </Typography>
                        <VoucherCard />

                        <Divider />

                        {/* Payment Options */}
                        <Typography
                            variant='h6'
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                textAlign: { xs: 'center', sm: 'left' },
                                padding: { xs: 0, sm: '8px 32px' },
                                mb: 1,
                            }}
                        >
                            Review and Pay
                        </Typography>
                        <ReviewPay
                            finalPrice={finalPrice}
                            handleConfirmPayment={handleConfirmPayment}
                        />
                    </Paper>
                </Grid>
            </Grid>

            {/* Payment Success Dialog */}
            <Dialog
                open={openPaymentSuccessDialog}
                onClose={() => setOpenPaymentSuccessDialog(false)}
                maxWidth='xs'
                fullWidth
            >
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        padding: { xs: '24px', sm: '32px' },
                    }}
                >
                    <CheckCircleIcon
                        color='success'
                        sx={{ fontSize: { xs: 40, sm: 60 } }}
                    />
                    <Typography
                        variant='h5'
                        fontWeight='bold'
                        gutterBottom
                        sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                    >
                        Your payment was successful!
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        gutterBottom
                    >
                        We'll email admin@mycompany.com a receipt for each
                        invoice.
                    </Typography>
                    <Typography variant='body2' fontWeight='bold' gutterBottom>
                        Invoices
                    </Typography>
                    <Typography variant='h6' gutterBottom>
                        9999504
                    </Typography>
                    <Button
                        variant='save'
                        onClick={() => setOpenPaymentSuccessDialog(false)}
                        sx={{
                            marginTop: '16px',
                            width: { xs: '100%', sm: 'auto' },
                        }}
                    >
                        Return
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default CheckoutPage;
