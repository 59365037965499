import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Paper,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Generic file icon for other types

import DeleteIcon from '@mui/icons-material/Delete';
import AddFileIcon from "../shared/AddFileIcon";
import { fetchUploads, addUpload, deleteUpload } from '../../redux/uploadSlice';

const BASE_URL = 'https://ev-merchants.s3.ap-southeast-1.amazonaws.com/';

const Verification = () => {
    const dispatch = useDispatch();
    const { items: files = [], status, error, progress = {} } = useSelector(state => state.uploads);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchUploads());
        }
    }, [status, dispatch]);

    const handleFileUpload = (event, fileType) => {
        const newFiles = Array.from(event.target.files);
        dispatch(addUpload({ files: newFiles, productId: 1, fileType }));
    };

    const handleDelete = (fileId) => {
        dispatch(deleteUpload(fileId));
    };

    const renderFileList = (fileList) => (
        <List>
            {fileList.map((file) => (
                <ListItem key={file.id} sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'center' } }}>
                    <ListItemText
                        primary={
                            <>
                                {/* Determine the icon based on file extension */}
                                {['.jpg', '.jpeg', '.png', '.gif'].includes(file.originalName.toLowerCase().slice(-4)) ? (
                                    <ImageIcon sx={{ mr: 1 }} />
                                ) : file.originalName.toLowerCase().endsWith('.pdf') ? (
                                    <PictureAsPdfIcon sx={{ mr: 1 }} />
                                ) : file.originalName.toLowerCase().endsWith('.doc') || file.originalName.toLowerCase().endsWith('.docx') ? (
                                    <DescriptionIcon sx={{ mr: 1 }} />
                                ) : (
                                    <InsertDriveFileIcon sx={{ mr: 1 }} /> // Default icon for other file types
                                )}
                                {file.originalName}
                            </>
                        }
                        secondary={
                            <>
                                {/* Show image preview if it's an image file, otherwise a link */}
                                {['.jpg', '.jpeg', '.png', '.gif'].includes(file.originalName.toLowerCase().slice(-4)) ? (
                                    <img
                                        src={`${BASE_URL}${file.originalName}`} // Construct the URL to the image
                                        alt={file.originalName}
                                        style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '8px' }} // Limit image size
                                    />
                                ) : (
                                    <a href={`${BASE_URL}${file.originalName}`} target="_blank" rel="noopener noreferrer">
                                        View File
                                    </a>
                                )}
                            </>
                        }
                        sx={{ flex: '1 1 auto', minWidth: 0, pr: 2 }}
                    />
                    <ListItemSecondaryAction sx={{ order: { xs: 2, sm: 1 }, ml: { xs: 'auto', sm: 0 }, mt: { xs: 1, sm: 0 } }}>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                    <Box sx={{ width: '100%', marginTop: 1, order: { xs: 1, sm: 2 } }}>
                        <LinearProgress variant="determinate" value={progress[file.originalName] || 0} />
                        <Typography variant="caption" display="block" align="right">
                            {progress[file.originalName] || 0}%
                        </Typography>
                    </Box>
                </ListItem>
            ))}
        </List>
    );

    const renderUploadSection = (title, description, buttonText, acceptedFormats, fileType) => (
        <Paper elevation={3} sx={{ padding: 1, borderRadius: 2, mb: 2 }}>
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" sx={{ backgroundColor: '#f4f6f8', pb: { xs: 1, md: 0 } }}>
                <Box padding="24px" display="flex" justifyContent="center" alignItems="center">
                    <AddFileIcon />
                </Box>
                <Box sx={{ flexGrow: 1, textAlign: { xs: 'center', sm: 'left' } }}>
                    <Typography
                        sx={{
                            color: 'var(--dark-500, #09090B)',
                            fontFamily: 'Inter',
                            fontSize: { xs: '12px', sm: '14px' },
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            color: 'var(--Gray-500, #667085)',
                            fontFamily: 'Inter',
                            fontSize: { xs: '10px', sm: '12px' },
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '18px',
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
                <Box sx={{ paddingRight: { xs: 0, sm: '24px' }, textAlign: { xs: 'center', sm: 'right' } }}>
                    <Button
                        variant='cancel'
                        onClick={() => document.getElementById(`file-upload-input-${fileType}`).click()}
                    >
                        {buttonText}
                    </Button>
                    <input
                        id={`file-upload-input-${fileType}`}
                        type="file"
                        hidden
                        multiple
                        accept={acceptedFormats}
                        onChange={(e) => handleFileUpload(e, fileType)}
                    />
                </Box>
            </Box>
            {renderFileList(files.filter(file => {
                const extension = file.originalName.split('.').pop().toLowerCase();
                return acceptedFormats.split(',').includes(`.${extension}`);
            }))}
        </Paper>
    );

    return (
        <>
            {renderUploadSection(
                "Biz Owner Selfie with ID",
                "Upload a selfie with your ID. Acceptable formats are JPG and PNG.",
                "Upload Images",
                ".jpg,.jpeg,.png",
                "images"
            )}
            {renderUploadSection(
                "Company SSM Documents",
                "Upload the SSM documents for your company. Acceptable formats are PDF, DOC, and DOCX.",
                "Upload Documents",
                ".pdf,.doc,.docx",
                "documents"
            )}
            {error && (
                <Typography color="error">
                    {typeof error === 'string' ? error : JSON.stringify(error)}
                </Typography>
            )}
        </>
    );
};

export default Verification;