import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to create a new organization address
export const createOrganizationAddress = createAsyncThunk('organizationAddresses/create', async (addressData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/organizations-addresses`, addressData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch an organization address by ID
export const fetchOrganizationAddressById = createAsyncThunk('organizationAddresses/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/organizations-addresses/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete an organization address by ID
export const deleteOrganizationAddress = createAsyncThunk('organizationAddresses/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        await axios.delete(`${API_BASE_URL}/organizations-addresses/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update an organization address by ID
export const updateOrganizationAddress = createAsyncThunk('organizationAddresses/update', async ({ id, addressData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/organizations-addresses/${id}`, addressData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch organization addresses by organization ID
export const fetchOrganizationAddressesByOrgId = createAsyncThunk('organizationAddresses/fetchByOrgId', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/organizations-addresses/organizations/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch the default organization address by organization ID
export const fetchDefaultOrganizationAddress = createAsyncThunk('organizationAddresses/fetchDefault', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/organizations-addresses/organizations/${id}/default`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Organization Addresses
const organizationAddressesSlice = createSlice({
    name: 'organizationAddresses',
    initialState: {
        addresses: [],
        selectedAddress: null,
        defaultAddress: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createOrganizationAddress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createOrganizationAddress.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.addresses.push(action.payload);
            })
            .addCase(createOrganizationAddress.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create organization address';
            })

            .addCase(fetchOrganizationAddressById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationAddressById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedAddress = action.payload;
            })
            .addCase(fetchOrganizationAddressById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch organization address by ID';
            })

            .addCase(deleteOrganizationAddress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteOrganizationAddress.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.addresses = state.addresses.filter(address => address.id !== action.payload);
                if (state.selectedAddress && state.selectedAddress.id === action.payload) {
                    state.selectedAddress = null;
                }
            })
            .addCase(deleteOrganizationAddress.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete organization address';
            })

            .addCase(updateOrganizationAddress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateOrganizationAddress.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.addresses.findIndex(address => address.id === action.payload.id);
                if (index !== -1) state.addresses[index] = action.payload;
                if (state.selectedAddress && state.selectedAddress.id === action.payload.id) {
                    state.selectedAddress = action.payload;
                }
            })
            .addCase(updateOrganizationAddress.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update organization address';
            })

            .addCase(fetchOrganizationAddressesByOrgId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationAddressesByOrgId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.addresses = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchOrganizationAddressesByOrgId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch organization addresses by organization ID';
            })

            .addCase(fetchDefaultOrganizationAddress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDefaultOrganizationAddress.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.defaultAddress = action.payload;
            })
            .addCase(fetchDefaultOrganizationAddress.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch default organization address';
            });
    },
});

export default organizationAddressesSlice.reducer;
