import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all notifications
export const fetchNotifications = createAsyncThunk('notifications/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/notifications`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new notification
export const createNotification = createAsyncThunk('notifications/create', async (notificationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/notifications`, notificationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to mark a notification as read
export const markNotificationAsRead = createAsyncThunk('notifications/markAsRead', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/notifications/${id}/read`, null, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific notification by ID
export const fetchNotificationById = createAsyncThunk('notifications/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/notifications/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch unread notifications
export const fetchUnreadNotifications = createAsyncThunk('notifications/fetchUnread', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/notifications/unread`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Notifications
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
        selectedNotification: null,
        unreadNotifications: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.notifications = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch notifications';
            })

            .addCase(createNotification.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createNotification.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.notifications.push(action.payload);
            })
            .addCase(createNotification.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create notification';
            })

            .addCase(markNotificationAsRead.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(markNotificationAsRead.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.notifications.findIndex(notification => notification.id === action.payload.id);
                if (index !== -1) state.notifications[index] = action.payload;
                if (state.selectedNotification && state.selectedNotification.id === action.payload.id) {
                    state.selectedNotification = action.payload;
                }
            })
            .addCase(markNotificationAsRead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to mark notification as read';
            })

            .addCase(fetchNotificationById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNotificationById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedNotification = action.payload;
            })
            .addCase(fetchNotificationById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch notification by ID';
            })

            .addCase(fetchUnreadNotifications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.unreadNotifications = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchUnreadNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch unread notifications';
            });
    },
});

export default notificationsSlice.reducer;
