import React, { useState } from 'react';
import { Box, Typography, Checkbox, FormGroup, FormControlLabel, TextField, Button } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import AppPermissions from "./AppList";

const PermissionSection = ({ title, items, subHeaders }) => {
    const [expanded, setExpanded] = useState(false);
    const [checked, setChecked] = useState(
        subHeaders
            ? subHeaders.flatMap(sh => sh.items.map(() => false))
            : (items ? items.map(() => false) : [])
    );

    const toggleExpand = () => setExpanded(!expanded);

    const handleMainCheckboxChange = (event) => {
        setChecked(checked.map(() => event.target.checked));
    };

    const handleItemCheckboxChange = (index) => (event) => {
        const newChecked = [...checked];
        newChecked[index] = event.target.checked;
        setChecked(newChecked);
    };

    const checkedCount = checked.filter(Boolean).length;
    const totalItems = subHeaders
        ? subHeaders.reduce((sum, sh) => sum + sh.items.length, 0)
        : (items ? items.length : 0);

    return (
        <Box sx={{ mb: 2 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkedCount === totalItems && totalItems > 0}
                        indeterminate={checkedCount > 0 && checkedCount < totalItems}
                        onChange={handleMainCheckboxChange}
                    />
                }
                label={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography variant="subtitle1">{title}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                                {checkedCount}/{totalItems}
                            </Typography>
                            <Button
                                onClick={toggleExpand}
                                variant='cancel'
                                endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                                style={{ background: 'none', border: 'none' }}
                            >
                            </Button>
                        </Box>
                    </Box>
                }
                sx={{
                    backgroundColor: '#f5f5f5',
                    width: '100%',
                    ml: 0,
                    borderRadius: '8px',
                    padding: '4px',
                    boxSizing: 'border-box',
                    '& .MuiFormControlLabel-label': {
                        flex: 1,
                        fontWeight: '600',
                    }
                }}
            />
            {expanded && (
                <Box sx={{ ml: 6, display: 'flex', flexDirection: 'column' }}>
                    {subHeaders ? (
                        subHeaders.map((subHeader, shIndex) => (
                            <Box key={shIndex} sx={{ mt: 1 }}>
                                <Typography sx={{fontSize:'16px', fontWeight:'600', my: 1}}>{subHeader.title}</Typography>
                                {subHeader.items.map((item, itemIndex) => {
                                    const index = subHeaders.slice(0, shIndex).reduce((sum, sh) => sum + sh.items.length, 0) + itemIndex;
                                    return (
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <FormControlLabel
                                                key={itemIndex}
                                                control={<Checkbox checked={checked[index]} onChange={handleItemCheckboxChange(index)} />}
                                                label={item}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        ))
                    ) : items ? (
                        items.map((item, index) => (
                            <FormControlLabel
                                key={index}
                                control={<Checkbox checked={checked[index]} onChange={handleItemCheckboxChange(index)} />}
                                label={item}
                            />
                        ))
                    ) : null}
                </Box>
            )}
        </Box>
    );
};

const PermissionsPage = () => {
    const [globalExpanded, setGlobalExpanded] = useState(true);

    const toggleGlobalExpand = () => {
        setGlobalExpanded(!globalExpanded);
    };

    const sections = [
        { title: 'Home', items: ['Home'] },
        {
            title: 'Orders',
            items: [
                'View', 'Manage order information', 'Edit orders', 'Apply discounts', 'Set payment terms',
                'Charge credit card', 'Charge vault card', 'Mark as paid', 'Capture payments',
                'Fulfill and ship', 'Buy shipping labels', 'Return', 'Refund', 'Cancel', 'Export', 'Delete'
            ]
        },
        { title: 'Abandoned Checkouts', items: ['Manage'] },
        {
            title: 'Draft Orders',
            items: [
                'View', 'Create and edit', 'Apply discounts', 'Set payment terms',
                'Charge credit card', 'Mark as paid', 'Export', 'Delete'
            ]
        },
        {
            title: 'Products',
            items: [
                'View products', 'Create and edit products', 'Delete products', 'View collections',
                'Create and edit collections', 'Delete collections'
            ]
        },
        { title: 'Gift cards', items: ['View, create, and delete'] },
        {
            title: 'Content',
            subHeaders: [
                { title: 'Metaobject definitions', items: ['View', 'Create and edit', 'Delete'] },
                { title: 'Entries', items: ['View', 'Create and edit', 'Delete'] }
            ]
        },
        {
            title: 'Customers',
            items: ['View', 'Create and edit', 'Export/import data', 'Request data', 'Export', 'Merge']
        },
        { title: 'Analytics', items: ['Reports', 'Dashboards'] },
        { title: 'Marketing', items: ['View, create, and delete campaigns'] },
        { title: 'Discounts', items: ['View, create, and delete'] },
        {
            title: 'Online store',
            items: ['Themes', 'Edit code', 'Blog posts and pages', 'Navigation']
        },
        {
            title: 'Store content',
            items: ['Add and remove', 'Manage page structure', 'Remote services tokens', 'Collaborations', 'Manage channels']
        },
        {
            title: 'App management',
            items: ['View apps developed by staff and collaborators', 'Enable development']
        },
        {
            title: 'Store settings',
            items: ['View store details', 'Manage settings', 'Manage taxes and duties', 'Locations', 'Checkout', 'Transfer services to another Shopify store', 'View customer events']
        },
        {
            title: 'Finance',
            items: ['View billing and invoice details', 'Update payment method and pay invoices', 'Manage plan', 'View Shopify Payments payouts', 'View transaction fees']
        }
    ];


    return (
        <Box>
            <Box sx={{ maxWidth: 1000, margin: 'auto', padding: 2, backgroundColor: 'white', borderRadius: '12px' }}>
                <Typography variant="h6" gutterBottom>
                    Store permissions
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Some permissions for EventLah's Online Store permissions are disabled because you don't have permission to change them.
                </Typography>

                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    size="small"
                    sx={{ mb: 2 }}
                />

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="subtitle1">Select all permissions</Typography>
                    <Button
                        variant='cancel'
                        endIcon={globalExpanded ? <ExpandLess /> : <ExpandMore />}
                        onClick={toggleGlobalExpand}
                    >
                        {globalExpanded ? 'Collapse all' : 'Expand all'}
                    </Button>
                </Box>

                {globalExpanded && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {sections.map((section, index) => (
                            <PermissionSection key={index} title={section.title} items={section.items} subHeaders={section.subHeaders}/>
                        ))}
                    </Box>
                )}


            </Box>
            <Box sx={{ maxWidth: 1000, margin: 'auto', padding: 2, backgroundColor: 'white', borderRadius: '12px', mt:5 }}>
                <AppPermissions/>
            </Box>
        </Box>
    );
};

export default PermissionsPage;