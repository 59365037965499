import {
    Box,
    Card,
    CardActions,
    CardContent, Checkbox, Divider,
    IconButton,
    Menu,
    MenuItem, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
    Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import StarIcon from '@mui/icons-material/Star';

export const packages = [
    { title: 'Annual Dinner Basic Package', price: 'RM 15,000.00', pax: '50 pax', services: '2 Services', status: 'Active' },
    { title: 'Annual Dinner Gold Package', price: 'RM 25,500.00', pax: '200 pax', services: '4 Services', status: 'Active' },
    { title: 'Annual Dinner Platinum Package', price: 'RM 45,800.00', pax: '400 pax', services: '6 Services', status: 'Active' },
    { title: 'Annual Dinner Platinum Package', price: 'RM 45,800.00', pax: '400 pax', services: '6 Services', status: 'Active' },
    { title: 'Annual Dinner Platinum Package', price: 'RM 45,800.00', pax: '400 pax', services: '6 Services', status: 'Active' },
    { title: 'Annual Dinner Platinum Package', price: 'RM 45,800.00', pax: '400 pax', services: '6 Services', status: 'Active' },
];

const TableTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--dark-500, #09090B)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px', // 142.857%
}));

const TableContents = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-500, #667085)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px', // 166.667%
}));

export const PackageList = ({ packages, handleMenuClick, anchorEl, handleMenuClose }) => (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '24px', paddingTop: '24px', }}>
        {packages.map((pkg, index) => (
            <Card key={index} sx={{ borderRadius: '10px', margin: 0 }}>
                <CardContent sx={{ padding: 0, margin: 0, }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '50px', paddingX: '16px', paddingBottom: '0px', background: 'white' }}>
                        <Box sx={{
                            background: 'var(--alert-green-50, #ECFDF3)',
                            display: 'flex',
                            height: '18px',
                            width: '45px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            margin: 0,
                        }}
                        >
                            <Typography
                                sx={{
                                    color: 'var(--alert-green-500, #12B76A)', // Text color
                                    fontFamily: 'Inter', // Font family
                                    fontSize: '10px', // Font size
                                    fontStyle: 'normal', // Font style
                                    fontWeight: 600, // Font weight
                                    margin: 0,
                                }}
                            >
                                {pkg.status}
                            </Typography>
                        </Box>
                        <IconButton aria-label="settings" onClick={handleMenuClick}>
                            <MoreVertIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{
                                sx: {
                                    boxShadow: '0px 0px 14px 0px rgba(191, 205, 255, 0.10)',
                                    width:'140px',
                                    height:'98px',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={handleMenuClose}
                                sx={{
                                    border:'none',
                                    color: 'var(--gray-500, #6D6D6D)',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    height: '41px',
                                    paddingLeft:'16px'
                            }}
                            >
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={handleMenuClose}
                                sx={{
                                    border:'none',
                                    color: 'var(--gray-500, #6D6D6D)',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    height: '41px',
                                    paddingLeft:'16px'
                                }}
                            >
                                Archive
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Box sx={{ height: '102px', display: 'flex', alignItems: 'center', paddingX: '16px', marginBottom: '0px', }}>
                        <Box>
                            <Typography
                                sx={{
                                    color: 'var(--dark-500, #09090B)',
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px', // 150%
                                    margin: 0,
                                }}
                            >
                                {pkg.title}
                            </Typography>
                            <Typography
                                sx={{
                                    color: 'var(--dark-500, #09090B)',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '26px', // 144.444%
                                    margin: 0,
                                }}
                            >
                                {pkg.price}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>

                <CardActions sx={{background: 'var(--gray-30, #F7F7F7)', height:'44px', padding:0 }}>
                    <Box sx={{ paddingX: '16px' }}>
                        <Typography
                            sx={{
                                color: 'var(--Gray-500, #667085)',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '20px', // 166.667%
                            }}
                        >
                            {pkg.pax} • {pkg.services}
                        </Typography>
                    </Box>
                </CardActions>
            </Card>
        ))}
    </Box>
);

export const PackageServices1 = () => {
    const catererData = [
        {
            id: 1,
            name: 'Tea Time Package',
            price: 'RM 4,000.00',
            pax: '500 pax',
            description: '2 Main, 3 Side',
            provider: 'Faz Food Catering',
            rating: 5,
        },
        {
            id: 2,
            name: 'Breakfast & Lunch Package',
            price: 'RM 8,200.00',
            pax: '500 pax',
            description: '5 Main, 6 Side',
            provider: 'Faz Food Catering',
            rating: 4,
        },
        {
            id: 3,
            name: 'Breakfast, Lunch & Dinner Package',
            price: 'RM 15,000.00',
            pax: '500 pax',
            description: '8 Main, 10 Side',
            provider: 'Faz Food Catering',
            rating: 4,
        },
        {
            id: 4,
            name: 'Annual Dinner Package',
            price: 'RM 30,000.00',
            pax: '500 pax',
            description: '8 Main, 20 Side',
            provider: 'Faz Food Catering',
            rating: 4,
        }
    ];
    return (
        <Paper sx={{ background:'var(--gray-30, #F7F7F7)', padding:'16px' }}>
            <Box sx={{display:'flex'}}>
                <Typography
                    sx={{
                        color: 'var(--dark-500, #09090B)',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '20px', // 142.857%
                    }}
                >
                    Caterer
                </Typography>
            </Box>

            <Divider
                orientation="vertical"
                sx={{
                    height: '1px',
                    alignSelf: 'stretch',
                    backgroundColor: 'var(--Gray-200, #EAECF0)',
                    margin: '18px 0 24px 0', // Adjust spacing around the divider if needed
                }}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search for caterer"
                    InputProps={{
                        startAdornment: (
                            <IconButton position="start">
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                />
                <Button
                    variant='cancel'
                    sx={{
                        color: 'var(--Gray-700, #344054)',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '20px', // 142.857%
                        textTransform: 'none', // Prevent button text from being uppercase
                    }}
                >
                    <FilterListIcon />
                    Filters
                </Button>
            </Box>

            <TableContainer
                component={Paper}
                sx={{
                    border: '1px solid var(--gray-100, #E7E7E7)',
                    borderRadius: '8px',
                    overflow: 'hidden', // Ensure child elements do not overflow the container
                    boxShadow: 'none',
                    marginTop:'24px'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow sx={{ height:'44px', background:'var(--Gray-50, #F9FAFB)' }}>
                            <TableCell
                                sx={{
                                    color: 'var(--Gray-500, #667085)',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '18px', // 150%
                                }}
                            >
                                Name of services
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: 'var(--Gray-500, #667085)',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '18px', // 150%
                                }}
                            >
                                Price & Pax
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: 'var(--Gray-500, #667085)',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '18px', // 150%
                                }}
                            >
                                Provider
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: 'var(--Gray-500, #667085)',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '18px', // 150%
                                }}
                            >
                                {/* Empty cell */}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {catererData.map((row) => (
                            <TableRow key={row.id} sx={{height:'72px'}}>
                                <TableCell>
                                    <Box sx={{display:'flex', width:'242px'}}>
                                        <Checkbox />
                                        <Box sx={{paddingLeft:'12px'}}>
                                            <TableTitle>{row.name}</TableTitle>
                                            <TableContents>{row.description}</TableContents>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ width:'152px' }}>
                                    <TableTitle>{row.price}</TableTitle>
                                    <TableContents>{row.pax}</TableContents>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{display:'flex',}}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <circle cx="12" cy="12" r="12" fill="#D9D9D9"/>
                                            </svg>
                                        </Box>
                                        <Box>
                                            <TableTitle sx={{paddingLeft: '7px'}}>{row.provider}</TableTitle>
                                            <Box>
                                                {Array.from({length: 5}).map((_, index) => (
                                                    <StarIcon
                                                        key={index}
                                                        style={{
                                                            color: index < row.rating ? '#ffb800' : '#e0e0e0',
                                                            height: '11px',
                                                            marginRight: '-12px',
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Button variant="cancel">View</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop:'12px'}}>
                <Typography variant="body2">Page 1 of 10</Typography>
                <Box sx={{ display: 'flex', gap: '12px' }}>
                    <Button variant='cancel' sx={{ minWidth: '32px', width: '32px', height: '32px' }}> {'<'} </Button>
                    <Button variant='cancel' sx={{ minWidth: '32px', width: '32px', height: '32px' }}> {'>'} </Button>
                </Box>
            </Box>
        </Paper>
    );
};
