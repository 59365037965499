import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchPermissions = createAsyncThunk(
    'adminPermissions/fetchPermissions',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/permissions`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params,
            });
            console.log('fetchPermissions response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchPermissions error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const createPermission = createAsyncThunk(
    'adminPermissions/createPermission',
    async (permissionData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/permissions`,
                permissionData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('createPermission response:', response.data);
            return response.data;
        } catch (error) {
            console.error('createPermission error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const updatePermission = createAsyncThunk(
    'adminPermissions/updatePermission',
    async ({ id, ...updateData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/permissions/${id}`,
                updateData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('updatePermission response:', response.data);
            return response.data;
        } catch (error) {
            console.error('updatePermission error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchSinglePermission = createAsyncThunk(
    'adminPermissions/fetchSinglePermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchSinglePermission response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchSinglePermission error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deletePermission = createAsyncThunk(
    'adminPermissions/deletePermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deletePermission success for id:', id);
            return id;
        } catch (error) {
            console.error('deletePermission error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

const adminPermissionsSlice = createSlice({
    name: 'adminPermissions',
    initialState: {
        permissions: [],
        selectedPermission: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPermissions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPermissions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.permissions = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(fetchPermissions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch permissions';
            })
            .addCase(createPermission.fulfilled, (state, action) => {
                state.permissions.push(action.payload);
                state.pagination.totalItems += 1;
            })
            .addCase(updatePermission.fulfilled, (state, action) => {
                const index = state.permissions.findIndex(p => p.id === action.payload.id);
                if (index !== -1) {
                    state.permissions[index] = action.payload;
                }
                if (state.selectedPermission && state.selectedPermission.id === action.payload.id) {
                    state.selectedPermission = action.payload;
                }
            })
            .addCase(fetchSinglePermission.fulfilled, (state, action) => {
                state.selectedPermission = action.payload;
            })
            .addCase(deletePermission.fulfilled, (state, action) => {
                state.permissions = state.permissions.filter(p => p.id !== action.payload);
                state.pagination.totalItems -= 1;
                if (state.selectedPermission && state.selectedPermission.id === action.payload) {
                    state.selectedPermission = null;
                }
            });
    },
});

export default adminPermissionsSlice.reducer;