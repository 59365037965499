import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

// const API_BASE_URL = 'http://localhost:3001/api';
const API_BASE_URL = 'https://api-dev.eventlah.com/api'

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to create a general email
export const createEmail = createAsyncThunk('email/create', async (emailData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/email`, emailData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// New thunk for deleting an email
export const deleteEmail = createAsyncThunk('email/delete', async (emailId, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.delete(`${API_BASE_URL}/emails?id=${emailId}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});


// Thunk to create agent registration email
export const createAgentRegistrationEmail = createAsyncThunk('email/createAgentRegistration', async ({ keycloakId, emailData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/email/users/registrations/agents/${keycloakId}`, emailData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create an email with HTML content
export const createEmailWithHtml = createAsyncThunk('email/createWithHtml', async (emailData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/emails`, emailData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create booking email
export const createBookingEmail = createAsyncThunk('email/createBooking', async ({ code, emailData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/email/booking/${code}`, emailData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create booking email with referral
export const createBookingEmailWithReferral = createAsyncThunk('email/createBookingWithReferral', async ({ code, emailData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/email/booking/${code}/referral`, emailData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Email Operations
const emailSlice = createSlice({
    name: 'email',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEmail.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createEmail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create email';
            })

            .addCase(createAgentRegistrationEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createAgentRegistrationEmail.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createAgentRegistrationEmail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create agent registration email';
            })

            .addCase(createEmailWithHtml.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEmailWithHtml.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createEmailWithHtml.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create email with HTML';
            })

            .addCase(createBookingEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createBookingEmail.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createBookingEmail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create booking email';
            })

            .addCase(createBookingEmailWithReferral.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createBookingEmailWithReferral.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createBookingEmailWithReferral.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create booking email with referral';
            })
            .addCase(deleteEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteEmail.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(deleteEmail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete email';
            });
        ;

    },
});

export default emailSlice.reducer;
