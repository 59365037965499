import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

const ServiceFormAppointments = () => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ padding: 1, borderRadius: 2 }}>
                <Box display="flex" bgcolor='#f5f5f5'>
                    <Box padding="24px">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M34.3333 12.6802V6.00016C34.3333 5.55814 34.1577 5.13421 33.8452 4.82165C33.5326 4.50909 33.1087 4.3335 32.6667 4.3335H2.66667C2.22464 4.3335 1.80072 4.50909 1.48816 4.82165C1.17559 5.13421 1 5.55814 1 6.00016V12.6802H34.3333Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M1 12.6798V29.3332C1 29.7752 1.17559 30.1991 1.48816 30.5117C1.80072 30.8242 2.22464 30.9998 2.66667 30.9998H32.6667C33.1087 30.9998 33.5326 30.8242 33.8452 30.5117C34.1577 30.1991 34.3333 29.7752 34.3333 29.3332V12.6665L1 12.6798Z"
                                fill="white"/>
                            <path
                                d="M16 31.0002H2.66667C2.22464 31.0002 1.80072 30.8246 1.48816 30.512C1.17559 30.1994 1 29.7755 1 29.3335V6.00016C1 5.55814 1.17559 5.13421 1.48816 4.82165C1.80072 4.50909 2.22464 4.3335 2.66667 4.3335H32.6667C33.1087 4.3335 33.5326 4.50909 33.8452 4.82165C34.1577 5.13421 34.3333 5.55814 34.3333 6.00016V16.8335"
                                stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.33301 1V9.33333" stroke="#00303E" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M26 1V9.33333" stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 12.6665H34.3333" stroke="#00303E" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                                d="M29.333 39.3335C31.3108 39.3335 33.2442 38.747 34.8887 37.6482C36.5332 36.5494 37.8149 34.9876 38.5718 33.1603C39.3287 31.3331 39.5267 29.3224 39.1409 27.3826C38.755 25.4428 37.8026 23.661 36.4041 22.2624C35.0056 20.8639 33.2237 19.9115 31.2839 19.5256C29.3441 19.1398 27.3334 19.3378 25.5062 20.0947C23.6789 20.8516 22.1171 22.1333 21.0183 23.7778C19.9195 25.4223 19.333 27.3557 19.333 29.3335C19.333 31.9857 20.3866 34.5292 22.2619 36.4046C24.1373 38.2799 26.6808 39.3335 29.333 39.3335Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M29.333 39.3335C31.3108 39.3335 33.2442 38.747 34.8887 37.6482C36.5332 36.5494 37.8149 34.9876 38.5718 33.1603C39.3287 31.3331 39.5267 29.3224 39.1409 27.3826C38.755 25.4428 37.8026 23.661 36.4041 22.2624C35.0056 20.8639 33.2237 19.9115 31.2839 19.5256C29.3441 19.1398 27.3334 19.3378 25.5062 20.0947C23.6789 20.8516 22.1171 22.1333 21.0183 23.7778C19.9195 25.4223 19.333 27.3557 19.333 29.3335C19.333 31.9857 20.3866 34.5292 22.2619 36.4046C24.1373 38.2799 26.6808 39.3335 29.333 39.3335Z"
                                stroke="#00303E" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M29.333 24.3335V34.3335" stroke="#00303E" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M34.333 29.3335H24.333" stroke="#00303E" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <Typography
                            sx={{
                                color: 'var(--dark-500, #09090B)',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                                paddingTop: '24px',
                            }}
                        >
                            Setup appointment
                        </Typography>
                        <Typography
                            sx={{
                                color: 'var(--Gray-500, #667085)',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '18px',
                            }}
                        >
                            Setup appointment for each services
                        </Typography>
                    </Box>

                    <Box sx={{paddingTop: '24px', paddingRight: '24px'}}>
                        <Button
                            variant='cancel'
                            onClick={handleClickOpen}
                        >
                            + Add Appointment
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Dialog open={open} onClose={handleClose}  PaperProps={{sx: { borderRadius: '16px', width:'480px', height:'430px', paddingBottom:'30px', paddingTop:'10px'}}}>
                <DialogTitle>Setup Appointments Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Name of appointment
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label=""
                        type="text"
                        fullWidth
                        variant="outlined"
                        placeholder="Cake Review / Discussion"
                        sx={{ marginBottom: 2 }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                        <TextField
                            margin="dense"
                            id="days"
                            label=""
                            type="number"
                            fullWidth
                            variant="outlined"
                            defaultValue="20"
                            sx={{ marginBottom: 2, width:'63px' }}
                        />
                        <DialogContentText sx={{paddingLeft:'6px', paddingTop:'17px'}}>
                            days before event
                        </DialogContentText>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Price charge of appointment"
                        />
                        <TextField
                            id="price"
                            type="text"
                            variant="outlined"
                            placeholder="RM 500.00"
                            sx={{width:'207px'}}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{px:'24px'}}>
                    <Button variant="cancel" onClick={handleClose} sx={{ width: '100%' }}>Cancel</Button>
                    <Button variant="save" onClick={handleClose} sx={{ width: '100%'}}>Save</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ServiceFormAppointments;
