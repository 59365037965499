import React from 'react';
import { Puck } from '@measured/puck';
import '@measured/puck/puck.css';
import { ContentfulImageField, ContentfulRichTextField } from '@measured/puck-field-contentful';

// Create Puck component config
const config = {
    components: {
        HeadingBlock: {
            fields: {
                children: {
                    type: 'text',
                },
            },
            render: ({ children }) => {
                return <h1>{children}</h1>;
            },
        },
        ImageBlock: {
            fields: {
                src: {
                    type: 'text',
                },
                alt: {
                    type: 'text',
                },
            },
            render: ({ src, alt }) => {
                return <img src={src} alt={alt} style={{ maxWidth: '100%' }} />;
            },
        },
        ContentfulImageBlock: {
            fields: {
                image: ContentfulImageField,
            },
            render: ({ image }) => {
                return <img src={image.url} alt={image.title} style={{ maxWidth: '100%' }} />;
            },
        },
        ContentfulRichTextBlock: {
            fields: {
                content: ContentfulRichTextField,
            },
            render: ({ content }) => {
                return <div dangerouslySetInnerHTML={{ __html: content }} />;
            },
        },
    },
};

// Describe the initial data with a default template
const initialData = {
    blocks: [
        {
            type: 'HeadingBlock',
            fields: {
                children: 'Welcome to the Editor!',
            },
        },
        {
            type: 'ImageBlock',
            fields: {
                src: 'https://via.placeholder.com/600x400',
                alt: 'Placeholder Image',
            },
        },
        {
            type: 'ContentfulImageBlock',
            fields: {
                image: {
                    url: 'https://images.ctfassets.net/example.jpg',
                    title: 'Contentful Image',
                },
            },
        },
        {
            type: 'ContentfulRichTextBlock',
            fields: {
                content: '<p>This is a rich text block from Contentful.</p>',
            },
        },
        {
            type: 'HeadingBlock',
            fields: {
                children: 'Add your content below:',
            },
        },
    ],
};

// Save the data to your database
const save = (data) => {
    console.log('Saved data:', data);
};

// Render Puck editor
const Editor = ({ data, onSave }) => {
    return <Puck config={config} data={data || initialData} onPublish={onSave || save} />;
};

export default Editor;