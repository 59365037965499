import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Paper, Divider, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// EditableArea component
const EditableArea = ({ initialContent, onSave }) => {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(initialContent)))
    );

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
        onSave(JSON.stringify(convertToRaw(newState.getCurrentContent())));
    };

    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
        />
    );
};

const EmailPreview = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState({
        subject: JSON.stringify(convertToRaw(EditorState.createWithText('[Customer] Payment couldn\'t be processed').getCurrentContent())),
        title: JSON.stringify(convertToRaw(EditorState.createWithText('Your payment couldn\'t be processed').getCurrentContent())),
        body1: JSON.stringify(convertToRaw(EditorState.createWithText('You added items to your shopping cart but the payment couldn\'t be processed. You haven\'t been charged.').getCurrentContent())),
        body2: JSON.stringify(convertToRaw(EditorState.createWithText('You can still return to your cart to complete your purchase.').getCurrentContent())),
    });
    const [htmlContent, setHtmlContent] = useState('');

    const handleSave = (key, newContent) => {
        setContent(prev => ({ ...prev, [key]: newContent }));
    };

    const generateHtmlContent = () => {
        const convertContentToHtml = (rawContent) => {
            const contentState = convertFromRaw(JSON.parse(rawContent));
            return stateToHTML(contentState);
        };

        const subjectHtml = convertContentToHtml(content.subject);
        const titleHtml = convertContentToHtml(content.title);
        const body1Html = convertContentToHtml(content.body1);
        const body2Html = convertContentToHtml(content.body2);

        return `
            <html>
            <head>
                <style>
                    body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
                    .container { max-width: 600px; margin: 0 auto; padding: 20px; }
                    .logo { text-align: center; background-color: #f0f0f0; padding: 20px; }
                    .button { display: inline-block; padding: 10px 20px; background-color: #007bff; color: #ffffff; text-decoration: none; border-radius: 5px; }
                </style>
            </head>
            <body>
                <div class="container">
                    <div class="logo">Your Company's Logo</div>
                    <h1>${titleHtml}</h1>
                    <p>${body1Html}</p>
                    <p>${body2Html}</p>
                    <p style="text-align: center;">
                        <a href="#" class="button">Return to cart</a>
                        <a href="#" class="button" style="background-color: #6c757d;">Visit our store</a>
                    </p>
                </div>
            </body>
            </html>
        `;
    };

    useEffect(() => {
        setHtmlContent(generateHtmlContent());
    }, [content]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#f5f5f5' }}>
                <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                    {/*<ArrowBackIcon sx={{ mr: 1 }} /> Payment error*/}
                </Typography>
                <Box>
                    <Button variant="save" onClick={() => setIsEditing(!isEditing)}>
                        {isEditing ? 'Save changes' : 'Edit email'}
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}>
                <Typography variant="subtitle2" gutterBottom color="text.secondary">
                    Email Preview
                </Typography>
                <Paper variant="outlined" sx={{ p: 2, mt: 1, maxWidth: 600, mx: 'auto' }}>
                    {isEditing ? (
                        <EditableArea initialContent={content.subject} onSave={(newContent) => handleSave('subject', newContent)} />
                    ) : (
                        <Typography variant="body2" gutterBottom>
                            Subject: {convertFromRaw(JSON.parse(content.subject)).getPlainText()}
                        </Typography>
                    )}
                    <Box sx={{ my: 3, textAlign: 'center' }}>
                        <Box
                            sx={{
                                width: '100%',
                                height: 100,
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#666',
                                border: '1px solid #ddd',
                            }}
                        >
                            Your Company's Logo
                        </Box>
                    </Box>
                    {isEditing ? (
                        <EditableArea initialContent={content.title} onSave={(newContent) => handleSave('title', newContent)} />
                    ) : (
                        <Typography variant="h6" gutterBottom align="center">
                            {convertFromRaw(JSON.parse(content.title)).getPlainText()}
                        </Typography>
                    )}
                    {isEditing ? (
                        <EditableArea initialContent={content.body1} onSave={(newContent) => handleSave('body1', newContent)} />
                    ) : (
                        <Typography variant="body2" paragraph align="center">
                            {convertFromRaw(JSON.parse(content.body1)).getPlainText()}
                        </Typography>
                    )}
                    {isEditing ? (
                        <EditableArea initialContent={content.body2} onSave={(newContent) => handleSave('body2', newContent)} />
                    ) : (
                        <Typography variant="body2" paragraph align="center">
                            {convertFromRaw(JSON.parse(content.body2)).getPlainText()}
                        </Typography>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 3 }}>
                        <Button variant="save">
                            Return to cart
                        </Button>
                        <Button variant="cancel" >
                            Visit our store
                        </Button>
                    </Box>
                </Paper>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom color="text.secondary">
                    HTML Content
                </Typography>
                <TextField
                    multiline
                    fullWidth
                    rows={10}
                    value={htmlContent}
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
        </Box>
    );
};

export default EmailPreview;