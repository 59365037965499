import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    IconButton,
    Select,
    MenuItem,
} from '@mui/material';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { createAgreement } from '../../../redux/agreementSlice';

const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 96];

const CustomEditor = ({ editorState, onEditorChange }) => {
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (inlineStyle) => {
        onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const handleFontSizeChange = (event) => {
        const fontSize = event.target.value;
        onEditorChange(RichUtils.toggleInlineStyle(editorState, `FONTSIZE_${fontSize}`));
    };

    const customStyleMap = FONT_SIZES.reduce((acc, size) => {
        acc[`FONTSIZE_${size}`] = { fontSize: `${size}px` };
        return acc;
    }, {});

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                <IconButton onClick={() => toggleInlineStyle('BOLD')}>
                    <FormatBoldIcon />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle('ITALIC')}>
                    <FormatItalicIcon />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle('UNDERLINE')}>
                    <FormatUnderlinedIcon />
                </IconButton>
                <Select
                    value=""
                    onChange={handleFontSizeChange}
                    displayEmpty
                    sx={{ minWidth: 100 }}
                >
                    <MenuItem value="" disabled>Font Size</MenuItem>
                    {FONT_SIZES.map((size) => (
                        <MenuItem key={size} value={size}>{size}px</MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{ border: '1px solid #ccc', borderRadius: '12px', minHeight: '200px', padding: '0 10px' }}>
                <Editor
                    editorState={editorState}
                    onChange={onEditorChange}
                    handleKeyCommand={handleKeyCommand}
                    customStyleMap={customStyleMap}
                />
            </Box>
        </Box>
    );
};

const AddAgreement = ({ open, onClose, onSuccess }) => {
    const dispatch = useDispatch();
    const status = useSelector(state => state.agreement.status);
    const [title, setTitle] = useState('');
    const [sections, setSections] = useState([
        { subtitle: '', content: EditorState.createEmpty() }
    ]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleSubtitleChange = (index, event) => {
        const newSections = [...sections];
        newSections[index].subtitle = event.target.value;
        setSections(newSections);
    };

    const handleContentChange = (index, newEditorState) => {
        const newSections = [...sections];
        newSections[index].content = newEditorState;
        setSections(newSections);
    };

    const addSection = () => {
        setSections([...sections, { subtitle: '', content: EditorState.createEmpty() }]);
    };

    const removeSection = (index) => {
        const newSections = sections.filter((_, i) => i !== index);
        setSections(newSections);
    };

    const createFullHtmlSource = (title, sections) => {
        const sectionHtml = sections.map(section => `
            <h2>${section.subtitle}</h2>
            ${stateToHTML(section.content.getCurrentContent())}
        `).join('');

        return `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${title}</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
        }
        h1 {
            color: #2c3e50;
        }
        h2 {
            color: #34495e;
        }
    </style>
</head>
<body>
    <h1>${title}</h1>
    ${sectionHtml}
</body>
</html>
        `.trim();
    };

    const handleSaveAdd = async () => {
        const htmlSource = createFullHtmlSource(title, sections);

        const newAgreement = {
            title,
            content: htmlSource,
            status: 1
        };

        try {
            await dispatch(createAgreement(newAgreement)).unwrap();
            onSuccess('Agreement created successfully');
            onClose();
        } catch (error) {
            onSuccess('Failed to create agreement: ' + error.message, 'error');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Add New Agreement</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                    />
                    {sections.map((section, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <TextField
                                    label={`Subtitle ${index + 1}`}
                                    value={section.subtitle}
                                    onChange={(e) => handleSubtitleChange(index, e)}
                                    fullWidth
                                />
                                <IconButton onClick={() => removeSection(index)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                            <CustomEditor
                                editorState={section.content}
                                onEditorChange={(newState) => handleContentChange(index, newState)}
                            />
                        </Box>
                    ))}
                    <Button variant='save' onClick={addSection}>
                        + Add Section
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='save' onClick={handleSaveAdd} disabled={status === 'loading'}>
                    {status === 'loading' ? 'Saving...' : 'Save'}
                </Button>
                <Button variant='cancel' onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddAgreement;