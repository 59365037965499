import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => ({
    'Authorization': `Bearer ${await getToken()}`,
    'Content-Type': 'application/json'
});

// Fetch all voucher space packages
export const fetchVoucherSpacePackages = createAsyncThunk('voucherSpacePackages/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/voucher-space-has-packages`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Create a new voucher space package
export const createVoucherSpacePackage = createAsyncThunk('voucherSpacePackages/create', async (data, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/voucher-space-has-packages`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch a voucher space package by ID
export const fetchVoucherSpacePackageById = createAsyncThunk('voucherSpacePackages/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/voucher-space-has-packages/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Update a voucher space package by ID
export const updateVoucherSpacePackage = createAsyncThunk('voucherSpacePackages/update', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/bridges/voucher-space-has-packages/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Delete a voucher space package by ID
export const deleteVoucherSpacePackage = createAsyncThunk('voucherSpacePackages/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/voucher-space-has-packages/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch all voucher has spaces
export const fetchVoucherHasSpaces = createAsyncThunk('voucherHasSpaces/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/voucher-has-spaces`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Create a new voucher has space
export const createVoucherHasSpace = createAsyncThunk('voucherHasSpaces/create', async (data, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/voucher-has-spaces`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch a voucher has space by ID
export const fetchVoucherHasSpaceById = createAsyncThunk('voucherHasSpaces/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/voucher-has-spaces/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Update a voucher has space by ID
export const updateVoucherHasSpace = createAsyncThunk('voucherHasSpaces/update', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/bridges/voucher-has-spaces/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Delete a voucher has space by ID
export const deleteVoucherHasSpace = createAsyncThunk('voucherHasSpaces/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/voucher-has-spaces/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch all user bookings
export const fetchUserBookings = createAsyncThunk('userBookings/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/user-has-bookings`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Create a new user booking
export const createUserBooking = createAsyncThunk('userBookings/create', async (data, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/user-has-bookings`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch a user booking by ID
export const fetchUserBookingById = createAsyncThunk('userBookings/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/user-has-bookings/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Update a user booking by ID
export const updateUserBooking = createAsyncThunk('userBookings/update', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/bridges/user-has-bookings/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Delete a user booking by ID
export const deleteUserBooking = createAsyncThunk('userBookings/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/user-has-bookings/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch all space packages
export const fetchSpacePackages = createAsyncThunk('spacePackages/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/space-has-packages`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Create a new space package
export const createSpacePackage = createAsyncThunk('spacePackages/create', async (data, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/space-has-packages`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch a space package by ID
export const fetchSpacePackageById = createAsyncThunk('spacePackages/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/space-has-packages/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Update a space package by ID
export const updateSpacePackage = createAsyncThunk('spacePackages/update', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/bridges/space-has-packages/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Delete a space package by ID
export const deleteSpacePackage = createAsyncThunk('spacePackages/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/space-has-packages/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch all event packages
export const fetchEventPackages = createAsyncThunk('eventPackages/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/event-has-packages`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Create a new event package
export const createEventPackage = createAsyncThunk('eventPackages/create', async (data, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/event-has-packages`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Fetch an event package by ID
export const fetchEventPackageById = createAsyncThunk('eventPackages/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/event-has-packages/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Update an event package by ID
export const updateEventPackage = createAsyncThunk('eventPackages/update', async ({ id, data }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/bridges/event-has-packages/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Delete an event package by ID
export const deleteEventPackage = createAsyncThunk('eventPackages/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/event-has-packages/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for managing all bridge-related entities
const bridgesSlice = createSlice({
    name: 'bridges',
    initialState: {
        voucherSpacePackages: [],
        voucherHasSpaces: [],
        userBookings: [],
        spacePackages: [],
        eventPackages: [],
        selectedItem: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch all voucher space packages
        builder
            .addCase(fetchVoucherSpacePackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVoucherSpacePackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.voucherSpacePackages = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchVoucherSpacePackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch voucher space packages';
            })

            // Create a new voucher space package
            .addCase(createVoucherSpacePackage.fulfilled, (state, action) => {
                state.voucherSpacePackages.push(action.payload);
            })

            // Fetch a voucher space package by ID
            .addCase(fetchVoucherSpacePackageById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVoucherSpacePackageById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedItem = action.payload;
            })
            .addCase(fetchVoucherSpacePackageById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch voucher space package';
            })

            // Update a voucher space package by ID
            .addCase(updateVoucherSpacePackage.fulfilled, (state, action) => {
                const index = state.voucherSpacePackages.findIndex(pkg => pkg.id === action.payload.id);
                if (index !== -1) state.voucherSpacePackages[index] = action.payload;
                if (state.selectedItem && state.selectedItem.id === action.payload.id) {
                    state.selectedItem = action.payload;
                }
            })

            // Delete a voucher space package by ID
            .addCase(deleteVoucherSpacePackage.fulfilled, (state, action) => {
                state.voucherSpacePackages = state.voucherSpacePackages.filter(pkg => pkg.id !== action.payload);
                if (state.selectedItem && state.selectedItem.id === action.payload) {
                    state.selectedItem = null;
                }
            })

            // Fetch all voucher has spaces
            .addCase(fetchVoucherHasSpaces.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVoucherHasSpaces.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.voucherHasSpaces = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchVoucherHasSpaces.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch voucher has spaces';
            })

            // Create a new voucher has space
            .addCase(createVoucherHasSpace.fulfilled, (state, action) => {
                state.voucherHasSpaces.push(action.payload);
            })

            // Fetch a voucher has space by ID
            .addCase(fetchVoucherHasSpaceById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVoucherHasSpaceById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedItem = action.payload;
            })
            .addCase(fetchVoucherHasSpaceById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch voucher has space';
            })

            // Update a voucher has space by ID
            .addCase(updateVoucherHasSpace.fulfilled, (state, action) => {
                const index = state.voucherHasSpaces.findIndex(space => space.id === action.payload.id);
                if (index !== -1) state.voucherHasSpaces[index] = action.payload;
                if (state.selectedItem && state.selectedItem.id === action.payload.id) {
                    state.selectedItem = action.payload;
                }
            })

            // Delete a voucher has space by ID
            .addCase(deleteVoucherHasSpace.fulfilled, (state, action) => {
                state.voucherHasSpaces = state.voucherHasSpaces.filter(space => space.id !== action.payload);
                if (state.selectedItem && state.selectedItem.id === action.payload) {
                    state.selectedItem = null;
                }
            })

            // Additional cases for other entities...

            // Fetch all user bookings
            .addCase(fetchUserBookings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserBookings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userBookings = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchUserBookings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch user bookings';
            })

            // Create a new user booking
            .addCase(createUserBooking.fulfilled, (state, action) => {
                state.userBookings.push(action.payload);
            })

            // Fetch a user booking by ID
            .addCase(fetchUserBookingById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserBookingById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedItem = action.payload;
            })
            .addCase(fetchUserBookingById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch user booking';
            })

            // Update a user booking by ID
            .addCase(updateUserBooking.fulfilled, (state, action) => {
                const index = state.userBookings.findIndex(booking => booking.id === action.payload.id);
                if (index !== -1) state.userBookings[index] = action.payload;
                if (state.selectedItem && state.selectedItem.id === action.payload.id) {
                    state.selectedItem = action.payload;
                }
            })

            // Delete a user booking by ID
            .addCase(deleteUserBooking.fulfilled, (state, action) => {
                state.userBookings = state.userBookings.filter(booking => booking.id !== action.payload);
                if (state.selectedItem && state.selectedItem.id === action.payload) {
                    state.selectedItem = null;
                }
            })

            // Fetch all space packages
            .addCase(fetchSpacePackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSpacePackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.spacePackages = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchSpacePackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch space packages';
            })

            // Create a new space package
            .addCase(createSpacePackage.fulfilled, (state, action) => {
                state.spacePackages.push(action.payload);
            })

            // Fetch a space package by ID
            .addCase(fetchSpacePackageById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSpacePackageById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedItem = action.payload;
            })
            .addCase(fetchSpacePackageById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch space package';
            })

            // Update a space package by ID
            .addCase(updateSpacePackage.fulfilled, (state, action) => {
                const index = state.spacePackages.findIndex(pkg => pkg.id === action.payload.id);
                if (index !== -1) state.spacePackages[index] = action.payload;
                if (state.selectedItem && state.selectedItem.id === action.payload.id) {
                    state.selectedItem = action.payload;
                }
            })

            // Delete a space package by ID
            .addCase(deleteSpacePackage.fulfilled, (state, action) => {
                state.spacePackages = state.spacePackages.filter(pkg => pkg.id !== action.payload);
                if (state.selectedItem && state.selectedItem.id === action.payload) {
                    state.selectedItem = null;
                }
            })

            // Fetch all event packages
            .addCase(fetchEventPackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventPackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventPackages = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchEventPackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event packages';
            })

            // Create a new event package
            .addCase(createEventPackage.fulfilled, (state, action) => {
                state.eventPackages.push(action.payload);
            })

            // Fetch an event package by ID
            .addCase(fetchEventPackageById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventPackageById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedItem = action.payload;
            })
            .addCase(fetchEventPackageById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event package';
            })

            // Update an event package by ID
            .addCase(updateEventPackage.fulfilled, (state, action) => {
                const index = state.eventPackages.findIndex(pkg => pkg.id === action.payload.id);
                if (index !== -1) state.eventPackages[index] = action.payload;
                if (state.selectedItem && state.selectedItem.id === action.payload.id) {
                    state.selectedItem = action.payload;
                }
            })

            // Delete an event package by ID
            .addCase(deleteEventPackage.fulfilled, (state, action) => {
                state.eventPackages = state.eventPackages.filter(pkg => pkg.id !== action.payload);
                if (state.selectedItem && state.selectedItem.id === action.payload) {
                    state.selectedItem = null;
                }
            });
    },
});

export default bridgesSlice.reducer;
