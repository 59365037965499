import keycloak from "../keycloak";

export const getToken = async () => {
    try {
        // Introduce a delay of 30 seconds before attempting to get the token
        await new Promise(resolve => setTimeout(resolve, 3000)); // 30-second delay
        return keycloak.token;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw new Error('Failed to refresh authentication token');
    }
};