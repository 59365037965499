import { configureStore } from '@reduxjs/toolkit';
import adminEventTypeReducer from './adminEventTypeSlice';
import adminFeatureReducer from './adminFeatureSlice';
import adminOrganizationReducer from './adminOrganizationSlice';
import adminPaymentStructureReducer from './adminPaymentStructureSlice';
import adminPermissionReducer from './adminPermissionSlice';
import adminPermissionFeatureReducer from './adminPermissionFeatureSlice';
import adminRoleDefaultPermissionsReducer from './adminRoleDefaultPermissionsSlice';
import adminRolesReducer from './adminRolesSlice';
import adminReducer from './adminSlice';
import adminStatusOwnershipsReducer from './adminStatusOwnershipsSlice';
import adminUserOrganizationsReducer from './adminUserOrganizationsSlice';
import adminUserRolePermissionFeaturesReducer from './adminUserRolePermissionFeaturesSlice';
import adminUserRolePermissionsReducer from './adminUserRolePermissionsSlice';
import adminUserRolesReducer from './adminUserRolesSlice';
import adminUsersReducer from './adminUsersSlice';
import apiMigrationsReducer from './apiMigrationsSlice';
import appointmentsReducer from './appointmentsSlice';
import bridgesReducer from './bridgesSlice';
import bookingHasPackagesReducer from './bookingHasPackagesSlice';
import currentUserMigrationsReducer from './currentUserMigrationsSlice';
import customerDetailsReducer from './customerDetailsSlice';
import emailReducer from './emailSlice';
import eventHasPackagesReducer from './eventHasPackagesSlice';
import eventsReducer from './eventsSlice';
import eventTypeReducer from './eventTypeSlice';
import featureMigrationsReducer from './featureMigrationsSlice';
import featuresReducer from './featuresSlice';
import migrationsReducer from './migrationsSlice';
import notificationsReducer from './notificationsSlice';
import organizationAddressesReducer from './organizationAddressesSlice';
import organizationProfilesReducer from './organizationProfilesSlice';
import organizationReducer from './organizationSlice';
import packageHasServicesReducer from './packageHasServicesSlice';
import packagesReducer from './packagesSlice';
import paymentDetailsReducer from './paymentDetailsSlice';
import paymentStructuresReducer from './paymentStructuresSlice';
import permissionHasFeatureMigrationsReducer from './permissionHasFeatureMigrationsSlice';
import permissionsHasFeaturesReducer from './permissionsHasFeaturesSlice';
import permissionsReducer from './permissionsSlice';
import productReducer from './productSlice';
import publicEventTypesReducer from './publicEventTypesSlice';
import publicReducer from './publicSlice';
import publicVouchersReducer from './publicVouchersSlice';
import referenceCategoriesReducer from './referenceCategoriesSlice';
import referenceCurrenciesReducer from './referenceCurrenciesSlice';
import referenceEventRelationshipsReducer from './referenceEventRelationshipsSlice';
import referenceFlowsReducer from './referenceFlowsSlice';
import referenceStatusesReducer from './referenceStatusesSlice';
import referencesReducer from './referencesSlice';
import roleDefaultPermissionsReducer from './roleDefaultPermissionsSlice';
import roleMigrationsReducer from './roleMigrationsSlice';
import rolesReducer from './rolesSlice';
import servicesReducer from './servicesSlice';
import spaceHasPackagesReducer from './spaceHasPackagesSlice';
import spacesReducer from './spacesSlice';
import statusesReducer from './statusesSlice';
import statusMigrationsReducer from './statusMigrationsSlice';
import statusOwnershipsMigrationsReducer from './statusOwnershipsMigrationsSlice';
import statusOwnershipsReducer from './statusOwnershipsSlice';
import uploadReducer from './uploadSlice';
import userHasBookingsReducer from './userHasBookingsSlice';
import userOrganizationsReducer from './userOrganizationsSlice';
import userRoleHasPermissionsReducer from './userRoleHasPermissionsSlice';
import userRolePermissionFeaturesReducer from './userRolePermissionFeaturesSlice';
import userRolesReducer from './userRolesSlice';
import usersReducer from './usersSlice';
import voucherHasSpacesReducer from './voucherHasSpacesSlice';
import voucherSpaceHasPackagesReducer from './voucherSpaceHasPackagesSlice';
import vouchersReducer from './vouchersSlice';
import agreementReducer from './agreementSlice';

export const store = configureStore({
    reducer: {
        adminEventType: adminEventTypeReducer,
        adminFeature: adminFeatureReducer,
        adminOrganizations: adminOrganizationReducer, // Unique key
        adminPaymentStructure: adminPaymentStructureReducer,
        adminPermission: adminPermissionReducer,
        adminPermissionFeature: adminPermissionFeatureReducer,
        adminRoleDefaultPermissions: adminRoleDefaultPermissionsReducer,
        adminRoles: adminRolesReducer,
        admin: adminReducer,
        adminStatusOwnerships: adminStatusOwnershipsReducer,
        adminUserOrganizations: adminUserOrganizationsReducer,
        adminUserRolePermissionFeatures: adminUserRolePermissionFeaturesReducer,
        adminUserRolePermissions: adminUserRolePermissionsReducer,
        adminUserRoles: adminUserRolesReducer,
        adminUsers: adminUsersReducer,
        agreement: agreementReducer,
        apiMigrations: apiMigrationsReducer,
        appointments: appointmentsReducer,
        bridges: bridgesReducer,
        bookingHasPackages: bookingHasPackagesReducer,
        currentUserMigrations: currentUserMigrationsReducer,
        customerDetails: customerDetailsReducer,
        email: emailReducer,
        eventHasPackages: eventHasPackagesReducer,
        events: eventsReducer,
        eventType: eventTypeReducer,
        featureMigrations: featureMigrationsReducer,
        features: featuresReducer,
        migrations: migrationsReducer,
        notifications: notificationsReducer,
        organizationAddresses: organizationAddressesReducer,
        organizationProfiles: organizationProfilesReducer,
        organizations: organizationReducer, // Unique key for organizations
        packageHasServices: packageHasServicesReducer,
        packages: packagesReducer,
        paymentDetails: paymentDetailsReducer,
        paymentStructures: paymentStructuresReducer,
        permissionHasFeatureMigrations: permissionHasFeatureMigrationsReducer,
        permissionsHasFeatures: permissionsHasFeaturesReducer,
        permissions: permissionsReducer,
        products: productReducer, // Updated key to avoid duplicates
        publicEventTypes: publicEventTypesReducer,
        public: publicReducer,
        publicVouchers: publicVouchersReducer,
        referenceCategories: referenceCategoriesReducer,
        referenceCurrencies: referenceCurrenciesReducer,
        referenceEventRelationships: referenceEventRelationshipsReducer,
        referenceFlows: referenceFlowsReducer,
        referenceStatuses: referenceStatusesReducer,
        references: referencesReducer,
        roleDefaultPermissions: roleDefaultPermissionsReducer,
        roleMigrations: roleMigrationsReducer,
        roles: rolesReducer,
        services: servicesReducer,
        spaceHasPackages: spaceHasPackagesReducer,
        spaces: spacesReducer,
        statuses: statusesReducer,
        statusMigrations: statusMigrationsReducer,
        statusOwnershipsMigrations: statusOwnershipsMigrationsReducer,
        statusOwnerships: statusOwnershipsReducer,
        uploads: uploadReducer, // Unique key
        userHasBookings: userHasBookingsReducer,
        userOrganizations: userOrganizationsReducer,
        userRoleHasPermissions: userRoleHasPermissionsReducer,
        userRolePermissionFeatures: userRolePermissionFeaturesReducer,
        userRoles: userRolesReducer,
        users: usersReducer,
        voucherHasSpaces: voucherHasSpacesReducer,
        voucherSpaceHasPackages: voucherSpaceHasPackagesReducer,
        vouchers: vouchersReducer,
    },
});

export default store;
