import {Box} from "@mui/material";
import TransactionHistory from "../../settings/others/TransactionHistory";

const AllTransactions = () => {
    return (
        <Box>
            <TransactionHistory/>
        </Box>
    )
}

export default AllTransactions;