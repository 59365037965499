import {Box, Divider, Typography} from "@mui/material";
import ProductImage from "../product-settings/ProductCatalog/AddProduct/ProductImage";

const MediaGallery = () => {
    return (
        <Box>
            <Typography variant="h1">Uploads</Typography>
            <Typography variant="h2">Manage and upload your media files.</Typography>

            <Divider/>

            <ProductImage/>
        </Box>
    )
}

export default MediaGallery;