import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async actions for API calls with Bearer token
export const fetchPaymentMethods = createAsyncThunk('paymentMethods/fetchPaymentMethods', async () => {
    const token = await getToken(); // Get the token
    const response = await axios.get(`${API_BASE_URL}/administrations/payments`, { // Adjust API_BASE_URL
        headers: {
            'Authorization': `Bearer ${token}`, // Add Bearer token to header
        }
    });
    return response.data;
});

// New patchPaymentMethod thunk
export const patchPaymentMethod = createAsyncThunk(
    'paymentMethods/patchPaymentMethod',
    async (paymentMethodData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/payments/${paymentMethodData.id}`,
                paymentMethodData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Define the addPaymentMethod function
export const addPaymentMethod = async (paymentMethodData) => {
    try {
        const token = await getToken(); // Get the token
        const response = await axios.post(
            `${API_BASE_URL}/administrations/payments`,
            paymentMethodData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
};

// Delete payment method thunk
export const deletePaymentMethod = createAsyncThunk(
  'paymentMethods/deletePaymentMethod',
  async (paymentMethodId, { rejectWithValue }) => {
    try {
      const token = await getToken();
      const response = await axios.delete(
        `${API_BASE_URL}/administrations/payments/${paymentMethodId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const paymentMethodSlice = createSlice({
    name: 'paymentMethods',
    initialState: {
        paymentMethods: [],
        status: 'idle',
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentMethods.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentMethods = action.payload;
            })
            .addCase(fetchPaymentMethods.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(patchPaymentMethod.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(patchPaymentMethod.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentMethods = state.paymentMethods.map((paymentMethod) =>
                    paymentMethod.id === action.payload.id ? action.payload : paymentMethod
                );
            })
            .addCase(patchPaymentMethod.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addPaymentMethod.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addPaymentMethod.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paymentMethods.push(action.payload);
            })
            .addCase(addPaymentMethod.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { updatePaymentMethod } = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;