import {Box} from "@mui/material";
import EmailNotifications from "../../settings/business-settings/EmailNotifications";

const AdminEmail = () => {
    return (
        <Box>
            <EmailNotifications/>
        </Box>
    )
}

export default AdminEmail;