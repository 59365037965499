import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Box, Typography, Button, Paper, Divider, TextField, IconButton, Autocomplete, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import { styled } from "@mui/system";
import { createEmailWithHtml } from "../../../redux/emailSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const API_BASE_URL = 'https://api-dev.eventlah.com/api'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '212px',
}));

const AddEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const emailStatus = useSelector((state) => state.email.status);
    const emailError = useSelector((state) => state.email.error);

    const [htmlContent, setHtmlContent] = useState(`
        <h1>Your payment couldn't be processed</h1>
        <p>You added items to your shopping cart but the payment couldn't be processed. You haven't been charged.</p>
        <p>You can still return to your cart to complete your purchase.</p>
    `);
    const [showPreview, setShowPreview] = useState(true);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [title, setTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (emailStatus === 'succeeded') {
            setOpenSnackbar(true);
            setTimeout(() => {
                navigate('/admin/email-management', { state: { newItemAdded: true } });
            }, 2000);
        }
    }, [emailStatus, navigate]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/ref-email-categories`);
            const data = response.data;
            if (data.code === 200 && data.data && data.data.refEmailCategories) {
                setCategories(data.data.refEmailCategories);
            } else {
                console.error('Unexpected API response format:', data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleAddCategory = async (newCategory) => {
        if (newCategory.trim() === '') return;
        console.log("New category added:", newCategory);
        setCategories([...categories, { name: newCategory }]);
        setSelectedCategory(newCategory);
        setInputValue('');
    };

    const handleHtmlContentChange = (content) => {
        setHtmlContent(content);
    };

    const handleSaveChanges = () => {
        const emailData = {
            title,
            subject,
            status: 1,
            email_content: htmlContent,
            html_source: htmlContent, // Added this line to save HTML source
            ref_email_category_id: 1
        };

        dispatch(createEmailWithHtml(emailData));
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const options = useMemo(() => {
        const existingCategories = categories.map((category) => category.name);
        const addNewOption = {
            inputValue: inputValue,
            name: `${inputValue}`,
        };
        return [addNewOption, ...existingCategories];
    }, [categories, inputValue]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#f5f5f5' }}>
                <Typography variant="h1">Add Email Contents</Typography>
                <Box>
                    <IconButton onClick={() => setShowPreview(!showPreview)} sx={{ mr: 1 }}>
                        {showPreview ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    <Button
                        variant="save"
                        onClick={handleSaveChanges}
                        disabled={emailStatus === 'loading'}
                    >
                        {emailStatus === 'loading' ? 'Saving...' : 'Save changes'}
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', position: 'relative' }}>
                <Box sx={{ width: showPreview ? 'calc(100% - 400px)' : '100%', p: 2, overflowY: 'auto', transition: 'width 0.3s ease' }}>
                    <Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <TextFieldTitle>Category</TextFieldTitle>
                            <Autocomplete
                                fullWidth
                                freeSolo
                                options={options}
                                value={selectedCategory}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setSelectedCategory(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                        handleAddCategory(newValue.inputValue);
                                    } else {
                                        setSelectedCategory(newValue?.name || null);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = options.filter(option => {
                                        const optionName = typeof option === 'string' ? option : option.name;
                                        return optionName && optionName.toLowerCase().includes(params.inputValue.toLowerCase());
                                    });

                                    if (params.inputValue !== '' && !filtered.some(option => {
                                        const optionName = typeof option === 'string' ? option : option.name;
                                        return optionName === params.inputValue;
                                    })) {
                                        filtered.unshift({
                                            inputValue: params.inputValue,
                                            name: "+ Add New Category",
                                        });
                                    }
                                    return filtered;
                                }}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.name || '';
                                }}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        {(typeof option === 'string' ? option : option.name).startsWith('Add New Category:') ? (
                                            <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AddIcon sx={{ mr: 1 }} />
                                                {typeof option === 'string' ? option : option.name}
                                            </Typography>
                                        ) : (
                                            typeof option === 'string' ? option : option.name
                                        )}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select or add a category"
                                        variant="outlined"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                padding: '8px 14px',
                                            },
                                        }}
                                    />
                                )}
                                size="small"
                            />
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <TextFieldTitle>Title</TextFieldTitle>
                            <TextField
                                label="Enter the title"
                                fullWidth
                                variant="outlined"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <TextFieldTitle>Subject</TextFieldTitle>
                            <TextField
                                label="Enter the subject"
                                fullWidth
                                variant="outlined"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </Box>
                    </Box>

                    <Divider/>

                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom color="text.secondary">
                            Content
                        </Typography>
                        <ReactQuill
                            value={htmlContent}
                            onChange={handleHtmlContentChange}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    ['link', 'image'],
                                    ['clean']
                                ],
                            }}
                        />
                    </Box>
                </Box>

                {showPreview && (
                    <Box sx={{
                        width: '600px',
                        right: '20px',
                        borderRadius: '8px',
                        p: 2,
                        transition: 'right 0.3s ease',
                    }}>
                        <Typography variant="h6" gutterBottom>Email Preview</Typography>
                        <Paper variant="outlined" sx={{ p: 3, mx: 'auto', borderRadius:'12px', boxShadow:10 }}>
                            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, marginTop: '40px', marginBottom:'20px' }}>
                                <Button variant='save'>Return to cart</Button>
                                <Button variant='cancel'>Visit our store</Button>
                            </Box>
                        </Paper>
                    </Box>

                )}
            </Box>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Email template added successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddEmail;