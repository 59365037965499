import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Box,
    Typography,
    Paper,
    TextField,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    Button,
    IconButton,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import {DeleteIcon, EditIcon, ViewIcon} from "../../shared/IconButtons";
import EmailPreview from "./EmailPreview";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { deleteEmail } from '../../../redux/emailSlice';  // Ensure this import path is correct

const API_BASE_URL = 'https://api-dev.eventlah.com/api'
// const API_BASE_URL = 'http://localhost:3001/api'

const AdminEmailManagement = () => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedContent, setSelectedContent] = useState('');
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [totalRows, setTotalRows] = useState(0);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        emailId: null
    });

    useEffect(() => {
        fetchEmails(paginationModel.page, paginationModel.pageSize);
    }, [paginationModel]);

    const fetchEmails = async (page, pageSize) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/emails`, {
                params: {
                    page: page + 1,
                    limit: pageSize,
                },
            });
            if (response.data.code === 200 && response.data.data && response.data.data.emails) {
                setEmails(response.data.data.emails);
                setTotalRows(response.data.data.pagination.totalCount);
            } else {
                setError('Unexpected API response format');
            }
        } catch (error) {
            setError('Error fetching emails: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = (id) => {
        setConfirmDialog({
            open: true,
            emailId: id
        });
    };

    const handleConfirmDelete = async () => {
        const id = confirmDialog.emailId;
        setDeleteLoading(true);
        try {
            await dispatch(deleteEmail(id)).unwrap();
            setEmails(emails.filter(email => email.id !== id));
            setSnackbar({
                open: true,
                message: 'Email deleted successfully',
                severity: 'success'
            });
        } catch (error) {
            setError('Failed to delete email: ' + error.message);
            setSnackbar({
                open: true,
                message: 'Failed to delete email',
                severity: 'error'
            });
        } finally {
            setDeleteLoading(false);
            setConfirmDialog({ open: false, emailId: null });
        }
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialog({ open: false, emailId: null });
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleViewContent = (id) => {
        const email = emails.find(email => email.id === id);
        if (email) {
            setSelectedContent(email.email_content);
            setOpenDialog(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const filteredEmails = emails.filter((email) =>
        email.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.subject.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const columns = [
        { field: 'emailCategoryName', headerName: 'Category', flex: 1 },
        { field: 'title', headerName: 'Title', flex: 2 },
        { field: 'subject', headerName: 'Subject', flex: 2 },
        { field: 'status', headerName: 'Status', flex: 0.4 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => handleViewContent(params.row.id)}>
                        <ViewIcon/>
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleViewContent(params.id)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            ),
        },
    ];

    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box sx={{ padding: '32px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant="h1">Email Management</Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <Box>
                        <Button variant="save" component={Link} to="/admin/email-management/edit-email">+ Add Email</Button>
                    </Box>
                </Box>
            </Box>

            <Divider />

            <TextField
                variant="outlined"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                size="small"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        height: '34px',
                        '& input': {
                            height: '34px',
                            padding: '0 14px',
                        },
                        '& .MuiInputBase-input': {
                            height: '34px',
                            padding: '0 14px',
                        }
                    }
                }}
            />

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Email Management List
                </Typography>
                <DataGrid
                    rows={filteredEmails}
                    columns={columns}
                    rowCount={totalRows}
                    loading={loading}
                    pageSizeOptions={[5, 10, 20]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowHeight={70}
                    slots={{ toolbar: GridToolbar }}
                    sx={{
                        '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                            backgroundColor: '#737373',
                            color: '#fff',
                            height: '34px',
                            my: 1,
                            minWidth: 'auto',
                            '&:hover': {
                                backgroundColor: '#404040',
                            },
                        },
                    }}
                />
            </Paper>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <EmailPreview content={selectedContent} />
                </DialogContent>
                <DialogActions>
                    <Button variant='cancel' onClick={() => setOpenDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmDialog.open}
                onClose={handleCloseConfirmDialog}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this email? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} variant="cancel" disabled={deleteLoading}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="save"
                        autoFocus
                        disabled={deleteLoading}
                        startIcon={deleteLoading ? <CircularProgress size={20} /> : null}
                    >
                        {deleteLoading ? 'Deleting...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AdminEmailManagement;