import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUserRolePermissionFeatures = createAsyncThunk(
    'adminUserRolePermissionFeatures/fetchUserRolePermissionFeatures',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-role-permission-features`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createUserRolePermissionFeature = createAsyncThunk(
    'adminUserRolePermissionFeatures/createUserRolePermissionFeature',
    async (featureData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/user-role-permission-features`,
                featureData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const patchUserRolePermissionFeatureStatus = createAsyncThunk(
    'adminUserRolePermissionFeatures/patchUserRolePermissionFeatureStatus',
    async ({ id, entityId, statusData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/user-role-permission-features/${id}/status/${entityId}`,
                statusData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleUserRolePermissionFeature = createAsyncThunk(
    'adminUserRolePermissionFeatures/fetchSingleUserRolePermissionFeature',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-role-permission-features/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolePermissionFeature = createAsyncThunk(
    'adminUserRolePermissionFeatures/deleteUserRolePermissionFeature',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-role-permission-features/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminUserRolePermissionFeaturesSlice = createSlice({
    name: 'adminUserRolePermissionFeatures',
    initialState: {
        features: [],
        selectedFeature: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRolePermissionFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(createUserRolePermissionFeature.fulfilled, (state, action) => {
                state.features.push(action.payload);
            })
            .addCase(patchUserRolePermissionFeatureStatus.fulfilled, (state, action) => {
                const index = state.features.findIndex(feature => feature.id === action.payload.id);
                if (index !== -1) {
                    state.features[index] = action.payload;
                }
                if (state.selectedFeature && state.selectedFeature.id === action.payload.id) {
                    state.selectedFeature = action.payload;
                }
            })
            .addCase(fetchSingleUserRolePermissionFeature.fulfilled, (state, action) => {
                state.selectedFeature = action.payload;
            })
            .addCase(deleteUserRolePermissionFeature.fulfilled, (state, action) => {
                state.features = state.features.filter(feature => feature.id !== action.payload);
                if (state.selectedFeature && state.selectedFeature.id === action.payload) {
                    state.selectedFeature = null;
                }
            })
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.payload?.message || 'An error occurred';
                }
            )
            .addMatcher(
                action => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            );
    },
});

export default adminUserRolePermissionFeaturesSlice.reducer;