import React from 'react';
import {
    Paper,
    Box,
    Typography,
    Button,
    Divider, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {DataGrid} from "@mui/x-data-grid";
import {ViewIcon} from "../../shared/IconButtons";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";

const rows = [
    { id: 1, name: 'John Doe', email: 'test@gmail.com', defaultPaymentMethod: 'Credit Card', created: '2024-07-10T16:25:00' },
    { id: 2, name: 'Ivy Martinez', email: 'test1@gmail.com', defaultPaymentMethod: 'Credit Card', created: '2024-07-05T12:55:00' },
    { id: 3, name: 'Jane Smith', email: 'test2@gmail.com', defaultPaymentMethod: 'PayPal', created: '2024-07-09T12:39:00' },
    { id: 4, name: 'Alice Johnson', email: 'test3@gmail.com', defaultPaymentMethod: 'Bank Transfer', created: '2024-07-11T11:00:00' },
    { id: 5, name: 'Bob Brown', email: 'test4@gmail.com', defaultPaymentMethod: 'Credit Card', created: '2024-07-08T08:25:00' },
    { id: 6, name: 'Charlie Davis', email: 'test5@gmail.com', defaultPaymentMethod: 'PayPal', created: '2024-07-12T10:30:00' },
    { id: 7, name: 'Diana Evans', email: 'test6@gmail.com', defaultPaymentMethod: 'Bank Transfer', created: '2024-07-07T14:20:00' },
    { id: 8, name: 'Frank Green', email: 'test7@gmail.com', defaultPaymentMethod: 'Credit Card', created: '2024-07-13T09:15:00' },
    { id: 9, name: 'Grace Harris', email: 'test8@gmail.com', defaultPaymentMethod: 'PayPal', created: '2024-07-06T16:45:00' },
    { id: 10, name: 'Henry Lee', email: 'test9@gmail.com', defaultPaymentMethod: 'Bank Transfer', created: '2024-07-14T17:35:00' },
];

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'defaultPaymentMethod',
        headerName: 'Payment Method',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'created',
        headerName: 'Created Date',
        flex: 1,
        renderCell: (params) => {
            const date = new Date(params.value);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        }
    },
    {
        field: 'actions',
        headerName: '',
        width: 150,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                <IconButton aria-label="view" onClick={() => console.log('view', params.row)}>
                    <ViewIcon />
                </IconButton>
                <IconButton aria-label="edit" onClick={() => console.log('edit', params.row)}>
                    <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => console.log('delete', params.row.id)}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        ),
    },
];

const CustomerRecords = () => {
    return (
        <Box>
            <Box flex={1} mb={2}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>
                            Customer Records
                        </Typography >
                        <Typography variant='h2'>
                            Manage customers records and profiles.
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex' }}>
                        <Button variant="save" startIcon={<AddIcon />}>
                            Add customer
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Divider/>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    All Customer Records
                </Typography>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={70}
                    pageSizeOptions={[5, 10, 20]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 6,
                            },
                        },
                    }}
                />
            </Paper>
        </Box>
    );
};

export default CustomerRecords;
