import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Divider,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CampaignIcon from '@mui/icons-material/Campaign';
import FacebookIcon from '@mui/icons-material/Facebook';
import SecurityIcon from '@mui/icons-material/Security';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InboxIcon from '@mui/icons-material/Inbox';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const AppPermissions = () => {
    const [manageApps, setManageApps] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const appList = [
        { name: 'Email', icon: <EmailIcon /> },
        { name: 'Entirety - Affiliate Marketing', icon: <CampaignIcon /> },
        { name: 'Facebook & Instagram', icon: <FacebookIcon /> },
        { name: 'Fraud Filter', icon: <SecurityIcon /> },
        { name: 'Google & YouTube', icon: <YouTubeIcon /> },
        { name: 'Inbox', icon: <InboxIcon /> },
        { name: 'Instafeed', icon: <RssFeedIcon /> },
        { name: 'Ninja Van (MY, PH)', icon: <LocalShippingIcon /> },
    ];

    const initialAppPermissions = appList.reduce((acc, app) => {
        acc[app.name.toLowerCase().replace(/[&\s-(),]/g, '')] = false;
        return acc;
    }, {});

    const [appPermissions, setAppPermissions] = useState(initialAppPermissions);

    const handleManageAppsChange = (event) => {
        setManageApps(event.target.checked);
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        setAppPermissions(Object.keys(appPermissions).reduce((acc, key) => {
            acc[key] = isChecked;
            return acc;
        }, {}));
    };

    const handleAppPermissionChange = (app) => (event) => {
        setAppPermissions({ ...appPermissions, [app]: event.target.checked });
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                App permissions
            </Typography>
            <Typography variant="body2" gutterBottom>
                Manage app permissions for Motorparts Online
            </Typography>

            <FormControlLabel
                control={<Checkbox checked={manageApps} onChange={handleManageAppsChange} />}
                label="Manage and install apps and channels"
            />

            <Paper variant="outlined" sx={{ mt: 2, p: 2 }}>
                <FormControlLabel
                    control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} />}
                    label="Select all"
                />
                <Divider/>
                <List>
                    {appList.map((app, index) => {
                        const appKey = app.name.toLowerCase().replace(/[&\s-(),]/g, '');
                        return (
                            <ListItem key={index} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={appPermissions[appKey]}
                                        onChange={handleAppPermissionChange(appKey)}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemIcon>{app.icon}</ListItemIcon>
                                <ListItemText primary={app.name} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        </Box>
    );
};

export default AppPermissions;