import { alpha, createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffeb3b', // yellow color to match the image
        },
        background: {
            default: '#f5f5f5', // Set your desired default background color here
        },
    },
    typography: {
        fontFamily: 'Inter',
        h1: {
            color: 'var(--Gray-900, #101828)',
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '38px',
        },
        h2: {
            color: 'var(--gray-500, #6D6D6D)',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            paddingTop: '10px',
        },
        h3: {
            color: 'var(--gray-500, #6D6D6D)',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '14px',
        },
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    // Add any custom styles for the Tabs component here
                },
                indicator: {
                    height: '2px',
                    backgroundColor: 'var(--dark-500, #09090B)',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    marginRight: '16px',
                    paddingRight: '4px',
                    paddingLeft: '4px',
                    paddingTop: '0px',
                    paddingBottom: '16px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: 'var(--dark-50, #71717A)', // Default color for unselected tab
                    '&.Mui-selected': {
                        color: 'var(--dark-500, #09090B)', // Color for selected tab
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'var(--gray-300, #B0B0B0)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    transform: 'translate(15px, 10px) scale(1)', // Adjust position and scale
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(20px, -7px) scale(0.60)', // Adjust for shrunk state
                        color: 'var(--gray-300, #B0B0B0)',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    backgroundColor: 'var(--White, #FFF)',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--Gray-300, #D0D5DD)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--Gray-300, #D0D5DD)',
                    },
                },
                input: {
                    borderRadius: '8px',
                    backgroundColor: 'var(--White, #FFF)',
                    padding: '10px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    padding: '8px 16px',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                    '&:hover': {
                        boxShadow: '0px 2px 4px 0px rgba(16, 24, 40, 0.1)',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'cancel' },
                    style: {
                        border: '1px solid var(--Gray-300, #D0D5DD)',
                        backgroundColor: 'var(--White, #FFF)',
                        height: '34px',
                        color: 'var(--Gray-700, #344054)',
                        textTransform: 'none',
                        whiteSpace: 'nowrap', // Prevent text wrapping
                        overflow: 'hidden', // Hide overflowed text
                        textOverflow: 'ellipsis', // Add ellipsis if text overflows
                        '&:hover': {
                            backgroundColor: 'var(--White, #FFF)',
                        },
                    },
                },
                {
                    props: { variant: 'save' },
                    style: {
                        border: '1px solid #FFD600',
                        backgroundColor: 'var(--primary-500, #FFED00)',
                        height: '34px',
                        color: 'var(--dark-500, #09090B)',
                        textTransform: 'none',
                        whiteSpace: 'nowrap', // Prevent text wrapping
                        overflow: 'hidden', // Hide overflowed text
                        textOverflow: 'ellipsis', // Add ellipsis if text overflows
                        '&:hover': {
                            backgroundColor: '#FFC000',
                        },
                    },
                },
            ],
        },
        MuiDataGrid: {
            styleOverrides: {
                columnHeaders: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                columnHeaderTitle: {
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    textAlign: 'center',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        borderRadius: '5px',
                        fontSize: 20,
                        border: '1px solid var(--Gray-300, #D0D5DD)',
                        boxShadow: 'none', // Remove any existing shadow that might look like a border
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                        borderRadius: '8px',
                        backgroundColor: '#FFF', // Change the background color when checked
                        boxShadow: 'none', // Remove any existing shadow that might look like a border
                        overflow: 'hidden',
                        border: 'none',
                        fontSize: 22,
                    },
                    '& .MuiSvgIcon-root path': {
                        fill: 'none', // Ensure the checkmark is visible by using the correct fill
                        stroke: 'none', // Remove stroke to hide the checkmark in the default state
                    },
                    '&.Mui-checked .MuiSvgIcon-root path': {
                        fill: '#FFB800', // Set the checkmark color to white
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    border: '1px solid var(--Gray-100, #F2F4F7)',
                    padding: 6,
                    margin: 0,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    height: '1px',
                    alignSelf: 'stretch',
                    backgroundColor: 'var(--Gray-200, #EAECF0)',
                    margin: '21px 0', // Adjust spacing around the divider if needed
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&.Mui-checked': {
                        color: '#fafafa', // Green color for checked state
                        '&:hover': {
                            backgroundColor: alpha('#4caf50', 0.12), // Green color with hover effect
                        },
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#008c10', // Track color when checked
                    },
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: '#E0E0E0', // Light gray for incomplete steps
                    '&.Mui-completed': {
                        color: 'orange', // Gold color for completed steps
                    },
                    '&.Mui-active': {
                        color: '#FFB800', // Bright yellow for active step
                    },
                },
                text: {
                    fill: '#000000', // Black text for better contrast on light backgrounds
                    fontWeight: 'bold',
                },
            },
        },
    },
});

export default theme;
