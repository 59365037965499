import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        companyName: '',
        registrationNo: '',
        addressLine1: '',
        postcode: '',
        state: '',
        country: '',
        storeName: '',
        contactNumber: '',
        emailAddress: '',
        openTime: '',
        closeTime: '',
        storeDescription: '',
        selectedDays: [],
        productName: '',
        productCategory: '',
        price: '',
        productDescription: '',
    });

    const updateFormData = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    return (
        <FormContext.Provider value={{ formData, updateFormData }}>
            {children}
        </FormContext.Provider>
    );
};
