import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all flows
export const fetchFlows = createAsyncThunk('referenceFlows/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/flows`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new flow
export const createFlow = createAsyncThunk('referenceFlows/create', async (flowData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/flows`, flowData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a flow by ID
export const updateFlow = createAsyncThunk('referenceFlows/update', async ({ id, flowData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/flows/${id}`, flowData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific flow by ID
export const fetchFlowById = createAsyncThunk('referenceFlows/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/flows/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a flow by ID
export const deleteFlow = createAsyncThunk('referenceFlows/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/flows/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Reference Flows
const referenceFlowsSlice = createSlice({
    name: 'referenceFlows',
    initialState: {
        flows: [],
        selectedFlow: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlows.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFlows.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.flows = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchFlows.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch flows';
            })

            .addCase(createFlow.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createFlow.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.flows.push(action.payload);
            })
            .addCase(createFlow.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create flow';
            })

            .addCase(updateFlow.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateFlow.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.flows.findIndex(flow => flow.id === action.payload.id);
                if (index !== -1) {
                    state.flows[index] = action.payload;
                }
            })
            .addCase(updateFlow.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update flow';
            })

            .addCase(fetchFlowById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFlowById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedFlow = action.payload;
            })
            .addCase(fetchFlowById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch flow by ID';
            })

            .addCase(deleteFlow.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteFlow.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.flows = state.flows.filter(flow => flow.id !== action.payload);
                if (state.selectedFlow && state.selectedFlow.id === action.payload) {
                    state.selectedFlow = null;
                }
            })
            .addCase(deleteFlow.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete flow';
            });
    },
});

export default referenceFlowsSlice.reducer;
