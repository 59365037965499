import React, { useState } from "react";
import {
    Box, Button,
    Divider,
    FormControl,
    FormControlLabel,
    InputAdornment, InputLabel, MenuItem, Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' },
}));

const PublicDetails = () => {
    const [legalBusinessName, setLegalBusinessName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [statementDescriptor, setStatementDescriptor] = useState('');
    const [shortenedDescriptor, setShortenedDescriptor] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [country, setCountry] = useState('Malaysia');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [supportEmail, setSupportEmail] = useState('');
    const [supportURL, setSupportURL] = useState('');
    const [businessWebsite, setBusinessWebsite] = useState('');
    const [privacyPolicyURL, setPrivacyPolicyURL] = useState('');
    const [termsOfServiceURL, setTermsOfServiceURL] = useState('');

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
    const handleToggleChange = (event) => {
        setShowPhoneNumber(event.target.checked);
    };
    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    return (
        <>
            <Box>
                <Typography variant='h1' fontSize='20px' fontWeight='600'>
                    Public details
                </Typography>
                <Typography variant='h2'>
                    This information may be visible in payment statements, invoices, and receipts.
                </Typography>
            </Box>

            <Divider />

            <form noValidate autoComplete="off">
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Legal business name</TextFieldTitle>
                    <StyledTextField
                        value={legalBusinessName}
                        onChange={(e) => setLegalBusinessName(e.target.value)}
                        fullWidth
                        placeholder='ys event & properties sdn bhd'
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Business name</TextFieldTitle>
                    <StyledTextField
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        fullWidth
                        placeholder='ORCHARD VILLA'
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Statement descriptor</TextFieldTitle>
                    <StyledTextField
                        value={statementDescriptor}
                        onChange={(e) => setStatementDescriptor(e.target.value)}
                        fullWidth
                        placeholder='ORCHARDVILLA'
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Shortened descriptor</TextFieldTitle>
                    <StyledTextField
                        value={shortenedDescriptor}
                        onChange={(e) => setShortenedDescriptor(e.target.value)}
                        fullWidth
                        placeholder='OV'
                    />
                </Box>

                <Divider />

                <FormControlLabel
                    control={<Switch checked={showPhoneNumber} onChange={handleToggleChange} />}
                    label="Show phone number on receipts and invoices"
                />

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Customer support phone number</TextFieldTitle>
                    <StyledTextField
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        fullWidth
                        placeholder='14 911 2550'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg"
                                            alt="Malaysia Flag"
                                            style={{ width: '24px', height: '16px', marginRight: '8px' }}
                                        />
                                        <Typography variant="body2">+60</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Customer support address</TextFieldTitle>
                    <Box sx={{ width: '100%' }}>
                        <FormControl fullWidth sx={{ marginBottom: '18px' }}>
                            <Select
                                value={country}
                                onChange={handleCountryChange}
                            >
                                <MenuItem value="Malaysia">Malaysia</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>

                        <StyledTextField
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                            fullWidth
                            label="Address line 1"
                            sx={{ marginBottom: '18px' }}
                        />

                        <StyledTextField
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                            fullWidth
                            label="Address line 2"
                            sx={{ marginBottom: '18px' }}
                        />

                        <StyledTextField
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            fullWidth
                            label="Postal code (43000)"
                            sx={{ marginBottom: '18px' }}
                        />

                        <StyledTextField
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            fullWidth
                            label="City"
                            sx={{ marginBottom: '18px' }}
                        />

                        <FormControl fullWidth sx={{ marginBottom: '18px' }}>
                            <InputLabel>State</InputLabel>
                            <Select
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                label='State'
                            >
                                <MenuItem value="State 1">State 1</MenuItem>
                                <MenuItem value="State 2">State 2</MenuItem>
                                {/* Add more states as needed */}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Customer support email</TextFieldTitle>
                    <StyledTextField
                        value={supportEmail}
                        onChange={(e) => setSupportEmail(e.target.value)}
                        fullWidth
                        placeholder='ys.event.properites@gmail.com'
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Customer support URL</TextFieldTitle>
                    <StyledTextField
                        value={supportURL}
                        onChange={(e) => setSupportURL(e.target.value)}
                        fullWidth
                        placeholder='https://example.com/support'
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextFieldTitle>Business website</TextFieldTitle>
                    <StyledTextField
                        value={businessWebsite}
                        onChange={(e) => setBusinessWebsite(e.target.value)}
                        fullWidth
                        placeholder='www.orchardvilla.com.my'
                    />
                </Box>

                <Divider />

                {/*<Box sx={{ display: 'flex', gap: '10px'}}>*/}
                {/*    <TextFieldTitle>Privacy Policy URL</TextFieldTitle>*/}
                {/*    <StyledTextField*/}
                {/*        value={privacyPolicyURL}*/}
                {/*        onChange={(e) => setPrivacyPolicyURL(e.target.value)}*/}
                {/*        fullWidth*/}
                {/*        placeholder='https://example.com/privacy-policy'*/}
                {/*    />*/}
                {/*</Box>*/}

                {/*<Divider />*/}

                {/*<Box sx={{ display: 'flex', gap: '10px'}}>*/}
                {/*    <TextFieldTitle>Terms Of Service URL</TextFieldTitle>*/}
                {/*    <StyledTextField*/}
                {/*        value={termsOfServiceURL}*/}
                {/*        onChange={(e) => setTermsOfServiceURL(e.target.value)}*/}
                {/*        fullWidth*/}
                {/*        placeholder='https://example.com/terms-of-service'*/}
                {/*    />*/}
                {/*</Box>*/}

                <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px', paddingTop: '32px' }}>
                    <Button variant='cancel'>Cancel</Button>
                    <Button variant='save'>Continue</Button>
                </Box>
            </form>
        </>
    )
}

export default PublicDetails;
