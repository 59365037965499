export const couponsOptions = [
    {
        title: "Early Bird Discount",
        description: "20% off on all events if you register before [Date].",
        discount: "20% off"
    },
    {
        title: "First 50 Tickets Discount",
        description: "RM10 off for the first 50 registrants.",
        discount: "RM10 off"
    },
    {
        title: "Group Booking Discount",
        description: "15% off when purchasing 10 or more tickets.",
        discount: "15% off"
    },
    {
        title: "Corporate Booking Discount",
        description: "20% off for corporate groups of 20 or more.",
        discount: "20% off"
    },
    {
        title: "Refer a Friend",
        description: "Refer a friend and both get RM20 off your next event.",
        discount: "RM20 off"
    },
    {
        title: "Ambassador Program",
        description: "Earn RM50 for every 5 friends you refer who buy tickets.",
        discount: "RM50 off"
    },
    {
        title: "New Organizer Discount",
        description: "Get 25% off your first event listing fee.",
        discount: "25% off"
    },
    {
        title: "Welcome Bonus",
        description: "Get a free featured listing upgrade for your first event.",
        discount: "Free upgrade"
    },
    {
        title: "Frequent Organizer Discount",
        description: "Get 10% off your next event listing after hosting 5 events.",
        discount: "10% off"
    },
    {
        title: "Points Program",
        description: "Earn 1 point for every RM1 spent, redeem 100 points for RM10 off.",
        discount: "RM10 off"
    },
    {
        title: "Holiday or Seasonal Discounts",
        description: "15% off all events during the Christmas season.",
        discount: "15% off"
    },
    {
        title: "Anniversary Sale",
        description: "RM25 off all events to celebrate our 5th anniversary.",
        discount: "RM25 off"
    },
    {
        title: "Exclusive VIP CouponsOptions",
        description: "20% off on all VIP events.",
        discount: "20% off"
    },
    {
        title: "Membership Discount",
        description: "Get 30% off your first event with a yearly membership subscription.",
        discount: "30% off"
    },
    {
        title: "Limited-Time Offers",
        description: "50% off all events for the next 24 hours.",
        discount: "50% off"
    },
    {
        title: "Happy Hour Discounts",
        description: "RM10 off on tickets purchased between 12 PM - 2 PM.",
        discount: "RM10 off"
    },
    {
        title: "Last-Minute Ticket Discount",
        description: "Get 30% off tickets purchased in the last 48 hours before the event.",
        discount: "30% off"
    },
    {
        title: "Last Chance Offer",
        description: "Complete your booking now and get an extra 10% off.",
        discount: "10% off"
    },
    {
        title: "Partnered Event Discounts",
        description: "Attend 3 events this month, get 50% off your next event.",
        discount: "50% off"
    },
    {
        title: "Vendor-Specific Discounts",
        description: "Get RM100 off on catering services when booking through our platform.",
        discount: "RM100 off"
    },
    {
        title: "Industry-Specific Discounts",
        description: "10% off all tech events this month.",
        discount: "10% off"
    },
    {
        title: "Niche Event Discounts",
        description: "15% off all wellness and fitness events.",
        discount: "15% off"
    },
    {
        title: "Upgrade to VIP",
        description: "Upgrade to VIP for just RM50 more.",
        discount: "RM50 off"
    },
    {
        title: "Free Merchandise with Upgrade",
        description: "Upgrade your ticket and get a free T-shirt.",
        discount: "Free T-shirt"
    },
    {
        title: "Post-Event Survey Discount",
        description: "Get 20% off your next event for providing feedback.",
        discount: "20% off"
    },
    {
        title: "Review Rewards",
        description: "Leave a review and get RM10 off your next ticket purchase.",
        discount: "RM10 off"
    },
    {
        title: "New Event Launch Discount",
        description: "10% off on newly listed events.",
        discount: "10% off"
    },
    {
        title: "Event Creator Promotion",
        description: "Host your next event with a 15% discount.",
        discount: "15% off"
    }
];
