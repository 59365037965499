import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all events
export const fetchEvents = createAsyncThunk('events/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/events`, { headers });
        return response.data.data.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new event
export const createEvent = createAsyncThunk('events/create', async (eventData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/events`, eventData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific event by ID
export const fetchEventById = createAsyncThunk('events/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/events/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update an event by ID
export const updateEvent = createAsyncThunk('events/update', async ({ id, eventData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/events/${id}`, eventData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update event status
export const updateEventStatus = createAsyncThunk('events/updateStatus', async ({ id, entityId, statusData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/events/${id}/status/${entityId}`, statusData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete an event by ID
export const deleteEvent = createAsyncThunk('events/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        await axios.delete(`${API_BASE_URL}/events/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Events Operations
const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        events: {
            page: 0,
            limit: 10,
            total_items: 0,
            total_pages: 0,
            data: []
        },
        selectedEvent: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events = action.payload; // Store the entire response object
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch events';
            })

            .addCase(createEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events.push(action.payload);
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create event';
            })

            .addCase(fetchEventById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedEvent = action.payload;
            })
            .addCase(fetchEventById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event by ID';
            })

            .addCase(updateEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.events.findIndex(event => event.id === action.payload.id);
                if (index !== -1) state.events[index] = action.payload;
                if (state.selectedEvent && state.selectedEvent.id === action.payload.id) {
                    state.selectedEvent = action.payload;
                }
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update event';
            })

            .addCase(updateEventStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEventStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.events.findIndex(event => event.id === action.payload.id);
                if (index !== -1) state.events[index] = action.payload;
                if (state.selectedEvent && state.selectedEvent.id === action.payload.id) {
                    state.selectedEvent = action.payload;
                }
            })
            .addCase(updateEventStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update event status';
            })

            .addCase(deleteEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events = state.events.filter(event => event.id !== action.payload);
                if (state.selectedEvent && state.selectedEvent.id === action.payload) {
                    state.selectedEvent = null;
                }
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete event';
            });
    },
});

export default eventsSlice.reducer;
