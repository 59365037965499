import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunks for service operations
export const fetchServices = createAsyncThunk(
    'services/fetchServices',
    async (_, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/services`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createService = createAsyncThunk(
    'services/createService',
    async (serviceData, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/services`, serviceData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchServiceById = createAsyncThunk(
    'services/fetchServiceById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/services/${id}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteService = createAsyncThunk(
    'services/deleteService',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/services/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateService = createAsyncThunk(
    'services/updateService',
    async ({ id, serviceData }, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/services/${id}`, serviceData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateServiceStatus = createAsyncThunk(
    'services/updateServiceStatus',
    async ({ id, entityId, statusData }, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/services/${id}/status/${entityId}`, statusData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Services slice
const servicesSlice = createSlice({
    name: 'services',
    initialState: {
        services: [],
        selectedService: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {
        // You can add any additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            // Fetch all services
            .addCase(fetchServices.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchServices.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.services = action.payload;
            })
            .addCase(fetchServices.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch services';
            })
            // Create service
            .addCase(createService.fulfilled, (state, action) => {
                state.services.push(action.payload);
            })
            // Fetch service by ID
            .addCase(fetchServiceById.fulfilled, (state, action) => {
                state.selectedService = action.payload;
            })
            // Delete service
            .addCase(deleteService.fulfilled, (state, action) => {
                state.services = state.services.filter(service => service.id !== action.payload);
                if (state.selectedService && state.selectedService.id === action.payload) {
                    state.selectedService = null;
                }
            })
            // Update service
            .addCase(updateService.fulfilled, (state, action) => {
                const index = state.services.findIndex(service => service.id === action.payload.id);
                if (index !== -1) {
                    state.services[index] = action.payload;
                }
                if (state.selectedService && state.selectedService.id === action.payload.id) {
                    state.selectedService = action.payload;
                }
            })
            // Update service status
            .addCase(updateServiceStatus.fulfilled, (state, action) => {
                // Assuming the response includes the updated service
                const index = state.services.findIndex(service => service.id === action.payload.id);
                if (index !== -1) {
                    state.services[index] = action.payload;
                }
                if (state.selectedService && state.selectedService.id === action.payload.id) {
                    state.selectedService = action.payload;
                }
            });
    },
});

export default servicesSlice.reducer;