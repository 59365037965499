import React from 'react';
import { Box, Button, InputAdornment, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, TableFooter, TablePagination } from '@mui/material';
import { Search } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const createData = (action, category, deviceId, ipAddress, time) => {
    return { action, category, deviceId, ipAddress, time };
}

const rows = [
    createData('john.doe@example.com changed account name', 'Account', '#0C7CB2CC', '14.141.141.141', 'Aug 2, 3:11 PM'),
    createData('john.doe@example.com logged in from a new device', 'User security', '#0C7CB2CC', '14.141.141.141', 'Jul 29, 6:35 PM'),
    createData('john.doe@example.com added a webhook endpoint', 'API', '#1B29ABC2', '14.141.141.141', 'Jul 10, 4:19 PM'),
    // ...add more rows as needed
];

const SecurityHistory = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <OutlinedInput
                    startAdornment={
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    }
                    placeholder="Filter by action or category..."
                    sx={{ height: '34px', width: '300px' }}
                    inputProps={{
                        sx: {
                            padding: 0
                        }
                    }}
                />
                <Box>
                    <Button variant="cancel" startIcon={<FilterListIcon />}>Filter</Button>
                    <Button variant="cancel" startIcon={<FileDownloadIcon />} sx={{ ml: 1 }}>Export</Button>
                </Box>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="subtitle2">ACTION</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">CATEGORY</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">DEVICE ID</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">IP ADDRESS</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">TIME</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                        ).map((row) => (
                            <TableRow key={row.action}>
                                <TableCell>
                                    <Typography variant="body2">{row.action}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{row.category}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{row.deviceId}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{row.ipAddress}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{row.time}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={5}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Typography variant="body2">{rows.length} results</Typography>
            </Box>
        </Box>
    );
};

export default SecurityHistory;
