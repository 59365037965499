import {Box, Divider, MenuItem, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {styled} from "@mui/system";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '312px',
}));

const SpacesFormDetails = () => {
    const [spaceType, setSpaceType] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    return (
        <Box>
            <form noValidate autoComplete="off">
                <Box display="flex">
                    <TextFieldTitle>Space Type</TextFieldTitle>
                    <TextField
                        select
                        label="Choose space type"
                        value={spaceType}
                        onChange={(e) => setSpaceType(e.target.value)}
                        fullWidth
                        sx={{paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }}}
                    >
                        <MenuItem value="">
                            <em>Choose space type</em>
                        </MenuItem>
                        {/* Add more options here */}
                    </TextField>
                </Box>

                <Divider/>

                <Box display="flex">
                    <TextFieldTitle>Title of Space</TextFieldTitle>
                    <TextField
                        label="Enter the title of space"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        sx={{paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }}}
                    />
                </Box>

                <Divider/>

                <Box display="flex">
                    <TextFieldTitle>Description of Space</TextFieldTitle>
                    <TextField
                        label="Enter the description of space"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={7}
                        inputProps={{ maxLength: 250 }}
                        helperText={`${description.length}/250`}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px', // Remove padding from the input container
                                '& .MuiOutlinedInput-input': {
                                    padding: 0, // Remove padding from the input text
                                },
                            },
                            paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }
                        }}
                    />
                </Box>
            </form>
        </Box>
    );
};

export default SpacesFormDetails;