import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to create a new feature migration
export const createFeatureMigration = createAsyncThunk('featureMigrations/create', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/migrations/features`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a feature migration
export const updateFeatureMigration = createAsyncThunk('featureMigrations/update', async (migrationData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/migrations/features`, migrationData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Feature Migrations
const featureMigrationsSlice = createSlice({
    name: 'featureMigrations',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createFeatureMigration.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createFeatureMigration.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createFeatureMigration.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create feature migration';
            })

            .addCase(updateFeatureMigration.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateFeatureMigration.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(updateFeatureMigration.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update feature migration';
            });
    },
});

export default featureMigrationsSlice.reducer;
