import React, { useState } from 'react';
import {Box, Tabs, Tab, Typography, Button} from '@mui/material';
import LandingPage from './BrandingContents/LandingPage';
import Email from "./BrandingContents/Email";
import '../../../App.css';
import {Add as AddIcon} from "@mui/icons-material"; // Ensure this path is correct

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
};

const Branding = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Typography
                        sx={{
                            color: 'var(--Gray-900, #101828)',
                            fontFamily: 'Inter',
                            fontSize: '30px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '38px'
                        }}
                    >
                        Effortlessly create a checkout page in just a few clicks
                    </Typography>
                    <Typography sx={{
                        color: 'var(--gray-500, #6D6D6D)',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px'
                    }}>
                        Sell products, offer subscriptions, or accept donations with a link, all without any coding required.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ paddingTop: '16px' }}>
                <Tabs value={value} onChange={handleChange} aria-label="configs tabs">
                    <Tab label="Landing Page" {...a11yProps(0)} />
                    <Tab label="Email" {...a11yProps(1)} />
                    {/* Add more tabs here */}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <LandingPage />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Email />
                </TabPanel>
                {/* Add more TabPanels here */}
            </Box>
        </Box>
    );
};

export default Branding;
