import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    IconButton,
    Collapse,
    Box
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandableRow = ({ title, description, examples }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <TableRow>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">{title}</Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <IconButton size="small" onClick={() => setExpanded(!expanded)}>
                        {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Examples
                            </Typography>
                            <Table size="small">
                                <TableBody>
                                    {examples.map((example, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{example}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const CustomerNotifications = () => {
    const [expanded, setExpanded] = useState(true);

    const rows = [
        {
            title: 'Order confirmation',
            description: 'Sent when a customer places an order',
            examples: ['Example 1: New order received', 'Example 2: Order #1234 confirmed']
        },
        {
            title: 'Draft order invoice',
            description: 'Sent when you create an invoice on the draft order page',
            examples: ['Example 1: Invoice created for draft order #5678', 'Example 2: Draft invoice ready for review']
        },
        {
            title: 'Shipping confirmation',
            description: 'Sent when you mark an order as fulfilled',
            examples: ['Example 1: Order #9012 shipped', 'Example 2: Tracking information for order #3456']
        },
    ];

    return (
        <TableContainer sx={{ width: '100%', height: '100%' }}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            <IconButton size="small">
                                {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                            </IconButton>
                            Order processing
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Table>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <ExpandableRow key={index} {...row} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomerNotifications;