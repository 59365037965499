import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchStatusOwnerships = createAsyncThunk(
    'adminStatusOwnerships/fetchStatusOwnerships',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/status-ownerships`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createStatusOwnership = createAsyncThunk(
    'adminStatusOwnerships/createStatusOwnership',
    async (statusOwnershipData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/status-ownerships`,
                statusOwnershipData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleStatusOwnership = createAsyncThunk(
    'adminStatusOwnerships/fetchSingleStatusOwnership',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/status-ownerships/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteStatusOwnership = createAsyncThunk(
    'adminStatusOwnerships/deleteStatusOwnership',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/status-ownerships/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateStatusOwnership = createAsyncThunk(
    'adminStatusOwnerships/updateStatusOwnership',
    async ({ id, ...updateData }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/status-ownerships/${id}`,
                updateData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminStatusOwnershipsSlice = createSlice({
    name: 'adminStatusOwnerships',
    initialState: {
        statusOwnerships: [],
        selectedStatusOwnership: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatusOwnerships.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statusOwnerships = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(createStatusOwnership.fulfilled, (state, action) => {
                state.statusOwnerships.push(action.payload);
            })
            .addCase(fetchSingleStatusOwnership.fulfilled, (state, action) => {
                state.selectedStatusOwnership = action.payload;
            })
            .addCase(deleteStatusOwnership.fulfilled, (state, action) => {
                state.statusOwnerships = state.statusOwnerships.filter(so => so.id !== action.payload);
                if (state.selectedStatusOwnership && state.selectedStatusOwnership.id === action.payload) {
                    state.selectedStatusOwnership = null;
                }
            })
            .addCase(updateStatusOwnership.fulfilled, (state, action) => {
                const index = state.statusOwnerships.findIndex(so => so.id === action.payload.id);
                if (index !== -1) {
                    state.statusOwnerships[index] = action.payload;
                }
                if (state.selectedStatusOwnership && state.selectedStatusOwnership.id === action.payload.id) {
                    state.selectedStatusOwnership = action.payload;
                }
            })
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.payload?.message || 'An error occurred';
                }
            )
            .addMatcher(
                action => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            );
    },
});

export default adminStatusOwnershipsSlice.reducer;