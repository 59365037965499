import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';  // Assuming you have a getToken utility function

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Retrieves the token
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

// Thunks for "Reference Statuses"
export const fetchStatuses = createAsyncThunk('references/fetchStatuses', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/statuses`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createStatus = createAsyncThunk('references/createStatus', async (statusData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/statuses`, statusData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateStatus = createAsyncThunk('references/updateStatus', async ({ id, statusData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/statuses/${id}`, statusData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteStatus = createAsyncThunk('references/deleteStatus', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/statuses/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunks for "Reference Flows"
export const fetchFlows = createAsyncThunk('references/fetchFlows', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/flows`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createFlow = createAsyncThunk('references/createFlow', async (flowData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/flows`, flowData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateFlow = createAsyncThunk('references/updateFlow', async ({ id, flowData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/flows/${id}`, flowData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteFlow = createAsyncThunk('references/deleteFlow', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/flows/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunks for "Reference Event Relationships"
export const fetchEventRelationships = createAsyncThunk('references/fetchEventRelationships', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/event-relationships`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createEventRelationship = createAsyncThunk('references/createEventRelationship', async (eventRelationshipData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/event-relationships`, eventRelationshipData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateEventRelationship = createAsyncThunk('references/updateEventRelationship', async ({ id, eventRelationshipData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/event-relationships/${id}`, eventRelationshipData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteEventRelationship = createAsyncThunk('references/deleteEventRelationship', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/event-relationships/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunks for "Reference Currencies"
export const fetchCurrencies = createAsyncThunk('references/fetchCurrencies', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/currencies`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createCurrency = createAsyncThunk('references/createCurrency', async (currencyData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/currencies`, currencyData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateCurrency = createAsyncThunk('references/updateCurrency', async ({ id, currencyData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/currencies/${id}`, currencyData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteCurrency = createAsyncThunk('references/deleteCurrency', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/currencies/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunks for "Reference Categories"
export const fetchCategories = createAsyncThunk('references/fetchCategories', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/references/categories`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const createCategory = createAsyncThunk('references/createCategory', async (categoryData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/references/categories`, categoryData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateCategory = createAsyncThunk('references/updateCategory', async ({ id, categoryData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/references/categories/${id}`, categoryData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const deleteCategory = createAsyncThunk('references/deleteCategory', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/references/categories/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Reference slice
const referencesSlice = createSlice({
    name: 'references',
    initialState: {
        statuses: [],
        flows: [],
        eventRelationships: [],
        currencies: [],
        categories: [],
        selectedStatus: null,
        selectedFlow: null,
        selectedEventRelationship: null,
        selectedCurrency: null,
        selectedCategory: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Statuses
            .addCase(fetchStatuses.pending, (state) => { state.status = 'loading'; })
            .addCase(fetchStatuses.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuses = action.payload.data;
            })
            .addCase(fetchStatuses.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch statuses';
            })
            .addCase(createStatus.fulfilled, (state, action) => {
                state.statuses.push(action.payload);
            })
            .addCase(updateStatus.fulfilled, (state, action) => {
                const index = state.statuses.findIndex(status => status.id === action.payload.id);
                if (index !== -1) state.statuses[index] = action.payload;
            })
            .addCase(deleteStatus.fulfilled, (state, action) => {
                state.statuses = state.statuses.filter(status => status.id !== action.payload);
                if (state.selectedStatus && state.selectedStatus.id === action.payload) {
                    state.selectedStatus = null;
                }
            })
            // Flows
            .addCase(fetchFlows.pending, (state) => { state.status = 'loading'; })
            .addCase(fetchFlows.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.flows = action.payload.data;
            })
            .addCase(fetchFlows.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch flows';
            })
            .addCase(createFlow.fulfilled, (state, action) => {
                state.flows.push(action.payload);
            })
            .addCase(updateFlow.fulfilled, (state, action) => {
                const index = state.flows.findIndex(flow => flow.id === action.payload.id);
                if (index !== -1) state.flows[index] = action.payload;
            })
            .addCase(deleteFlow.fulfilled, (state, action) => {
                state.flows = state.flows.filter(flow => flow.id !== action.payload);
                if (state.selectedFlow && state.selectedFlow.id === action.payload) {
                    state.selectedFlow = null;
                }
            })
            // Event Relationships
            .addCase(fetchEventRelationships.pending, (state) => { state.status = 'loading'; })
            .addCase(fetchEventRelationships.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventRelationships = action.payload.data;
            })
            .addCase(fetchEventRelationships.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event relationships';
            })
            .addCase(createEventRelationship.fulfilled, (state, action) => {
                state.eventRelationships.push(action.payload);
            })
            .addCase(updateEventRelationship.fulfilled, (state, action) => {
                const index = state.eventRelationships.findIndex(eventRelationship => eventRelationship.id === action.payload.id);
                if (index !== -1) state.eventRelationships[index] = action.payload;
            })
            .addCase(deleteEventRelationship.fulfilled, (state, action) => {
                state.eventRelationships = state.eventRelationships.filter(eventRelationship => eventRelationship.id !== action.payload);
                if (state.selectedEventRelationship && state.selectedEventRelationship.id === action.payload) {
                    state.selectedEventRelationship = null;
                }
            })
            // Currencies
            .addCase(fetchCurrencies.pending, (state) => { state.status = 'loading'; })
            .addCase(fetchCurrencies.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currencies = action.payload.data;
            })
            .addCase(fetchCurrencies.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch currencies';
            })
            .addCase(createCurrency.fulfilled, (state, action) => {
                state.currencies.push(action.payload);
            })
            .addCase(updateCurrency.fulfilled, (state, action) => {
                const index = state.currencies.findIndex(currency => currency.id === action.payload.id);
                if (index !== -1) state.currencies[index] = action.payload;
            })
            .addCase(deleteCurrency.fulfilled, (state, action) => {
                state.currencies = state.currencies.filter(currency => currency.id !== action.payload);
                if (state.selectedCurrency && state.selectedCurrency.id === action.payload) {
                    state.selectedCurrency = null;
                }
            })
            // Categories
            .addCase(fetchCategories.pending, (state) => { state.status = 'loading'; })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload.data;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch categories';
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.categories.push(action.payload);
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                const index = state.categories.findIndex(category => category.id === action.payload.id);
                if (index !== -1) state.categories[index] = action.payload;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.categories = state.categories.filter(category => category.id !== action.payload);
                if (state.selectedCategory && state.selectedCategory.id === action.payload) {
                    state.selectedCategory = null;
                }
            });
    },
});

export default referencesSlice.reducer;
