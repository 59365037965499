import CompanyProfile from "../../shared/profiles/CompanyProfile";
import {Box, Divider, Typography} from "@mui/material";

const CompanyName = () => {
    return (
        <Box>
            <Typography variant='h1'>Company Name</Typography>
            <Typography variant='h2'>Keep Your Company Name & Profile Current and Accurate</Typography>
            <Divider/>
            <CompanyProfile/>
        </Box>
    )
}

export default CompanyName;