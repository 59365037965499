import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchRoleDefaultPermissions = createAsyncThunk(
    'adminRoleDefaultPermissions/fetchRoleDefaultPermissions',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/role-default-permissions`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createRoleDefaultPermission = createAsyncThunk(
    'adminRoleDefaultPermissions/createRoleDefaultPermission',
    async (permissionData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/role-default-permissions`,
                permissionData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleRoleDefaultPermission = createAsyncThunk(
    'adminRoleDefaultPermissions/fetchSingleRoleDefaultPermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/role-default-permissions/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteRoleDefaultPermission = createAsyncThunk(
    'adminRoleDefaultPermissions/deleteRoleDefaultPermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/role-default-permissions/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchRoleDefaultPermissionsByRoleId = createAsyncThunk(
    'adminRoleDefaultPermissions/fetchRoleDefaultPermissionsByRoleId',
    async (roleId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/role-default-permissions/roles/${roleId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteRoleDefaultPermissionsByRoleId = createAsyncThunk(
    'adminRoleDefaultPermissions/deleteRoleDefaultPermissionsByRoleId',
    async (roleId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/role-default-permissions/roles/${roleId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return roleId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchRoleDefaultPermissionsByPermissionId = createAsyncThunk(
    'adminRoleDefaultPermissions/fetchRoleDefaultPermissionsByPermissionId',
    async (permissionId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/role-default-permissions/permissions/${permissionId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteRoleDefaultPermissionsByPermissionId = createAsyncThunk(
    'adminRoleDefaultPermissions/deleteRoleDefaultPermissionsByPermissionId',
    async (permissionId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/role-default-permissions/permissions/${permissionId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return permissionId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchRoleDefaultPermissionsByOrganizationId = createAsyncThunk(
    'adminRoleDefaultPermissions/fetchRoleDefaultPermissionsByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/role-default-permissions/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteRoleDefaultPermissionsByOrganizationId = createAsyncThunk(
    'adminRoleDefaultPermissions/deleteRoleDefaultPermissionsByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/role-default-permissions/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return organizationId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminRoleDefaultPermissionsSlice = createSlice({
    name: 'adminRoleDefaultPermissions',
    initialState: {
        roleDefaultPermissions: [],
        selectedRoleDefaultPermission: null,
        byRole: {},
        byPermission: {},
        byOrganization: {},
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoleDefaultPermissions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roleDefaultPermissions = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(createRoleDefaultPermission.fulfilled, (state, action) => {
                state.roleDefaultPermissions.push(action.payload);
            })
            .addCase(fetchSingleRoleDefaultPermission.fulfilled, (state, action) => {
                state.selectedRoleDefaultPermission = action.payload;
            })
            .addCase(deleteRoleDefaultPermission.fulfilled, (state, action) => {
                state.roleDefaultPermissions = state.roleDefaultPermissions.filter(p => p.id !== action.payload);
            })
            .addCase(fetchRoleDefaultPermissionsByRoleId.fulfilled, (state, action) => {
                state.byRole[action.meta.arg] = action.payload;
            })
            .addCase(deleteRoleDefaultPermissionsByRoleId.fulfilled, (state, action) => {
                delete state.byRole[action.payload];
            })
            .addCase(fetchRoleDefaultPermissionsByPermissionId.fulfilled, (state, action) => {
                state.byPermission[action.meta.arg] = action.payload;
            })
            .addCase(deleteRoleDefaultPermissionsByPermissionId.fulfilled, (state, action) => {
                delete state.byPermission[action.payload];
            })
            .addCase(fetchRoleDefaultPermissionsByOrganizationId.fulfilled, (state, action) => {
                state.byOrganization[action.meta.arg] = action.payload;
            })
            .addCase(deleteRoleDefaultPermissionsByOrganizationId.fulfilled, (state, action) => {
                delete state.byOrganization[action.payload];
            })
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.payload?.message || 'An error occurred';
                }
            )
            .addMatcher(
                action => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            );
    },
});

export default adminRoleDefaultPermissionsSlice.reducer;