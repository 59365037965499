import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchRoleDefaultPermissions = createAsyncThunk(
    'roleDefaultPermissions/fetchRoleDefaultPermissions',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/role-default-permissions`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchRoleDefaultPermissions response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchRoleDefaultPermissions error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const createRoleDefaultPermission = createAsyncThunk(
    'roleDefaultPermissions/createRoleDefaultPermission',
    async (permissionData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/role-default-permissions`,
                permissionData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('createRoleDefaultPermission response:', response.data);
            return response.data;
        } catch (error) {
            console.error('createRoleDefaultPermission error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchRoleDefaultPermissionById = createAsyncThunk(
    'roleDefaultPermissions/fetchRoleDefaultPermissionById',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/role-default-permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchRoleDefaultPermissionById response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchRoleDefaultPermissionById error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deleteRoleDefaultPermission = createAsyncThunk(
    'roleDefaultPermissions/deleteRoleDefaultPermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/role-default-permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deleteRoleDefaultPermission success for id:', id);
            return id;
        } catch (error) {
            console.error('deleteRoleDefaultPermission error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

const roleDefaultPermissionsSlice = createSlice({
    name: 'roleDefaultPermissions',
    initialState: {
        permissions: [],
        selectedPermission: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoleDefaultPermissions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRoleDefaultPermissions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.permissions = action.payload;
            })
            .addCase(fetchRoleDefaultPermissions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch role default permissions';
            })
            .addCase(createRoleDefaultPermission.fulfilled, (state, action) => {
                state.permissions.push(action.payload);
            })
            .addCase(fetchRoleDefaultPermissionById.fulfilled, (state, action) => {
                state.selectedPermission = action.payload;
            })
            .addCase(deleteRoleDefaultPermission.fulfilled, (state, action) => {
                state.permissions = state.permissions.filter(perm => perm.id !== action.payload);
                if (state.selectedPermission && state.selectedPermission.id === action.payload) {
                    state.selectedPermission = null;
                }
            });
    },
});

export default roleDefaultPermissionsSlice.reducer;