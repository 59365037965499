import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUserRolePermissions = createAsyncThunk(
    'adminUserRolePermissions/fetchUserRolePermissions',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-role-permissions`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createUserRolePermission = createAsyncThunk(
    'adminUserRolePermissions/createUserRolePermission',
    async (permissionData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/user-role-permissions`,
                permissionData,
                { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSingleUserRolePermission = createAsyncThunk(
    'adminUserRolePermissions/fetchSingleUserRolePermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-role-permissions/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolePermission = createAsyncThunk(
    'adminUserRolePermissions/deleteUserRolePermission',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-role-permissions/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserRolePermissionsByUserRoleId = createAsyncThunk(
    'adminUserRolePermissions/fetchUserRolePermissionsByUserRoleId',
    async (userRoleId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-role-permissions/user-roles/${userRoleId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { userRoleId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolePermissionsByUserRoleId = createAsyncThunk(
    'adminUserRolePermissions/deleteUserRolePermissionsByUserRoleId',
    async (userRoleId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-role-permissions/user-roles/${userRoleId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return userRoleId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserRolePermissionsByPermissionId = createAsyncThunk(
    'adminUserRolePermissions/fetchUserRolePermissionsByPermissionId',
    async (permissionId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-role-permissions/permissions/${permissionId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { permissionId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolePermissionsByPermissionId = createAsyncThunk(
    'adminUserRolePermissions/deleteUserRolePermissionsByPermissionId',
    async (permissionId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-role-permissions/permissions/${permissionId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return permissionId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchUserRolePermissionsByOrganizationId = createAsyncThunk(
    'adminUserRolePermissions/fetchUserRolePermissionsByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/user-role-permissions/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return { organizationId, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserRolePermissionsByOrganizationId = createAsyncThunk(
    'adminUserRolePermissions/deleteUserRolePermissionsByOrganizationId',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/user-role-permissions/organizations/${organizationId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            return organizationId;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const adminUserRolePermissionsSlice = createSlice({
    name: 'adminUserRolePermissions',
    initialState: {
        userRolePermissions: [],
        selectedUserRolePermission: null,
        userRolePermissionsByUserRole: {},
        userRolePermissionsByPermission: {},
        userRolePermissionsByOrganization: {},
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRolePermissions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userRolePermissions = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(createUserRolePermission.fulfilled, (state, action) => {
                state.userRolePermissions.push(action.payload);
            })
            .addCase(fetchSingleUserRolePermission.fulfilled, (state, action) => {
                state.selectedUserRolePermission = action.payload;
            })
            .addCase(deleteUserRolePermission.fulfilled, (state, action) => {
                state.userRolePermissions = state.userRolePermissions.filter(urp => urp.id !== action.payload);
                if (state.selectedUserRolePermission && state.selectedUserRolePermission.id === action.payload) {
                    state.selectedUserRolePermission = null;
                }
            })
            .addCase(fetchUserRolePermissionsByUserRoleId.fulfilled, (state, action) => {
                state.userRolePermissionsByUserRole[action.payload.userRoleId] = action.payload.data;
            })
            .addCase(deleteUserRolePermissionsByUserRoleId.fulfilled, (state, action) => {
                delete state.userRolePermissionsByUserRole[action.payload];
                state.userRolePermissions = state.userRolePermissions.filter(urp => urp.userRoleId !== action.payload);
            })
            .addCase(fetchUserRolePermissionsByPermissionId.fulfilled, (state, action) => {
                state.userRolePermissionsByPermission[action.payload.permissionId] = action.payload.data;
            })
            .addCase(deleteUserRolePermissionsByPermissionId.fulfilled, (state, action) => {
                delete state.userRolePermissionsByPermission[action.payload];
                state.userRolePermissions = state.userRolePermissions.filter(urp => urp.permissionId !== action.payload);
            })
            .addCase(fetchUserRolePermissionsByOrganizationId.fulfilled, (state, action) => {
                state.userRolePermissionsByOrganization[action.payload.organizationId] = action.payload.data;
            })
            .addCase(deleteUserRolePermissionsByOrganizationId.fulfilled, (state, action) => {
                delete state.userRolePermissionsByOrganization[action.payload];
                state.userRolePermissions = state.userRolePermissions.filter(urp => urp.organizationId !== action.payload);
            })
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.payload?.message || 'An error occurred';
                }
            )
            .addMatcher(
                action => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            );
    },
});

export default adminUserRolePermissionsSlice.reducer;