import React, { useState } from "react";
import { Box, Typography, TextField, Paper, IconButton, Chip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ViewIcon, DeleteIcon, EditIcon } from "../../../shared/IconButtons";

const StatusChip = ({ status }) => {
    const statusColors = {
        Verified: { backgroundColor: '#4caf50', textColor: '#fff' },
        Warning: { backgroundColor: '#ff9800', textColor: '#fff' },
        Incomplete: { backgroundColor: '#f44336', textColor: '#fff' },
        Expired: { backgroundColor: '' +
                '', textColor: '#fff' },
    };

    const { backgroundColor, textColor } = statusColors[status] || {};

    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.6rem',
                height: '20px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const AdminAccountStatusTable = ({ title, rows }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredRows = rows.filter((row) =>
        row.merchantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.merchantEmail.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const columns = [
        { field: 'merchantName', headerName: 'Merchant Name', flex: 1 },
        { field: 'merchantEmail', headerName: 'Merchant Email', flex: 1 },
        { field: 'merchantId', headerName: 'Merchant ID', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Merchant Status',
            flex: 0.5,
            renderCell: (params) => <StatusChip status={params.value} />,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.3,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => console.log('view', params.row)}>
                        <ViewIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => console.log('edit', params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => console.log('delete', params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': {
                                height: '34px',
                                padding: '0 14px',
                            },
                            '& .MuiInputBase-input': {
                                height: '34px',
                                padding: '0 14px',
                            }
                        }
                    }}
                />
            </Box>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    {title}
                </Typography>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={filteredRows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={70}
                    pageSizeOptions={[5, 10, 20]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 6,
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                            backgroundColor: '#737373',
                            color: '#fff',
                            height: '34px',
                            my: 1,
                            minWidth: 'auto',
                            '&:hover': {
                                backgroundColor: '#404040',
                            },
                        },
                    }}
                />
            </Paper>
        </Box>
    );
};

export default AdminAccountStatusTable;
