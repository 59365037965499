import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { couponsOptions } from "../../shared/CouponsOptions";

const VoucherCard = () => {
    const [expanded, setExpanded] = useState(false);
    const [selectedCoupons, setSelectedCoupons] = useState([]);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const handleSelectCoupon = (index) => {
        if (selectedCoupons.includes(index)) {
            setSelectedCoupons(selectedCoupons.filter(couponIndex => couponIndex !== index));
        } else {
            setSelectedCoupons([...selectedCoupons, index]);
        }
    };

    return (
        <Box sx={{ padding: { xs: 0, sm: '0 32px' } }}>
            <Accordion
                expanded={expanded}
                onChange={handleExpand}
                sx={{ borderRadius: '12px', overflow: 'hidden' }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <LocalOfferIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body1">Select Coupon</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {couponsOptions.map((coupon, index) => (
                        <Paper
                            key={index}
                            onClick={() => handleSelectCoupon(index)}
                            sx={{
                                marginBottom: '5px',
                                padding: { xs: '8px', sm: '10px' },
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                backgroundColor: selectedCoupons.includes(index) ? '#ffeb3b' : '',
                            }}
                        >
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body2" fontWeight="bold">
                                        {coupon.discount}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {coupon.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <Button
                                        variant={selectedCoupons.includes(index) ? "outlined" : "contained"}
                                        color={selectedCoupons.includes(index) ? "primary" : "error"}
                                        sx={{ borderRadius: '20px', padding: { xs: '4px 8px', sm: '6px 12px' } }}
                                    >
                                        <Typography variant="caption">
                                            {selectedCoupons.includes(index) ? "SELECTED" : "Claim"}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default VoucherCard;
