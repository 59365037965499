import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { IconButton, Box, Typography, FormControl, InputLabel, Input, Grid, Button } from '@mui/material';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { styled, ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import CustomInput from "../../../../input/CustomInput";

// Define multiple themes with necessary properties
const themes = {
    light: createTheme({
        palette: {
            mode: 'light',
            background: {
                default: '#ffffff'
            },
            text: {
                primary: '#000000'
            }
        },
    }),
    dark: createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: '#303030'
            },
            text: {
                primary: '#ffffff'
            }
        },
    }),
    blue: createTheme({
        palette: {
            background: {
                default: '#e3f2fd'
            },
            text: {
                primary: '#0d47a1'
            }
        },
    }),
    green: createTheme({
        palette: {
            background: {
                default: '#e8f5e9'
            },
            text: {
                primary: '#1b5e20'
            }
        },
    }),
    red: createTheme({
        palette: {
            background: {
                default: '#ffebee'
            },
            text: {
                primary: '#b71c1c'
            }
        },
    }),
};

const themeColors = {
    light: '#ffffff',
    dark: '#303030',
    blue: '#e3f2fd',
    green: '#e8f5e9',
    red: '#ffebee'
};

const PhoneFrame = styled(Box)(({ theme }) => ({
    width: '375px',
    height: '667px',
    border: '16px solid #e0e0e0',
    borderRadius: '36px',
    padding: '10px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    margin: '0 auto'
}));

const BrowserFrame = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '1024px',
    height: '576px',
    border: '16px solid #e0e0e0',
    borderRadius: '12px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column'
}));

const BrowserHeader = styled(Box)(({ theme }) => ({
    height: '40px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    borderBottom: '1px solid #ddd'
}));

const AddressBar = styled(Box)(({ theme }) => ({
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '5px 10px',
    marginLeft: '10px',
    marginRight: '10px',
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)'
}));

const LandingPage = () => {
    const [config, setConfig] = useState({
        text: 'Sample text',
        color: '#000000',
        backgroundColor: '#ffffff',
        fontSize: 16
    });
    const [isMobileView, setIsMobileView] = useState(false);
    const [theme, setTheme] = useState('light');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfig({ ...config, [name]: value });
    };

    const toggleToMobileView = () => {
        setIsMobileView(true);
    };

    const toggleToDesktopView = () => {
        setIsMobileView(false);
    };

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' });

    const commonStyles = {
        color: config.color,
        fontSize: `${config.fontSize}px`,
        backgroundColor: config.backgroundColor,
        padding: '10px',
        marginTop: '10px',
        boxSizing: 'border-box'
    };

    const iconButtonStyles = (selected) => ({
        backgroundColor: selected ? '#e0e0e0' : 'transparent',
        margin: '0 4px',
        borderRadius: '8px',
        padding: '8px'
    });

    const handleThemeChange = (newTheme) => {
        const selectedTheme = themes[newTheme].palette;
        setTheme(newTheme);
        setConfig((prevConfig) => ({
            ...prevConfig,
            color: selectedTheme.text.primary,
            backgroundColor: selectedTheme.background.default,
        }));
    };

    return (
        <ThemeProvider theme={themes[theme]}>
            <Box display="flex" flexDirection={isTabletOrMobile ? 'column' : 'row'}>
                <Box flex={isTabletOrMobile ? '1' : '0 0 30%'} padding="10px" borderRight={isDesktop ? 'none' : 'none'}>
                    <Box marginBottom="10px">
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="text">Text</InputLabel>
                            <Input
                                id="text"
                                name="text"
                                value={config.text}
                                onChange={handleChange}
                                aria-describedby="text-helper-text"
                            />
                        </FormControl>
                    </Box>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <InputLabel htmlFor="color">Color</InputLabel>
                        </Grid>
                        <Grid item xs={2}>
                            <CustomInput
                                id="color"
                                name="color"
                                type="color"
                                value={config.color}
                                onChange={handleChange}
                                aria-describedby="color-helper-text"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <InputLabel htmlFor="backgroundColor">Background Color</InputLabel>
                        </Grid>
                        <Grid item xs={2}>
                            <CustomInput
                                id="backgroundColor"
                                name="backgroundColor"
                                type="color"
                                value={config.backgroundColor}
                                onChange={handleChange}
                                aria-describedby="backgroundColor-helper-text"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <InputLabel htmlFor="fontSize">Font Size</InputLabel>
                        </Grid>
                        <Grid item xs={2}>
                            <Input
                                id="fontSize"
                                name="fontSize"
                                type="number"
                                value={config.fontSize}
                                onChange={handleChange}
                                aria-describedby="fontSize-helper-text"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box marginTop="10px">
                        <Typography variant="h6">Select Theme</Typography>
                        <Grid container spacing={2}>
                            {Object.keys(themes).map((themeKey) => (
                                <Grid item key={themeKey}>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: themeColors[themeKey], color: themes[themeKey].palette.text.primary }}
                                        onClick={() => handleThemeChange(themeKey)}
                                    >
                                        {themeKey.charAt(0).toUpperCase() + themeKey.slice(1)}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                <Box flex={isTabletOrMobile ? '1' : '0 0 70%'} padding="10px" display="flex" flexDirection="column" alignItems="center" bgcolor="#f5f5f5">
                    <Box display="flex" alignItems="center" marginBottom="10px">
                        <IconButton
                            onClick={toggleToMobileView}
                            aria-label="mobile view"
                            style={iconButtonStyles(isMobileView)}
                        >
                            <PhoneIphoneIcon color={isMobileView ? "primary" : "inherit"} />
                        </IconButton>
                        <IconButton
                            onClick={toggleToDesktopView}
                            aria-label="desktop view"
                            style={iconButtonStyles(!isMobileView)}
                        >
                            <DesktopWindowsIcon color={!isMobileView ? "primary" : "inherit"} />
                        </IconButton>
                    </Box>
                    {isMobileView ? (
                        <PhoneFrame>
                            <Box style={commonStyles}>
                                <Typography variant="h6">Mobile View</Typography>
                                <p>{config.text || 'Your preview text will appear here (Mobile)'}</p>
                                <p>Additional content for mobile view to illustrate scrolling and layout differences.</p>
                            </Box>
                        </PhoneFrame>
                    ) : (
                        <BrowserFrame>
                            <BrowserHeader>
                                <AddressBar>buy.eventlah.com</AddressBar>
                            </BrowserHeader>
                            <Box display="flex" height="100%">
                                <Box width="50%" style={{ ...commonStyles, borderRight: '1px solid #ddd' }}>
                                    <Typography variant="h6">Product name</Typography>
                                    <Typography variant="h4">MYR 0.00</Typography>
                                    <Typography variant="body1">{config.text || 'Sample text for product description.'}</Typography>
                                </Box>
                                <Box width="50%" style={{ ...commonStyles }}>
                                    <Typography variant="h6">Payment Information</Typography>
                                    <p>Email</p>
                                    <p>Card information</p>
                                    <p>Cardholder name</p>
                                    <p>Country or region</p>
                                </Box>
                            </Box>
                        </BrowserFrame>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default LandingPage;