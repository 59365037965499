import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all package services
export const fetchPackageServices = createAsyncThunk('packageServices/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/package-has-services`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new package service
export const createPackageService = createAsyncThunk('packageServices/create', async (serviceData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/package-has-services`, serviceData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific package service by ID
export const fetchPackageServiceById = createAsyncThunk('packageServices/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/package-has-services/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a package service by ID
export const deletePackageService = createAsyncThunk('packageServices/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/package-has-services/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a package service by ID
export const updatePackageService = createAsyncThunk('packageServices/update', async ({ id, serviceData }, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/bridges/package-has-services/${id}`, serviceData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch package services by package ID
export const fetchPackageServicesByPackageId = createAsyncThunk('packageServices/fetchByPackageId', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/package-has-services/packages/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Package Services
const packageHasServicesSlice = createSlice({
    name: 'packageServices',
    initialState: {
        services: [],
        selectedService: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPackageServices.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPackageServices.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.services = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchPackageServices.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch package services';
            })

            .addCase(createPackageService.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPackageService.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.services.push(action.payload);
            })
            .addCase(createPackageService.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create package service';
            })

            .addCase(fetchPackageServiceById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPackageServiceById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedService = action.payload;
            })
            .addCase(fetchPackageServiceById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch package service by ID';
            })

            .addCase(deletePackageService.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePackageService.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.services = state.services.filter(service => service.id !== action.payload);
                if (state.selectedService && state.selectedService.id === action.payload) {
                    state.selectedService = null;
                }
            })
            .addCase(deletePackageService.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete package service';
            })

            .addCase(updatePackageService.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePackageService.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.services.findIndex(service => service.id === action.payload.id);
                if (index !== -1) state.services[index] = action.payload;
                if (state.selectedService && state.selectedService.id === action.payload.id) {
                    state.selectedService = action.payload;
                }
            })
            .addCase(updatePackageService.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update package service';
            })

            .addCase(fetchPackageServicesByPackageId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPackageServicesByPackageId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // handle the fetched package services by package ID
            })
            .addCase(fetchPackageServicesByPackageId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch package services by package ID';
            });
    },
});

export default packageHasServicesSlice.reducer;
