import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchPermissionFeatures = createAsyncThunk(
    'adminPermissionFeatures/fetchPermissionFeatures',
    async (params = { page: 0, limit: 10 }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/permission-features`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params,
            });
            console.log('fetchPermissionFeatures response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchPermissionFeatures error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const createPermissionFeature = createAsyncThunk(
    'adminPermissionFeatures/createPermissionFeature',
    async (permissionFeatureData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${API_BASE_URL}/administrations/permission-features`,
                permissionFeatureData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('createPermissionFeature response:', response.data);
            return response.data;
        } catch (error) {
            console.error('createPermissionFeature error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchSinglePermissionFeature = createAsyncThunk(
    'adminPermissionFeatures/fetchSinglePermissionFeature',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/permission-features/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchSinglePermissionFeature response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchSinglePermissionFeature error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deletePermissionFeature = createAsyncThunk(
    'adminPermissionFeatures/deletePermissionFeature',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/permission-features/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deletePermissionFeature success for id:', id);
            return id;
        } catch (error) {
            console.error('deletePermissionFeature error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchPermissionFeatureByPermissionId = createAsyncThunk(
    'adminPermissionFeatures/fetchPermissionFeatureByPermissionId',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/permission-features/permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchPermissionFeatureByPermissionId response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchPermissionFeatureByPermissionId error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deletePermissionFeatureByPermissionId = createAsyncThunk(
    'adminPermissionFeatures/deletePermissionFeatureByPermissionId',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/permission-features/permissions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deletePermissionFeatureByPermissionId success for id:', id);
            return id;
        } catch (error) {
            console.error('deletePermissionFeatureByPermissionId error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchPermissionFeatureByFeatureId = createAsyncThunk(
    'adminPermissionFeatures/fetchPermissionFeatureByFeatureId',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/permission-features/features/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchPermissionFeatureByFeatureId response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchPermissionFeatureByFeatureId error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deletePermissionFeatureByFeatureId = createAsyncThunk(
    'adminPermissionFeatures/deletePermissionFeatureByFeatureId',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/permission-features/features/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deletePermissionFeatureByFeatureId success for id:', id);
            return id;
        } catch (error) {
            console.error('deletePermissionFeatureByFeatureId error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

const adminPermissionFeaturesSlice = createSlice({
    name: 'adminPermissionFeatures',
    initialState: {
        permissionFeatures: [],
        selectedPermissionFeature: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPermissionFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPermissionFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.permissionFeatures = action.payload.data;
                state.pagination = {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    totalItems: action.payload.total_items,
                    totalPages: action.payload.total_pages,
                };
            })
            .addCase(fetchPermissionFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch permission features';
            })
            .addCase(createPermissionFeature.fulfilled, (state, action) => {
                state.permissionFeatures.push(action.payload);
                state.pagination.totalItems += 1;
            })
            .addCase(fetchSinglePermissionFeature.fulfilled, (state, action) => {
                state.selectedPermissionFeature = action.payload;
            })
            .addCase(deletePermissionFeature.fulfilled, (state, action) => {
                state.permissionFeatures = state.permissionFeatures.filter(pf => pf.id !== action.payload);
                state.pagination.totalItems -= 1;
                if (state.selectedPermissionFeature && state.selectedPermissionFeature.id === action.payload) {
                    state.selectedPermissionFeature = null;
                }
            })
        // Handle other cases as needed for the additional endpoints
        ;
    },
});

export default adminPermissionFeaturesSlice.reducer;