import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { Box, IconButton, Paper, Typography, ImageList, ImageListItem } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const BASE_URL = 'https://ev-merchants.s3.ap-southeast-1.amazonaws.com/';

const ProductDetails = () => {
    const { id } = useParams();
    const { keycloak } = useKeycloak();
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/services/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${keycloak.token}`
                    }
                });
                setProduct(response.data.data);
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        const fetchImages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/uploads/services/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${keycloak.token}`
                    }
                });
                const imageUrls = response.data.data.documents.map(doc => `${BASE_URL}${doc.originalName}`);
                setImages(imageUrls);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchProduct();
        fetchImages();
    }, [id, keycloak.token]);

    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 4000);
        return () => clearInterval(interval);
    }, [currentIndex, images.length]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const getVisibleThumbnails = () => {
        const visibleCount = 3;
        let start = currentIndex - 1;
        let end = currentIndex + 2;
        if (start < 0) {
            start = 0;
            end = visibleCount;
        } else if (end > images.length) {
            start = images.length - visibleCount;
            end = images.length;
        }
        return images.slice(start, end);
    };

    return (
        <Box>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    padding: '32px',
                    alignItems: { xs: 'center', md: 'flex-start' },
                    justifyContent:'center',
                    borderRadius:'12px',
                    elevation:1,
                    width: 'fit-content',
                    margin: 'auto',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {images.length > 0 && (
                        <Box
                            component="img"
                            sx={{
                                width: { xs: '320px', md: '500px' },
                                height: 'auto',
                                borderRadius: '8px',
                                marginBottom: '16px',
                            }}
                            alt="Main Product Image"
                            src={images[currentIndex]}
                        />
                    )}

                    {images.length > 1 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', mt: { xs:0, md:3 } }}>
                            <IconButton onClick={handlePrev} size="small">
                                <ArrowBackIosIcon fontSize="small" />
                            </IconButton>
                            <Box sx={{ display: 'flex', overflow: 'hidden', width: 'calc(100% - 64px)', gap: '12px' }}>
                                {getVisibleThumbnails().map((thumbnail, index) => (
                                    <Box
                                        key={index}
                                        component="img"
                                        sx={{
                                            width: 'auto',
                                            height: { xs:'60px', md:'95px' },
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            opacity: images[currentIndex] === thumbnail ? 1 : 0.6,
                                            transition: 'opacity 0.5s',
                                        }}
                                        alt={`Thumbnail ${index + 1}`}
                                        src={thumbnail}
                                        onClick={() => setCurrentIndex(images.indexOf(thumbnail))}
                                    />
                                ))}
                            </Box>
                            <IconButton onClick={handleNext} size="small">
                                <ArrowForwardIosIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <Box sx={{ marginLeft: { xs: 0, md: '48px' }, marginTop: { xs: 5, md: 0 }, textAlign: { xs: 'center', md: 'left' } }}>
                    <Typography variant="h1" component="h1">
                        {product?.name}
                    </Typography>
                    <Typography variant="h2" >
                        {product?.description}
                    </Typography>
                    <Typography variant="h2">
                        Price: {product?.minimumCurrency?.symbol}
                        {product?.minimum_amount} - {product?.maximumCurrency?.symbol}
                        {product?.maximum_amount}
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default ProductDetails;