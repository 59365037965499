import React from 'react';
import {
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton, Link,
} from '@mui/material';
import { Info, ContentCopy } from '@mui/icons-material';

const ViewRolePermissions = () => {
    return (
        <Box sx={{ maxWidth: 1000, margin: 'auto', p: 2, bgcolor: '#f5f5f5' }}>
            <Typography variant="h5" gutterBottom sx={{fontWeight:'600'}}>Users and permissions</Typography>

            <Typography variant="h6" gutterBottom>Permissions</Typography>
            <Typography variant="body2" gutterBottom>Manage what users can see or do in your store.</Typography>

            <Paper sx={{ p: 2, my: 2, borderRadius:'12px' }}>
                <Typography variant="h6">Store owner</Typography>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ borderRadius:'6px', bgcolor: 'primary.main' }}>ZF</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<><Typography component="span" fontWeight="bold">Zain Fauzan</Typography> <Info fontSize="small" sx={{ verticalAlign: 'middle', ml: 1 }} /></>}
                            secondary="Last login was Wednesday, November 1, 2023 9:41 AM GMT+8"
                        />
                    </ListItem>
                </List>
            </Paper>
            <Paper sx={{ p: 2, my: 2, borderRadius:'12px' }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Staff (5 of 15)
                    <Link href="#" sx={{ color: 'orange', textDecoration: 'none', mt:2, fontWeight:'600', fontSize:'16px' }}>Add staff</Link>
                </Typography>
                <List>
                    {['MKA Group', 'Support Motorparts', 'halo thame', 'halo themes', 'Damian TYK'].map((name, index) => (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar sx={{ borderRadius:'6px', bgcolor: ['#1976d2', '#388e3c', '#d32f2f', '#f57c00', '#7b1fa2'][index % 5] }}>
                                    {name.split(' ').map(n => n[0]).join('')}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={name}
                                secondary={index === 0 ? "Invitation sent" : index === 4 ? "Invitation sent" : "Limited permissions"}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>

            <Paper sx={{ p: 2, mb: 2, borderRadius:'12px' }}>
                <Typography variant="h6" gutterBottom>Collaborators</Typography>
                <Typography variant="body2" gutterBottom>Give external designers, developers, and marketers access to your Shopify admin.</Typography>

                <RadioGroup defaultValue="restricted">
                    <FormControlLabel value="open" control={<Radio />} label="Anyone can send a collaborator request" />
                    <FormControlLabel value="restricted" control={<Radio />} label="Only people with a collaborator request code can send a collaborator request" />
                </RadioGroup>

                <Box sx={{ display: 'flex', alignItems: 'center', padding:1, borderRadius:'12px', mt: 2, backgroundColor:'#fdffcc' }}>
                    <Info color="primary" sx={{ mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                        To protect your store's security, all collaborator requests now require a code by default.
                    </Typography>
                </Box>

                <Box sx={{display:'flex', justifyContent: 'space-between',mt:2 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Collaborator Request Code</Typography>
                    <Link href="#" sx={{ color: 'orange', textDecoration: 'none', mt:2, fontWeight:'600', fontSize:'16px' }}>Generate new code</Link>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        value="8062"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1 }}
                    />
                    <IconButton size="small" sx={{ ml: 1 }}>
                        <ContentCopy />
                    </IconButton>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Share this code to allow someone to send you a collaborator request. You'll still need to review and approve their request.
                </Typography>
            </Paper>

            <Paper sx={{ p: 2, mb: 2, borderRadius:'12px' }}>
                <Typography variant="h6" gutterBottom>Login services</Typography>
                <Typography variant="body2" gutterBottom>Allow staff to use external services to log in to Shopify.</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Google Apps</TableCell>
                            <TableCell>Disabled: Staff can't use Google Apps to log in</TableCell>
                            <TableCell>
                                <Link href="#" sx={{ color: 'orange', textDecoration: 'none', mt:2, fontWeight:'600', fontSize:'16px' }}>Edit</Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
};

export default ViewRolePermissions;