import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all permission features
export const fetchPermissionFeatures = createAsyncThunk('permissionFeatures/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/permission-has-features`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new permission feature
export const createPermissionFeature = createAsyncThunk('permissionFeatures/create', async (featureData, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/bridges/permission-has-features`, featureData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific permission feature by ID
export const fetchPermissionFeatureById = createAsyncThunk('permissionFeatures/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/permission-has-features/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a permission feature by ID
export const deletePermissionFeature = createAsyncThunk('permissionFeatures/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/bridges/permission-has-features/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch permission features by group
export const fetchPermissionFeaturesByGroup = createAsyncThunk('permissionFeatures/fetchByGroup', async (_, { rejectWithValue }) => {
    try {
        const headers = getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/bridges/permission-has-features/groups/features`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Permission Features
const permissionFeaturesSlice = createSlice({
    name: 'permissionFeatures',
    initialState: {
        features: [],
        selectedFeature: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPermissionFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPermissionFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchPermissionFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch permission features';
            })

            .addCase(createPermissionFeature.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPermissionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features.push(action.payload);
            })
            .addCase(createPermissionFeature.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create permission feature';
            })

            .addCase(fetchPermissionFeatureById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPermissionFeatureById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedFeature = action.payload;
            })
            .addCase(fetchPermissionFeatureById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch permission feature by ID';
            })

            .addCase(deletePermissionFeature.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePermissionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = state.features.filter(feature => feature.id !== action.payload);
                if (state.selectedFeature && state.selectedFeature.id === action.payload) {
                    state.selectedFeature = null;
                }
            })
            .addCase(deletePermissionFeature.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete permission feature';
            })

            .addCase(fetchPermissionFeaturesByGroup.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPermissionFeaturesByGroup.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload.data; // Adjust based on actual response structure
            })
            .addCase(fetchPermissionFeaturesByGroup.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch permission features by group';
            });
    },
});

export default permissionFeaturesSlice.reducer;
