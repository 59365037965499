// import Keycloak from 'keycloak-js';

// const keycloakConfig = {
//     url: 'https://accounts.eventlah.com/auth',
//     realm: 'eventlah-demo',
//     clientId: 'ui-merchants-app',
// };
//
// try {
//     const authenticated = await Keycloak.init();
//     console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
// } catch (error) {
//     console.error('Failed to initialize adapter:', error);
// }
//
// const keycloak = new Keycloak(keycloakConfig);
//
// export default keycloak;

// // src/keycloak.js
import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: 'https://accounts.eventlah.com',
    realm: 'eventlah-demo',
    clientId: 'ui-merchants-app'
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
