import React, { useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, TextField, Paper, Typography, Chip } from '@mui/material';

const initialRows = [
    { id: 1, merchantName: 'Merchant A', subscriptionPlan: 'Premium', subscriptionStatus: 'Active', nextBillingDate: '2024-10-09', amountDue: 199.99, paymentStatus: 'Upcoming' },
    { id: 2, merchantName: 'Merchant B', subscriptionPlan: 'Basic', subscriptionStatus: 'Active', nextBillingDate: '2024-09-25', amountDue: 49.99, paymentStatus: 'Paid' },
    { id: 3, merchantName: 'Merchant C', subscriptionPlan: 'Premium', subscriptionStatus: 'Inactive', nextBillingDate: '2024-10-15', amountDue: 199.99, paymentStatus: 'Overdue' },
    { id: 4, merchantName: 'Merchant D', subscriptionPlan: 'Standard', subscriptionStatus: 'Active', nextBillingDate: '2024-09-30', amountDue: 99.99, paymentStatus: 'Upcoming' },
    { id: 5, merchantName: 'Merchant E', subscriptionPlan: 'Basic', subscriptionStatus: 'Active', nextBillingDate: '2024-10-05', amountDue: 49.99, paymentStatus: 'Paid' },
    { id: 6, merchantName: 'Merchant F', subscriptionPlan: 'Premium', subscriptionStatus: 'Active', nextBillingDate: '2024-11-01', amountDue: 199.99, paymentStatus: 'Upcoming' },
    { id: 7, merchantName: 'Merchant G', subscriptionPlan: 'Standard', subscriptionStatus: 'Inactive', nextBillingDate: '2024-09-20', amountDue: 99.99, paymentStatus: 'Overdue' },
    { id: 8, merchantName: 'Merchant H', subscriptionPlan: 'Basic', subscriptionStatus: 'Active', nextBillingDate: '2024-09-27', amountDue: 49.99, paymentStatus: 'Paid' },
    { id: 9, merchantName: 'Merchant I', subscriptionPlan: 'Premium', subscriptionStatus: 'Active', nextBillingDate: '2024-10-12', amountDue: 199.99, paymentStatus: 'Upcoming' },
    { id: 10, merchantName: 'Merchant J', subscriptionPlan: 'Standard', subscriptionStatus: 'Active', nextBillingDate: '2024-10-01', amountDue: 99.99, paymentStatus: 'Upcoming' },
];

const StatusChip = ({ status }) => {
    const statusColors = {
        Active: { backgroundColor: '#4caf50', textColor: '#fff' },
        Inactive: { backgroundColor: '#f44336', textColor: '#fff' },
    };

    const { backgroundColor, textColor } = statusColors[status] || {};

    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.6rem',
                height: '20px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const PaymentStatusChip = ({ status }) => {
    const statusColors = {
        Paid: { backgroundColor: '#4caf50', textColor: '#fff' },
        Upcoming: { backgroundColor: '#ff9800', textColor: '#fff' },
        Overdue: { backgroundColor: '#f44336', textColor: '#fff' },
    };

    const { backgroundColor, textColor } = statusColors[status] || {};

    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.6rem',
                height: '20px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const Billing = () => {
    const [rows, setRows] = useState(initialRows);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredRows = rows.filter((row) =>
        row.merchantName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const columns = [
        { field: 'merchantName', headerName: 'Merchant Name', flex: 1 },
        { field: 'subscriptionPlan', headerName: 'Subscription Plan', flex: 1 },
        {
            field: 'subscriptionStatus',
            headerName: 'Status',
            flex: 0.7,
            renderCell: (params) => <StatusChip status={params.value} />
        },
        { field: 'nextBillingDate', headerName: 'Next Billing Date', flex: 1 },
        {
            field: 'amountDue',
            headerName: 'Amount Due',
            flex: 0.7,
            renderCell: (params) => `RM${params.value.toFixed(2)}`
        },
        {
            field: 'paymentStatus',
            headerName: 'Payment Status',
            flex: 0.7,
            renderCell: (params) => <PaymentStatusChip status={params.value} />
        },
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <TextField
                    variant="outlined"
                    placeholder="Search by Merchant Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': {
                                height: '34px',
                                padding: '0 14px',
                            },
                            '& .MuiInputBase-input': {
                                height: '34px',
                                padding: '0 14px',
                            }
                        }
                    }}
                />
            </Box>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Billing
                </Typography>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={filteredRows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={70}
                    pageSizeOptions={[5, 10, 20]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 6,
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                            backgroundColor: '#737373',
                            color: '#fff',
                            height: '34px',
                            my: 1,
                            minWidth: 'auto',
                            '&:hover': {
                                backgroundColor: '#404040',
                            },
                        },
                    }}
                />
            </Paper>
        </Box>
    );
};

export default Billing;