import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useKeycloak } from '@react-keycloak/web';
import { fetchProducts } from '../../../redux/productSlice';
import { CompanyLogo } from '../../header/HeaderSvgImages';

const images = [
    "/1.jpeg",
    "/2.jpeg",
    "/3.jpeg",
    "/4.jpeg",
    "/5.jpeg",
    "/6.jpeg",
];

const AddProductToEvent = () => {
    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const products = useSelector((state) => state.products.products);
    const productStatus = useSelector((state) => state.products.status);
    const [searchQuery, setSearchQuery] = useState('');
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const [currentProductId, setCurrentProductId] = useState(null);
    const [addEventDialogOpen, setAddEventDialogOpen] = useState(false);
    const [productToAdd, setProductToAdd] = useState(null);

    useEffect(() => {
        if (initialized && productStatus === 'idle') {
            dispatch(fetchProducts(keycloak.token));
        }
    }, [initialized, productStatus, dispatch, keycloak.token]);

    const handleImageDialogOpen = (productId) => {
        setCurrentProductId(productId);
        setImageDialogOpen(true);
    };

    const handleImageDialogClose = () => {
        setImageDialogOpen(false);
        setCurrentProductId(null);
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image);
        handleImageDialogClose();
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleAddEvent = (productId) => {
        setProductToAdd(productId);
        setAddEventDialogOpen(true);
    };

    const handleAddEventDialogClose = () => {
        setAddEventDialogOpen(false);
        setProductToAdd(null);
    };

    const handleConfirmAdd = () => {
        console.log(`Adding product ${productToAdd} to event`);
        handleAddEventDialogClose();
    };

    const filteredRows = React.useMemo(() => {
        return products.filter((row) =>
            row.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [products, searchQuery]);

    if (!initialized || productStatus === 'idle') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CompanyLogo className="heartbeat" />
            </Box>
        );
    }

    const columns = [
        {
            field: 'Image',
            headerName: '',
            flex: 0.4,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '60px',
                            height: 'auto',
                            borderRadius: '4px',
                            cursor: 'pointer',
                        }}
                        alt="Product Image"
                        src={selectedImage}
                        onClick={() => handleImageDialogOpen(params.row.id)}
                    />
                </Box>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2" noWrap>{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'pricing',
            headerName: 'Pricing',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'created',
            headerName: 'Created Date',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'updated',
            headerName: 'Updated Date',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <Button variant='save' onClick={() => handleAddEvent(params.row.id)}>Add Product</Button>
                    </Box>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" gap="12px">
                    </Box>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Products
                    </Typography>

                    {productStatus === 'loading' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={filteredRows}
                            columns={columns}
                            checkboxSelection
                            disableSelectionOnClick
                            rowHeight={70}
                            pageSizeOptions={[5, 10, 20]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 6,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>

            {/* Image Selection Dialog */}
            <Dialog open={imageDialogOpen} onClose={handleImageDialogClose}>
                <DialogTitle>Select Default Image</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {images.map((image) => (
                            <Box
                                key={image}
                                component="img"
                                src={image}
                                alt="Product option"
                                sx={{
                                    width: '100px',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    border: selectedImage === image ? '2px solid #1976d2' : '1px solid #ccc',
                                }}
                                onClick={() => handleImageSelect(image)}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleImageDialogClose} variant="cancel">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Event Confirmation Dialog */}
            <Dialog
                open={addEventDialogOpen}
                onClose={handleAddEventDialogClose}
            >
                <DialogTitle>Confirm Add Event</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to add this product to the event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddEventDialogClose} variant="cancel">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmAdd} variant="save" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AddProductToEvent;