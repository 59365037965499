import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunks for spaces operations
export const fetchSpaces = createAsyncThunk(
    'spaces/fetchSpaces',
    async (_, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/spaces`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createSpace = createAsyncThunk(
    'spaces/createSpace',
    async (spaceData, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/spaces`, spaceData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchSpaceById = createAsyncThunk(
    'spaces/fetchSpaceById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/spaces/${id}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteSpace = createAsyncThunk(
    'spaces/deleteSpace',
    async (id, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/spaces/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateSpace = createAsyncThunk(
    'spaces/updateSpace',
    async ({ id, spaceData }, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/spaces/${id}`, spaceData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateSpaceStatus = createAsyncThunk(
    'spaces/updateSpaceStatus',
    async ({ id, entityId, statusData }, { rejectWithValue }) => {
        try {
            const headers = getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/${id}/status/${entityId}`, statusData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Spaces slice
const spacesSlice = createSlice({
    name: 'spaces',
    initialState: {
        spaces: [],
        selectedSpace: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {
        // You can add any additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            // Fetch all spaces
            .addCase(fetchSpaces.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSpaces.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.spaces = action.payload;
            })
            .addCase(fetchSpaces.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch spaces';
            })
            // Create space
            .addCase(createSpace.fulfilled, (state, action) => {
                state.spaces.push(action.payload);
            })
            // Fetch space by ID
            .addCase(fetchSpaceById.fulfilled, (state, action) => {
                state.selectedSpace = action.payload;
            })
            // Delete space
            .addCase(deleteSpace.fulfilled, (state, action) => {
                state.spaces = state.spaces.filter(space => space.id !== action.payload);
                if (state.selectedSpace && state.selectedSpace.id === action.payload) {
                    state.selectedSpace = null;
                }
            })
            // Update space
            .addCase(updateSpace.fulfilled, (state, action) => {
                const index = state.spaces.findIndex(space => space.id === action.payload.id);
                if (index !== -1) {
                    state.spaces[index] = action.payload;
                }
                if (state.selectedSpace && state.selectedSpace.id === action.payload.id) {
                    state.selectedSpace = action.payload;
                }
            })
            // Update space status
            .addCase(updateSpaceStatus.fulfilled, (state, action) => {
                const index = state.spaces.findIndex(space => space.id === action.payload.id);
                if (index !== -1) {
                    state.spaces[index] = action.payload;
                }
                if (state.selectedSpace && state.selectedSpace.id === action.payload.id) {
                    state.selectedSpace = action.payload;
                }
            });
    },
});

export default spacesSlice.reducer;